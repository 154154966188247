import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  Email: any;
  JSON: any;
  Password: any;
  PhoneNumber: any;
};

/** 관리자 역할 */
export type AdminRole = {
  __typename?: 'AdminRole';
  /** 교재관리 */
  bookManagement?: Maybe<AdminPermissionEnum>;
  /** 정산관리 */
  calculationManagement?: Maybe<AdminPermissionEnum>;
  /** 채팅상담권한 */
  chatCounselling?: Maybe<Scalars['Boolean']>;
  /** 생성 날짜/시간 */
  createdAt: Scalars['DateTime'];
  /** 삭제 날짜/시간 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** 디자인관리 */
  designManagement?: Maybe<AdminPermissionEnum>;
  /** 문의권한 */
  faq?: Maybe<Scalars['Boolean']>;
  /** 기본 키(UUID) */
  id: Scalars['ID'];
  /** 수업관리 */
  lessonManagement?: Maybe<AdminPermissionEnum>;
  /** 운영관리 */
  operationManagement?: Maybe<AdminPermissionEnum>;
  /** 승인요청관리 */
  requestManagement?: Maybe<AdminPermissionEnum>;
  /** 통계관리 */
  statisticManagement?: Maybe<AdminPermissionEnum>;
  /** 수정 날짜/시간 */
  updatedAt: Scalars['DateTime'];
  /** 사용자관리 */
  userManagement?: Maybe<AdminPermissionEnum>;
  /** 출금관리 */
  withdrawManagement?: Maybe<AdminPermissionEnum>;
};

/** admin role enum */
export type AdminRoleEnumFilter = {
  operator: EnumFilterOperators;
  value?: InputMaybe<AdminPermissionEnum>;
  values?: InputMaybe<Array<AdminPermissionEnum>>;
};

/** admin role input */
export type AdminRoleUpdateInput = {
  /** 교재관리 */
  bookManagement?: InputMaybe<AdminPermissionEnum>;
  /** 정산관리 */
  calculationManagement?: InputMaybe<AdminPermissionEnum>;
  /** 채팅상담권한 */
  chatCounselling?: InputMaybe<Scalars['Boolean']>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  /** 디자인관리 */
  designManagement?: InputMaybe<AdminPermissionEnum>;
  /** 문의권한 */
  faq?: InputMaybe<Scalars['Boolean']>;
  /** 기본 키(UUID) */
  id?: InputMaybe<Scalars['ID']>;
  /** 수업관리 */
  lessonManagement?: InputMaybe<AdminPermissionEnum>;
  /** 운영관리 */
  operationManagement?: InputMaybe<AdminPermissionEnum>;
  /** 승인요청관리 */
  requestManagement?: InputMaybe<AdminPermissionEnum>;
  /** 통계관리 */
  statisticManagement?: InputMaybe<AdminPermissionEnum>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** 사용자관리 */
  userManagement?: InputMaybe<AdminPermissionEnum>;
  /** 출금관리 */
  withdrawManagement?: InputMaybe<AdminPermissionEnum>;
};

/** 모든 제휴 정산 */
export type AllPartnershipCalculate = {
  __typename?: 'AllPartnershipCalculate';
  /** 현재 정산 월 */
  month?: Maybe<Scalars['Float']>;
  /** 월간 정산 확인수 */
  monthCalculateConfirmationCount?: Maybe<Scalars['Float']>;
  /** 월간 정산 미확인수 */
  monthCalculateUnConfirmationCount?: Maybe<Scalars['Float']>;
  /** 월간 정산 제휴자 수 */
  monthCalculateUserCount?: Maybe<Scalars['Float']>;
  /** 현재 정산 월 마감일 */
  monthEndDate?: Maybe<Scalars['DateTime']>;
  /** 월간 누적 지출 */
  monthTotalCalculate?: Maybe<Scalars['Float']>;
  /** 월간 총 제휴가입/결제 자 수 */
  monthTotalUserPaymentCount?: Maybe<Scalars['Float']>;
};

/** 모든 출판사 정산 */
export type AllPublisherCalculate = {
  __typename?: 'AllPublisherCalculate';
  /** 현재 정산 월 */
  month?: Maybe<Scalars['Float']>;
  /** 월간 교재 구매 수 */
  monthBookCount?: Maybe<Scalars['Float']>;
  /** 월간 교재 구매자 수 */
  monthBookUserCount?: Maybe<Scalars['Float']>;
  /** 월간 정산 확인수 */
  monthCalculateConfirmationCount?: Maybe<Scalars['Float']>;
  /** 월간 정산 미확인수 */
  monthCalculateUnConfirmationCount?: Maybe<Scalars['Float']>;
  /** 현재 정산 월 마감일 */
  monthEndDate?: Maybe<Scalars['DateTime']>;
  /** 월간 정산 출판사 수 */
  monthPublisherCount?: Maybe<Scalars['Float']>;
  /** 월간 누적 수수료 */
  monthTotalFee?: Maybe<Scalars['Float']>;
  /** 월간 총 매출 */
  monthTotalSales?: Maybe<Scalars['Float']>;
};

export type AllTeacherCalculate = {
  __typename?: 'AllTeacherCalculate';
  /** 일간 정산 대상 수업 수 */
  dayCalculateLessonCount?: Maybe<Scalars['Float']>;
  /** 일 정산 금액 */
  dayCalculatePrice?: Maybe<Scalars['Float']>;
  /** D + 1 정산 금액 */
  dayPlusOneCalculatePrice?: Maybe<Scalars['Float']>;
  /** D + 2 정산 금액 */
  dayPlusTwoCalculatePrice?: Maybe<Scalars['Float']>;
  /** 월간 정산 대상 수업 수 */
  monthCalculateLessonCount?: Maybe<Scalars['Float']>;
  /** 월간 누적 정산 금액 */
  monthCalculatePrice?: Maybe<Scalars['Float']>;
  /** 월간 누적 수수료 */
  monthCommission?: Maybe<Scalars['Float']>;
  /** 주간 정산 대상 수업 수 */
  weekCalculateLessonCount?: Maybe<Scalars['Float']>;
  /** 주간 누적 정산 금액 */
  weekCalculatePrice?: Maybe<Scalars['Float']>;
  /** 주간 누적 수수료 */
  weekCommission?: Maybe<Scalars['Float']>;
};

/** Apple 토큰 */
export type AppleLoginTokenResponse = {
  __typename?: 'AppleLoginTokenResponse';
  access_token: Scalars['String'];
  expires_in: Scalars['Int'];
  id_token: Scalars['String'];
  refresh_token: Scalars['String'];
  token_type: Scalars['String'];
};

/** 토큰 */
export type AuthToken = {
  __typename?: 'AuthToken';
  /** access token */
  accessToken?: Maybe<Scalars['String']>;
  /** refresh token */
  refreshToken?: Maybe<Scalars['String']>;
  /** two factor token */
  twoFactorToken?: Maybe<Scalars['String']>;
};

export type BankAccount = {
  __typename?: 'BankAccount';
  /** 은행명 */
  bank?: Maybe<Scalars['String']>;
  /** 계좌주 */
  bankAccountOwner?: Maybe<Scalars['String']>;
  /** 계좌번호 */
  bankNumber?: Maybe<Scalars['String']>;
};

export type BankAccountUpdateInput = {
  /** 은행명 */
  bank?: InputMaybe<Scalars['String']>;
  /** 계좌주 */
  bankAccountOwner?: InputMaybe<Scalars['String']>;
  /** 계좌번호 */
  bankNumber?: InputMaybe<Scalars['String']>;
};

export type BankCode = {
  __typename?: 'BankCode';
  code: Scalars['String'];
  /** 생성 날짜/시간 */
  createdAt: Scalars['DateTime'];
  /** 삭제 날짜/시간 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** 기본 키(UUID) */
  id: Scalars['ID'];
  name: Scalars['String'];
  /** 수정 날짜/시간 */
  updatedAt: Scalars['DateTime'];
};

/** 교재 */
export type Book = {
  __typename?: 'Book';
  /** 홍보 문구 */
  advertisingText?: Maybe<Scalars['String']>;
  /** 교재 정답 */
  answerFiles?: Maybe<Array<File>>;
  /** 저자명 */
  author?: Maybe<Scalars['String']>;
  bookCart?: Maybe<Array<BookCart>>;
  /** 교재 즐겨찾기 수 */
  bookmarkNumber?: Maybe<Scalars['Float']>;
  /** 북마크한 유저 */
  bookmarkingUsers?: Maybe<Array<User>>;
  /** 브랜드 */
  brand?: Maybe<Brand>;
  /** 주문 목록 */
  businesses?: Maybe<Array<Business>>;
  /** 카테고리 */
  category?: Maybe<Array<BookCategory>>;
  /** 승인일 */
  completionDate?: Maybe<Scalars['DateTime']>;
  /** 교재 내용 */
  contentFiles?: Maybe<Array<File>>;
  /** 생성 날짜/시간 */
  createdAt: Scalars['DateTime'];
  /** 삭제 날짜/시간 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** 교재 설명 */
  descriptionText?: Maybe<Scalars['String']>;
  /** 할인할 금액 */
  discountPrice?: Maybe<Scalars['Float']>;
  /** 할인률 */
  discountRate?: Maybe<Scalars['Float']>;
  /** 변경 히스토리 */
  history?: Maybe<Array<BookHistory>>;
  /** 기본 키(UUID) */
  id: Scalars['ID'];
  /** 교재 목차 */
  indexFile?: Maybe<File>;
  /** 소개 문구 */
  introductionText?: Maybe<Scalars['String']>;
  /** 즐겨찾기 여부 */
  isBookmarked?: Maybe<Scalars['Boolean']>;
  /** 유저가 구매했는지 여부 */
  isMemberPurchased?: Maybe<Scalars['Boolean']>;
  /** 교재 등록 여부 */
  isRegistered: Scalars['Boolean'];
  /**
   * ISBN10 0-000-00000-0
   * @deprecated 13으로 통일
   */
  isbn10?: Maybe<Scalars['String']>;
  /** ISBN13 000-0-000-00000-0 */
  isbn13?: Maybe<Scalars['String']>;
  /** 로그인한 유저의 business */
  memberBusiness?: Maybe<Business>;
  /** 등록 거절, 보완 메시지 */
  memo?: Maybe<Scalars['String']>;
  /** 교재 제목 */
  name?: Maybe<Scalars['String']>;
  /** 미리보기 파일 */
  previewFile?: Maybe<File>;
  /** 교재 금액 */
  price?: Maybe<Scalars['Float']>;
  /** 처리 관리자 */
  processor?: Maybe<User>;
  /** 출판일자 */
  publicationDate?: Maybe<Scalars['DateTime']>;
  /** 거절일 */
  refusalDate?: Maybe<Scalars['DateTime']>;
  /** 거절 요청 사유 */
  refusalReason?: Maybe<BookRefusalReason>;
  /** 내가 당한 신고 목록 */
  reported?: Maybe<Array<Report>>;
  /** 승인요청일 */
  requestDate?: Maybe<Scalars['DateTime']>;
  /** 개정일자 */
  revisedDate?: Maybe<Scalars['DateTime']>;
  /** 판매정책 */
  salesPolicy?: Maybe<Scalars['String']>;
  /** 정렬 필드 */
  sortField?: Maybe<CommonSortView>;
  /** 교재 상태 */
  state?: Maybe<BookStateType>;
  /** 보완요청일 */
  supplementationDate?: Maybe<Scalars['DateTime']>;
  /** 보완 요청 사유 */
  supplementationReason?: Maybe<BookSupplementationReason>;
  /** 선생님 */
  teacher?: Maybe<Teacher>;
  /** 교재 썸네일 */
  thumbnailFile?: Maybe<File>;
  /** 교제 타입 */
  type: BookTypeEnum;
  /** 수정 날짜/시간 */
  updatedAt: Scalars['DateTime'];
};

export type BookCart = {
  __typename?: 'BookCart';
  book: Book;
  /** 생성 날짜/시간 */
  createdAt: Scalars['DateTime'];
  /** 삭제 날짜/시간 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** 기본 키(UUID) */
  id: Scalars['ID'];
  /** 수정 날짜/시간 */
  updatedAt: Scalars['DateTime'];
  user: User;
};

export type BookCartEdge = {
  __typename?: 'BookCartEdge';
  /** 커서 */
  cursor: Scalars['String'];
  /** 노드 */
  node: BookCart;
};

export type BookCartFilterInput = {
  /** 교재 id */
  book__id?: InputMaybe<Array<IdFilterInput>>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** UUID */
  id?: InputMaybe<Array<IdFilterInput>>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Array<DateTimeFilterInput>>;
};

export type BookCartList = {
  __typename?: 'BookCartList';
  /** 페이지네이션된 데이터 목록 */
  edges: Array<Maybe<BookCartEdge>>;
  /** 페이지네이션된 페이지 정보 */
  pageInfo: PageInfo;
  /** 전체 데이터 개 수 */
  totalCount: Scalars['Int'];
};

export type BookCartOrderByInput = {
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<SortInput>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<SortInput>;
  /** UUID */
  id?: InputMaybe<SortInput>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<SortInput>;
};

/** 카테고리 */
export type BookCategory = {
  __typename?: 'BookCategory';
  /** 생성 날짜/시간 */
  createdAt: Scalars['DateTime'];
  /** 삭제 날짜/시간 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** 기본 키(UUID) */
  id: Scalars['ID'];
  /** 카테고리 이름 */
  name?: Maybe<Scalars['String']>;
  /** 순서 */
  priority?: Maybe<Scalars['Float']>;
  /** 수정 날짜/시간 */
  updatedAt: Scalars['DateTime'];
};

/** 카테고리 생성 */
export type BookCategoryCreateInput = {
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  /** 기본 키(UUID) */
  id?: InputMaybe<Scalars['ID']>;
  /** 카테고리 이름 */
  name?: InputMaybe<Scalars['String']>;
  /** 상위 카테고리 아이디 */
  parent__id?: InputMaybe<Scalars['ID']>;
  /** 순서 */
  priority?: InputMaybe<Scalars['Float']>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type BookCategoryEdge = {
  __typename?: 'BookCategoryEdge';
  /** 커서 */
  cursor: Scalars['String'];
  /** 노드 */
  node: BookCategory;
};

/** 카테고리 업데이트 */
export type BookCategoryUpdateInput = {
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  /** 기본 키(UUID) */
  id?: InputMaybe<Scalars['ID']>;
  /** 카테고리 이름 */
  name?: InputMaybe<Scalars['String']>;
  /** 순서 */
  priority?: InputMaybe<Scalars['Float']>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

/** 교재 등록 생성 */
export type BookCreateInput = {
  /** 홍보 문구 */
  advertisingText?: InputMaybe<Scalars['String']>;
  /** 정답 파일 */
  answerFile__ids?: InputMaybe<Array<Scalars['ID']>>;
  /** 저자명 */
  author?: InputMaybe<Scalars['String']>;
  /** 브랜드명 */
  brand__id?: InputMaybe<Scalars['ID']>;
  /** 카테고리 */
  category__id?: InputMaybe<Scalars['ID']>;
  /** 승인일 */
  completionDate?: InputMaybe<Scalars['DateTime']>;
  /** 콘텐츠 파일 */
  contentFile__ids?: InputMaybe<Array<Scalars['ID']>>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  /** 교재 설명 */
  descriptionText?: InputMaybe<Scalars['String']>;
  /** 할인할 금액 */
  discountPrice?: InputMaybe<Scalars['Float']>;
  /** 할인률 */
  discountRate?: InputMaybe<Scalars['Float']>;
  /** 기본 키(UUID) */
  id?: InputMaybe<Scalars['ID']>;
  /** 목차 파일 */
  indexFile__id?: InputMaybe<Scalars['ID']>;
  /** 소개 문구 */
  introductionText?: InputMaybe<Scalars['String']>;
  /** ISBN10 0-000-00000-0 */
  isbn10?: InputMaybe<Scalars['String']>;
  /** ISBN13 000-0-000-00000-0 */
  isbn13?: InputMaybe<Scalars['String']>;
  /** 등록 거절, 보완 메시지 */
  memo?: InputMaybe<Scalars['String']>;
  /** 교재 제목 */
  name?: InputMaybe<Scalars['String']>;
  /** 미리보기 파일 */
  previewFile__id?: InputMaybe<Scalars['ID']>;
  /** 교재 금액 */
  price?: InputMaybe<Scalars['Float']>;
  /** 출판일자 */
  publicationDate?: InputMaybe<Scalars['DateTime']>;
  /** 거절일 */
  refusalDate?: InputMaybe<Scalars['DateTime']>;
  /** 거절 요청 사유 */
  refusalReason?: InputMaybe<BookRefusalReason>;
  /** 승인요청일 */
  requestDate?: InputMaybe<Scalars['DateTime']>;
  /** 개정일자 */
  revisedDate?: InputMaybe<Scalars['DateTime']>;
  /** 판매정책 */
  salesPolicy?: InputMaybe<Scalars['String']>;
  /** 교재 상태 */
  state?: InputMaybe<BookStateType>;
  /** 보완요청일 */
  supplementationDate?: InputMaybe<Scalars['DateTime']>;
  /** 보완 요청 사유 */
  supplementationReason?: InputMaybe<BookSupplementationReason>;
  /** teacher id */
  teacher__id?: InputMaybe<Scalars['ID']>;
  /** 썸네일 파일 */
  thumbnailFile__id?: InputMaybe<Scalars['ID']>;
  /** 교제 타입 */
  type?: InputMaybe<BookTypeEnum>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type BookEdge = {
  __typename?: 'BookEdge';
  /** 커서 */
  cursor: Scalars['String'];
  /** 노드 */
  node: Book;
};

/** 교재 필터 */
export type BookFilterInput = {
  /** 홍보 문구 */
  advertisingText?: InputMaybe<Array<StringFilterInput>>;
  /** 저자명 */
  author?: InputMaybe<Array<StringFilterInput>>;
  /** 브랜드 id */
  brand__id?: InputMaybe<Array<IdFilterInput>>;
  /** 브랜드 이름 */
  brand__name?: InputMaybe<Array<StringFilterInput>>;
  /** 카테고리 코드 */
  category__code?: InputMaybe<Array<StringFilterInput>>;
  /** 카테고리 id */
  category__id?: InputMaybe<Array<IdFilterInput>>;
  /** 카테고리 이름 */
  category__name?: InputMaybe<Array<StringFilterInput>>;
  /** 승인일 */
  completionDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 교재 설명 */
  descriptionTextOfPlainText?: InputMaybe<Array<StringFilterInput>>;
  /** UUID */
  id?: InputMaybe<Array<IdFilterInput>>;
  /** 소개 문구 */
  introductionText?: InputMaybe<Array<StringFilterInput>>;
  /** 교재 이름 */
  name?: InputMaybe<Array<StringFilterInput>>;
  /** 처리 관리자 id */
  processor__id?: InputMaybe<Array<IdFilterInput>>;
  /** 출판일자 */
  publicationDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 출판사아이디 */
  publisher__id?: InputMaybe<Array<IdFilterInput>>;
  /** 출판사명 */
  publisher__name?: InputMaybe<Array<StringFilterInput>>;
  /** 거절일 */
  refusalDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 거절 요청 사유 */
  refusalReason?: InputMaybe<Array<BookRefusalReasonEnumFilter>>;
  /** 승인요청일 */
  requestDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 리뷰수 */
  reviewCount?: InputMaybe<Array<StringFilterInput>>;
  /** 개정일자 */
  revisedDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 책 상태 */
  state?: InputMaybe<Array<BookStateEnumFilter>>;
  /** 보완요청일 */
  supplementationDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 보완 요청 사유 */
  supplementationReason?: InputMaybe<Array<BookSupplementationReasonEnumFilter>>;
  /** 선생님 id */
  teacher__id?: InputMaybe<Array<IdFilterInput>>;
  /** 교재 상태 */
  type?: InputMaybe<Array<BookTypeEnumFilter>>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Array<DateTimeFilterInput>>;
};

/** 교재 변경 히스토리 */
export type BookHistory = {
  __typename?: 'BookHistory';
  /** 바뀐 값 */
  changedValue?: Maybe<Scalars['String']>;
  /** 생성 날짜/시간 */
  createdAt: Scalars['DateTime'];
  /** 삭제 날짜/시간 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** 바뀐 필드 */
  field?: Maybe<Scalars['String']>;
  /** 기본 키(UUID) */
  id: Scalars['ID'];
  /** 이전 값 */
  previousValue?: Maybe<Scalars['String']>;
  /** 수정 날짜/시간 */
  updatedAt: Scalars['DateTime'];
};

export type BookHistoryEdge = {
  __typename?: 'BookHistoryEdge';
  /** 커서 */
  cursor: Scalars['String'];
  /** 노드 */
  node: BookHistory;
};

/** 교재 목록 */
export type BookList = {
  __typename?: 'BookList';
  /** 페이지네이션된 데이터 목록 */
  edges: Array<Maybe<BookEdge>>;
  /** 페이지네이션된 페이지 정보 */
  pageInfo: PageInfo;
  /** 전체 데이터 개 수 */
  totalCount: Scalars['Int'];
};

/** 교재 정렬 */
export type BookOrderByInput = {
  /** 저자명 */
  author?: InputMaybe<SortInput>;
  /** 즐겨찾기순 */
  bookSort__bookmarkCount?: InputMaybe<SortInput>;
  /** 구매순 */
  bookSort__businessCount?: InputMaybe<SortInput>;
  /** 평점순(평점 평균) */
  bookSort__gpaAvg?: InputMaybe<SortInput>;
  /** 리뷰순(리뷰수) */
  bookSort__gpaCount?: InputMaybe<SortInput>;
  /** 인기순 */
  bookSort__popular?: InputMaybe<SortInput>;
  /** 브랜드 이름 */
  brand__name?: InputMaybe<SortInput>;
  /** 카테고리 코드 */
  category__code?: InputMaybe<SortInput>;
  /** 카테고리 이름 */
  category__name?: InputMaybe<SortInput>;
  /** 승인일 */
  completionDate?: InputMaybe<SortInput>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<SortInput>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<SortInput>;
  /** UUID */
  id?: InputMaybe<SortInput>;
  /** 교재명 */
  name?: InputMaybe<SortInput>;
  /** 처리관리자 이메일 */
  processor__email?: InputMaybe<SortInput>;
  /** 출판일자 */
  publicationDate?: InputMaybe<SortInput>;
  /** 출판사명 */
  publisher__name?: InputMaybe<SortInput>;
  /** 거절일 */
  refusalDate?: InputMaybe<SortInput>;
  /** 거절 요청 사유 */
  refusalReason?: InputMaybe<SortInput>;
  /** 승인요청일 */
  requestDate?: InputMaybe<SortInput>;
  /** 리뷰수 */
  reviewCount?: InputMaybe<SortInput>;
  /** 개정일자 */
  revisedDate?: InputMaybe<SortInput>;
  /** 교재 상태 */
  state?: InputMaybe<BookStateSortInput>;
  /** 보완요청일 */
  supplementationDate?: InputMaybe<SortInput>;
  /** 보완 요청 사유 */
  supplementationReason?: InputMaybe<SortInput>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<SortInput>;
};

/** 교재 결제 생성 */
export type BookOrderCreateInput = {
  /** 교재 ids */
  book__ids: Array<Scalars['ID']>;
  /** 사용할 캐시(2000원 이상부터 사용 가능) */
  cash: Scalars['Float'];
};

export enum BookRefusalReason {
  /** 사진 화질 */
  PhotoQuality = 'PHOTO_QUALITY'
}

/** 거절 사유 */
export type BookRefusalReasonEnumFilter = {
  operator: EnumFilterOperators;
  value?: InputMaybe<BookSupplementationReason>;
  values?: InputMaybe<Array<BookSupplementationReason>>;
};

export type BookRegistration = {
  __typename?: 'BookRegistration';
  /** 승인일 */
  approvedAt?: Maybe<Scalars['DateTime']>;
  /** book */
  book: Book;
  /** isbn */
  bookISBN: Scalars['String'];
  /** 교재명 */
  bookName: Scalars['String'];
  /** 생성 날짜/시간 */
  createdAt: Scalars['DateTime'];
  /** 삭제 날짜/시간 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** 기본 키(UUID) */
  id: Scalars['ID'];
  /** 거절일 */
  rejectedAt?: Maybe<Scalars['DateTime']>;
  /** 거절 사유 */
  rejectedReason?: Maybe<Scalars['String']>;
  state: BookRegistrationStateEnum;
  /** 수정 날짜/시간 */
  updatedAt: Scalars['DateTime'];
  /** user */
  user: User;
  /** video */
  video: File;
};

export type BookRegistrationCreateInput = {
  /** isbn */
  bookISBN: Scalars['String'];
  /** 교재명 */
  bookName: Scalars['String'];
  /** book id */
  book__id: Scalars['ID'];
  /** video id */
  video__id: Scalars['ID'];
};

export type BookRegistrationEdge = {
  __typename?: 'BookRegistrationEdge';
  /** 커서 */
  cursor: Scalars['String'];
  /** 노드 */
  node: BookRegistration;
};

export type BookRegistrationFilterInput = {
  /** 승인일 */
  approvedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** UUID */
  id?: InputMaybe<Array<IdFilterInput>>;
  /** 거절일 */
  rejectedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 거절 사유 */
  rejectedReason?: InputMaybe<Array<StringFilterInput>>;
  /** 상태 */
  state?: InputMaybe<Array<BookRegistrationStateEnumFilter>>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 사용자 이메일 */
  user__email?: InputMaybe<Array<StringFilterInput>>;
  /** 사용자 id */
  user__id?: InputMaybe<Array<IdFilterInput>>;
  /** 사용자 이름 */
  user__name?: InputMaybe<Array<StringFilterInput>>;
};

export type BookRegistrationList = {
  __typename?: 'BookRegistrationList';
  /** 페이지네이션된 데이터 목록 */
  edges: Array<Maybe<BookRegistrationEdge>>;
  /** 페이지네이션된 페이지 정보 */
  pageInfo: PageInfo;
  /** 전체 데이터 개 수 */
  totalCount: Scalars['Int'];
};

export type BookRegistrationSortInput = {
  /** 승인일 */
  approvedAt?: InputMaybe<SortInput>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<SortInput>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<SortInput>;
  /** UUID */
  id?: InputMaybe<SortInput>;
  /** 거절일 */
  rejectedAt?: InputMaybe<SortInput>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<SortInput>;
  /** 회원가입일 */
  user__joinDate?: InputMaybe<SortInput>;
};

export enum BookRegistrationStateEnum {
  /** 승인 */
  Approved = 'APPROVED',
  /** 거절 */
  Rejected = 'REJECTED',
  /** 대기 */
  Waiting = 'WAITING'
}

export type BookRegistrationStateEnumFilter = {
  operator: EnumFilterOperators;
  value?: InputMaybe<BookRegistrationStateEnum>;
  values?: InputMaybe<Array<BookRegistrationStateEnum>>;
};

/** 구입한 책 리뷰 */
export type BookReview = {
  __typename?: 'BookReview';
  /** 리뷰 작성 시간 */
  bookReviewWriteDate?: Maybe<Scalars['DateTime']>;
  /** 평점 */
  bookgpa?: Maybe<Scalars['Float']>;
  /** 리뷰 내용 */
  bookgpaContent?: Maybe<Scalars['String']>;
  /** 생성 날짜/시간 */
  createdAt: Scalars['DateTime'];
  /** 삭제 날짜/시간 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** 기본 키(UUID) */
  id: Scalars['ID'];
  /** 교재 리뷰 노출 여부 */
  isBookGPAVisible?: Maybe<Scalars['Boolean']>;
  /** 수정 날짜/시간 */
  updatedAt: Scalars['DateTime'];
};

/** 구입한 책 생성 */
export type BookReviewCreateInput = {
  /** 리뷰 작성 시간 */
  bookReviewWriteDate?: InputMaybe<Scalars['DateTime']>;
  /** 평점 */
  bookgpa?: InputMaybe<Scalars['Float']>;
  /** 리뷰 내용 */
  bookgpaContent?: InputMaybe<Scalars['String']>;
  /** 수업 */
  business__id?: InputMaybe<Scalars['ID']>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  /** 기본 키(UUID) */
  id?: InputMaybe<Scalars['ID']>;
  /** 교재 리뷰 노출 여부 */
  isBookGPAVisible?: InputMaybe<Scalars['Boolean']>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type BookReviewEdge = {
  __typename?: 'BookReviewEdge';
  /** 커서 */
  cursor: Scalars['String'];
  /** 노드 */
  node: BookReview;
};

/** 구입한 책 필터 */
export type BookReviewFilterInput = {
  /** 리뷰 작성 시간 */
  bookReviewWriteDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 평점 */
  bookgpa?: InputMaybe<Array<FloatFilterInput>>;
  /** 리뷰 내용 */
  bookgpaContent?: InputMaybe<Array<StringFilterInput>>;
  /** 비지니스아이디 */
  business__id?: InputMaybe<Array<IdFilterInput>>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** UUID */
  id?: InputMaybe<Array<IdFilterInput>>;
  /** 교재 리뷰 노출 여부 */
  isBookGPAVisible?: InputMaybe<Array<BooleanFilterInput>>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Array<DateTimeFilterInput>>;
};

/** 구입한 책 리뷰 목록 */
export type BookReviewList = {
  __typename?: 'BookReviewList';
  /** 페이지네이션된 데이터 목록 */
  edges: Array<Maybe<BookReviewEdge>>;
  /** 페이지네이션된 페이지 정보 */
  pageInfo: PageInfo;
  /** 전체 데이터 개 수 */
  totalCount: Scalars['Int'];
};

/** 구입한 책 정렬 */
export type BookReviewOrderByInput = {
  /** 리뷰 작성 시간 */
  bookReviewWriteDate?: InputMaybe<SortInput>;
  /** 평점 */
  bookgpa?: InputMaybe<SortInput>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<SortInput>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<SortInput>;
  /** UUID */
  id?: InputMaybe<SortInput>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<SortInput>;
};

/** 구입한 책 업데이트 */
export type BookReviewUpdateInput = {
  /** 리뷰 작성 시간 */
  bookReviewWriteDate?: InputMaybe<Scalars['DateTime']>;
  /** 평점 */
  bookgpa?: InputMaybe<Scalars['Float']>;
  /** 리뷰 내용 */
  bookgpaContent?: InputMaybe<Scalars['String']>;
  /** 수업 */
  business__id?: InputMaybe<Scalars['ID']>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  /** 기본 키(UUID) */
  id?: InputMaybe<Scalars['ID']>;
  /** 교재 리뷰 노출 여부 */
  isBookGPAVisible?: InputMaybe<Scalars['Boolean']>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export enum BookSaleState {
  /** 판매중 */
  Continued = 'CONTINUED',
  /** 판매 중지 */
  Discontinued = 'DISCONTINUED'
}

/** 교재 판매 대시보드 */
export type BookSalesDashboard = {
  __typename?: 'BookSalesDashboard';
  /** 누적 금액 */
  cumulativeSales?: Maybe<Scalars['Float']>;
  /** 일간 누적 판매 수량 */
  dailySalesVolume?: Maybe<Scalars['Float']>;
  /** 월간 누적 판매 수량 */
  monthlySalesVolume?: Maybe<Scalars['Float']>;
  /** 주간 누적 판매 수량 */
  weeklySalesVolume?: Maybe<Scalars['Float']>;
};

/** 교재 판매 상태 */
export type BookStateEnumFilter = {
  operator: EnumFilterOperators;
  value?: InputMaybe<BookStateType>;
  values?: InputMaybe<Array<BookStateType>>;
};

/** 상태 정렬 */
export type BookStateSortInput = {
  case?: InputMaybe<Array<BookStateType>>;
  nulls?: InputMaybe<Nulls>;
  order: Order;
};

/** 교재 승인 상태 변경 */
export type BookStateUpdateInput = {
  /** 등록 거절, 보완 메시지 */
  memo?: InputMaybe<Scalars['String']>;
  /** 거절 요청 사유 */
  refusalReason?: InputMaybe<BookRefusalReason>;
  /** 교재 상태 */
  state?: InputMaybe<BookStateType>;
  /** 보완 요청 사유 */
  supplementationReason?: InputMaybe<BookSupplementationReason>;
};

export enum BookSupplementationReason {
  /** 사진 화질 */
  PhotoQuality = 'PHOTO_QUALITY'
}

/** 보완 요청 사유 */
export type BookSupplementationReasonEnumFilter = {
  operator: EnumFilterOperators;
  value?: InputMaybe<BookSupplementationReason>;
  values?: InputMaybe<Array<BookSupplementationReason>>;
};

export enum BookTypeEnum {
  /** e-book/책자 판매 + 구매 인증 */
  Type1 = 'TYPE1',
  /** e-book 팬매 + 구매 인증 */
  Type2 = 'TYPE2',
  /** ebook/책자 판매 */
  Type3 = 'TYPE3',
  /** 구매 인증 */
  Type4 = 'TYPE4',
  /** e-book 판매 */
  Type5 = 'TYPE5'
}

export type BookTypeEnumFilter = {
  operator: EnumFilterOperators;
  value?: InputMaybe<BookTypeEnum>;
  values?: InputMaybe<Array<BookTypeEnum>>;
};

/** 교재 수정 */
export type BookUpdateInput = {
  /** 홍보 문구 */
  advertisingText?: InputMaybe<Scalars['String']>;
  /** 정답 파일 */
  answerFile__ids?: InputMaybe<Array<Scalars['ID']>>;
  /** 저자명 */
  author?: InputMaybe<Scalars['String']>;
  /** 브랜드명 */
  brand__id?: InputMaybe<Scalars['ID']>;
  /** 카테고리 */
  category__id?: InputMaybe<Scalars['ID']>;
  /** 승인일 */
  completionDate?: InputMaybe<Scalars['DateTime']>;
  /** 콘텐츠 파일 */
  contentFile__ids?: InputMaybe<Array<Scalars['ID']>>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  /** 교재 설명 */
  descriptionText?: InputMaybe<Scalars['String']>;
  /** 할인할 금액 */
  discountPrice?: InputMaybe<Scalars['Float']>;
  /** 할인률 */
  discountRate?: InputMaybe<Scalars['Float']>;
  /** 기본 키(UUID) */
  id?: InputMaybe<Scalars['ID']>;
  /** 목차 파일 */
  indexFile__id?: InputMaybe<Scalars['ID']>;
  /** 소개 문구 */
  introductionText?: InputMaybe<Scalars['String']>;
  /** ISBN10 0-000-00000-0 */
  isbn10?: InputMaybe<Scalars['String']>;
  /** ISBN13 000-0-000-00000-0 */
  isbn13?: InputMaybe<Scalars['String']>;
  /** 등록 거절, 보완 메시지 */
  memo?: InputMaybe<Scalars['String']>;
  /** 교재 제목 */
  name?: InputMaybe<Scalars['String']>;
  /** 미리보기 파일 */
  previewFile__id?: InputMaybe<Scalars['ID']>;
  /** 교재 금액 */
  price?: InputMaybe<Scalars['Float']>;
  /** 출판일자 */
  publicationDate?: InputMaybe<Scalars['DateTime']>;
  /** 거절일 */
  refusalDate?: InputMaybe<Scalars['DateTime']>;
  /** 거절 요청 사유 */
  refusalReason?: InputMaybe<BookRefusalReason>;
  /** 승인요청일 */
  requestDate?: InputMaybe<Scalars['DateTime']>;
  /** 개정일자 */
  revisedDate?: InputMaybe<Scalars['DateTime']>;
  /** 판매정책 */
  salesPolicy?: InputMaybe<Scalars['String']>;
  /** 교재 상태 */
  state?: InputMaybe<BookStateType>;
  /** 보완요청일 */
  supplementationDate?: InputMaybe<Scalars['DateTime']>;
  /** 보완 요청 사유 */
  supplementationReason?: InputMaybe<BookSupplementationReason>;
  /** teacher id */
  teacher__id?: InputMaybe<Scalars['ID']>;
  /** 썸네일 파일 */
  thumbnailFile__id?: InputMaybe<Scalars['ID']>;
  /** 교제 타입 */
  type?: InputMaybe<BookTypeEnum>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

/** 논리(Boolean) 필터 */
export type BooleanFilterInput = {
  operator: BooleanFilterOperators;
  value?: InputMaybe<Scalars['Boolean']>;
};

/** 논리 필터 연산자 */
export enum BooleanFilterOperators {
  Equal = 'EQUAL',
  IsNotNull = 'IS_NOT_NULL',
  IsNull = 'IS_NULL',
  NotEqual = 'NOT_EQUAL'
}

/** 브랜드 */
export type Brand = {
  __typename?: 'Brand';
  /** 첨부파일 */
  attachment?: Maybe<File>;
  /** 교재 목록 */
  bookList?: Maybe<Array<Book>>;
  /** 승인완료일 */
  completionDate?: Maybe<Scalars['DateTime']>;
  /** 생성 날짜/시간 */
  createdAt: Scalars['DateTime'];
  /** 삭제 날짜/시간 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** 기본 키(UUID) */
  id: Scalars['ID'];
  /** 등록 거절, 보완 메시지 */
  memo?: Maybe<BrandMemoEnum>;
  /** 브랜드 이름 */
  name?: Maybe<Scalars['String']>;
  /** 처리 관리자 */
  processor?: Maybe<User>;
  /** 출판사 */
  publisher?: Maybe<Publisher>;
  /** 승인요청일 */
  requestDate?: Maybe<Scalars['DateTime']>;
  /** 브랜드 상태 */
  state?: Maybe<BrandStateType>;
  /** 수정 날짜/시간 */
  updatedAt: Scalars['DateTime'];
};

/** 브랜드 생성 */
export type BrandCreateInput = {
  /** 첨부파일 */
  attachment__id?: InputMaybe<Scalars['ID']>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  /** 기본 키(UUID) */
  id?: InputMaybe<Scalars['ID']>;
  /** 등록 거절, 보완 메시지 */
  memo?: InputMaybe<BrandMemoEnum>;
  /** 브랜드 이름 */
  name?: InputMaybe<Scalars['String']>;
  /** 출판사 id */
  publisher__id?: InputMaybe<Scalars['ID']>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type BrandEdge = {
  __typename?: 'BrandEdge';
  /** 커서 */
  cursor: Scalars['String'];
  /** 노드 */
  node: Brand;
};

/** 브랜드 필터 */
export type BrandFilterInput = {
  /** 승인완료일 */
  completionDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** UUID */
  id?: InputMaybe<Array<IdFilterInput>>;
  /** 등록 거절, 보완 메시지 */
  memo?: InputMaybe<Array<BrandMemoEnumFilterInput>>;
  /** 브랜드 이름 */
  name?: InputMaybe<Array<StringFilterInput>>;
  /** 관리자 이메일 */
  processor__email?: InputMaybe<Array<StringFilterInput>>;
  /** 관리자 아이디 */
  processor__id?: InputMaybe<Array<IdFilterInput>>;
  /** 관리자 이름 */
  processor__name?: InputMaybe<Array<StringFilterInput>>;
  /** 정산 계좌명 */
  publisher__accountName?: InputMaybe<Array<StringFilterInput>>;
  /** 계좌번호 */
  publisher__accountNumber?: InputMaybe<Array<StringFilterInput>>;
  /** 정산 은행 */
  publisher__bankName?: InputMaybe<Array<StringFilterInput>>;
  /** 회원가입일 */
  publisher__createdAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 출판사 */
  publisher__id?: InputMaybe<Array<IdFilterInput>>;
  /** 최근 로그인 */
  publisher__lastLogin?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 담당자 이메일 */
  publisher__managerEmail?: InputMaybe<Array<StringFilterInput>>;
  /** 담당자 이름 */
  publisher__managerName?: InputMaybe<Array<StringFilterInput>>;
  /** 담당자 전화번호 */
  publisher__managerPhone?: InputMaybe<Array<StringFilterInput>>;
  /** 등록 거절, 보완 메시지 */
  publisher__memo?: InputMaybe<Array<StringFilterInput>>;
  /** 출판사 이름 */
  publisher__name?: InputMaybe<Array<StringFilterInput>>;
  /** 등록 상태(대기, 승인, 거절, 보완) */
  publisher__registrationState?: InputMaybe<Array<PublisherRegistrationStateEnumFilter>>;
  /** 대표자 이메일 */
  publisher__representativeEmail?: InputMaybe<Array<StringFilterInput>>;
  /** 대표자 이름 */
  publisher__representativeName?: InputMaybe<Array<StringFilterInput>>;
  /** 대표자 전화번호 */
  publisher__representativePhone?: InputMaybe<Array<StringFilterInput>>;
  /** 정산 상태 */
  publisher__settlementState?: InputMaybe<Array<PublisherSettlementStateEnumFilter>>;
  /** 출판사 상태 */
  publisher__state?: InputMaybe<Array<PublisherStateEnumFilter>>;
  /** 승인요청일 */
  requestDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 브랜드 상태 */
  state?: InputMaybe<Array<BrandStateEnumFilter>>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Array<DateTimeFilterInput>>;
};

/** 브랜드 목록 */
export type BrandList = {
  __typename?: 'BrandList';
  /** 페이지네이션된 데이터 목록 */
  edges: Array<Maybe<BrandEdge>>;
  /** 페이지네이션된 페이지 정보 */
  pageInfo: PageInfo;
  /** 전체 데이터 개 수 */
  totalCount: Scalars['Int'];
};

export enum BrandMemoEnum {
  /** 이미 존재하는 브랜드 */
  AlreadyExists = 'ALREADY_EXISTS',
  /** 사업에 문제가 있는 브랜드 */
  BusinessProblem = 'BUSINESS_PROBLEM',
  /** 명칭 문제(욕설, 부적절한 단어 등) */
  NamingProblem = 'NAMING_PROBLEM',
  /** 상표권권 문제 */
  TrademarkProblem = 'TRADEMARK_PROBLEM'
}

/** 브랜드 거절, 보완 메시지 */
export type BrandMemoEnumFilterInput = {
  operator: EnumFilterOperators;
  value?: InputMaybe<BrandMemoEnum>;
  values?: InputMaybe<Array<BrandMemoEnum>>;
};

/** 브랜드 정렬 */
export type BrandOrderByInput = {
  /** 평점순 */
  accumulategpa?: InputMaybe<SortInput>;
  /** 즐겨찾기순 */
  bookmarksCount?: InputMaybe<SortInput>;
  /** 승인완료일 */
  completionDate?: InputMaybe<SortInput>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<SortInput>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<SortInput>;
  /** UUID */
  id?: InputMaybe<SortInput>;
  /** 브랜드명 */
  name?: InputMaybe<SortInput>;
  /** 인기순 */
  popularityCount?: InputMaybe<SortInput>;
  /** 회원가입일 */
  publisher__createdAt?: InputMaybe<SortInput>;
  /** 최근 로그인 */
  publisher__lastLogin?: InputMaybe<SortInput>;
  /** 출판사명 */
  publisher__name?: InputMaybe<SortInput>;
  /** 승인요청일 */
  requestDate?: InputMaybe<SortInput>;
  /** 리뷰순 */
  reviewsCount?: InputMaybe<SortInput>;
  /** 판매순 */
  salesCount?: InputMaybe<SortInput>;
  /** 상태(필드용) */
  state?: InputMaybe<BrandStateSortInput>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<SortInput>;
};

/** 브랜드 상태 */
export type BrandStateEnumFilter = {
  operator: EnumFilterOperators;
  value?: InputMaybe<BrandStateType>;
  values?: InputMaybe<Array<BrandStateType>>;
};

/** 브랜드 상태 정렬 */
export type BrandStateSortInput = {
  case?: InputMaybe<Array<BrandStateType>>;
  nulls?: InputMaybe<Nulls>;
  order: Order;
};

/** 브랜드 업데이트 */
export type BrandUpdateInput = {
  /** 첨부파일 */
  attachment__id?: InputMaybe<Scalars['ID']>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  /** 기본 키(UUID) */
  id?: InputMaybe<Scalars['ID']>;
  /** 등록 거절, 보완 메시지 */
  memo?: InputMaybe<BrandMemoEnum>;
  /** 브랜드 이름 */
  name?: InputMaybe<Scalars['String']>;
  /** 출판사 id */
  publisher__id?: InputMaybe<Scalars['ID']>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export enum BusinessAdminCancellationReason {
  /** 직접 입력 */
  DirectInput = 'DIRECT_INPUT',
  /** 변심 */
  MindChange = 'MIND_CHANGE'
}

/** 사유 */
export type BusinessCancellationReasonEnumFilter = {
  operator: EnumFilterOperators;
  value?: InputMaybe<BusinessAdminCancellationReason>;
  values?: InputMaybe<Array<BusinessAdminCancellationReason>>;
};

/** 주문 생성 */
export type BusinessCreateInput = {
  /** 교재 열람일 */
  bookReadDate?: InputMaybe<Scalars['DateTime']>;
  /** 리뷰 정보 */
  bookReview__id?: InputMaybe<Scalars['ID']>;
  /** 교재 ID */
  book__id?: InputMaybe<Scalars['ID']>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  /** 기본 키(UUID) */
  id?: InputMaybe<Scalars['ID']>;
  /** 소득공제 여부 */
  isDeducted?: InputMaybe<Scalars['Boolean']>;
  /** 수업 옵션 */
  lessonPrice__id?: InputMaybe<Scalars['ID']>;
  /** 수업ID */
  lesson__id?: InputMaybe<Scalars['ID']>;
  /** 주문확정일 */
  orderConfirmedDate?: InputMaybe<Scalars['DateTime']>;
  /** 주문 정보 */
  orderInfo__id?: InputMaybe<Scalars['ID']>;
  /** 주문 코드 */
  payment__id?: InputMaybe<Scalars['ID']>;
  /** 취소/환불 정보 */
  refundHistory__id?: InputMaybe<Scalars['ID']>;
  /** 주문 상태 */
  state?: InputMaybe<OrderState>;
  /** 주문 타입 */
  type?: InputMaybe<OrderType>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** 사용한 캐시 합 */
  usedAllCash?: InputMaybe<Scalars['Float']>;
  /** 사용한 현금성 캐시 */
  usedMainCash?: InputMaybe<Scalars['Float']>;
  /** 사용한 비현금성 캐시 */
  usedSubCash?: InputMaybe<Scalars['Float']>;
  /** 사용자 id */
  user__id?: InputMaybe<Scalars['ID']>;
};

export type BusinessEdge = {
  __typename?: 'BusinessEdge';
  /** 커서 */
  cursor: Scalars['String'];
  /** 노드 */
  node: Business;
};

/** 주문 필터 */
export type BusinessFilterInput = {
  /** 교재 카테고리 id */
  bookCategory__id?: InputMaybe<Array<IdFilterInput>>;
  /** 교재 카테고리 이름 */
  bookCategory__name?: InputMaybe<Array<StringFilterInput>>;
  /** 교재 열람일 */
  bookReadDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 평점 */
  bookReview__bookgpa?: InputMaybe<Array<FloatFilterInput>>;
  /** 리뷰 내용 */
  bookReview__bookgpaContent?: InputMaybe<Array<StringFilterInput>>;
  /** 교재 리뷰 노출 여부 */
  bookReview__isBookGPAVisible?: InputMaybe<Array<BooleanFilterInput>>;
  /** 책 저자명 */
  book__author?: InputMaybe<Array<StringFilterInput>>;
  /** 교재 id */
  book__id?: InputMaybe<Array<IdFilterInput>>;
  /** 교재 이름 */
  book__name?: InputMaybe<Array<StringFilterInput>>;
  /** 브랜드 id */
  brand__id?: InputMaybe<Array<IdFilterInput>>;
  /** 브랜드명 */
  brand__name?: InputMaybe<Array<StringFilterInput>>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** UUID */
  id?: InputMaybe<Array<IdFilterInput>>;
  /** 소득공제 여부 */
  isDeducted?: InputMaybe<Array<BooleanFilterInput>>;
  /** 수업 카테고리 */
  lesson__category?: InputMaybe<Array<StringFilterInput>>;
  /** 수업 id */
  lesson__id?: InputMaybe<Array<IdFilterInput>>;
  /** 수업명 */
  lesson__lessonName?: InputMaybe<Array<StringFilterInput>>;
  /** 주문확정일 */
  orderConfirmedDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 할인 금액 */
  orderInfo__discountPrice?: InputMaybe<Array<FloatFilterInput>>;
  /** 할인률 */
  orderInfo__discountRate?: InputMaybe<Array<FloatFilterInput>>;
  /** 구매 금액 */
  orderInfo__price?: InputMaybe<Array<IntFilterInput>>;
  /** 할인전 금액 */
  orderInfo__totalOriginalPrice?: InputMaybe<Array<FloatFilterInput>>;
  /** 주문 번호 */
  payment__orderNumber?: InputMaybe<Array<StringFilterInput>>;
  /** 구매일 */
  payment__paidDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 정산id */
  publisherCalculate__id?: InputMaybe<Array<IdFilterInput>>;
  /** 출판사 ID */
  publisher__id?: InputMaybe<Array<IdFilterInput>>;
  /** 출판사 이름 */
  publisher__name?: InputMaybe<Array<StringFilterInput>>;
  /** 취소/환불 사유 */
  refundHiestory__reason?: InputMaybe<Array<BusinessCancellationReasonEnumFilter>>;
  /** 취소/환불 처리일 */
  refundHistory__CompletionDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 취소/환불 요청일 */
  refundHistory__createdAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 관리자 이메일 */
  refundHistory__processor__email?: InputMaybe<Array<StringFilterInput>>;
  /** 주문 상태 */
  state?: InputMaybe<Array<BusinessStateEnumFilter>>;
  /** 선생님명 */
  teacherUser__name?: InputMaybe<Array<StringFilterInput>>;
  /** 선생님아이디 */
  teacher__id?: InputMaybe<Array<IdFilterInput>>;
  /** 상품 타입 */
  type?: InputMaybe<Array<OrderTypeEnumFilterInput>>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 사용한 캐시 합 */
  usedAllCash?: InputMaybe<Array<IntFilterInput>>;
  /** 사용한 현금성 캐시 */
  usedMainCash?: InputMaybe<Array<IntFilterInput>>;
  /** 사용한 비현금성 캐시 */
  usedSubCash?: InputMaybe<Array<IntFilterInput>>;
  /** 유저 email */
  user__email?: InputMaybe<Array<StringFilterInput>>;
  /** 유저 아이디 */
  user__id?: InputMaybe<Array<IdFilterInput>>;
  /** 유저 이름 */
  user__name?: InputMaybe<Array<StringFilterInput>>;
};

/** 주문 목록 */
export type BusinessList = {
  __typename?: 'BusinessList';
  /** 페이지네이션된 데이터 목록 */
  edges: Array<Maybe<BusinessEdge>>;
  /** 페이지네이션된 페이지 정보 */
  pageInfo: PageInfo;
  /** 전체 데이터 개 수 */
  totalCount: Scalars['Int'];
};

/** 주문 정렬 */
export type BusinessOrderByInput = {
  /** 교재 카테고리 이름 */
  bookCategory__name?: InputMaybe<SortInput>;
  /** 교재 열람일 */
  bookReadDate?: InputMaybe<SortInput>;
  /** 리뷰 작성 시간 */
  bookReview__bookReviewWriteDate?: InputMaybe<SortInput>;
  /** 평점 */
  bookReview__bookgpa?: InputMaybe<SortInput>;
  /** 누적 판매 */
  bookSort__businessCount?: InputMaybe<SortInput>;
  /** 누적 평점 */
  bookSort__gpaAvg?: InputMaybe<SortInput>;
  /** 교재 이름 */
  book__name?: InputMaybe<SortInput>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<SortInput>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<SortInput>;
  /** UUID */
  id?: InputMaybe<SortInput>;
  /** 소득공제 여부 */
  isDeducted?: InputMaybe<SortInput>;
  /** 할인금액 */
  lessonPrice__discountPrice?: InputMaybe<SortInput>;
  /** 할인율 */
  lessonPrice__discountRate?: InputMaybe<SortInput>;
  /** 수업횟수 */
  lessonPrice__lessonCount?: InputMaybe<SortInput>;
  /** 수업시간 */
  lessonPrice__lessonTime?: InputMaybe<SortInput>;
  /** 최종금액 */
  lessonPrice__totalPrice?: InputMaybe<SortInput>;
  /** 수업 카테고리 */
  lesson__category?: InputMaybe<SortInput>;
  /** 수업 이름 */
  lesson__lessonName?: InputMaybe<SortInput>;
  /** 주문확정일 */
  orderConfirmedDate?: InputMaybe<SortInput>;
  /** 최종 결제금액 */
  payment__amount?: InputMaybe<SortInput>;
  /** 결제일자 */
  payment__createdAt?: InputMaybe<SortInput>;
  /** 주문 번호 */
  payment__orderNumber?: InputMaybe<SortInput>;
  /** 구매일 */
  payment__paidDate?: InputMaybe<SortInput>;
  /** 결제상태 */
  payment__state?: InputMaybe<SortInput>;
  /** 사용캐시 */
  payment__usedCash?: InputMaybe<SortInput>;
  /** 관리자 이메일 */
  processor__email?: InputMaybe<SortInput>;
  /** 출판사 ID */
  publisher__id?: InputMaybe<SortInput>;
  /** 출판사 이름 */
  publisher__name?: InputMaybe<SortInput>;
  /** 상태 */
  state?: InputMaybe<BusinessStateSortInput>;
  /** 선생님 이름 */
  teacherUser__name?: InputMaybe<SortInput>;
  /** 상품 타입 */
  type?: InputMaybe<SortInput>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<SortInput>;
  /** 사용한 캐시 합 */
  usedAllCash?: InputMaybe<SortInput>;
  /** 사용한 현금성 캐시 */
  usedMainCash?: InputMaybe<SortInput>;
  /** 사용한 비현금성 캐시 */
  usedSubCash?: InputMaybe<SortInput>;
  /** 유저 email */
  user__email?: InputMaybe<SortInput>;
  /** 유저 이름 */
  user__name?: InputMaybe<SortInput>;
};

/** 주문 취소/환불 데이터 */
export type BusinessReasonInput = {
  /** 취소/환불 사유 */
  reason?: InputMaybe<Scalars['String']>;
};

/** 주문 상태 */
export type BusinessStateEnumFilter = {
  operator: EnumFilterOperators;
  value?: InputMaybe<OrderState>;
  values?: InputMaybe<Array<OrderState>>;
};

export type BusinessStateSortInput = {
  case?: InputMaybe<Array<OrderState>>;
  nulls?: InputMaybe<Nulls>;
  order: Order;
};

/** 주문 업데이트 */
export type BusinessUpdateInput = {
  /** 교재 열람일 */
  bookReadDate?: InputMaybe<Scalars['DateTime']>;
  /** 리뷰 정보 */
  bookReview__id?: InputMaybe<Scalars['ID']>;
  /** 교재 ID */
  book__id?: InputMaybe<Scalars['ID']>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  /** 기본 키(UUID) */
  id?: InputMaybe<Scalars['ID']>;
  /** 소득공제 여부 */
  isDeducted?: InputMaybe<Scalars['Boolean']>;
  /** 수업 옵션 */
  lessonPrice__id?: InputMaybe<Scalars['ID']>;
  /** 수업ID */
  lesson__id?: InputMaybe<Scalars['ID']>;
  /** 주문확정일 */
  orderConfirmedDate?: InputMaybe<Scalars['DateTime']>;
  /** 주문 정보 */
  orderInfo__id?: InputMaybe<Scalars['ID']>;
  /** 주문 코드 */
  payment__id?: InputMaybe<Scalars['ID']>;
  /** 취소/환불 정보 */
  refundHistory__id?: InputMaybe<Scalars['ID']>;
  /** 주문 상태 */
  state?: InputMaybe<OrderState>;
  /** 주문 타입 */
  type?: InputMaybe<OrderType>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** 사용한 캐시 합 */
  usedAllCash?: InputMaybe<Scalars['Float']>;
  /** 사용한 현금성 캐시 */
  usedMainCash?: InputMaybe<Scalars['Float']>;
  /** 사용한 비현금성 캐시 */
  usedSubCash?: InputMaybe<Scalars['Float']>;
  /** 사용자 id */
  user__id?: InputMaybe<Scalars['ID']>;
};

/** 캐쉬 */
export type Cash = {
  __typename?: 'Cash';
  /** 금액 */
  amount?: Maybe<Scalars['Float']>;
  /** 생성 날짜/시간 */
  createdAt: Scalars['DateTime'];
  /** 삭제 날짜/시간 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** 비고 */
  etc?: Maybe<Scalars['String']>;
  /** 기본 키(UUID) */
  id: Scalars['ID'];
  /** 현금성캐쉬 */
  mainCash?: Maybe<Scalars['Float']>;
  /** 처리 관리자 */
  processor?: Maybe<User>;
  /** 속성 */
  property?: Maybe<CashType>;
  /** 사유 */
  reason?: Maybe<CashEvent>;
  /** 비현금성캐쉬 */
  subCash?: Maybe<Scalars['Float']>;
  /** 수정 날짜/시간 */
  updatedAt: Scalars['DateTime'];
  /** 사용자 */
  user?: Maybe<User>;
};

/** 캐시 데이터 생성 */
export type CashCreateInput = {
  /** 금액 */
  amount?: InputMaybe<Scalars['Float']>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  /** 비고 */
  etc?: InputMaybe<Scalars['String']>;
  /** 기본 키(UUID) */
  id?: InputMaybe<Scalars['ID']>;
  /** 속성 */
  property?: InputMaybe<CashType>;
  /** 사유 */
  reason?: InputMaybe<CashEvent>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** 유저 ID */
  user__id?: InputMaybe<Scalars['ID']>;
};

export type CashEdge = {
  __typename?: 'CashEdge';
  /** 커서 */
  cursor: Scalars['String'];
  /** 노드 */
  node: Cash;
};

export enum CashEvent {
  /** 관리자 */
  Admin = 'ADMIN',
  /** 정산 */
  Calculate = 'CALCULATE',
  /** 주문 취소 */
  Cancellation = 'CANCELLATION',
  /** 친구초대 */
  Friends = 'FRIENDS',
  /** 외부프로모션 */
  OutsidePromotion = 'OUTSIDE_PROMOTION',
  /** 제휴초대 */
  PartnershipInvitation = 'PARTNERSHIP_INVITATION',
  /** 제휴가입 */
  PartnershipJoin = 'PARTNERSHIP_JOIN',
  /** 제휴 결제 */
  PartnershipPurchase = 'PARTNERSHIP_PURCHASE',
  /** 취소(예비) */
  PreCancellation = 'PRE_CANCELLATION',
  /** 사용(예비) */
  PreUse = 'PRE_USE',
  /** 추천가입 */
  Recommendation = 'RECOMMENDATION',
  /** 환불 */
  Refund = 'REFUND',
  /** 사용 */
  Use = 'USE',
  /** 출금 */
  Withdrawal = 'WITHDRAWAL'
}

/** 캐쉬 필터 */
export type CashFilterInput = {
  /** 금액 */
  amount?: InputMaybe<Array<StringFilterInput>>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 비고 */
  etc?: InputMaybe<Array<StringFilterInput>>;
  /** UUID */
  id?: InputMaybe<Array<IdFilterInput>>;
  /** 관리자 이메일 */
  processor__email?: InputMaybe<Array<StringFilterInput>>;
  /** 관리자 id */
  processor__id?: InputMaybe<Array<IdFilterInput>>;
  /** 현금성/비현금성 */
  property?: InputMaybe<Array<CashTypeEnumFilter>>;
  /** 지급 사유 */
  reason?: InputMaybe<Array<CashReasonEnumFilter>>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 이메일 */
  user__email?: InputMaybe<Array<StringFilterInput>>;
  /** 유저 id */
  user__id?: InputMaybe<Array<IdFilterInput>>;
  /** 이름 */
  user__name?: InputMaybe<Array<StringFilterInput>>;
  /** 사용자 타입 */
  user__role?: InputMaybe<Array<UserRoleEnumFilter>>;
};

/** 캐쉬 종합 정보 */
export type CashInfo = {
  __typename?: 'CashInfo';
  /** 현금성캐쉬 */
  mainCash?: Maybe<Scalars['Float']>;
  /** 현금성캐쉬 보유자 수 */
  mainCashUserCount?: Maybe<Scalars['Float']>;
  /** 비현금성캐쉬 */
  subCash?: Maybe<Scalars['Float']>;
  /** 현금성캐쉬 보유자 수 */
  subCashUserCount?: Maybe<Scalars['Float']>;
};

/** 캐쉬 리스트 */
export type CashList = {
  __typename?: 'CashList';
  /** 페이지네이션된 데이터 목록 */
  edges: Array<Maybe<CashEdge>>;
  /** 페이지네이션된 페이지 정보 */
  pageInfo: PageInfo;
  /** 전체 데이터 개 수 */
  totalCount: Scalars['Int'];
};

/** 정지 정렬 */
export type CashOrderByInput = {
  /** 금액 */
  amount?: InputMaybe<SortInput>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<SortInput>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<SortInput>;
  /** UUID */
  id?: InputMaybe<SortInput>;
  /** 관리자 이메일 */
  processor__email?: InputMaybe<SortInput>;
  /** 속성 */
  property?: InputMaybe<SortInput>;
  /** 이벤트 정보 */
  reason?: InputMaybe<SortInput>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<SortInput>;
  /** 이메일 */
  user__email?: InputMaybe<SortInput>;
  /** 이름 */
  user__name?: InputMaybe<SortInput>;
};

/** 지급 사유 */
export type CashReasonEnumFilter = {
  operator: EnumFilterOperators;
  value?: InputMaybe<CashEvent>;
  values?: InputMaybe<Array<CashEvent>>;
};

export enum CashType {
  /** 현금성 */
  Cashability = 'CASHABILITY',
  /** 비현금성 */
  NonCashability = 'NON_CASHABILITY'
}

/** 다중 캐시 데이터 생성 */
export type CashesCreateInput = {
  /** 금액 */
  amount?: InputMaybe<Scalars['Float']>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  /** 비고 */
  etc?: InputMaybe<Scalars['String']>;
  /** 기본 키(UUID) */
  id?: InputMaybe<Scalars['ID']>;
  /** 속성 */
  property?: InputMaybe<CashType>;
  /** 사유 */
  reason?: InputMaybe<CashEvent>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** 유저 ID */
  user__ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type ChangeSubLesson = {
  __typename?: 'ChangeSubLesson';
  /** 변경 전 시간 */
  beforeLessonTime?: Maybe<Scalars['DateTime']>;
  /** 변경 사유 */
  changeReason: ChangeSubLessonChangeReasonEnumType;
  /** 생성 날짜/시간 */
  createdAt: Scalars['DateTime'];
  /** 삭제 날짜/시간 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** 기본 키(UUID) */
  id: Scalars['ID'];
  /** 변경할 시간 */
  lessonTimeToChange?: Maybe<Scalars['DateTime']>;
  /** 직접 입력 */
  personalChangeReason?: Maybe<Scalars['String']>;
  /** 직접 입력 */
  personalRefusalReason?: Maybe<Scalars['String']>;
  /** 거절 사유 */
  refusalReason?: Maybe<ChangeSubLessonRefusalReasonEnumType>;
  /** 상태 */
  state: ChangeSubLessonStateEnumType;
  subLesson: SubLesson;
  /** 수정 날짜/시간 */
  updatedAt: Scalars['DateTime'];
};

export type ChangeSubLessonChangeReasonEnumFilter = {
  operator: EnumFilterOperators;
  value?: InputMaybe<ChangeSubLessonChangeReasonEnumType>;
  values?: InputMaybe<Array<ChangeSubLessonChangeReasonEnumType>>;
};

export enum ChangeSubLessonChangeReasonEnumType {
  /** 기타 */
  Etc = 'ETC',
  /** 건강 문제 */
  Health = 'HEALTH',
  /** 긴급한 문제 */
  Urgency = 'URGENCY',
  /** 휴가 */
  Vacation = 'VACATION'
}

export type ChangeSubLessonEdge = {
  __typename?: 'ChangeSubLessonEdge';
  /** 커서 */
  cursor: Scalars['String'];
  /** 노드 */
  node: ChangeSubLesson;
};

export type ChangeSubLessonList = {
  __typename?: 'ChangeSubLessonList';
  /** 페이지네이션된 데이터 목록 */
  edges: Array<Maybe<ChangeSubLessonEdge>>;
  /** 페이지네이션된 페이지 정보 */
  pageInfo: PageInfo;
  /** 전체 데이터 개 수 */
  totalCount: Scalars['Int'];
};

export type ChangeSubLessonOrderByInput = {
  /** 변경 전 시간 */
  beforeLessonTime?: InputMaybe<SortInput>;
  /** 변경 사유 */
  changeReason?: InputMaybe<SortInput>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<SortInput>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<SortInput>;
  /** UUID */
  id?: InputMaybe<SortInput>;
  /** 변경할 시간 */
  lessonTimeToChange?: InputMaybe<SortInput>;
  /** 거절 사유 */
  refusalReason?: InputMaybe<SortInput>;
  /** 상태 */
  state?: InputMaybe<SortInput>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<SortInput>;
};

export type ChangeSubLessonRefusalReasonEnumFilter = {
  operator: EnumFilterOperators;
  value?: InputMaybe<ChangeSubLessonRefusalReasonEnumType>;
  values?: InputMaybe<Array<ChangeSubLessonRefusalReasonEnumType>>;
};

export enum ChangeSubLessonRefusalReasonEnumType {
  /** 학업 진도 상 변경 불가능 */
  AcademicProgress = 'ACADEMIC_PROGRESS',
  /** 기타 */
  Etc = 'ETC',
  /** 스케쥴 상 변경 불가능 */
  Schedule = 'SCHEDULE',
  /** 원하지 않음 */
  Unwanted = 'UNWANTED'
}

export type ChangeSubLessonStateEnumFilter = {
  operator: EnumFilterOperators;
  value?: InputMaybe<ChangeSubLessonStateEnumType>;
  values?: InputMaybe<Array<ChangeSubLessonStateEnumType>>;
};

export enum ChangeSubLessonStateEnumType {
  /** 수락 */
  Acceptance = 'ACCEPTANCE',
  /** 취소 */
  Cancelation = 'CANCELATION',
  /** 거절 */
  Refusal = 'REFUSAL',
  /** 요청 */
  Requesting = 'REQUESTING'
}

/** 채팅방 */
export type ChatChannel = {
  __typename?: 'ChatChannel';
  /** 생성 날짜/시간 */
  createdAt: Scalars['DateTime'];
  /** 생성자 */
  creator?: Maybe<User>;
  /** 삭제 날짜/시간 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** 파일 */
  files?: Maybe<Array<File>>;
  id: Scalars['ID'];
  /** 사진 */
  images?: Maybe<Array<File>>;
  /**
   * 핀 여부
   * @deprecated 미사용
   */
  isPinned: Scalars['Boolean'];
  /** 마지막 메시지(관리자용),memberLastMessage 사용 */
  lastMessage?: Maybe<ChatMessage>;
  /** 사용자용 마지막 메시지 */
  memberLastMessage?: Maybe<ChatMessage>;
  /** 참여자 */
  participants: Array<ChatChannelParticipant>;
  /** 상태 */
  state: ChatChannelState;
  /** 종류 */
  type: ChatChannelType;
  /** 안읽은 메세지 수 */
  unreadMessageCount: Scalars['Int'];
  /** 수정 날짜/시간 */
  updatedAt: Scalars['DateTime'];
  /** 동영상 */
  videos?: Maybe<Array<File>>;
};

/** 채팅방 생성 */
export type ChatChannelCreateInput = {
  /** 참여자 ID */
  participants__userId: Array<Scalars['ID']>;
  /** 종류 */
  type: ChatChannelType;
};

export type ChatChannelEdge = {
  __typename?: 'ChatChannelEdge';
  /** 커서 */
  cursor: Scalars['String'];
  /** 노드 */
  node: ChatChannel;
};

/** 채팅방 필터 */
export type ChatChannelFilterInput = {
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 생성자 ID */
  creator__id?: InputMaybe<Array<IdFilterInput>>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** UUID */
  id?: InputMaybe<Array<IdFilterInput>>;
  /** 매칭 종류 이름 */
  matchPostType__name?: InputMaybe<Array<StringFilterInput>>;
  /** 거래 게시물 ID */
  matchPost__id?: InputMaybe<Array<IdFilterInput>>;
  /** 참여자 이름 */
  participantsUser__name?: InputMaybe<Array<StringFilterInput>>;
  /** 참여자 ID */
  participants__userId?: InputMaybe<Array<IdFilterInput>>;
  /** 상태 */
  state?: InputMaybe<Array<ChatChannelStateEnumFilterInput>>;
  /** 종류 */
  type?: InputMaybe<Array<ChatChannelTypeEnumFilterInput>>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Array<DateTimeFilterInput>>;
};

/** 채팅방 목록 */
export type ChatChannelList = {
  __typename?: 'ChatChannelList';
  /** 페이지네이션된 데이터 목록 */
  edges: Array<Maybe<ChatChannelEdge>>;
  /** 페이지네이션된 페이지 정보 */
  pageInfo: PageInfo;
  /** 전체 데이터 개 수 */
  totalCount: Scalars['Int'];
};

/** 채팅방 정렬 */
export type ChatChannelOrderByInput = {
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<SortInput>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<SortInput>;
  /** UUID */
  id?: InputMaybe<SortInput>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<SortInput>;
};

/** 채팅 참여자 */
export type ChatChannelParticipant = {
  __typename?: 'ChatChannelParticipant';
  /** 채팅방 */
  channel: ChatChannel;
  /** 생성 날짜/시간 */
  createdAt: Scalars['DateTime'];
  /** 삭제 날짜/시간 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  /** 마지막 조회 시간 */
  lastSeen?: Maybe<Scalars['DateTime']>;
  /** 수정 날짜/시간 */
  updatedAt: Scalars['DateTime'];
  /** 사용자 */
  user: User;
};

export type ChatChannelParticipantEdge = {
  __typename?: 'ChatChannelParticipantEdge';
  /** 커서 */
  cursor: Scalars['String'];
  /** 노드 */
  node: ChatChannelParticipant;
};

export type ChatChannelParticipantFilterInput = {
  /** 채팅상담권한 */
  adminRole__chatCounselling?: InputMaybe<Array<BooleanFilterInput>>;
  /** 채널 id */
  channel__id?: InputMaybe<Array<IdFilterInput>>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** ID */
  id?: InputMaybe<Array<IdFilterInput>>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 유저 email */
  user__email?: InputMaybe<Array<StringFilterInput>>;
  /** 유저 id */
  user__id?: InputMaybe<Array<IdFilterInput>>;
  /** 유저 이름 */
  user__name?: InputMaybe<Array<StringFilterInput>>;
  /** 유저 역할 */
  user__role?: InputMaybe<Array<UserRoleEnumFilter>>;
};

/** 채팅 참여자 리스트 */
export type ChatChannelParticipantList = {
  __typename?: 'ChatChannelParticipantList';
  /** 페이지네이션된 데이터 목록 */
  edges: Array<Maybe<ChatChannelParticipantEdge>>;
  /** 페이지네이션된 페이지 정보 */
  pageInfo: PageInfo;
  /** 전체 데이터 개 수 */
  totalCount: Scalars['Int'];
};

export type ChatChannelParticipantOrderByInput = {
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<SortInput>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<SortInput>;
  /** ID */
  id?: InputMaybe<SortInput>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<SortInput>;
  /** 유저 email */
  user__email?: InputMaybe<SortInput>;
  /** 유저 이름 */
  user__name?: InputMaybe<SortInput>;
  /** 유저 역할 */
  user__role?: InputMaybe<SortInput>;
};

/** 채팅방 상태 */
export enum ChatChannelState {
  /** 활성화 */
  Active = 'ACTIVE',
  /** 비활성화 */
  Inactive = 'INACTIVE'
}

/** 채팅방 상태 필터 */
export type ChatChannelStateEnumFilterInput = {
  operator: EnumFilterOperators;
  value?: InputMaybe<ChatChannelState>;
  values?: InputMaybe<Array<ChatChannelState>>;
};

/** 채팅방 상태 */
export enum ChatChannelType {
  /** 거래 */
  Business = 'BUSINESS',
  /** 상담 */
  Counselling = 'COUNSELLING',
  /** 일반 */
  General = 'GENERAL'
}

/** 채팅방 종류 필터 */
export type ChatChannelTypeEnumFilterInput = {
  operator: EnumFilterOperators;
  value?: InputMaybe<ChatChannelType>;
  values?: InputMaybe<Array<ChatChannelType>>;
};

/** 채팅 메시지 */
export type ChatMessage = {
  __typename?: 'ChatMessage';
  /** 작성자 */
  author?: Maybe<User>;
  /** 채팅방 */
  channel: ChatChannel;
  /** 생성 날짜/시간 */
  createdAt: Scalars['DateTime'];
  /** 삭제 날짜/시간 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  /** 메시지 */
  message?: Maybe<Scalars['String']>;
  /** 추가 데이터 */
  payload?: Maybe<Scalars['JSON']>;
  /** 관련된 ID */
  targetId?: Maybe<Scalars['String']>;
  /** 종류 */
  type: ChatMessageType;
  /** 수정 날짜/시간 */
  updatedAt: Scalars['DateTime'];
};

/** 채팅 메시지 생성 */
export type ChatMessageCreateInput = {
  /** 채팅방 ID */
  channel__id: Scalars['ID'];
  /** 메시지 */
  message?: InputMaybe<Scalars['String']>;
  /** 추가 데이터 */
  payload?: InputMaybe<Scalars['JSON']>;
  /** 관련된 ID */
  targetId?: InputMaybe<Scalars['String']>;
  /** 종류 */
  type: ChatMessageType;
};

export type ChatMessageEdge = {
  __typename?: 'ChatMessageEdge';
  /** 커서 */
  cursor: Scalars['String'];
  /** 노드 */
  node: ChatMessage;
};

/** 채팅 메시지 필터 */
export type ChatMessageFilterInput = {
  /** 작성자 ID */
  author__id?: InputMaybe<Array<IdFilterInput>>;
  /** 채팅방 ID */
  channel__id?: InputMaybe<Array<IdFilterInput>>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** UUID */
  id?: InputMaybe<Array<IdFilterInput>>;
  /** 메시지 */
  message?: InputMaybe<Array<StringFilterInput>>;
  /** 종류 */
  type?: InputMaybe<Array<ChatMessageTypeFilterInput>>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Array<DateTimeFilterInput>>;
};

/** 채팅 메시지 목록 */
export type ChatMessageList = {
  __typename?: 'ChatMessageList';
  /** 페이지네이션된 데이터 목록 */
  edges: Array<Maybe<ChatMessageEdge>>;
  /** 페이지네이션된 페이지 정보 */
  pageInfo: PageInfo;
  /** 전체 데이터 개 수 */
  totalCount: Scalars['Int'];
};

/** 채팅 메시지 정렬 */
export type ChatMessageOrderByInput = {
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<SortInput>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<SortInput>;
  /** UUID */
  id?: InputMaybe<SortInput>;
  /** 메시지 */
  message?: InputMaybe<SortInput>;
  /** 종류 */
  type?: InputMaybe<SortInput>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<SortInput>;
};

/** 채팅 메시지 종류 */
export enum ChatMessageType {
  /** 카드형 */
  Card = 'CARD',
  /** 수업변경수락 */
  ChangeAcceptance = 'CHANGE_ACCEPTANCE',
  /** 수업변경거절 */
  ChangeRefusal = 'CHANGE_REFUSAL',
  /** 수업변경요청 */
  ChangeRequest = 'CHANGE_REQUEST',
  /** 파일 */
  File = 'FILE',
  /** 이미지 */
  Image = 'IMAGE',
  /** 링크 */
  Link = 'LINK',
  /** 공지사항 */
  Notice = 'NOTICE',
  /** 시스템 */
  System = 'SYSTEM',
  /** 텍스트 */
  Text = 'TEXT',
  /** 비디오 */
  Video = 'VIDEO'
}

/** 채팅 메시지 종류 필터 */
export type ChatMessageTypeFilterInput = {
  operator: EnumFilterOperators;
  value?: InputMaybe<ChatMessageType>;
  values?: InputMaybe<Array<ChatMessageType>>;
};

export enum ClassTypeEnum {
  /** 수업 */
  Class = 'CLASS',
  /** 숙제 */
  Homework = 'HOMEWORK',
  /** 준비하기 */
  Preparation = 'PREPARATION',
  /** 복습 */
  Review = 'REVIEW'
}

/** 콘텐츠 */
export type Content = {
  __typename?: 'Content';
  /** 카테고리 */
  category?: Maybe<ContentCategory>;
  /** 생성 날짜/시간 */
  createdAt: Scalars['DateTime'];
  /** 삭제 날짜/시간 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** 기본 키(UUID) */
  id: Scalars['ID'];
  /** 첨부파일 */
  image?: Maybe<File>;
  /** 종류 */
  kind?: Maybe<ContentKind>;
  /** 링크 */
  link?: Maybe<Scalars['String']>;
  /** 처리 관리자 */
  processor?: Maybe<User>;
  /** 게시날짜 */
  publishedDate?: Maybe<Scalars['DateTime']>;
  /** 상태 */
  state?: Maybe<ContentState>;
  /** 제목 */
  title?: Maybe<Scalars['String']>;
  /** 타입 */
  type?: Maybe<ContentType>;
  /** 수정 날짜/시간 */
  updatedAt: Scalars['DateTime'];
  /** 작성자 */
  writer?: Maybe<User>;
};

/** 콘텐츠 카테고리 enum */
export type ContentCategoryEnumFilter = {
  operator: EnumFilterOperators;
  value?: InputMaybe<ContentCategory>;
  values?: InputMaybe<Array<ContentCategory>>;
};

/** 콘텐츠 생성 */
export type ContentCreateInput = {
  /** 카테고리 */
  category?: InputMaybe<ContentCategory>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  /** 기본 키(UUID) */
  id?: InputMaybe<Scalars['ID']>;
  /** 파일 id */
  image__id?: InputMaybe<Scalars['ID']>;
  /** 종류 */
  kind?: InputMaybe<ContentKind>;
  /** 링크 */
  link?: InputMaybe<Scalars['String']>;
  /** 게시날짜 */
  publishedDate?: InputMaybe<Scalars['DateTime']>;
  /** 상태 */
  state?: InputMaybe<ContentState>;
  /** 제목 */
  title?: InputMaybe<Scalars['String']>;
  /** 타입 */
  type?: InputMaybe<ContentType>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** 작성자 id */
  writer__id?: InputMaybe<Scalars['ID']>;
};

export type ContentEdge = {
  __typename?: 'ContentEdge';
  /** 커서 */
  cursor: Scalars['String'];
  /** 노드 */
  node: Content;
};

/** 콘텐츠 필터 */
export type ContentFilterInput = {
  /** 카테고리 */
  category?: InputMaybe<Array<ContentCategoryEnumFilter>>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** UUID */
  id?: InputMaybe<Array<IdFilterInput>>;
  /** 종류 */
  kind?: InputMaybe<Array<ContentKindEnumFilter>>;
  /** 처리 관리자 email */
  processor__email?: InputMaybe<Array<StringFilterInput>>;
  /** 처리 관리자 id */
  processor__id?: InputMaybe<Array<IdFilterInput>>;
  /** 처리 관리자 이름 */
  processor__name?: InputMaybe<Array<StringFilterInput>>;
  /** 게시 날짜 */
  publishedDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 노출 상태 */
  state?: InputMaybe<Array<ContentStateEnumFilter>>;
  /** 제목 */
  title?: InputMaybe<Array<StringFilterInput>>;
  /** 타입 */
  type?: InputMaybe<Array<ContentTypeEnumFilter>>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 작성자이메일 */
  writer__email?: InputMaybe<Array<StringFilterInput>>;
  /** 작성자 */
  writer__id?: InputMaybe<Array<IdFilterInput>>;
  /** 작성자 */
  writer__name?: InputMaybe<Array<StringFilterInput>>;
};

/** 콘텐츠 종류 enum */
export type ContentKindEnumFilter = {
  operator: EnumFilterOperators;
  value?: InputMaybe<ContentKind>;
  values?: InputMaybe<Array<ContentKind>>;
};

/** 게시판 목록 */
export type ContentList = {
  __typename?: 'ContentList';
  /** 페이지네이션된 데이터 목록 */
  edges: Array<Maybe<ContentEdge>>;
  /** 페이지네이션된 페이지 정보 */
  pageInfo: PageInfo;
  /** 전체 데이터 개 수 */
  totalCount: Scalars['Int'];
};

/** 콘텐츠 정렬 */
export type ContentOrderByInput = {
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<SortInput>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<SortInput>;
  /** UUID */
  id?: InputMaybe<SortInput>;
  /** 처리 관리자 이메일 */
  processor__email?: InputMaybe<SortInput>;
  /** 제목 */
  title?: InputMaybe<SortInput>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<SortInput>;
  /** 작성자메일 */
  writer__email?: InputMaybe<SortInput>;
  /** 작성자 */
  writer__id?: InputMaybe<SortInput>;
  /** 작성자 */
  writer__name?: InputMaybe<SortInput>;
};

/** 콘텐츠 상태 enum */
export type ContentStateEnumFilter = {
  operator: EnumFilterOperators;
  value?: InputMaybe<ContentState>;
  values?: InputMaybe<Array<ContentState>>;
};

/** 콘텐츠 타입 enum */
export type ContentTypeEnumFilter = {
  operator: EnumFilterOperators;
  value?: InputMaybe<ContentType>;
  values?: InputMaybe<Array<ContentType>>;
};

/** 콘텐츠 업데이트 */
export type ContentUpdateInput = {
  /** 카테고리 */
  category?: InputMaybe<ContentCategory>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  /** 기본 키(UUID) */
  id?: InputMaybe<Scalars['ID']>;
  /** 파일 id */
  image__id?: InputMaybe<Scalars['ID']>;
  /** 종류 */
  kind?: InputMaybe<ContentKind>;
  /** 링크 */
  link?: InputMaybe<Scalars['String']>;
  /** 게시날짜 */
  publishedDate?: InputMaybe<Scalars['DateTime']>;
  /** 상태 */
  state?: InputMaybe<ContentState>;
  /** 제목 */
  title?: InputMaybe<Scalars['String']>;
  /** 타입 */
  type?: InputMaybe<ContentType>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

/** 날짜(DateTime) 필터 */
export type DateTimeFilterInput = {
  operator: NumberFilterOperators;
  value?: InputMaybe<Scalars['DateTime']>;
  value2?: InputMaybe<Scalars['DateTime']>;
  values?: InputMaybe<Array<Scalars['DateTime']>>;
};

export enum DayOfTheWeekEnumType {
  /** 금요일 */
  Fri = 'FRI',
  /** 월요일 */
  Mon = 'MON',
  /** 토요일 */
  Sat = 'SAT',
  /** 일요일 */
  Sun = 'SUN',
  /** 목요일 */
  Thu = 'THU',
  /** 화요일 */
  Tue = 'TUE',
  /** 수요일 */
  Wed = 'WED'
}

export type DefaultFilterInput = {
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** UUID */
  id?: InputMaybe<Array<IdFilterInput>>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Array<DateTimeFilterInput>>;
};

export type DefaultSortInput = {
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<SortInput>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<SortInput>;
  /** UUID */
  id?: InputMaybe<SortInput>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<SortInput>;
};

/** Enum 필터 연산자 */
export enum EnumFilterOperators {
  Equal = 'EQUAL',
  In = 'IN',
  IsNotNull = 'IS_NOT_NULL',
  IsNull = 'IS_NULL',
  NotEqual = 'NOT_EQUAL',
  NotIn = 'NOT_IN'
}

/** 다중 캐시 데이터 생성 */
export type ExcelCashesCreateInput = {
  /** 금액 */
  amount?: InputMaybe<Scalars['Float']>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  /** 비고 */
  etc?: InputMaybe<Scalars['String']>;
  /** 기본 키(UUID) */
  id?: InputMaybe<Scalars['ID']>;
  /** 속성 */
  property?: InputMaybe<CashType>;
  /** 사유 */
  reason?: InputMaybe<CashEvent>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** 유저 email */
  user__email?: InputMaybe<Scalars['String']>;
};

/** 소명 상태 */
export type ExplanationStateEnumFilter = {
  operator: EnumFilterOperators;
  value?: InputMaybe<ExplanationStateEnum>;
  values?: InputMaybe<Array<ExplanationStateEnum>>;
};

/** 카테고리 */
export type FaqCategory = {
  __typename?: 'FAQCategory';
  /** 생성 날짜/시간 */
  createdAt: Scalars['DateTime'];
  /** 삭제 날짜/시간 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** 기본 키(UUID) */
  id: Scalars['ID'];
  /** 카테고리 이름 */
  name?: Maybe<Scalars['String']>;
  /** 순서 */
  priority?: Maybe<Scalars['Float']>;
  /** 수정 날짜/시간 */
  updatedAt: Scalars['DateTime'];
};

/** 카테고리 생성 */
export type FaqCategoryCreateInput = {
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  /** 기본 키(UUID) */
  id?: InputMaybe<Scalars['ID']>;
  /** 카테고리 이름 */
  name?: InputMaybe<Scalars['String']>;
  /** 상위 카테고리 아이디 */
  parent__id?: InputMaybe<Scalars['ID']>;
  /** 순서 */
  priority?: InputMaybe<Scalars['Float']>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type FaqCategoryEdge = {
  __typename?: 'FAQCategoryEdge';
  /** 커서 */
  cursor: Scalars['String'];
  /** 노드 */
  node: FaqCategory;
};

/** 카테고리 업데이트 */
export type FaqCategoryUpdateInput = {
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  /** 기본 키(UUID) */
  id?: InputMaybe<Scalars['ID']>;
  /** 카테고리 이름 */
  name?: InputMaybe<Scalars['String']>;
  /** 순서 */
  priority?: InputMaybe<Scalars['Float']>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export enum FaqEnumType {
  /** 관리자 FAQ */
  FaqAdmin = 'FAQ_ADMIN',
  /** 출판사 FAQ */
  FaqPublisher = 'FAQ_PUBLISHER',
  /** 선생님 FAQ */
  FaqTeacher = 'FAQ_TEACHER',
  /** 사용자 FAQ */
  FaqUser = 'FAQ_USER'
}

export type FaqEnumTypeFilterInput = {
  operator: EnumFilterOperators;
  value?: InputMaybe<FaqEnumType>;
  values?: InputMaybe<Array<FaqEnumType>>;
};

/** 파일 */
export type File = {
  __typename?: 'File';
  /** 생성 날짜/시간 */
  createdAt: Scalars['DateTime'];
  /** 삭제 날짜/시간 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** 파일 이름 */
  filename: Scalars['String'];
  /** 기본 키(UUID) */
  id: Scalars['ID'];
  /** MD5 체크섬 */
  md5: Scalars['String'];
  mimetype: Scalars['String'];
  /** 우선 순위 */
  priority: Scalars['Int'];
  /** url */
  relativeURL?: Maybe<Scalars['String']>;
  /** 파일 크기 (byte) */
  size: Scalars['Int'];
  /** 수정 날짜/시간 */
  updatedAt: Scalars['DateTime'];
  url: Scalars['String'];
};

/** 배열 선택 */
export enum FilterSelectors {
  All = 'ALL',
  Any = 'ANY'
}

export type FindBookByIsbnModel = {
  __typename?: 'FindBookByIsbnModel';
  /** 파인티처 서버에 교재 존재 여부 */
  exist: Scalars['Boolean'];
  /** 교재 id */
  id?: Maybe<Scalars['ID']>;
  /** 교재 이름 */
  name?: Maybe<Scalars['String']>;
};

export type FindMyEmail = {
  __typename?: 'FindMyEmail';
  email: Scalars['String'];
  joinDate?: Maybe<Scalars['DateTime']>;
};

/** 소수(Float) 필터 */
export type FloatFilterInput = {
  operator: NumberFilterOperators;
  value?: InputMaybe<Scalars['Float']>;
  value2?: InputMaybe<Scalars['Float']>;
  values?: InputMaybe<Array<Scalars['Float']>>;
};

export enum ForbiddenUsedLocation {
  /** 전체 채팅 */
  AllChat = 'ALL_CHAT',
  /** 판서 채팅 */
  BookChat = 'BOOK_CHAT',
  /** 교재 평가 */
  BookReview = 'BOOK_REVIEW',
  /** 선생님 평가 */
  TeacherReview = 'TEACHER_REVIEW',
  /** 구매자 평가 */
  UserReview = 'USER_REVIEW'
}

/** 금칙어 발생 위치 */
export type ForbiddenUsedLocationEnumFilter = {
  operator: EnumFilterOperators;
  value?: InputMaybe<ForbiddenUsedLocation>;
  values?: InputMaybe<Array<ForbiddenUsedLocation>>;
};

/** 금칙어 */
export type ForbiddenWord = {
  __typename?: 'ForbiddenWord';
  /** 생성 날짜/시간 */
  createdAt: Scalars['DateTime'];
  /** 삭제 날짜/시간 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** 기본 키(UUID) */
  id: Scalars['ID'];
  /** 순서  */
  priority?: Maybe<Scalars['Float']>;
  /** 수정 날짜/시간 */
  updatedAt: Scalars['DateTime'];
  /** 사용횟수  */
  useCount?: Maybe<Scalars['Float']>;
  /** 금칙단어 */
  word?: Maybe<Scalars['String']>;
};

/** 금칙어 생성 */
export type ForbiddenWordCreateInput = {
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  /** 기본 키(UUID) */
  id?: InputMaybe<Scalars['ID']>;
  /** 순서  */
  priority?: InputMaybe<Scalars['Float']>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** 사용횟수  */
  useCount?: InputMaybe<Scalars['Float']>;
  /** 금칙단어 */
  word?: InputMaybe<Scalars['String']>;
};

export type ForbiddenWordEdge = {
  __typename?: 'ForbiddenWordEdge';
  /** 커서 */
  cursor: Scalars['String'];
  /** 노드 */
  node: ForbiddenWord;
};

/** 금칙어 필터 */
export type ForbiddenWordFilterInput = {
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** UUID */
  id?: InputMaybe<Array<IdFilterInput>>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 금칙단어 */
  word?: InputMaybe<Array<StringFilterInput>>;
};

/** 금칙어 목록 */
export type ForbiddenWordList = {
  __typename?: 'ForbiddenWordList';
  /** 페이지네이션된 데이터 목록 */
  edges: Array<Maybe<ForbiddenWordEdge>>;
  /** 페이지네이션된 페이지 정보 */
  pageInfo: PageInfo;
  /** 전체 데이터 개 수 */
  totalCount: Scalars['Int'];
};

/** 금칙어 정렬 */
export type ForbiddenWordOrderByInput = {
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<SortInput>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<SortInput>;
  /** UUID */
  id?: InputMaybe<SortInput>;
  /** 순서 */
  priority?: InputMaybe<SortInput>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<SortInput>;
  /** 사용횟수 */
  useCount?: InputMaybe<SortInput>;
  /** 금칙단어 */
  word?: InputMaybe<SortInput>;
};

export enum ForbiddenWordState {
  /** 검토전 */
  Before = 'BEFORE',
  /** 검토완료 */
  Completion = 'COMPLETION',
  /** 제재중 */
  Sanction = 'SANCTION'
}

/** 상태 */
export type ForbiddenWordStateEnumFilter = {
  operator: EnumFilterOperators;
  value?: InputMaybe<ForbiddenWordState>;
  values?: InputMaybe<Array<ForbiddenWordState>>;
};

/** 금칙어 업데이트 */
export type ForbiddenWordUpdateInput = {
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  /** 기본 키(UUID) */
  id?: InputMaybe<Scalars['ID']>;
  /** 순서  */
  priority?: InputMaybe<Scalars['Float']>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** 사용횟수  */
  useCount?: InputMaybe<Scalars['Float']>;
  /** 금칙단어 */
  word?: InputMaybe<Scalars['String']>;
};

/** 금칙어 모니터링 모니터링 */
export type ForbiddenWordUse = {
  __typename?: 'ForbiddenWordUse';
  /** 생성 날짜/시간 */
  createdAt: Scalars['DateTime'];
  /** 삭제 날짜/시간 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** 금칙어 */
  forbiddenWord?: Maybe<ForbiddenWord>;
  /** 기본 키(UUID) */
  id: Scalars['ID'];
  /** 발생위치 */
  location?: Maybe<ForbiddenUsedLocation>;
  /** 연결아이디  */
  objectId?: Maybe<Scalars['String']>;
  /** 신고 정보 */
  report?: Maybe<Report>;
  /** 상태값  */
  state?: Maybe<ForbiddenWordState>;
  /** 수정 날짜/시간 */
  updatedAt: Scalars['DateTime'];
  /** 사용자 정보 */
  user?: Maybe<User>;
};

/** 금칙어 모니터링 생성 */
export type ForbiddenWordUseCreateInput = {
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  /** 금칙어 id */
  forbiddenWord__id?: InputMaybe<Scalars['ID']>;
  /** 기본 키(UUID) */
  id?: InputMaybe<Scalars['ID']>;
  /** 발생위치 */
  location?: InputMaybe<ForbiddenUsedLocation>;
  /** 연결아이디  */
  objectId?: InputMaybe<Scalars['String']>;
  /** 신고 id */
  report__id?: InputMaybe<Scalars['ID']>;
  /** 상태값  */
  state?: InputMaybe<ForbiddenWordState>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** 사용자 id */
  user__id?: InputMaybe<Scalars['ID']>;
};

export type ForbiddenWordUseEdge = {
  __typename?: 'ForbiddenWordUseEdge';
  /** 커서 */
  cursor: Scalars['String'];
  /** 노드 */
  node: ForbiddenWordUse;
};

/** 금칙어 모니터링 필터 */
export type ForbiddenWordUseFilterInput = {
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 금지어 id */
  forbiddenWord__id?: InputMaybe<Array<IdFilterInput>>;
  /** 금지어 */
  forbiddenWord__word?: InputMaybe<Array<StringFilterInput>>;
  /** UUID */
  id?: InputMaybe<Array<IdFilterInput>>;
  /** 발생 위치 */
  location?: InputMaybe<Array<ForbiddenUsedLocationEnumFilter>>;
  /** 상태 */
  state?: InputMaybe<Array<ForbiddenWordStateEnumFilter>>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 사용자 분류 */
  user__role?: InputMaybe<Array<UserRoleEnumFilter>>;
};

/** 금칙어 모니터링 목록 */
export type ForbiddenWordUseList = {
  __typename?: 'ForbiddenWordUseList';
  /** 페이지네이션된 데이터 목록 */
  edges: Array<Maybe<ForbiddenWordUseEdge>>;
  /** 페이지네이션된 페이지 정보 */
  pageInfo: PageInfo;
  /** 전체 데이터 개 수 */
  totalCount: Scalars['Int'];
};

/** 금칙어 모니터링 정렬 */
export type ForbiddenWordUseOrderByInput = {
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<SortInput>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<SortInput>;
  /** 사용 금지 단어 */
  forbiddenWord__word?: InputMaybe<SortInput>;
  /** UUID */
  id?: InputMaybe<SortInput>;
  /** 발생위치 */
  location?: InputMaybe<SortInput>;
  /** 상태 */
  state?: InputMaybe<SortInput>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<SortInput>;
  /** 사용자 이메일 */
  user__email?: InputMaybe<SortInput>;
  /** 사용자 이름 */
  user__name?: InputMaybe<SortInput>;
  /** 사용자 분류 */
  user__role?: InputMaybe<SortInput>;
};

/** 금칙어 모니터링 업데이트 */
export type ForbiddenWordUseUpdateInput = {
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  /** 금칙어 id */
  forbiddenWord__id?: InputMaybe<Scalars['ID']>;
  /** 기본 키(UUID) */
  id?: InputMaybe<Scalars['ID']>;
  /** 발생위치 */
  location?: InputMaybe<ForbiddenUsedLocation>;
  /** 연결아이디  */
  objectId?: InputMaybe<Scalars['String']>;
  /** 신고 id */
  report__id?: InputMaybe<Scalars['ID']>;
  /** 상태값  */
  state?: InputMaybe<ForbiddenWordState>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** 사용자 id */
  user__id?: InputMaybe<Scalars['ID']>;
};

export enum GenderEnumType {
  /** 여성 */
  Female = 'FEMALE',
  /** 남성 */
  Male = 'MALE'
}

/** Google 토큰 */
export type GoogleLoginTokenResponse = {
  __typename?: 'GoogleLoginTokenResponse';
  access_token?: Maybe<Scalars['String']>;
  expiry_date?: Maybe<Scalars['Float']>;
  id_token?: Maybe<Scalars['String']>;
  refresh_token?: Maybe<Scalars['String']>;
  scope?: Maybe<Scalars['String']>;
  token_type?: Maybe<Scalars['String']>;
};

export type GuardianRequest = {
  __typename?: 'GuardianRequest';
  /** request의 id */
  requestId?: Maybe<Scalars['ID']>;
  requestState?: Maybe<GuardianRequestStateEnumType>;
};

export enum GuardianRequestStateEnumType {
  /** 수락 */
  Acceptance = 'ACCEPTANCE',
  /** 요청 받음 */
  Received = 'RECEIVED',
  /** 요청 보냄 */
  Sent = 'SENT',
  /** 알수없음 */
  Unknown = 'UNKNOWN'
}

export enum GuardianState {
  /** 수락 */
  Acceptance = 'ACCEPTANCE',
  /** 요청 취소 */
  Cancelation = 'CANCELATION',
  /** 연결 해제 */
  Disconnect = 'DISCONNECT',
  /** 거절 */
  Refusal = 'REFUSAL',
  /** 요청중 */
  Requesting = 'REQUESTING'
}

/** 보호자 요청 상태 */
export type GuardianStateEnumFilter = {
  operator: EnumFilterOperators;
  value?: InputMaybe<GuardianState>;
  values?: InputMaybe<Array<GuardianState>>;
};

export enum GuardianType {
  /** 보호자 */
  Guardian = 'GUARDIAN',
  /** 피보호자 */
  Protege = 'PROTEGE'
}

/** 보호자/피보호자 필터 */
export type GuardianTypeEnumFilter = {
  operator: EnumFilterOperators;
  value?: InputMaybe<GuardianType>;
  values?: InputMaybe<Array<GuardianType>>;
};

/** 숙제상태 */
export type HomeWorkStateEnumFilter = {
  operator: EnumFilterOperators;
  value?: InputMaybe<HomeworkState>;
  values?: InputMaybe<Array<HomeworkState>>;
};

export enum HomeworkState {
  /** 숙제 완료 */
  Done = 'DONE',
  /** 노력 */
  Effort = 'EFFORT',
  /** 좋음 */
  Good = 'GOOD',
  /** 미완료 */
  Incompletion = 'INCOMPLETION',
  /** 없음  */
  None = 'NONE',
  /** 보통 */
  Ordinary = 'ORDINARY'
}

/** ID 필터 */
export type IdFilterInput = {
  operator: IdFilterOperators;
  value?: InputMaybe<Scalars['ID']>;
  values?: InputMaybe<Array<Scalars['ID']>>;
};

/** ID 필터 연산자 */
export enum IdFilterOperators {
  Equal = 'EQUAL',
  In = 'IN',
  IsNotNull = 'IS_NOT_NULL',
  IsNull = 'IS_NULL',
  NotEqual = 'NOT_EQUAL',
  NotIn = 'NOT_IN'
}

/** 문의하기 */
export type Inquiry = {
  __typename?: 'Inquiry';
  /** 카테고리 */
  category?: Maybe<InquiryCategory>;
  /** 생성 날짜/시간 */
  createdAt: Scalars['DateTime'];
  /** 삭제 날짜/시간 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** 기본 키(UUID) */
  id: Scalars['ID'];
  /** 답변 */
  inquiryReplies?: Maybe<Array<InquiryReply>>;
  /** 가장 최근 문의,답변 */
  inquiryReply?: Maybe<InquiryReply>;
  /** 주문 */
  payment?: Maybe<Payment>;
  /** 상태 */
  state?: Maybe<InquiryState>;
  /** 제목 */
  title?: Maybe<Scalars['String']>;
  /** 분류 */
  type?: Maybe<InquiryType>;
  /** 수정 날짜/시간 */
  updatedAt: Scalars['DateTime'];
  /** 작성자 */
  writer?: Maybe<User>;
};

export enum InquiryCategory {
  /** 교재 요청 */
  BookRequest = 'BOOK_REQUEST',
  /** 캐시/출금/정산/세금 */
  Cash = 'CASH',
  /** 이벤트 */
  Event = 'EVENT',
  /** 사용문의 */
  Inquiry = 'INQUIRY',
  /** 수업/숙제 */
  Lesson = 'LESSON',
  /** 수업등록/관리 */
  LessonManagement = 'LESSON_MANAGEMENT',
  /** 결제/환불 */
  Payment = 'PAYMENT',
  /** 계정/프로필 */
  Profile = 'PROFILE',
  /** 만족도평가/패널티 */
  Satisfaction = 'SATISFACTION',
  /** 스케쥴관리 */
  Schedule = 'SCHEDULE',
  /** 건의사항/불편사항/기타 */
  Suggestions = 'SUGGESTIONS',
  /** 선생님되기 */
  Teacher = 'TEACHER'
}

/** 문의하기 카테고리 */
export type InquiryCategoryEnumFilter = {
  operator: EnumFilterOperators;
  value?: InputMaybe<InquiryCategory>;
  values?: InputMaybe<Array<InquiryCategory>>;
};

/** 문의하기 생성 */
export type InquiryCreateInput = {
  /** 카테고리 */
  category?: InputMaybe<InquiryCategory>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  /** 기본 키(UUID) */
  id?: InputMaybe<Scalars['ID']>;
  /** 주문 ID */
  payment__id?: InputMaybe<Scalars['ID']>;
  /** 상태 */
  state?: InputMaybe<InquiryState>;
  /** 제목 */
  title?: InputMaybe<Scalars['String']>;
  /** 분류 */
  type?: InputMaybe<InquiryType>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** 작성자 id */
  writer__id?: InputMaybe<Scalars['ID']>;
};

export type InquiryDashboard = {
  __typename?: 'InquiryDashboard';
  /** 신규 문의 수 (1주 전) */
  oneWeekAgoNewInquiryCount: Scalars['Float'];
  /** 재문의 수 (1주 전) */
  oneWeekAgoReInquiryCount: Scalars['Float'];
  /** 신규 문의 수 (3일 전) */
  threeDaysAgoNewInquiryCount: Scalars['Float'];
  /** 재문의 수 (3일 전) */
  threeDaysAgoReInquiryCount: Scalars['Float'];
  /** 신규 문의 수 (오늘) */
  todayNewInquiryCount: Scalars['Float'];
  /** 재문의 수 (오늘) */
  todayReInquiryCount: Scalars['Float'];
  /** 신규 문의 수 (전체) */
  totalNewInquiryCount: Scalars['Float'];
  /** 재문의 수 (전체) */
  totalReInquiryCount: Scalars['Float'];
};

export type InquiryEdge = {
  __typename?: 'InquiryEdge';
  /** 커서 */
  cursor: Scalars['String'];
  /** 노드 */
  node: Inquiry;
};

/** 문의하기 필터 */
export type InquiryFilterInput = {
  /** 답변자 이메일 */
  admin__email?: InputMaybe<Array<StringFilterInput>>;
  /** 답변자 id */
  admin__id?: InputMaybe<Array<IdFilterInput>>;
  /** 카테고리 */
  category?: InputMaybe<Array<InquiryCategoryEnumFilter>>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** UUID */
  id?: InputMaybe<Array<IdFilterInput>>;
  /** 주문번호 */
  payment__orderNumber?: InputMaybe<Array<StringFilterInput>>;
  /** 상태 */
  state?: InputMaybe<Array<InquiryStateEnumFilter>>;
  /** 제목 */
  title?: InputMaybe<Array<StringFilterInput>>;
  /** 문의하기 타입 */
  type?: InputMaybe<Array<InquiryTypeEnumFilter>>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 작성자 이메일 */
  writer__email?: InputMaybe<Array<StringFilterInput>>;
  /** 작성자 */
  writer__id?: InputMaybe<Array<IdFilterInput>>;
  /** 작성자 이름 */
  writer__name?: InputMaybe<Array<StringFilterInput>>;
};

/** 문의하기 목록 */
export type InquiryList = {
  __typename?: 'InquiryList';
  /** 페이지네이션된 데이터 목록 */
  edges: Array<Maybe<InquiryEdge>>;
  /** 페이지네이션된 페이지 정보 */
  pageInfo: PageInfo;
  /** 전체 데이터 개 수 */
  totalCount: Scalars['Int'];
};

/** 문의하기 정렬 */
export type InquiryOrderByInput = {
  /** 카테고리 */
  category?: InputMaybe<SortInput>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<SortInput>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<SortInput>;
  /** UUID */
  id?: InputMaybe<SortInput>;
  /** 상태 */
  state?: InputMaybe<SortInput>;
  /** 제목 */
  title?: InputMaybe<SortInput>;
  /** 타입 */
  type?: InputMaybe<SortInput>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<SortInput>;
  /** 작성자 */
  writer__id?: InputMaybe<SortInput>;
  /** 작성자 이름 */
  writer__name?: InputMaybe<SortInput>;
};

/** 문의관리 미리보기 */
export type InquiryPreview = {
  __typename?: 'InquiryPreview';
  /** 관리자 이메일 */
  admin_email?: Maybe<Scalars['String']>;
  /** 카테고리 */
  category?: Maybe<Scalars['String']>;
  /** 생성 날짜/시간 */
  createdAt: Scalars['DateTime'];
  /** 삭제 날짜/시간 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** 기본 키(UUID) */
  id: Scalars['ID'];
  /** 문의 답변 */
  inquiryReply_answer?: Maybe<Scalars['String']>;
  /** 답변 일자 */
  inquiryReply_answerDate?: Maybe<Scalars['DateTime']>;
  /** 문의 내용 */
  inquiryReply_content?: Maybe<Scalars['String']>;
  /** 문의 일자 */
  inquiryReply_createdAt?: Maybe<Scalars['DateTime']>;
  /** 상태 */
  state?: Maybe<InquiryState>;
  /** 제목 */
  title?: Maybe<Scalars['String']>;
  /** 문의하기 타입 */
  type?: Maybe<InquiryType>;
  /** 수정 날짜/시간 */
  updatedAt: Scalars['DateTime'];
  /** 문의자 이메일 */
  writer_email?: Maybe<Scalars['String']>;
  /** 문의자 이름 */
  writer_name?: Maybe<Scalars['String']>;
};

export type InquiryPreviewEdge = {
  __typename?: 'InquiryPreviewEdge';
  /** 커서 */
  cursor: Scalars['String'];
  /** 노드 */
  node: InquiryPreview;
};

/** 문의관리(리스트) 필터 */
export type InquiryPreviewFilterInput = {
  /** 관리자 이메일 */
  admin__email?: InputMaybe<Array<StringFilterInput>>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** UUID */
  id?: InputMaybe<Array<IdFilterInput>>;
  /** 문의 답변 */
  inquiryReply__answer?: InputMaybe<Array<StringFilterInput>>;
  /** 답변 일자 */
  inquiryReply__answerDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 문의 내용 */
  inquiryReply__content?: InputMaybe<Array<StringFilterInput>>;
  /** 문의 일자 */
  inquiryReply__createdAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 카테고리 */
  inquiry__category?: InputMaybe<Array<StringFilterInput>>;
  /** 상태 */
  inquiry__state?: InputMaybe<Array<InquiryStateEnumFilter>>;
  /** 제목 */
  inquiry__title?: InputMaybe<Array<StringFilterInput>>;
  /** 문의하기 타입 */
  inquiry__type?: InputMaybe<Array<InquiryTypeEnumFilter>>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 문의자 이메일 */
  writer__email?: InputMaybe<Array<StringFilterInput>>;
  /** 문의자 이름 */
  writer__name?: InputMaybe<Array<StringFilterInput>>;
};

/** 문의관리(리스트) 정렬 */
export type InquiryPreviewOrderByInput = {
  /** 관리자 이메일 */
  admin__email?: InputMaybe<SortInput>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<SortInput>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<SortInput>;
  /** UUID */
  id?: InputMaybe<SortInput>;
  /** 제목 */
  inquiry__title?: InputMaybe<SortInput>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<SortInput>;
  /** 문의자 이메일 */
  writer__email?: InputMaybe<SortInput>;
  /** 문의자 이름 */
  writer__name?: InputMaybe<SortInput>;
};

/** 문의하기 답글 */
export type InquiryReply = {
  __typename?: 'InquiryReply';
  /** 답변내용 */
  answer?: Maybe<Scalars['String']>;
  /** 답변일자 */
  answerDate?: Maybe<Scalars['DateTime']>;
  /** 문의내용 */
  content?: Maybe<Scalars['String']>;
  /** 생성 날짜/시간 */
  createdAt: Scalars['DateTime'];
  /** 삭제 날짜/시간 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** 기본 키(UUID) */
  id: Scalars['ID'];
  /** 문의 */
  inquiry?: Maybe<Inquiry>;
  /** 회차 */
  round?: Maybe<Scalars['Float']>;
  /** 상태 */
  state?: Maybe<InquiryReplyState>;
  /** 문의제목 */
  title?: Maybe<Scalars['String']>;
  /** 수정 날짜/시간 */
  updatedAt: Scalars['DateTime'];
  /** 관리자 */
  user?: Maybe<User>;
};

/** 문의하기 답변 */
export type InquiryReplyAnswerUpdateInput = {
  /** 답변내용 */
  answer?: InputMaybe<Scalars['String']>;
};

/** 문의하기 생성 */
export type InquiryReplyCreateInput = {
  /** 답변내용 */
  answer?: InputMaybe<Scalars['String']>;
  /** 답변일자 */
  answerDate?: InputMaybe<Scalars['DateTime']>;
  /** 문의내용 */
  content?: InputMaybe<Scalars['String']>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  /** 기본 키(UUID) */
  id?: InputMaybe<Scalars['ID']>;
  /** 문의하기 답변 */
  inquiry__id?: InputMaybe<Scalars['ID']>;
  /** 회차 */
  round?: InputMaybe<Scalars['Float']>;
  /** 상태 */
  state?: InputMaybe<InquiryReplyState>;
  /** 문의제목 */
  title?: InputMaybe<Scalars['String']>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** 작성자 id */
  user__id?: InputMaybe<Scalars['ID']>;
};

export type InquiryReplyEdge = {
  __typename?: 'InquiryReplyEdge';
  /** 커서 */
  cursor: Scalars['String'];
  /** 노드 */
  node: InquiryReply;
};

/** 문의하기 답글 필터 */
export type InquiryReplyFilterInput = {
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** UUID */
  id?: InputMaybe<Array<IdFilterInput>>;
  /** 문의하기 카테고리 필터 */
  inquiry__category?: InputMaybe<Array<InquiryCategoryEnumFilter>>;
  /** 문의하기 필터 */
  inquiry__id?: InputMaybe<Array<IdFilterInput>>;
  /** 문의하기 상태 필터 */
  inquiry__state?: InputMaybe<Array<InquiryStateEnumFilter>>;
  /** 문의하기 제목 필터 */
  inquiry__title?: InputMaybe<Array<StringFilterInput>>;
  /** 문의하기 타입 필터 */
  inquiry__type?: InputMaybe<Array<InquiryTypeEnumFilter>>;
  /** 회차 */
  round?: InputMaybe<Array<IntFilterInput>>;
  /** 상태 */
  state?: InputMaybe<Array<InquiryReplyStateEnumFilter>>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 관리자 이메일 */
  user__email?: InputMaybe<Array<StringFilterInput>>;
  /** 관리자 아이디 */
  user__id?: InputMaybe<Array<IdFilterInput>>;
  /** 관리자 이름 */
  user__name?: InputMaybe<Array<StringFilterInput>>;
  /** 문의자 이메일 */
  writer__email?: InputMaybe<Array<StringFilterInput>>;
};

/** 문의하기 답글 목록 */
export type InquiryReplyList = {
  __typename?: 'InquiryReplyList';
  /** 페이지네이션된 데이터 목록 */
  edges: Array<Maybe<InquiryReplyEdge>>;
  /** 페이지네이션된 페이지 정보 */
  pageInfo: PageInfo;
  /** 전체 데이터 개 수 */
  totalCount: Scalars['Int'];
};

/** 문의하기 정렬 */
export type InquiryReplyOrderByInput = {
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<SortInput>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<SortInput>;
  /** UUID */
  id?: InputMaybe<SortInput>;
  /** 회차 */
  round?: InputMaybe<SortInput>;
  /** 제목 */
  title?: InputMaybe<SortInput>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<SortInput>;
  /** 관리자 이메일 */
  user__email?: InputMaybe<SortInput>;
  /** 문의자 이메일 */
  writer__email?: InputMaybe<SortInput>;
  /** 문의자명 */
  writer__name?: InputMaybe<SortInput>;
};

export enum InquiryReplyState {
  /** 답변완료 */
  Completion = 'COMPLETION',
  /** 문의중 */
  Inquiry = 'INQUIRY',
  /** 재문의답변 */
  Recompletion = 'RECOMPLETION',
  /** 재문의 */
  Reinquiry = 'REINQUIRY'
}

/** 문의하기 노출 상태 enum */
export type InquiryReplyStateEnumFilter = {
  operator: EnumFilterOperators;
  value?: InputMaybe<InquiryReplyState>;
  values?: InputMaybe<Array<InquiryReplyState>>;
};

export enum InquiryState {
  /** 답변완료 */
  Completion = 'COMPLETION',
  /** 문의중 */
  Inquiry = 'INQUIRY',
  /** 재문의답변 */
  Recompletion = 'RECOMPLETION',
  /** 재문의 */
  Reinquiry = 'REINQUIRY'
}

/** 문의하기 노출 상태 enum */
export type InquiryStateEnumFilter = {
  operator: EnumFilterOperators;
  value?: InputMaybe<InquiryState>;
  values?: InputMaybe<Array<InquiryState>>;
};

export enum InquiryType {
  /** 선생님 문의 */
  Teacher = 'TEACHER',
  /** 사용자 문의 */
  User = 'USER'
}

/** 문의하기 타입 enum */
export type InquiryTypeEnumFilter = {
  operator: EnumFilterOperators;
  value?: InputMaybe<InquiryType>;
  values?: InputMaybe<Array<InquiryType>>;
};

/** 문의하기 업데이트 */
export type InquiryUpdateInput = {
  /** 카테고리 */
  category?: InputMaybe<InquiryCategory>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  /** 기본 키(UUID) */
  id?: InputMaybe<Scalars['ID']>;
  /** 주문 ID */
  payment__id?: InputMaybe<Scalars['ID']>;
  /** 상태 */
  state?: InputMaybe<InquiryState>;
  /** 제목 */
  title?: InputMaybe<Scalars['String']>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

/** 정수(Int) 필터 */
export type IntFilterInput = {
  operator: NumberFilterOperators;
  value?: InputMaybe<Scalars['Int']>;
  value2?: InputMaybe<Scalars['Int']>;
  values?: InputMaybe<Array<Scalars['Float']>>;
};

export type InterestBookCategoryUpdateInput = {
  /** 관심 교재 카테고리 1 */
  interestBookCategory1__id?: InputMaybe<Scalars['ID']>;
  /** 관심 교재 카테고리 2 */
  interestBookCategory2__id?: InputMaybe<Scalars['ID']>;
  /** 관심 교재 카테고리 3 */
  interestBookCategory3__id?: InputMaybe<Scalars['ID']>;
};

export type InterestCategoryUpdateInput = {
  /** 관심 교재 카테고리 1 */
  interestBookCategory1__id?: InputMaybe<Scalars['ID']>;
  /** 관심 교재 카테고리 2 */
  interestBookCategory2__id?: InputMaybe<Scalars['ID']>;
  /** 관심 교재 카테고리 3 */
  interestBookCategory3__id?: InputMaybe<Scalars['ID']>;
  /** 관심 수업 카테고리 1 */
  interestLessonCategory1__id?: InputMaybe<Scalars['ID']>;
  /** 관심 수업 카테고리 2 */
  interestLessonCategory2__id?: InputMaybe<Scalars['ID']>;
  /** 관심 수업 카테고리 3 */
  interestLessonCategory3__id?: InputMaybe<Scalars['ID']>;
};

export type InterestLessonCategoryUpdateInput = {
  /** 관심 수업 카테고리 1 */
  interestLessonCategory1__id?: InputMaybe<Scalars['ID']>;
  /** 관심 수업 카테고리 2 */
  interestLessonCategory2__id?: InputMaybe<Scalars['ID']>;
  /** 관심 수업 카테고리 3 */
  interestLessonCategory3__id?: InputMaybe<Scalars['ID']>;
};

/** 언어 과외 선생님 필터 */
export type LanguageTeacherFilterInput = {
  /** 카테고리 */
  lessons__categoryId?: InputMaybe<Array<IdFilterInput>>;
  /** 30분 기준 금액 */
  lessons__criteriaPrice?: InputMaybe<Array<IntFilterInput>>;
  /** 수업 이름 */
  lessons__lessonName?: InputMaybe<Array<StringFilterInput>>;
  /** 언어 주 1 */
  mainLanguageA?: InputMaybe<Array<StringFilterInput>>;
  /** 언어 주 1 레벨 */
  mainLanguageALevel?: InputMaybe<Array<TeacherLanguageLevelEnumFilter>>;
  /** 언어 주 2 */
  mainLanguageB?: InputMaybe<Array<StringFilterInput>>;
  /** 언어 주 2 레벨 */
  mainLanguageBLevel?: InputMaybe<Array<TeacherLanguageLevelEnumFilter>>;
  /** 출신 국가 */
  originCountry?: InputMaybe<Array<StringFilterInput>>;
  /** 거주 국가 */
  residenceCountry?: InputMaybe<Array<StringFilterInput>>;
  /** 언어 부 1 */
  subLanguageA?: InputMaybe<Array<StringFilterInput>>;
  /** 언어 부 1 레벨 */
  subLanguageALevel?: InputMaybe<Array<TeacherLanguageLevelEnumFilter>>;
  /** 언어 부 2 */
  subLanguageB?: InputMaybe<Array<StringFilterInput>>;
  /** 언어 부 2 레벨 */
  subLanguageBLevel?: InputMaybe<Array<TeacherLanguageLevelEnumFilter>>;
  user__gender?: InputMaybe<Array<UserGenderEnumFilter>>;
  /** 선생님 이름 */
  user__name?: InputMaybe<Array<StringFilterInput>>;
};

/** 학습 과외 선생님 필터 */
export type LearningTeacherFilterInput = {
  /** 카테고리 ids */
  category__ids?: InputMaybe<Array<Scalars['ID']>>;
  /** 성별 */
  gender?: InputMaybe<GenderEnumType>;
  /** 학습 대상 */
  teachingTarget?: InputMaybe<Array<UserType>>;
};

export type LedgerFilterInput = {
  /** ex 202302 */
  endYearMonth: Scalars['String'];
  /** ex 202302 */
  startYearMonth: Scalars['String'];
};

/** 수업 */
export type Lesson = {
  __typename?: 'Lesson';
  /** 주문 */
  businesses?: Maybe<Array<Business>>;
  /** 카테고리 */
  category?: Maybe<Array<LessonCategory>>;
  /** 생성 날짜/시간 */
  createdAt: Scalars['DateTime'];
  /** 30분 기준금액 */
  criteriaPrice?: Maybe<Scalars['Float']>;
  /** 삭제 날짜/시간 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** 기본 키(UUID) */
  id: Scalars['ID'];
  /** 소개글 */
  introduction?: Maybe<Scalars['String']>;
  /** google meet 사용 여부 */
  isUsingGoogleMeet: Scalars['Boolean'];
  /** 수업명 */
  lessonName?: Maybe<Scalars['String']>;
  /** 수업 가격옵션 */
  lessonPriceList?: Maybe<Array<LessonPrice>>;
  /** 주교재 */
  mainBook?: Maybe<Book>;
  /** 진행 전 수업 수 */
  scheduledLessonNumber: Scalars['Float'];
  /** 정렬 필드 */
  sortField?: Maybe<LessonSortView>;
  /** 판매 상태(판매중, 판매중지) */
  state?: Maybe<LessonStateType>;
  /** 수업을 수강하는 학생 리스트 */
  studentList?: Maybe<Array<User>>;
  /** 학생 수 */
  studentNumber: Scalars['Float'];
  /** 부교재 */
  subBook?: Maybe<Book>;
  /** 수업의 상세리스트 */
  subLessons?: Maybe<Array<SubLesson>>;
  /** 선생님 */
  teacher?: Maybe<Teacher>;
  /** 수업 구분(시범, 정규) */
  type?: Maybe<LessonType>;
  /** 수정 날짜/시간 */
  updatedAt: Scalars['DateTime'];
};

/** 수업 태도 리뷰 */
export type LessonAttitudeReviewUpdateInput = {
  /** 수업 태도 평점 */
  usergpa?: InputMaybe<Scalars['Float']>;
  /** 수업 태도 평점 내용 */
  usergpaContent?: InputMaybe<Scalars['String']>;
};

/** 수업정산 */
export type LessonCalculate = {
  __typename?: 'LessonCalculate';
  /** 주문 */
  business?: Maybe<Business>;
  /** 정산번호 */
  calculateCode?: Maybe<Scalars['String']>;
  /** 정산 확정 일시 */
  calculateConfirmationDate?: Maybe<Scalars['DateTime']>;
  /** 정산연월 */
  calculateDate?: Maybe<Scalars['String']>;
  /** 정산금액 */
  calculatePrice?: Maybe<Scalars['Float']>;
  /** 정산 회차 */
  calculateRound?: Maybe<Scalars['String']>;
  /** 정산 예정 일시 */
  calculateScheduleDate?: Maybe<Scalars['DateTime']>;
  /** 생성 날짜/시간 */
  createdAt: Scalars['DateTime'];
  /** 삭제 날짜/시간 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** 할인 금액 */
  discountPrice?: Maybe<Scalars['Float']>;
  /** 수수료 */
  fee?: Maybe<Scalars['Float']>;
  /** 최종 금액 */
  finalPrice?: Maybe<Scalars['Float']>;
  /** 기본 키(UUID) */
  id: Scalars['ID'];
  /** 주문번호 */
  orderNumber?: Maybe<Scalars['String']>;
  /** 상품 금액 */
  productPrice?: Maybe<Scalars['Float']>;
  /** 정산 상태 */
  state?: Maybe<LessonCalculateState>;
  /** 수업상세 */
  subLesson?: Maybe<SubLesson>;
  /** 전체 횟수 */
  totalRound?: Maybe<Scalars['Float']>;
  /** 수정 날짜/시간 */
  updatedAt: Scalars['DateTime'];
  /** 사용자 */
  user?: Maybe<User>;
};

/** 수업 정산 생성 */
export type LessonCalculateCreateInput = {
  /** 정산번호 */
  calculateCode?: InputMaybe<Scalars['String']>;
  /** 정산 확정 일시 */
  calculateConfirmationDate?: InputMaybe<Scalars['DateTime']>;
  /** 정산연월 */
  calculateDate?: InputMaybe<Scalars['String']>;
  /** 정산금액 */
  calculatePrice?: InputMaybe<Scalars['Float']>;
  /** 정산 회차 */
  calculateRound?: InputMaybe<Scalars['String']>;
  /** 정산 예정 일시 */
  calculateScheduleDate?: InputMaybe<Scalars['DateTime']>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  /** 할인 금액 */
  discountPrice?: InputMaybe<Scalars['Float']>;
  /** 수수료 */
  fee?: InputMaybe<Scalars['Float']>;
  /** 최종 금액 */
  finalPrice?: InputMaybe<Scalars['Float']>;
  /** 기본 키(UUID) */
  id?: InputMaybe<Scalars['ID']>;
  /** 주문번호 */
  orderNumber?: InputMaybe<Scalars['String']>;
  /** 상품 금액 */
  productPrice?: InputMaybe<Scalars['Float']>;
  /** 정산 상태 */
  state?: InputMaybe<LessonCalculateState>;
  /** 전체 횟수 */
  totalRound?: InputMaybe<Scalars['Float']>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** 유저 ID */
  user__id?: InputMaybe<Scalars['ID']>;
};

export type LessonCalculateEdge = {
  __typename?: 'LessonCalculateEdge';
  /** 커서 */
  cursor: Scalars['String'];
  /** 노드 */
  node: LessonCalculate;
};

/** 수업 정산 필터 */
export type LessonCalculateFilterInput = {
  /** 상품명 */
  businessLessons__lessonName?: InputMaybe<Array<StringFilterInput>>;
  /** 주문번호 */
  businessPayment__orderNumber?: InputMaybe<Array<StringFilterInput>>;
  /** 정산확정일 */
  calculateConfirmationDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 정산연월 */
  calculateDate?: InputMaybe<Array<StringFilterInput>>;
  /** 정산예정일 */
  calculateScheduleDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** UUID */
  id?: InputMaybe<Array<IdFilterInput>>;
  /** 주문번호 */
  orderNumber?: InputMaybe<Array<StringFilterInput>>;
  /** 정산 상태 */
  state?: InputMaybe<Array<LessonCalculateStateEnumFilter>>;
  /** 구매자 */
  subLessonUser__name?: InputMaybe<Array<StringFilterInput>>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 유저 이메일 */
  user__email?: InputMaybe<Array<StringFilterInput>>;
  /** 유저 id */
  user__id?: InputMaybe<Array<IdFilterInput>>;
  /** 선생님명 */
  user__name?: InputMaybe<Array<StringFilterInput>>;
  /** 선생님 전화번호 */
  user__phone?: InputMaybe<Array<StringFilterInput>>;
};

/** 수업 정산 리스트 */
export type LessonCalculateList = {
  __typename?: 'LessonCalculateList';
  /** 페이지네이션된 데이터 목록 */
  edges: Array<Maybe<LessonCalculateEdge>>;
  /** 페이지네이션된 페이지 정보 */
  pageInfo: PageInfo;
  /** 전체 데이터 개 수 */
  totalCount: Scalars['Int'];
};

/** 정산 정렬 */
export type LessonCalculateOrderByInput = {
  /** 상품명 */
  businessLessons__lessonName?: InputMaybe<SortInput>;
  /** 주문번호 */
  businessPayment__orderNumber?: InputMaybe<SortInput>;
  /** 정산연월 */
  calculateDate?: InputMaybe<SortInput>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<SortInput>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<SortInput>;
  /** UUID */
  id?: InputMaybe<SortInput>;
  /** 주문번호 */
  orderNumber?: InputMaybe<SortInput>;
  /** 구매자 */
  subLessonUser__name?: InputMaybe<SortInput>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<SortInput>;
  /** 유저 이메일 */
  user__email?: InputMaybe<SortInput>;
  /** 유저 아이디 */
  user__id?: InputMaybe<SortInput>;
  /** 선생님 명 */
  user__name?: InputMaybe<SortInput>;
  /** 선생님 전화번호 */
  user__phone?: InputMaybe<SortInput>;
};

/** 수업 정산 업데이트 */
export type LessonCalculateUpdateInput = {
  /** 정산번호 */
  calculateCode?: InputMaybe<Scalars['String']>;
  /** 정산 확정 일시 */
  calculateConfirmationDate?: InputMaybe<Scalars['DateTime']>;
  /** 정산연월 */
  calculateDate?: InputMaybe<Scalars['String']>;
  /** 정산금액 */
  calculatePrice?: InputMaybe<Scalars['Float']>;
  /** 정산 회차 */
  calculateRound?: InputMaybe<Scalars['String']>;
  /** 정산 예정 일시 */
  calculateScheduleDate?: InputMaybe<Scalars['DateTime']>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  /** 할인 금액 */
  discountPrice?: InputMaybe<Scalars['Float']>;
  /** 수수료 */
  fee?: InputMaybe<Scalars['Float']>;
  /** 최종 금액 */
  finalPrice?: InputMaybe<Scalars['Float']>;
  /** 기본 키(UUID) */
  id?: InputMaybe<Scalars['ID']>;
  /** 주문번호 */
  orderNumber?: InputMaybe<Scalars['String']>;
  /** 상품 금액 */
  productPrice?: InputMaybe<Scalars['Float']>;
  /** 정산 상태 */
  state?: InputMaybe<LessonCalculateState>;
  /** 전체 횟수 */
  totalRound?: InputMaybe<Scalars['Float']>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

/** 카테고리 */
export type LessonCategory = {
  __typename?: 'LessonCategory';
  /** 생성 날짜/시간 */
  createdAt: Scalars['DateTime'];
  /** 삭제 날짜/시간 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** 기본 키(UUID) */
  id: Scalars['ID'];
  /** 카테고리 이름 */
  name?: Maybe<Scalars['String']>;
  /** 순서 */
  priority?: Maybe<Scalars['Float']>;
  /** 수정 날짜/시간 */
  updatedAt: Scalars['DateTime'];
};

/** 카테고리 생성 */
export type LessonCategoryCreateInput = {
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  /** 기본 키(UUID) */
  id?: InputMaybe<Scalars['ID']>;
  /** 카테고리 이름 */
  name?: InputMaybe<Scalars['String']>;
  /** 상위 카테고리 아이디 */
  parent__id?: InputMaybe<Scalars['ID']>;
  /** 순서 */
  priority?: InputMaybe<Scalars['Float']>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type LessonCategoryEdge = {
  __typename?: 'LessonCategoryEdge';
  /** 커서 */
  cursor: Scalars['String'];
  /** 노드 */
  node: LessonCategory;
};

/** 선생님 학습/언어 구분 필터 */
export type LessonCategoryRootEnumFilter = {
  operator: EnumFilterOperators;
  value?: InputMaybe<LessonCategoryRootEnumType>;
  values?: InputMaybe<Array<LessonCategoryRootEnumType>>;
};

export enum LessonCategoryRootEnumType {
  /** 언어 */
  Language = 'LANGUAGE',
  /** 학습 */
  Learning = 'LEARNING',
  /**
   * 없음
   * @deprecated 안씀
   */
  None = 'NONE'
}

/** 카테고리 업데이트 */
export type LessonCategoryUpdateInput = {
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  /** 기본 키(UUID) */
  id?: InputMaybe<Scalars['ID']>;
  /** 카테고리 이름 */
  name?: InputMaybe<Scalars['String']>;
  /** 순서 */
  priority?: InputMaybe<Scalars['Float']>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

/** 수업 생성 */
export type LessonCreateInput = {
  /** 카테고리 */
  category__id?: InputMaybe<Scalars['ID']>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** 30분 기준금액 */
  criteriaPrice?: InputMaybe<Scalars['Float']>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  /** 기본 키(UUID) */
  id?: InputMaybe<Scalars['ID']>;
  /** 소개글 */
  introduction?: InputMaybe<Scalars['String']>;
  /** google meet 사용 여부 */
  isUsingGoogleMeet?: InputMaybe<Scalars['Boolean']>;
  /** 수업명 */
  lessonName?: InputMaybe<Scalars['String']>;
  /** 주교재 id */
  mainBook__id?: InputMaybe<Scalars['ID']>;
  /** 판매 상태(판매중, 판매중지) */
  state?: InputMaybe<LessonStateType>;
  /** 부교재 id */
  subBook__id?: InputMaybe<Scalars['ID']>;
  /** 선생님 id */
  teacher__id?: InputMaybe<Scalars['ID']>;
  /** 수업 구분(시범, 정규) */
  type?: InputMaybe<LessonType>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type LessonEdge = {
  __typename?: 'LessonEdge';
  /** 커서 */
  cursor: Scalars['String'];
  /** 노드 */
  node: Lesson;
};

/** 수업 필터 */
export type LessonFilterInput = {
  /** 카테고리 id */
  category__id?: InputMaybe<Array<IdFilterInput>>;
  /** 카테고리 이름 */
  category__name?: InputMaybe<Array<StringFilterInput>>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 30분 기준 가격 */
  criteriaPrice?: InputMaybe<Array<IntFilterInput>>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** UUID */
  id?: InputMaybe<Array<IdFilterInput>>;
  /** 수업명 */
  lessonName?: InputMaybe<Array<StringFilterInput>>;
  /** 주교재 이름 */
  mainBook__name?: InputMaybe<Array<StringFilterInput>>;
  /** 신고일 */
  reported__createdAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 신고 상태 */
  reported__state?: InputMaybe<Array<ReportStateEnumFilter>>;
  /** 판매 상태 */
  state?: InputMaybe<Array<LessonStateEnumFilterInput>>;
  /** 부교재 이름 */
  subBook__name?: InputMaybe<Array<StringFilterInput>>;
  /** 수업일 */
  subLessons__lessonDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 수업진행상태 */
  subLessons__state?: InputMaybe<Array<SubLessonsStateEnumFilter>>;
  /** 선생님 이름 */
  teacherUser__name?: InputMaybe<Array<StringFilterInput>>;
  /** 선생님 아이디 */
  teacher__id?: InputMaybe<Array<IdFilterInput>>;
  /** 수업 구분 */
  type?: InputMaybe<Array<LessonTypeEnumFilterInput>>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 학생 id */
  user__id?: InputMaybe<Array<IdFilterInput>>;
};

/** 수업 목록 */
export type LessonList = {
  __typename?: 'LessonList';
  /** 페이지네이션된 데이터 목록 */
  edges: Array<Maybe<LessonEdge>>;
  /** 페이지네이션된 페이지 정보 */
  pageInfo: PageInfo;
  /** 전체 데이터 개 수 */
  totalCount: Scalars['Int'];
};

/** 수업 모니터링 */
export type LessonMonitoring = {
  __typename?: 'LessonMonitoring';
  /** 카테고리 id */
  category_id?: Maybe<Scalars['String']>;
  /** 카테고리 이름 */
  category_name?: Maybe<Scalars['String']>;
  /** 수업 이름 */
  lesson_lessonName?: Maybe<Scalars['String']>;
  /** 신고 내용 */
  report_id?: Maybe<Scalars['ID']>;
  /** 신고일시 */
  report_reportDate?: Maybe<Scalars['DateTime']>;
  /** 신고 내용 */
  report_reporterMemo?: Maybe<Scalars['String']>;
  /** 신고 상태 */
  report_state?: Maybe<ReportStateEnum>;
  /** 구매자 이름 */
  student_name?: Maybe<Scalars['String']>;
  /** 숙제 상태 */
  subLesson_homeworkState?: Maybe<HomeworkState>;
  /** subLesson id */
  subLesson_id?: Maybe<Scalars['ID']>;
  /** 수업일시 */
  subLesson_lessonDate?: Maybe<Scalars['DateTime']>;
  /** 현재 회차 */
  subLesson_lessonRound?: Maybe<Scalars['String']>;
  /** 선생님 이름 */
  teacherUser_name?: Maybe<Scalars['String']>;
};

/** 수업 모니터링 필터 */
export type LessonMonitoringFilterInput = {
  /** 카테고리 id */
  category__id?: InputMaybe<Array<IdFilterInput>>;
  /** 카테고리 이름 */
  category__name?: InputMaybe<Array<StringFilterInput>>;
  /** 수업 이름 */
  lesson__lessonName?: InputMaybe<Array<StringFilterInput>>;
  /** 신고 일시 */
  report__reportDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 신고 내용 */
  report__reporterMemo?: InputMaybe<Array<StringFilterInput>>;
  /** 신고 상태 */
  report__state?: InputMaybe<Array<ReportStateEnumFilter>>;
  /** 구매자 이름 */
  student__name?: InputMaybe<Array<StringFilterInput>>;
  /** 수업 일시 */
  subLesson__lessonDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 현재 회차 */
  subLesson__lessonRound?: InputMaybe<Array<StringFilterInput>>;
  /** 선생님 이름 */
  teacherUser__name?: InputMaybe<Array<StringFilterInput>>;
};

/** 수업 모니터링 정렬 */
export type LessonMonitoringOrderByInput = {
  /** 수업 이름 */
  lesson__lessonName?: InputMaybe<SortInput>;
  /** 신고 내용 */
  report__reporterMemo?: InputMaybe<SortInput>;
  /** 신고 상태 */
  report__state?: InputMaybe<SortInput>;
  /** 구매자 이름 */
  student__name?: InputMaybe<SortInput>;
  /** 수업 일시 */
  subLesson__lessonDate?: InputMaybe<SortInput>;
  /** 현재 회차 */
  subLesson__lessonRound?: InputMaybe<SortInput>;
  /** 선생님 이름 */
  teacherUser__name?: InputMaybe<SortInput>;
};

/**  수업 정렬 */
export type LessonOrderByInput = {
  /** 카테고리명 */
  category__name?: InputMaybe<SortInput>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<SortInput>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<SortInput>;
  /** UUID */
  id?: InputMaybe<SortInput>;
  /** 수업 이름 */
  lessonName?: InputMaybe<SortInput>;
  /** 즐겨찾기순 */
  lessonSort__bookmarkCount?: InputMaybe<SortInput>;
  /** 구매순 */
  lessonSort__businessCount?: InputMaybe<SortInput>;
  /** 수업 진행 횟수 */
  lessonSort__cumulativeLessonCount?: InputMaybe<SortInput>;
  /** 평점순(평점 평균) */
  lessonSort__gpaAvg?: InputMaybe<SortInput>;
  /** 리뷰순(리뷰수) */
  lessonSort__gpaCount?: InputMaybe<SortInput>;
  /** 인기순 */
  lessonSort__popular?: InputMaybe<SortInput>;
  /** 주교재 */
  mainBook__name?: InputMaybe<SortInput>;
  /** 판매 상태(판매중, 판매중지) */
  state?: InputMaybe<SortInput>;
  /** 부교재 */
  subBook__name?: InputMaybe<SortInput>;
  /** 선생님 이름 */
  teacherUser__name?: InputMaybe<SortInput>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<SortInput>;
  /** 학생 이름 */
  user__name?: InputMaybe<SortInput>;
};

/** 수업 결제 생성 */
export type LessonOrderCreateInput = {
  /** 사용할 캐시(2000원 이상부터 사용 가능) */
  cash: Scalars['Float'];
  /** 예약한 수업일 리스트 */
  lessonDates: Array<Scalars['DateTime']>;
  /** 수업 옵션 id */
  lessonPrice__id: Scalars['ID'];
};

/** 수업금액옵션 */
export type LessonPrice = {
  __typename?: 'LessonPrice';
  /** 생성 날짜/시간 */
  createdAt: Scalars['DateTime'];
  /** 삭제 날짜/시간 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** 할인금액 */
  discountPrice?: Maybe<Scalars['Float']>;
  /** 할인율 */
  discountRate?: Maybe<Scalars['Float']>;
  /** 기본 키(UUID) */
  id: Scalars['ID'];
  /** 수업횟수 */
  lessonCount?: Maybe<Scalars['Float']>;
  /** 수업시간 */
  lessonTime?: Maybe<Scalars['Float']>;
  /** 1회금액 */
  onePrice?: Maybe<Scalars['Float']>;
  /** 순서  */
  priority?: Maybe<Scalars['Float']>;
  /** 전체 수업 금액 (할인 전) */
  totalOriginPrice?: Maybe<Scalars['Float']>;
  /** 총금액  */
  totalPrice?: Maybe<Scalars['Float']>;
  /** 수정 날짜/시간 */
  updatedAt: Scalars['DateTime'];
};

/** 수업 옵션 생성 */
export type LessonPriceCreateInput = {
  /** 수업횟수 */
  lessonCount?: InputMaybe<Scalars['Float']>;
  /** 수업시간 */
  lessonTime?: InputMaybe<Scalars['Float']>;
  /** 수업 */
  lesson__id: Scalars['ID'];
  /** 순서  */
  priority?: InputMaybe<Scalars['Float']>;
  /** 총금액  */
  totalPrice?: InputMaybe<Scalars['Float']>;
};

export type LessonPriceEdge = {
  __typename?: 'LessonPriceEdge';
  /** 커서 */
  cursor: Scalars['String'];
  /** 노드 */
  node: LessonPrice;
};

/** 수업금액옵션 필터 */
export type LessonPriceFilterInput = {
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** UUID */
  id?: InputMaybe<Array<IdFilterInput>>;
  /** 수업시간 */
  lessonTime?: InputMaybe<Array<StringFilterInput>>;
  /** 수업아이디 */
  lesson__id?: InputMaybe<Array<IdFilterInput>>;
  /** 전체 수업 금액 (할인 전) */
  totalOriginPrice?: InputMaybe<Array<StringFilterInput>>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Array<DateTimeFilterInput>>;
};

/** 수업금액옵션 목록 */
export type LessonPriceList = {
  __typename?: 'LessonPriceList';
  /** 페이지네이션된 데이터 목록 */
  edges: Array<Maybe<LessonPriceEdge>>;
  /** 페이지네이션된 페이지 정보 */
  pageInfo: PageInfo;
  /** 전체 데이터 개 수 */
  totalCount: Scalars['Int'];
};

/** 수업금액옵션 정렬 */
export type LessonPriceOrderByInput = {
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<SortInput>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<SortInput>;
  /** 할인금액 */
  discountPrice?: InputMaybe<SortInput>;
  /** 할인율 */
  discountRate?: InputMaybe<SortInput>;
  /** UUID */
  id?: InputMaybe<SortInput>;
  /** 수업횟수 */
  lessonCount?: InputMaybe<SortInput>;
  /** 수업시간 */
  lessonTime?: InputMaybe<SortInput>;
  /** 1회금액 */
  onePrice?: InputMaybe<SortInput>;
  /** 순서 */
  priority?: InputMaybe<SortInput>;
  /** 전체 수업 금액 (할인 전) */
  totalOriginPrice?: InputMaybe<SortInput>;
  /** 총금액 */
  totalPrice?: InputMaybe<SortInput>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<SortInput>;
};

/** 수업 옵션 설정 */
export type LessonPriceSetInput = {
  /** 수업횟수 */
  lessonCount?: InputMaybe<Scalars['Float']>;
  /** 수업시간 */
  lessonTime?: InputMaybe<Scalars['Float']>;
  /** 순서  */
  priority?: InputMaybe<Scalars['Float']>;
  /** 총금액  */
  totalPrice?: InputMaybe<Scalars['Float']>;
};

/** 수업 옵션 업데이트 */
export type LessonPriceUpdateInput = {
  /** 수업횟수 */
  lessonCount?: InputMaybe<Scalars['Float']>;
  /** 수업시간 */
  lessonTime?: InputMaybe<Scalars['Float']>;
  /** 수업 */
  lesson__id?: InputMaybe<Scalars['ID']>;
  /** 순서  */
  priority?: InputMaybe<Scalars['Float']>;
  /** 총금액  */
  totalPrice?: InputMaybe<Scalars['Float']>;
};

/** 수업 옵션 업데이트 */
export type LessonPriceUpdateMultipleInput = {
  /** 수업 옵션 id */
  id: Scalars['ID'];
  /** 수업횟수 */
  lessonCount?: InputMaybe<Scalars['Float']>;
  /** 수업시간 */
  lessonTime?: InputMaybe<Scalars['Float']>;
  /** 순서  */
  priority?: InputMaybe<Scalars['Float']>;
  /** 총금액  */
  totalPrice?: InputMaybe<Scalars['Float']>;
};

/** 수업 리뷰 */
export type LessonReviewUpdateInput = {
  /** 평점 */
  gpa?: InputMaybe<Scalars['Float']>;
  /** 평점내용 */
  gpaContent?: InputMaybe<Scalars['String']>;
};

/** 수업 정렬용 가공 필드 */
export type LessonSortView = {
  __typename?: 'LessonSortView';
  /** 즐겨찾기수 */
  bookmarkCount?: Maybe<Scalars['Float']>;
  /** 판매수 */
  businessCount?: Maybe<Scalars['Float']>;
  /** 수업 진행 횟수 */
  cumulativeLessonCount?: Maybe<Scalars['Float']>;
  /** 평점 평균 */
  gpaAvg?: Maybe<Scalars['Float']>;
  /** 리뷰수 */
  gpaCount?: Maybe<Scalars['Float']>;
  /** 아이디 */
  id?: Maybe<Scalars['String']>;
  /** 인기 */
  popular?: Maybe<Scalars['Float']>;
};

/** 수업 판매 상태 필터 */
export type LessonStateEnumFilterInput = {
  operator: EnumFilterOperators;
  value?: InputMaybe<LessonStateType>;
  values?: InputMaybe<Array<LessonStateType>>;
};

export enum LessonStateType {
  /** 판매중 */
  Continued = 'CONTINUED',
  /** 판매 중지 */
  Discontinued = 'DISCONTINUED'
}

export enum LessonType {
  /** 시범 수업  */
  Demonstration = 'DEMONSTRATION',
  /** 정규 수업 */
  Regular = 'REGULAR'
}

/** 수업 구분 필터 */
export type LessonTypeEnumFilterInput = {
  operator: EnumFilterOperators;
  value?: InputMaybe<LessonType>;
  values?: InputMaybe<Array<LessonType>>;
};

/** 수업 업데이트 */
export type LessonUpdateInput = {
  /** 카테고리 */
  category__id?: InputMaybe<Scalars['ID']>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** 30분 기준금액 */
  criteriaPrice?: InputMaybe<Scalars['Float']>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  /** 기본 키(UUID) */
  id?: InputMaybe<Scalars['ID']>;
  /** 소개글 */
  introduction?: InputMaybe<Scalars['String']>;
  /** google meet 사용 여부 */
  isUsingGoogleMeet?: InputMaybe<Scalars['Boolean']>;
  /** 수업명 */
  lessonName?: InputMaybe<Scalars['String']>;
  /** 주교재 id */
  mainBook__id?: InputMaybe<Scalars['ID']>;
  /** 판매 상태(판매중, 판매중지) */
  state?: InputMaybe<LessonStateType>;
  /** 부교재 id */
  subBook__id?: InputMaybe<Scalars['ID']>;
  /** 선생님 id */
  teacher__id?: InputMaybe<Scalars['ID']>;
  /** 수업 구분(시범, 정규) */
  type?: InputMaybe<LessonType>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export enum MainPageTeacherKindEnum {
  /** 수업 진행중인 선생님 */
  Ongoing = 'ONGOING',
  /** 지난 선생님 */
  Past = 'PAST'
}

export type MemberBookFilterInput = {
  /** 저자명 */
  author?: InputMaybe<Array<StringFilterInput>>;
  /** 브랜드 이름 */
  brand__name?: InputMaybe<Array<StringFilterInput>>;
  /** 교재 카테고리 id */
  category__id?: InputMaybe<Array<IdFilterInput>>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** UUID */
  id?: InputMaybe<Array<IdFilterInput>>;
  /** 교재 이름 */
  name?: InputMaybe<Array<StringFilterInput>>;
  /** 출판사 이름 */
  publisher__name?: InputMaybe<Array<StringFilterInput>>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Array<DateTimeFilterInput>>;
};

export type MemberBookOrderByInput = {
  /** 추천순 */
  bookSort__bookmarkCount?: InputMaybe<SortInput>;
  /** 구매순 */
  bookSort__businessCount?: InputMaybe<SortInput>;
  /** 할인 후 가격순 */
  bookSort__finalPrice?: InputMaybe<SortInput>;
  /** 평점순(평점 평균) */
  bookSort__gpaAvg?: InputMaybe<SortInput>;
  /** 리뷰순(리뷰수) */
  bookSort__gpaCount?: InputMaybe<SortInput>;
  /** 인기순 */
  bookSort__popular?: InputMaybe<SortInput>;
  id?: InputMaybe<SortInput>;
  /** 할인 전 가격순 */
  price?: InputMaybe<SortInput>;
};

export type MemberBookReview = {
  __typename?: 'MemberBookReview';
  /** 리뷰 작성 시간 */
  bookReviewWriteDate?: Maybe<Scalars['DateTime']>;
  /** 평점 */
  bookgpa?: Maybe<Scalars['Float']>;
  /** 리뷰 내용 */
  bookgpaContent?: Maybe<Scalars['String']>;
  /** 생성 날짜/시간 */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** 삭제 날짜/시간 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** 기본 키(UUID) */
  id?: Maybe<Scalars['ID']>;
  /** 교재 리뷰 노출 여부 */
  isBookGPAVisible?: Maybe<Scalars['Boolean']>;
  /** 수정 날짜/시간 */
  updatedAt?: Maybe<Scalars['DateTime']>;
  writer: WriterInfo;
};

export type MemberBookReviewCreateInput = {
  /** 평점 */
  gpa?: InputMaybe<Scalars['Float']>;
  /** 리뷰 */
  reviewContent?: InputMaybe<Scalars['String']>;
};

export type MemberBookReviewEdge = {
  __typename?: 'MemberBookReviewEdge';
  /** 커서 */
  cursor: Scalars['String'];
  /** 노드 */
  node: MemberBookReview;
};

export type MemberBookReviewList = {
  __typename?: 'MemberBookReviewList';
  /** 페이지네이션된 데이터 목록 */
  edges: Array<Maybe<MemberBookReviewEdge>>;
  /** 페이지네이션된 페이지 정보 */
  pageInfo: PageInfo;
  /** 전체 데이터 개 수 */
  totalCount: Scalars['Int'];
};

export type MemberBusinessFilterInput = {
  /** 교재 id */
  book__id?: InputMaybe<Array<IdFilterInput>>;
  /** 교재 이름 */
  book__name?: InputMaybe<Array<StringFilterInput>>;
  /** 브랜드명 */
  brand__name?: InputMaybe<Array<StringFilterInput>>;
  /** 소득공제 여부 */
  isDeducted?: InputMaybe<Array<BooleanFilterInput>>;
  /** 수업명 */
  lesson__lessonName?: InputMaybe<Array<StringFilterInput>>;
  /** 주문 번호 */
  payment__orderNumber?: InputMaybe<Array<StringFilterInput>>;
  /** 구매일 */
  payment__paidDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 출판사 이름 */
  publisher__name?: InputMaybe<Array<StringFilterInput>>;
  /** 주문 상태 */
  state?: InputMaybe<Array<BusinessStateEnumFilter>>;
  /** 선생님명 */
  teacherUser__name?: InputMaybe<Array<StringFilterInput>>;
  /** 상품 타입 */
  type?: InputMaybe<Array<OrderTypeEnumFilterInput>>;
};

export type MemberChangeSubLessonFilterInput = {
  /** 변경 전 시간 */
  beforeLessonTime?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 변경 사유 */
  changeReason?: InputMaybe<Array<ChangeSubLessonChangeReasonEnumFilter>>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** UUID */
  id?: InputMaybe<Array<IdFilterInput>>;
  /** 변경할 시간 */
  lessonTimeToChange?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 거절 사유 */
  refusalReason?: InputMaybe<Array<ChangeSubLessonRefusalReasonEnumFilter>>;
  /** 상태 */
  state?: InputMaybe<Array<ChangeSubLessonStateEnumFilter>>;
  /** sublesson id */
  subLesson__id?: InputMaybe<Array<IdFilterInput>>;
  /** teacher user id */
  teacherUser__id?: InputMaybe<Array<IdFilterInput>>;
  /** teacher id */
  teacher__id?: InputMaybe<Array<IdFilterInput>>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Array<DateTimeFilterInput>>;
};

export enum MemberChangeSubLessonStateEnumType {
  /** 수락 */
  Acceptance = 'ACCEPTANCE',
  /** 거절 */
  Refusal = 'REFUSAL'
}

export type MemberFaqFilterInput = {
  /** 카테고리 id */
  category__id?: InputMaybe<Array<IdFilterInput>>;
  /** 내용 */
  content?: InputMaybe<Array<StringFilterInput>>;
  /** 종료날짜 */
  expiredDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** UUID */
  id?: InputMaybe<Array<IdFilterInput>>;
  /** 제목 */
  title?: InputMaybe<Array<StringFilterInput>>;
  /** FAQ 타입 */
  type?: InputMaybe<Array<FaqEnumTypeFilterInput>>;
};

export type MemberInquiryCreateInput = {
  /** 카테고리 */
  category: InquiryCategory;
  /** 내용 */
  content: Scalars['String'];
  /** 제목 */
  title: Scalars['String'];
  /** 문의 유형 */
  type: InquiryType;
};

export type MemberInvitedUser = {
  __typename?: 'MemberInvitedUser';
  /** 이메일 */
  email?: Maybe<Scalars['String']>;
  /** 2만원 이상 최초 결제 일자 */
  firstPurchasedDate?: Maybe<Scalars['DateTime']>;
  /** 기본 키(UUID) */
  id: Scalars['ID'];
  /** 가입날짜 */
  joinDate?: Maybe<Scalars['DateTime']>;
};

export type MemberInvitedUserEdge = {
  __typename?: 'MemberInvitedUserEdge';
  /** 커서 */
  cursor: Scalars['String'];
  /** 노드 */
  node: MemberInvitedUser;
};

export type MemberInvitedUserList = {
  __typename?: 'MemberInvitedUserList';
  /** 페이지네이션된 데이터 목록 */
  edges: Array<Maybe<MemberInvitedUserEdge>>;
  /** 페이지네이션된 페이지 정보 */
  pageInfo: PageInfo;
  /** 이번달 적립금 */
  thisMonthReward: Scalars['Float'];
  /** 전체 데이터 개 수 */
  totalCount: Scalars['Int'];
  /** 총 적립금 */
  totalReward: Scalars['Float'];
};

/** 학습 과외 선생님 필터 */
export type MemberLearningTeacherFilterInput = {
  /** 선생님 자격 */
  grade?: InputMaybe<Array<TeacherGradeEnumFilter>>;
  /** 카테고리 */
  lessons__categoryId?: InputMaybe<Array<IdFilterInput>>;
  /** 30분 기준 금액 */
  lessons__criteriaPrice?: InputMaybe<Array<IntFilterInput>>;
  /** 수업 이름 */
  lessons__lessonName?: InputMaybe<Array<StringFilterInput>>;
  /** 과목 */
  subject?: InputMaybe<Array<TeacherSubjectArrayFilter>>;
  /** 학습 대상 */
  teachingTarget?: InputMaybe<Array<TeacherTeachingTargetArrayFilter>>;
  /** 선생님 학교 */
  university?: InputMaybe<Array<StringFilterInput>>;
  /** 선생님 학교 타입 */
  universityType?: InputMaybe<Array<StringFilterInput>>;
  user__gender?: InputMaybe<Array<UserGenderEnumFilter>>;
  /** 선생님 이름 */
  user__name?: InputMaybe<Array<StringFilterInput>>;
};

export type MemberLessonCalculate = {
  __typename?: 'MemberLessonCalculate';
  /** 정산번호 */
  calculateCode?: Maybe<Scalars['String']>;
  /** 정산 확정 일시 */
  calculateConfirmationDate?: Maybe<Scalars['DateTime']>;
  /** 정산연월 */
  calculateDate?: Maybe<Scalars['String']>;
  /** 정산금액 */
  calculatePrice?: Maybe<Scalars['Float']>;
  /** 정산 회차 */
  calculateRound?: Maybe<Scalars['String']>;
  /** 정산 예정 일시 */
  calculateScheduleDate?: Maybe<Scalars['DateTime']>;
  /** 생성 날짜/시간 */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** 삭제 날짜/시간 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** 할인 금액 */
  discountPrice?: Maybe<Scalars['Float']>;
  /** 수수료 */
  fee?: Maybe<Scalars['Float']>;
  /** 최종 금액 */
  finalPrice?: Maybe<Scalars['Float']>;
  /** 기본 키(UUID) */
  id?: Maybe<Scalars['ID']>;
  /** 상품명 */
  lessonName?: Maybe<Scalars['String']>;
  /** 주문번호 */
  orderNumber?: Maybe<Scalars['String']>;
  /** 상품 금액 */
  productPrice?: Maybe<Scalars['Float']>;
  /** 정산 상태 */
  state?: Maybe<LessonCalculateState>;
  /** 전체 횟수 */
  totalRound?: Maybe<Scalars['Float']>;
  /** 수정 날짜/시간 */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MemberLessonCalculateDashboard = {
  __typename?: 'MemberLessonCalculateDashboard';
  /** 정산 수 */
  calculateNumber: Scalars['Float'];
  /** 정산 금액 */
  calculatePrice: Scalars['Float'];
  /** 총 정산 금액 */
  totalCalculatePrice: Scalars['Float'];
};

export type MemberLessonCalculateEdge = {
  __typename?: 'MemberLessonCalculateEdge';
  /** 커서 */
  cursor: Scalars['String'];
  /** 노드 */
  node: MemberLessonCalculate;
};

export type MemberLessonCalculateFilterInput = {
  /** 상품명 */
  businessLessons__lessonName?: InputMaybe<Array<StringFilterInput>>;
  /** 정산예정일 */
  calculateScheduleDate?: InputMaybe<Array<DateTimeFilterInput>>;
};

export type MemberLessonCalculateList = {
  __typename?: 'MemberLessonCalculateList';
  dashboard?: Maybe<MemberLessonCalculateDashboard>;
  /** 페이지네이션된 데이터 목록 */
  edges: Array<Maybe<MemberLessonCalculateEdge>>;
  /** 페이지네이션된 페이지 정보 */
  pageInfo: PageInfo;
  /** 전체 데이터 개 수 */
  totalCount: Scalars['Int'];
};

export type MemberLessonCalculateOrderByInput = {
  /** 정산예정일자 */
  calculateScheduleDate?: InputMaybe<SortInput>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<SortInput>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<SortInput>;
  /** UUID */
  id?: InputMaybe<SortInput>;
  /** 상태 */
  state?: InputMaybe<SortInput>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<SortInput>;
};

/** 사용자용 수업 생성 */
export type MemberLessonCreateInput = {
  /** 카테고리 */
  category__id?: InputMaybe<Scalars['ID']>;
  /** 30분 기준금액 */
  criteriaPrice?: InputMaybe<Scalars['Float']>;
  /** 수업명 */
  introduction?: InputMaybe<Scalars['String']>;
  /** google meet 사용 여부 */
  isUsingGoogleMeet?: InputMaybe<Scalars['Boolean']>;
  /** 수업명 */
  lessonName?: InputMaybe<Scalars['String']>;
  /** 주교재 id */
  mainBook__id?: InputMaybe<Scalars['ID']>;
  /** 판매 상태(판매중, 판매중지) */
  state?: InputMaybe<LessonStateType>;
  /** 부교재 id */
  subBook__id?: InputMaybe<Scalars['ID']>;
  /** 수업 구분(시범, 정규) */
  type?: InputMaybe<LessonType>;
};

/** 수업 업데이트 */
export type MemberLessonUpdateInput = {
  /** 카테고리 */
  category__id?: InputMaybe<Scalars['ID']>;
  /** 30분 기준금액 */
  criteriaPrice?: InputMaybe<Scalars['Float']>;
  /** 수업명 */
  introduction?: InputMaybe<Scalars['String']>;
  /** google meet 사용 여부 */
  isUsingGoogleMeet?: InputMaybe<Scalars['Boolean']>;
  /** 수업명 */
  lessonName?: InputMaybe<Scalars['String']>;
  /** 주교재 id */
  mainBook__id?: InputMaybe<Scalars['ID']>;
  /** 판매 상태(판매중, 판매중지) */
  state?: InputMaybe<LessonStateType>;
  /** 부교재 id */
  subBook__id?: InputMaybe<Scalars['ID']>;
};

export type MemberMainPageBusinessList = {
  __typename?: 'MemberMainPageBusinessList';
  bookmarkedCount: Scalars['Float'];
  /** 페이지네이션된 데이터 목록 */
  edges: Array<Maybe<BusinessEdge>>;
  onGoingCount: Scalars['Float'];
  /** 페이지네이션된 페이지 정보 */
  pageInfo: PageInfo;
  pastCount: Scalars['Float'];
  /** 전체 데이터 개 수 */
  totalCount: Scalars['Int'];
};

export type MemberMyPageTeacher = {
  __typename?: 'MemberMyPageTeacher';
  teacher?: Maybe<Teacher>;
  /** 학습/언어 */
  type?: Maybe<LessonCategoryRootEnumType>;
};

export type MemberMyPageTeacherEdge = {
  __typename?: 'MemberMyPageTeacherEdge';
  /** 커서 */
  cursor: Scalars['String'];
  /** 노드 */
  node: MemberMyPageTeacher;
};

export type MemberMyPageTeacherList = {
  __typename?: 'MemberMyPageTeacherList';
  /** 전체 선생님 수 */
  allCount: Scalars['Float'];
  /** 즐겨찾기한 선생님 수 */
  bookmarkedCount: Scalars['Float'];
  /** 페이지네이션된 데이터 목록 */
  edges: Array<Maybe<MemberMyPageTeacherEdge>>;
  /** 진행중인 선생님 수 */
  onGoingCount: Scalars['Float'];
  /** 페이지네이션된 페이지 정보 */
  pageInfo: PageInfo;
  /** 지난 선생님 수 */
  pastCount: Scalars['Float'];
  /** 전체 데이터 개 수 */
  totalCount: Scalars['Int'];
};

/** 내 선생님 필터 */
export type MemberMyTeacherFilterInput = {
  /** 선생님 유형 필터 */
  condition__type?: InputMaybe<Array<LessonCategoryRootEnumFilter>>;
  /** 수업 명 */
  lessons__lessonName?: InputMaybe<Array<StringFilterInput>>;
  /** 선생님 명 */
  teacherUser__name?: InputMaybe<Array<StringFilterInput>>;
};

export type MemberNoticeFilterInput = {
  /** 카테고리 id */
  category__id?: InputMaybe<Array<IdFilterInput>>;
  /** 내용 */
  content?: InputMaybe<Array<StringFilterInput>>;
  /** 종료날짜 */
  expiredDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** UUID */
  id?: InputMaybe<Array<IdFilterInput>>;
  /** 제목 */
  title?: InputMaybe<Array<StringFilterInput>>;
  /** 공지사항 타입 */
  type?: InputMaybe<Array<NoticeEnumTypeFilterInput>>;
};

export type MemberPartnershipCalculate = {
  __typename?: 'MemberPartnershipCalculate';
  /** 정산 금액 */
  calculateAmount?: Maybe<Scalars['Float']>;
  /** 정산일 */
  calculatedDate?: Maybe<Scalars['DateTime']>;
  /** email */
  email?: Maybe<Scalars['String']>;
  /** 가입일 */
  joinDate?: Maybe<Scalars['DateTime']>;
  /** 결제일 */
  paidDate?: Maybe<Scalars['DateTime']>;
};

export type MemberPartnershipCalculateDashboard = {
  __typename?: 'MemberPartnershipCalculateDashboard';
  /** 연월(yyyymm) */
  calculateDate: Scalars['String'];
  /** 월간 등록 수 */
  calculatedNumber: Scalars['Float'];
  /** 가입자 수 */
  joinedUserNumber: Scalars['Float'];
  /** 이번달 수입 */
  monthCalculate: Scalars['Float'];
  /** 2만원 이상 유료 결제 */
  paymentAmount: Scalars['Float'];
  /** 가입자당 리워드 */
  rewardPerPerson: Scalars['Float'];
  /** 정산 상태 */
  state: PartnershipCalculateState;
  /** 총 적립 금액 (월 상관 없음) */
  totalCalculate: Scalars['Float'];
};

export type MemberPartnershipCalculateEdge = {
  __typename?: 'MemberPartnershipCalculateEdge';
  /** 커서 */
  cursor: Scalars['String'];
  /** 노드 */
  node: MemberPartnershipCalculate;
};

export type MemberPartnershipCalculateList = {
  __typename?: 'MemberPartnershipCalculateList';
  /** 페이지네이션된 데이터 목록 */
  edges: Array<Maybe<MemberPartnershipCalculateEdge>>;
  /** 페이지네이션된 페이지 정보 */
  pageInfo: PageInfo;
  /** 전체 데이터 개 수 */
  totalCount: Scalars['Int'];
};

export type MemberProfile = {
  __typename?: 'MemberProfile';
  /** 나이(한국) */
  age?: Maybe<Scalars['Float']>;
  /** 보유중인 e-book 수 */
  boughtBookNumber?: Maybe<Scalars['Float']>;
  /** 보유 캐시 */
  cash?: Maybe<Scalars['Float']>;
  /** 이름 */
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** 성 */
  lastName?: Maybe<Scalars['String']>;
  /** 이름 */
  name?: Maybe<Scalars['String']>;
  profileFile?: Maybe<File>;
  /** 진행중인 수업 수 */
  progressedLessonNumber?: Maybe<Scalars['Float']>;
};

/** 출판사용 출판사 업데이트 */
export type MemberPublisherUpdateInput = {
  /** 정산 계좌명 */
  accountName?: InputMaybe<Scalars['String']>;
  /** 계좌번호 */
  accountNumber?: InputMaybe<Scalars['String']>;
  /** 통장 사본 파일 ID */
  bankBookFile__id?: InputMaybe<Scalars['ID']>;
  /** 정산 은행 */
  bankName?: InputMaybe<Scalars['String']>;
  /** 사업자명 */
  businessName?: InputMaybe<Scalars['String']>;
  /** 사업자 등록증 파일 ID */
  businessRegistrationFile__id?: InputMaybe<Scalars['ID']>;
  /** 사업자 번호 */
  businessRegistrationNumber?: InputMaybe<Scalars['String']>;
  /** 담당자 이메일 */
  managerEmail?: InputMaybe<Scalars['String']>;
  /** 담당자 이름 */
  managerName?: InputMaybe<Scalars['String']>;
  /** 담당자 전화번호 */
  managerPhone?: InputMaybe<Scalars['String']>;
  /** 출판사 이름 */
  name?: InputMaybe<Scalars['String']>;
  /** 기본 정책 */
  policy?: InputMaybe<Scalars['String']>;
  /** 프로필 이미지 파일 ID */
  profileImageFile__id?: InputMaybe<Scalars['ID']>;
  /** 대표자 이메일 */
  representativeEmail?: InputMaybe<Scalars['String']>;
  /** 대표자 이름 */
  representativeName?: InputMaybe<Scalars['String']>;
  /** 대표자 전화번호 */
  representativePhone?: InputMaybe<Scalars['String']>;
};

export type MemberReceivedChangeSubLessonUpdateInput = {
  /** 직접 입력 */
  personalRefusalReason?: InputMaybe<Scalars['String']>;
  /** 거절 사유 */
  refusalReason?: InputMaybe<ChangeSubLessonRefusalReasonEnumType>;
  state: MemberChangeSubLessonStateEnumType;
};

/** 사용자용 신고 생성 */
export type MemberReportCreateInput = {
  /** 피신고 교재 ID */
  book__id?: InputMaybe<Scalars['ID']>;
  /** 신고 사유 */
  reportReason?: InputMaybe<SanctionReasonEnum>;
  /** 사용자가 입력한 메모 */
  reporterMemo?: InputMaybe<Scalars['String']>;
  /** 수업 ID */
  subLesson__id?: InputMaybe<Scalars['ID']>;
  /** 피신고인 ID */
  suspect__id?: InputMaybe<Scalars['ID']>;
  /** 신고 타입 */
  type: ReportTypeEnum;
};

/** 사용자용 수업 필터 */
export type MemberSubLessonFilterInput = {
  /** 숙제 상태 */
  homeworkState?: InputMaybe<Array<HomeWorkStateEnumFilter>>;
  /** subLesson id */
  id?: InputMaybe<Array<IdFilterInput>>;
  /** 수업일 */
  lessonDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 수업 종료일 */
  lessonEndDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 수업명 */
  lesson__lessonName?: InputMaybe<Array<StringFilterInput>>;
  /** 수업 타입(정규/시범) */
  lesson__type?: InputMaybe<Array<LessonTypeEnumFilterInput>>;
  /** 수업 상태 */
  state?: InputMaybe<Array<SubLessonStateEnumFilter>>;
  /** 학생 입장 시각 */
  studentJoinAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 선생님 입장 시각 */
  teacherJoinAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 선생님유저아이디 */
  teacherUser__id?: InputMaybe<Array<IdFilterInput>>;
  /** 선생님명 */
  teacherUser__name?: InputMaybe<Array<StringFilterInput>>;
};

/** 사용자용 수업 정렬 */
export type MemberSubLessonOrderByInput = {
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<SortInput>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<SortInput>;
  /** UUID */
  id?: InputMaybe<SortInput>;
  /** 수업일 */
  lessonDate?: InputMaybe<SortInput>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<SortInput>;
};

/** 수업 리뷰 */
export type MemberSubLessonReview = {
  __typename?: 'MemberSubLessonReview';
  /** email */
  email?: Maybe<Scalars['String']>;
  /** 평점 */
  gpa?: Maybe<Scalars['Float']>;
  /** 평가 내용 */
  gpaContent?: Maybe<Scalars['String']>;
  /** 수업명 */
  lessonName?: Maybe<Scalars['String']>;
  profileFile?: Maybe<File>;
};

export type MemberSubLessonReviewEdge = {
  __typename?: 'MemberSubLessonReviewEdge';
  /** 커서 */
  cursor: Scalars['String'];
  /** 노드 */
  node: MemberSubLessonReview;
};

export type MemberSubLessonReviewList = {
  __typename?: 'MemberSubLessonReviewList';
  /** 페이지네이션된 데이터 목록 */
  edges: Array<Maybe<MemberSubLessonReviewEdge>>;
  /** 페이지네이션된 페이지 정보 */
  pageInfo: PageInfo;
  /** 전체 데이터 개 수 */
  totalCount: Scalars['Int'];
};

export type MemberTeacherCareerCreateInput = {
  content: Scalars['String'];
  /** 종료연도 */
  endYear?: InputMaybe<Scalars['Float']>;
  /** 소재지 */
  location: Scalars['String'];
  /** 시작연도 */
  startYear?: InputMaybe<Scalars['Float']>;
  title: Scalars['String'];
};

export type MemberTeacherCareerUpdateInput = {
  content?: InputMaybe<Scalars['String']>;
  /** 종료연도 */
  endYear?: InputMaybe<Scalars['Float']>;
  id: Scalars['ID'];
  /** 소재지 */
  location?: InputMaybe<Scalars['String']>;
  /** 시작연도 */
  startYear?: InputMaybe<Scalars['Float']>;
  title?: InputMaybe<Scalars['String']>;
};

/** 사용자용 선생님 자격신청 */
export type MemberTeacherCreateInput = {
  /** 인증 기관 */
  CA?: InputMaybe<Scalars['String']>;
  /** 증빙자료 */
  attachmentFile__id?: InputMaybe<Scalars['ID']>;
  /** 사업자등록증 */
  businessRegistrationCertificateFile__id?: InputMaybe<Scalars['ID']>;
  /** 사업자 등록 번호, -등 특수기호 제거 */
  businessRegistrationNumber?: InputMaybe<Scalars['String']>;
  /** 사업자 개업연월일, yyyyMMdd */
  businessStartDate?: InputMaybe<Scalars['String']>;
  /** 학습 자격 */
  education?: InputMaybe<Teachereducation>;
  /** 성별 */
  gender?: InputMaybe<GenderEnumType>;
  /** 등급(일반 선생님, 전문 선생님) */
  grade?: InputMaybe<TeacherGradeEnum>;
  /** 대학원 */
  graduateSchool?: InputMaybe<Scalars['String']>;
  /** 고등학교 */
  highschool?: InputMaybe<Scalars['String']>;
  /** 신원 인증 파일 id */
  identityFile__id?: InputMaybe<Scalars['ID']>;
  /** 신청 언어 */
  language?: InputMaybe<Scalars['String']>;
  /** 언어소개 사진 id */
  languageIntroductionPhotoFile__id?: InputMaybe<Scalars['ID']>;
  /** 언어소개글 */
  languageIntroductionText?: InputMaybe<Scalars['String']>;
  /** 언어 소개 동영상 id */
  languageIntroductionVideoFile__id?: InputMaybe<Scalars['ID']>;
  /** 학습소개 사진 id */
  learningIntroductionPhotoFile__id?: InputMaybe<Scalars['ID']>;
  /** 학습소개글 */
  learningIntroductionText?: InputMaybe<Scalars['String']>;
  /** 학습 소개 동영상 id */
  learningIntroductionVideoFile__id?: InputMaybe<Scalars['ID']>;
  /** 레벨 */
  level?: InputMaybe<TeacherLanguageLevelEnum>;
  /** 소재지 */
  location?: InputMaybe<Scalars['String']>;
  /** 전공 */
  major?: InputMaybe<Scalars['String']>;
  /** 출신 국가 */
  originCountry?: InputMaybe<Scalars['String']>;
  /** 기타자격명 */
  qualificationName?: InputMaybe<Scalars['String']>;
  /** 대표자명 */
  representativeName?: InputMaybe<Scalars['String']>;
  /** 거주 국가 */
  residenceCountry?: InputMaybe<Scalars['String']>;
  /** 학습 과목 */
  subject?: InputMaybe<Array<TeacherSubjectEnum>>;
  /** 학습 대상 */
  teachingTarget?: InputMaybe<Array<UserType>>;
  /** 대학교 */
  university?: InputMaybe<Scalars['String']>;
  /** 대학교 타입 */
  universityType?: InputMaybe<Scalars['String']>;
};

export type MemberTeacherLessonTimeFilterInput = {
  /** 수업 요일 */
  lessonDays?: InputMaybe<Array<DayOfTheWeekEnumType>>;
  /** 수업 시간 범위 */
  timeRange?: InputMaybe<TeacherLessonTimeRangeFilter>;
  /** timezone */
  timeZone?: InputMaybe<Scalars['String']>;
};

/** 사용자용 선생님 수업 리스트 필터 */
export type MemberTeacherSubLessonFilterInput = {
  /** 숙제 상태 */
  homeworkState?: InputMaybe<Array<HomeWorkStateEnumFilter>>;
  /** subLesson id */
  id?: InputMaybe<Array<IdFilterInput>>;
  /** 수업일 */
  lessonDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 수업 종료일 */
  lessonEndDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 수업명 */
  lesson__lessonName?: InputMaybe<Array<StringFilterInput>>;
  /** 수업 타입(정규/시범) */
  lesson__type?: InputMaybe<Array<LessonTypeEnumFilterInput>>;
  /** 수업 상태 */
  state?: InputMaybe<Array<SubLessonStateEnumFilter>>;
  /** 선생님 id */
  teacher__id?: InputMaybe<Array<IdFilterInput>>;
};

/** 선생님용 선생님 수정 */
export type MemberTeacherUpdateInput = {
  /** 사업자등록증 */
  businessRegistrationCertificateFile__id?: InputMaybe<Scalars['ID']>;
  /** 사업자 등록 번호, -등 특수기호 제거 */
  businessRegistrationNumber?: InputMaybe<Scalars['String']>;
  /** 사업자 개업연월일, yyyyMMdd */
  businessStartDate?: InputMaybe<Scalars['String']>;
  /** 언어소개 사진 id */
  languageIntroductionPhotoFile__id?: InputMaybe<Scalars['ID']>;
  /** 언어소개글 */
  languageIntroductionText?: InputMaybe<Scalars['String']>;
  /** 언어 소개 동영상 id */
  languageIntroductionVideoFile__id?: InputMaybe<Scalars['ID']>;
  /** 학습소개 사진 id */
  learningIntroductionPhotoFile__id?: InputMaybe<Scalars['ID']>;
  /** 학습소개글 */
  learningIntroductionText?: InputMaybe<Scalars['String']>;
  /** 학습 소개 동영상 id */
  learningIntroductionVideoFile__id?: InputMaybe<Scalars['ID']>;
  /** 언어 주 1 */
  mainLanguageA?: InputMaybe<Scalars['String']>;
  /** 언어 주 1 레벨 */
  mainLanguageALevel?: InputMaybe<TeacherLanguageLevelEnum>;
  /** 언어 주 2 */
  mainLanguageB?: InputMaybe<Scalars['String']>;
  /** 언어 주 2 레벨 */
  mainLanguageBLevel?: InputMaybe<TeacherLanguageLevelEnum>;
  /** 출신 국가 */
  originCountry?: InputMaybe<Scalars['String']>;
  /** 대표자명 */
  representativeName?: InputMaybe<Scalars['String']>;
  /** 거주 국가 */
  residenceCountry?: InputMaybe<Scalars['String']>;
  /** 언어 부 1 */
  subLanguageA?: InputMaybe<Scalars['String']>;
  /** 언어 부 1 레벨 */
  subLanguageALevel?: InputMaybe<TeacherLanguageLevelEnum>;
  /** 언어 부 2 */
  subLanguageB?: InputMaybe<Scalars['String']>;
  /** 언어 부 2 레벨 */
  subLanguageBLevel?: InputMaybe<TeacherLanguageLevelEnum>;
  /** 학습 과목 */
  subject?: InputMaybe<Array<TeacherSubjectEnum>>;
  /** 학습 대상 */
  teachingTarget?: InputMaybe<Array<UserType>>;
};

export type MemberTermsOfService = {
  __typename?: 'MemberTermsOfService';
  /** 약관 내용 */
  content?: Maybe<Scalars['String']>;
  /** 생성 날짜/시간 */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** 삭제 날짜/시간 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** 기본 키(UUID) */
  id?: Maybe<Scalars['ID']>;
  /** 필수 여부 */
  isEssential?: Maybe<Scalars['Boolean']>;
  /** 약관 제목 */
  title?: Maybe<Scalars['String']>;
  /** 수정 날짜/시간 */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MemberTermsOfServiceEdge = {
  __typename?: 'MemberTermsOfServiceEdge';
  /** 커서 */
  cursor: Scalars['String'];
  /** 노드 */
  node: MemberTermsOfService;
};

export type MemberTermsOfServiceFilterInput = {
  /** 약관 내용 */
  content?: InputMaybe<Array<StringFilterInput>>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** UUID */
  id?: InputMaybe<Array<IdFilterInput>>;
  /** 필수 여부 */
  isEssential?: InputMaybe<Array<BooleanFilterInput>>;
  /** 약관 제목 */
  title?: InputMaybe<Array<StringFilterInput>>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Array<DateTimeFilterInput>>;
};

export type MemberTermsOfServiceList = {
  __typename?: 'MemberTermsOfServiceList';
  /** 페이지네이션된 데이터 목록 */
  edges: Array<Maybe<MemberTermsOfServiceEdge>>;
  /** 페이지네이션된 페이지 정보 */
  pageInfo: PageInfo;
  /** 전체 데이터 개 수 */
  totalCount: Scalars['Int'];
};

export type MemberTermsOfServiceOrderByInput = {
  /** 약관 내용 */
  content?: InputMaybe<SortInput>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<SortInput>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<SortInput>;
  /** UUID */
  id?: InputMaybe<SortInput>;
  /** 필수 여부 */
  isEssential?: InputMaybe<SortInput>;
  /** 약관 제목 */
  title?: InputMaybe<SortInput>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<SortInput>;
};

export type MemberUpdateInput = {
  /** 생년월일 */
  birthDate?: InputMaybe<Scalars['DateTime']>;
  /** 성별 */
  gender?: InputMaybe<GenderEnumType>;
  /** 프로필 파일 id */
  profileFile__id?: InputMaybe<Scalars['ID']>;
  /** 초,중,고 등 etc 구분 */
  type?: InputMaybe<UserType>;
};

export type MemberUserBlockingFilterInput = {
  /** 차단당한 사용자 이름 */
  blockedUser__name?: InputMaybe<Array<StringFilterInput>>;
  /** 차단당한 사용자 역할 */
  blockedUser__role?: InputMaybe<Array<UserRoleEnumFilter>>;
};

export type MemberUserFilterInput = {
  /** 생년월일 */
  birthDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 국가 */
  country?: InputMaybe<Array<StringFilterInput>>;
  /** 성별 */
  gender?: InputMaybe<Array<UserGenderEnumFilter>>;
  /** UUID */
  id?: InputMaybe<Array<IdFilterInput>>;
  /** 전체 이름 */
  name?: InputMaybe<Array<StringFilterInput>>;
  /** 초,중,고 등 etc 구분 */
  type?: InputMaybe<Array<UserTypeEnumFilter>>;
};

export type MemberUserOrderByInput = {
  /** 생년월일 */
  birthDate?: InputMaybe<SortInput>;
  /** 성별 */
  gender?: InputMaybe<SortInput>;
  /** UUID */
  id?: InputMaybe<SortInput>;
  /** 이름 */
  name?: InputMaybe<SortInput>;
  /** 사용자 유형(초,중,고...) */
  type?: InputMaybe<SortInput>;
};

/** 사용자용 사용자정보 업데이트 데이터 */
export type MemberUserUpdateInput = {
  /** 은행명 */
  bank?: InputMaybe<Scalars['String']>;
  /** 계좌주 */
  bankAccountOwner?: InputMaybe<Scalars['String']>;
  /** 계좌번호 */
  bankNumber?: InputMaybe<Scalars['String']>;
  /** 생년월일 */
  birthDate?: InputMaybe<Scalars['DateTime']>;
  /** email */
  email?: InputMaybe<Scalars['Email']>;
  /** email 인증 uuid */
  emailKey?: InputMaybe<Scalars['String']>;
  /** 성별 */
  gender?: InputMaybe<GenderEnumType>;
  /** phone */
  phone?: InputMaybe<Scalars['PhoneNumber']>;
  /** phone 인증 uuid */
  phoneKey?: InputMaybe<Scalars['String']>;
  /** 프로필파일 ID */
  profileFile__id?: InputMaybe<Scalars['ID']>;
  /** 사용자 유형 */
  type?: InputMaybe<UserType>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** 관리자의 일반 주문 취소/환불 */
  adminBusinessRefund: Business;
  /** 관리자 전용 주문 취소/환불 */
  adminOnlyBusinessRefund: Business;
  /** 교재 등록 승인 - 관리자 */
  approveBookRegistration: Array<BookRegistration>;
  authenticateTwoFactor: AuthToken;
  /** Book 공부하기 참가 */
  bookStudyClass?: Maybe<Scalars['String']>;
  /** 내가 보낸 요청 취소 */
  cancelMemberGuardianRequest: UserGuardian;
  /** 출판사 회원가입 취소 */
  cancelPublisherRegistration: Publisher;
  /** 유저 비밀번호 변경 */
  changePassword: User;
  /**
   * 비밀번호 찾기 비밀번호 변경
   * @deprecated changePasswordByPhone
   */
  changePasswordByEmail: Scalars['Boolean'];
  /** 비밀번호 찾기 비밀번호 변경 */
  changePasswordByPhone: Scalars['Boolean'];
  /**
   * 비밀번호 찾기 비밀번호 변경
   * @deprecated changePasswordByEmail
   */
  changePasswordByVerificationCode: Scalars['Boolean'];
  /** 채팅방 종료 */
  closeChatChannel: ChatChannel;
  /** 계좌 검증 확인 */
  confirmAccountVerificationCode: Scalars['String'];
  /** 어드민 유저 생성 */
  createAdmin: User;
  /** 교재 생성 */
  createBook: Book;
  /** 교재 카테고리 생성 */
  createBookCategory: BookCategory;
  /** 교재 등록 신청 */
  createBookRegistration: BookRegistration;
  /** 구입한 책 리뷰 생성 */
  createBookReview: BookReview;
  /** 브랜드 생성 */
  createBrand: Brand;
  /** 주문 생성 */
  createBusiness: Business;
  /** 캐쉬 생성 */
  createCash: Cash;
  /** 다중 캐쉬 생성 */
  createCashes: Array<Cash>;
  /** 이메일로 다중 캐쉬 생성 */
  createCashesByEmail: Array<Cash>;
  /** 채팅방 생성 */
  createChatChannel: ChatChannel;
  /** 콘텐츠 생성 */
  createContent: Content;
  /** FAQ 카테고리 생성 */
  createFAQCategory: FaqCategory;
  /** 금칙어 생성 */
  createForbiddenWord: ForbiddenWord;
  /** 금칙어외톨이 생성 */
  createForbiddenWordUse: ForbiddenWordUse;
  /** 금지어 제재 생성 */
  createForbiddenWordUseSanction: Array<Report>;
  /** 금칙어 다중 생성 */
  createForbiddenWords: Array<ForbiddenWord>;
  /** 숙제등록 */
  createHomework: SubLesson;
  /** 문의 생성 */
  createInquiry: Inquiry;
  /** 문의 답변 */
  createInquiryAnswer: InquiryReply;
  /** 문의 생성 */
  createInquiryReply: InquiryReply;
  /** 수업 생성 */
  createLesson: Lesson;
  /** 수업 태도 리뷰 작성 */
  createLessonAttitudeReview: SubLesson;
  /** 수업정산 생성 */
  createLessonCalculate: LessonCalculate;
  /** 수업 카테고리 생성 */
  createLessonCategory: LessonCategory;
  /** 수업옵션 생성 */
  createLessonPrice: LessonPrice;
  /** 수업 리뷰 작성 */
  createLessonReview: SubLesson;
  createMemberBookCartItem: BookCart;
  /** 교재 결제 (도서 구입 소득공제 적용) */
  createMemberBookOrder: Payment;
  /** 교재 찜 하기 */
  createMemberBookmarkBook: User;
  /** 선생님 찜 하기 */
  createMemberBookmarkTeacher: User;
  /** 시범수업 결제 */
  createMemberDemonLessonOrder: Payment;
  /** 보호자 요청하기(내가 보호자) */
  createMemberGuardianRequest: UserGuardian;
  createMemberInquiry: Inquiry;
  /** 재문의 생성 */
  createMemberInquiryReply: InquiryReply;
  /** 수업 결제 (도서 구입 소득공제 적용 X) */
  createMemberLessonOrder: Payment;
  /** 결제 승인 요청 */
  createMemberOrderConfirmingRequest: Payment;
  /** 피보호자 요청하기(내가 피보호자) */
  createMemberProtegeRequest: UserGuardian;
  createMemberTeacherCareers: Array<TeacherCareer>;
  createMemberWithdrawal: Withdrawal;
  /** 리뷰, 평점 작성. 기존 리뷰가 있으면 덮어씀. */
  createOrUpdateMemberBookReview: MemberBookReview;
  /**
   * 학생의 수업 리뷰 작성/수정
   * @deprecated createLessonReview와 중복
   */
  createOrUpdateMemberSubLessonReview: SubLesson;
  /** 주문 취소/환불 생성 */
  createOrderInfo: OrderInfo;
  /** 제휴자 정산 생성 */
  createPartnershipCalculate: PartnershipCalculate;
  /** 결제 생성 */
  createPayment: Payment;
  /** 팝업 생성 */
  createPopup: Popup;
  /** 게시글 생성 */
  createPost: Post;
  /** 출판사 생성 */
  createPublisher: Publisher;
  /** 교재 생성 */
  createPublisherBook: Book;
  /** 출판사 브랜드 생성 */
  createPublisherBrand: Brand;
  /** 출판사 정산 생성 */
  createPublisherCalculate: PublisherCalculate;
  /** 주문 취소/환불 생성 */
  createRefundHistory: RefundHistory;
  /** 신고/정지 생성 */
  createReport: Report;
  /** 복수 신고/정지 생성 */
  createReports: Array<Report>;
  /** 패널티 등록 */
  createSanction: Array<Report>;
  /** 개별 수업 생성 */
  createSubLesson: SubLesson;
  /** 선생 생성 */
  createTeacher: Teacher;
  createTeacherBook: Book;
  /** 변경 요청 */
  createTeacherChangeSubLessonRequest: ChangeSubLesson;
  /** 선생 언어 자격 생성 */
  createTeacherLanguageQualification: TeacherLanguageHistory;
  /** 선생님 학습 자격 생성 */
  createTeacherLearningQualification: TeacherLearningHistory;
  createTermsOfService: TermsOfService;
  /** 유저 생성 */
  createUser: User;
  /** 보호자 생성 요청 */
  createUserGuardian: UserGuardian;
  /** 추천친구 생성 */
  createUserInvite: UserInvite;
  /** 출금신청 생성 */
  createWithdrawal: Withdrawal;
  /** 금칙어 전체 삭제 */
  deleteAllForbiddenWords: Array<ForbiddenWord>;
  deleteAllMemberBookCartItems: Scalars['Boolean'];
  /** 단일 교재 삭제 */
  deleteBook: Book;
  /** 교재 카테고리 복수 삭제 */
  deleteBookCategories: Array<BookCategory>;
  /** 교재 카테고리 삭제 */
  deleteBookCategory: BookCategory;
  /** 구입한 책 리뷰 삭제 */
  deleteBookReview: BookReview;
  /** 구입한 책 리뷰 다중 삭제 */
  deleteBookReviews: Array<BookReview>;
  /** 복수 교재 삭제 */
  deleteBooks: Array<Book>;
  /** 브랜드 삭제 */
  deleteBrand: Brand;
  /** 브랜드 복수 삭제 */
  deleteBrands: Array<Brand>;
  /** 주문 삭제 */
  deleteBusiness: Business;
  /** 채팅 메시지 단일 삭제 */
  deleteChatMessage: ChatMessage;
  /** 채팅 메시지 복수 삭제 */
  deleteChatMessages: Array<ChatMessage>;
  /** 콘텐츠 삭제 */
  deleteContent: Content;
  /** FAQ 카테고리 복수 삭제 */
  deleteFAQCategories: Array<FaqCategory>;
  /** FAQ 카테고리 삭제 */
  deleteFAQCategory: FaqCategory;
  /** 금칙어 삭제 */
  deleteForbiddenWord: ForbiddenWord;
  /** 금칙어외톨이 삭제 */
  deleteForbiddenWordUse: ForbiddenWordUse;
  /** 금칙어외톨이 삭제 */
  deleteForbiddenWordUses: Array<ForbiddenWordUse>;
  /** 금칙어 삭제 */
  deleteForbiddenWords: Array<ForbiddenWord>;
  /** 문의 삭제 */
  deleteInquiry: Inquiry;
  /** 문의 삭제 */
  deleteInquiryReply: InquiryReply;
  /** 수업 삭제 */
  deleteLesson: Lesson;
  /** 수업정산 삭제 */
  deleteLessonCalculate: LessonCalculate;
  /** 수업정산 복수 삭제 */
  deleteLessonCalculates: Array<LessonCalculate>;
  /** 수업 카테고리 복수 삭제 */
  deleteLessonCategories: Array<LessonCategory>;
  /** 수업 카테고리 삭제 */
  deleteLessonCategory: LessonCategory;
  /** 수업옵션 삭제 */
  deleteLessonPrice: LessonPrice;
  /** 수업옵션 다중 삭제 */
  deleteLessonPrices: Array<LessonPrice>;
  /** 수업 복수 삭제 */
  deleteLessons: Array<Lesson>;
  /**
   * 차단 해제하기
   * @deprecated memberBlockUser과 중복
   */
  deleteMemberBlockTeacher: User;
  deleteMemberBookCartItem: Scalars['Boolean'];
  deleteMemberBookCartItems: Scalars['Boolean'];
  /** 교재 찜 삭제 */
  deleteMemberBookmarkBook: User;
  /** 선생님 찜 삭제 */
  deleteMemberBookmarkTeacher: User;
  /** 주문 삭제(결제 대기 상태일때만 가능) */
  deleteMemberOrder: Payment;
  deleteMemberTeacherCareers: Array<TeacherCareer>;
  /** 주문 취소/환불 삭제 */
  deleteOrderInfo: OrderInfo;
  /** 주문 취소/환불 다중 삭제 */
  deleteOrderInfos: Array<OrderInfo>;
  /** 제휴자 정산 삭제 */
  deletePartnershipCalculate: PartnershipCalculate;
  /** 제휴자 정산 복수 삭제 */
  deletePartnershipCalculates: Array<PartnershipCalculate>;
  /** 결제 삭제 */
  deletePayment: Payment;
  /** 팝업 삭제 */
  deletePopup: Popup;
  /** 게시글 삭제 */
  deletePost: Post;
  /** 게시글 다중 삭제 */
  deletePosts: Array<Post>;
  /** 출판사 삭제 */
  deletePublisher: Publisher;
  /** 출판사 단일 교재 삭제 */
  deletePublisherBook: Book;
  /** 출판사 복수 교재 삭제 */
  deletePublisherBooks: Array<Book>;
  /** 출판사 브랜드 삭제 */
  deletePublisherBrand: Brand;
  /** 출판사 브랜드 복수 삭제 */
  deletePublisherBrands: Array<Brand>;
  /** 출판사 정산 삭제 */
  deletePublisherCalculate: PublisherCalculate;
  /** 출판사 정산 복수 삭제 */
  deletePublisherCalculates: Array<PublisherCalculate>;
  /** 출판사 탈퇴 */
  deletePublisherMe: Publisher;
  /** 출판사 복수 삭제 */
  deletePublishers: Array<Publisher>;
  /** 주문 취소/환불 삭제 */
  deleteRefundHistory: RefundHistory;
  /** 주문 취소/환불 다중 삭제 */
  deleteRefundHistorys: Array<RefundHistory>;
  /** 신고/정지 삭제 */
  deleteReport: Report;
  /** 개별 수업 삭제 */
  deleteSubLesson: SubLesson;
  /** 사용자 태도 리뷰 삭제 */
  deleteSubLessonAttitudeReview: SubLesson;
  /** 수업 리뷰 삭제 */
  deleteSubLessonReview: SubLesson;
  /** 수업 복수 삭제 */
  deleteSubLessons: Array<SubLesson>;
  /** 선생님 삭제 */
  deleteTeacher: Teacher;
  deleteTeacherBook: Book;
  /** 보낸 요청 삭제 */
  deleteTeacherChangeSubLessonRequest: ChangeSubLesson;
  /** 선생님 언어 자격 삭제 */
  deleteTeacherLanguageQualification: TeacherLanguageHistory;
  /** 선생님 언어 자격 복수 삭제 */
  deleteTeacherLanguageQualifications: Array<TeacherLanguageHistory>;
  /** 선생님 학습 자격 삭제 */
  deleteTeacherLearningQualification: TeacherLearningHistory;
  /** 선생님 학습 자격 복수 삭제 */
  deleteTeacherLearningQualifications: Array<TeacherLearningHistory>;
  /** 선생님 복수 삭제 */
  deleteTeachers: Array<Teacher>;
  /** 유저 삭제 */
  deleteUser: User;
  /** 관리자 임의 계정 탈퇴 */
  deleteUserAccount?: Maybe<User>;
  /** 관리자 임의 다중 계정 탈퇴 */
  deleteUserAccounts?: Maybe<Array<User>>;
  /** 보호자 삭제 */
  deleteUserGuardian: UserGuardian;
  /** 보호자 복수 삭제 */
  deleteUserGuardians: Array<UserGuardian>;
  /** 친구 초대 삭제 삭제 */
  deleteUserInvite: UserInvite;
  /** 유저 복수 삭제 */
  deleteUsers: Array<User>;
  /** 출금신청 삭제 */
  deleteWithdrawal: Withdrawal;
  /** 출금신청 복수 삭제 */
  deleteWithdrawals: Array<Withdrawal>;
  /** 보호자/피보호자 연결 끊기 */
  disconnectMemberGuardian: UserGuardian;
  /** 보호자/피보호자 연결 끊기 */
  disconnectMemberGuardianByUserId: UserGuardian;
  /** 수업 종료 */
  endSubLesson: SubLesson;
  /** Apple 인가 코드로 토큰 얻기 */
  getAppleToken: AppleLoginTokenResponse;
  /** Google 인가 코드로 토큰 얻기 */
  getGoogleToken: GoogleLoginTokenResponse;
  /** Naver 인가 코드로 토큰 얻기 */
  getNaverToken: NaverLoginTokenResponse;
  grantSolVookPermission: SolVookModel;
  /** 이메일 중복 여부 */
  isDuplicateEmail: Scalars['Boolean'];
  joinChatChannel: ChatChannel;
  /** 수업에 참가합니다. */
  joinClass: Scalars['String'];
  /** 단일 채팅방 나가기 */
  leaveChatChannel: ChatChannel;
  /** 복수 채팅방 나가기 */
  leaveChatChannels: Array<ChatChannel>;
  /** 사용자 차단 또는 해제 */
  memberBlockUser: User;
  /** 사용자의 주문 취소/환불 */
  memberBusinessRefund: Business;
  /** 신고 철회 */
  memberCancelReport: Report;
  memberConfirmLessonCalculate: MemberLessonCalculate;
  /** 채팅방 생성 */
  memberCreateChatChannel: ChatChannel;
  /** 수업 생성 */
  memberCreateLesson: Lesson;
  /** 신고/정지 생성 */
  memberCreateReport: Report;
  /** 사용자용 선생 자격 생성 */
  memberCreateTeacher: Teacher;
  /** 수업 삭제 */
  memberDeleteLesson: Lesson;
  /** 소명요청답변 */
  memberRequestExplanation: Report;
  /**
   * 사용자용주문 상태값 업데이트
   * @deprecated 사용 안함
   */
  memberUpdateBusinessState: Business;
  /** 수업 업데이트 */
  memberUpdateLesson: Lesson;
  /**
   * 사용자용주문 상태값 다중 업데이트
   * @deprecated 사용 안함
   */
  memberUpdateManyBusinessState: Array<Business>;
  /** 사용자용 선생님 업데이트 */
  memberUpdateTeacher: Teacher;
  /** 사용자용 유저 업데이트 */
  memberUpdateUser: User;
  /** 출판사 정산 확정 */
  publisherConfirmPublisherCalculate: PublisherCalculate;
  /**
   * 전체 채팅 메시지 읽기
   * @deprecated updateChannelLastSeenNow 변경
   */
  readChatMessages: Scalars['Boolean'];
  readNotification: Notification;
  /** 언어 증빙자료 재신청 */
  reapplyTeacherLanguageAttachmentFile: TeacherLanguageHistory;
  /** 학습 증빙자료 재신청 */
  reapplyTeacherLearningAttachmentFile: TeacherLearningHistory;
  /** 소셜 서비스 갱신 */
  refreshService?: Maybe<Scalars['Boolean']>;
  /** 토큰 재발행 */
  reissueToken: AuthToken;
  /** 교재 등록 거절 - 관리자 */
  rejectBookRegistration: Array<BookRegistration>;
  /** 소명요청 */
  requestExplanation: Report;
  /** 2차인증 초기화 */
  reset2FASecret: User;
  /** 계좌 검증 */
  sendAccountVerificationCode: Scalars['String'];
  /**
   * 비밀번호 분실 이메일 인증코드 보내기
   * @deprecated sendChangePasswordVerificationCodeToPhone
   */
  sendChangePasswordVerificationCodeToEmail: Scalars['Boolean'];
  /** 비밀번호 분실 휴대폰 인증코드 보내기 */
  sendChangePasswordVerificationCodeToPhone: Scalars['Boolean'];
  /** 채팅 메시지 전송 */
  sendChatMessage: ChatMessage;
  /** 아이디(이메일) 찾기 전화번호 인증코드 보내기 */
  sendFindEmailVerificationCodeToPhone: Scalars['Boolean'];
  /** 회원가입 이메일 인증코드 보내기 */
  sendSignUpVerificationCodeToEmail: Scalars['Boolean'];
  /** 회원가입 전화번호 인증코드 보내기 */
  sendSignUpVerificationCodeToPhone: Scalars['Boolean'];
  /** 마스킹 안된 개인정보 인증코드 보내기 */
  sendUnmaskedMeVerificationCodeToPhone: Scalars['Boolean'];
  /**
   * 비밀번호 찾기 인증코드 이메일 보내기
   * @deprecated sendChangePasswordVerificationCodeToEmail
   */
  sendVerificationCodeToEmail: Scalars['String'];
  /** 교재 리뷰 숨김/숨김해제 */
  setBookReviewVisibleStatus: Business;
  /** 복수 유저 제휴 설정 */
  setManyPartnership: Array<User>;
  /**
   * 채팅방 핀 꽂기
   * @deprecated 미사용
   */
  setPinChatChannel: ChatChannel;
  /** 사용자 태도 리뷰 숨김/숨김해제 */
  setSubLessonAttitudeReviewVisibleStatus: SubLesson;
  /** 리뷰 숨김/숨김해제 */
  setSubLessonReviewVisibleStatus: SubLesson;
  /** 수업 옵션 설정 (기존 데이터 delete 후 create) */
  setTeacherLessonPrice: Array<LessonPrice>;
  setTeacherTimeSlots?: Maybe<Array<TimeSlot>>;
  /** 출금 보류 */
  setTeacherWithdrawalSuspension?: Maybe<Teacher>;
  /** 약관 동의/비동의 설정 뮤테이션 */
  setTermsOfServiceAgreement: TermsOfServiceAgreement;
  /** 이메일로 로그인 */
  signInWithEmail: AuthToken;
  /** 이메일로 로그인(관리자) */
  signInWithEmailForAdmin: AuthToken;
  /** 소셜 서비스로 로그인 */
  signInWithService: AuthToken;
  /** 출판사 회원가입 */
  signUpPublisher: Publisher;
  /** 회원가입 */
  signUpUser: Scalars['Boolean'];
  /** 소셜 서비스로 회원가입 */
  signUpWithService: AuthToken;
  startDirectMessage: ChatChannel;
  /**
   * 수업옵션 생성
   * @deprecated setTeacherLessonPrice로 변경
   */
  teacherCreateLessonPrice: LessonPrice;
  /** 수업옵션 다중 생성 */
  teacherCreateLessonPrices: Array<LessonPrice>;
  /**
   * 수업옵션 삭제
   * @deprecated setTeacherLessonPrice로 변경
   */
  teacherDeleteLessonPrice: LessonPrice;
  /** 수업옵션 다중 삭제 */
  teacherDeleteLessonPrices: Array<LessonPrice>;
  /** 숙제평가 */
  teacherHomeworkEvaluation: SubLesson;
  /**
   * 수업옵션 업데이트
   * @deprecated setTeacherLessonPrice로 변경
   */
  teacherUpdateLessonPrice: LessonPrice;
  /** 수업옵션 다중 업데이트 (multiple) */
  teacherUpdateLessonPriceMultiple: Array<LessonPrice>;
  /**
   * 수업옵션 다중 업데이트
   * @deprecated setTeacherLessonPrice로 변경
   */
  teacherUpdateLessonPrices: Array<LessonPrice>;
  /** 푸시 알림 동의 변경 */
  togglePushAgreement: User;
  /** 정보 수신 동의 변경 */
  toggleReceivingAgreement: User;
  /** 어드민 권한 수정 */
  updateAdminRole?: Maybe<AdminRole>;
  updateAdminRoles: Array<AdminRole>;
  /** 교재 수정 */
  updateBook: Book;
  /** 교재 카테고리 복수 업데이트 */
  updateBookCategories: Array<BookCategory>;
  /** 교재 카테고리 업데이트 */
  updateBookCategory: BookCategory;
  /** 교재 카테고리 우선순위 수정 */
  updateBookCategoryPriority: Array<TreeBookCategory>;
  /** 구입한 책 리뷰 업데이트 */
  updateBookReview: BookReview;
  /** 구입한 책 리뷰 다중 업데이트 */
  updateBookReviews: Array<BookReview>;
  /** 교재 판매 상태 수정 */
  updateBookState: Book;
  /** 교재 판매 상태 수정 */
  updateBookStates: Array<Book>;
  /** 복수 교재 수정 */
  updateBooks: Array<Book>;
  /** 브랜드 업데이트 */
  updateBrand: Brand;
  /** 브랜드 수락/거절/보완요청 */
  updateBrandState: Brand;
  /** 다중 브랜드 수락/거절/보완요청 수정 */
  updateBrandStates: Array<Brand>;
  /** 복수 브랜드 업데이트 */
  updateBrands: Array<Brand>;
  /** 주문 업데이트 */
  updateBusiness: Business;
  /** 채널 읽음처리 */
  updateChannelLastSeenNow: ChatChannelParticipant;
  /** 콘텐츠 업데이트 */
  updateContent: Content;
  /** FAQ 카테고리 복수 업데이트 */
  updateFAQCategories: Array<FaqCategory>;
  /** FAQ 카테고리 업데이트 */
  updateFAQCategory: FaqCategory;
  /** FAQ 카테고리 우선순위 수정 */
  updateFAQCategoryPriority: Array<FaqCategory>;
  /** 금칙어 업데이트 */
  updateForbiddenWord: ForbiddenWord;
  /** 금칙어외톨이 업데이트 */
  updateForbiddenWordUse: ForbiddenWordUse;
  /** 금칙어외톨이 업데이트 */
  updateForbiddenWordUses: Array<ForbiddenWordUse>;
  /** 금칙어 업데이트 */
  updateForbiddenWords: Array<ForbiddenWord>;
  /** 문의 업데이트 */
  updateInquiry: Inquiry;
  /** 수업 업데이트 */
  updateLesson: Lesson;
  /** 수업정산 업데이트 */
  updateLessonCalculate: LessonCalculate;
  /** 수업정산 복수 업데이트 */
  updateLessonCalculates: Array<LessonCalculate>;
  /** 수업 카테고리 복수 업데이트 */
  updateLessonCategories: Array<LessonCategory>;
  /** 수업 카테고리 업데이트 */
  updateLessonCategory: LessonCategory;
  /** 수업 카테고리 우선순위 수정 */
  updateLessonCategoryPriority: Array<LessonCategory>;
  /** 수업옵션 업데이트 */
  updateLessonPrice: LessonPrice;
  /** 수업옵션 다중 업데이트 */
  updateLessonPrices: Array<LessonPrice>;
  /** 수업 판매 중지/해제 */
  updateLessonState?: Maybe<Lesson>;
  /** 다중 수업 판매 중지/해제 */
  updateLessonStates?: Maybe<Array<Lesson>>;
  /** 복수 수업 업데이트 */
  updateLessons: Array<Lesson>;
  /** @deprecated sendAccountVerificationCode */
  updateMemberBankAccount: User;
  updateMemberInterestBookCategory: User;
  /** @deprecated book, lesson으로 분리 */
  updateMemberInterestCategory: User;
  updateMemberInterestLessonCategory: User;
  updateMemberProfile: User;
  /** 받은 변경 요청 관리 */
  updateMemberReceivedChangeSubLessonRequest: ChangeSubLesson;
  /** 받은 요청 관리 */
  updateMemberReceivedGuardianRequest: UserGuardian;
  updateMemberTeacherCareers: Array<TeacherCareer>;
  /** 주문 취소/환불 업데이트 */
  updateOrderInfo: OrderInfo;
  /** 주문 취소/환불 다중 업데이트 */
  updateOrderInfos: Array<OrderInfo>;
  /** 제휴자 정산 업데이트 */
  updatePartnershipCalculate: PartnershipCalculate;
  /** 제휴자 정산 복수 업데이트 */
  updatePartnershipCalculates: Array<PartnershipCalculate>;
  /** 결제 업데이트 */
  updatePayment: Payment;
  /** 팝업 업데이트 */
  updatePopup: Popup;
  /** 게시글 업데이트 */
  updatePost: Post;
  updatePostIsHelpful: Scalars['Boolean'];
  /** 게시글 상태 다중 업데이트 */
  updatePostStateMany: Array<Post>;
  /** 출판사 업데이트 */
  updatePublisher: Publisher;
  /** 출판사 교재 수정 */
  updatePublisherBook: Book;
  /** 복수 교재 판매 상태 */
  updatePublisherBookStates: Array<Book>;
  /** 출판사 브랜드 업데이트 */
  updatePublisherBrand: Brand;
  /** 출판사 정산 업데이트 */
  updatePublisherCalculate: PublisherCalculate;
  /** 출판사 정산 복수 업데이트 */
  updatePublisherCalculates: Array<PublisherCalculate>;
  /** 출판사 수수료 업데이트 */
  updatePublisherFee?: Maybe<Publisher>;
  /** 출판사 수수료 다중 업데이트 */
  updatePublisherFeeMany?: Maybe<Array<Publisher>>;
  /** 출판사 업데이트 */
  updatePublisherMe: Publisher;
  /** 출판사 수락/거절/보완요청 */
  updatePublisherRegistrationState?: Maybe<Publisher>;
  /** 복수 출판사 수락/거절/보완요청 */
  updatePublisherRegistrationStates?: Maybe<Array<Publisher>>;
  /** 출판사 활성화/비활성화 */
  updatePublisherState?: Maybe<Publisher>;
  /** 복수 출판사 업데이트 */
  updatePublishers: Array<Publisher>;
  /** 주문 취소/환불 업데이트 */
  updateRefundHistory: RefundHistory;
  /** 주문 취소/환불 다중 업데이트 */
  updateRefundHistorys: Array<RefundHistory>;
  /** 신고/정지 업데이트 */
  updateReport: Report;
  /** 신고/정지 상태 변경 */
  updateReportStates: Array<Report>;
  /** subLessonId로 신고/정지 상태 변경 */
  updateReportStatesBySubLessonIds: Array<Report>;
  /** 복수 신고/정지 업데이트 */
  updateReports: Array<Report>;
  /** 출판사 브랜드 재승인요청으로 업데이트 */
  updateStateReApprovalRequestPublisherBrand: Brand;
  /** 개별 수업 업데이트 */
  updateSubLesson: SubLesson;
  /** 수업 종료 시간 업데이트 */
  updateSubLessonClosedAt: SubLesson;
  /** 복수 수업 업데이트 */
  updateSubLessons: Array<SubLesson>;
  /** 선생님 업데이트 */
  updateTeacher: Teacher;
  updateTeacherBook: Book;
  /** 보낸 변경 요청 관리 */
  updateTeacherChangeSubLessonRequest: ChangeSubLesson;
  /** 선생님 수수료율 변경 */
  updateTeacherFeeRate: Teacher;
  /** 선생님 언어 자격 업데이트 */
  updateTeacherLanguageQualification: TeacherLanguageHistory;
  /** 선생님 언어 자격 수락/거절/보완요청 */
  updateTeacherLanguageQualificationState: TeacherLanguageHistory;
  /** 선생님 언어 자격 복수 업데이트 */
  updateTeacherLanguageQualifications: Array<TeacherLanguageHistory>;
  /** 선생님 학습 자격 업데이트 */
  updateTeacherLearningQualification: TeacherLearningHistory;
  /** 선생님 학습 자격 상태 변경 */
  updateTeacherLearningQualificationState: TeacherLearningHistory;
  /** 선생님 학습 자격 복수 업데이트 */
  updateTeacherLearningQualifications: Array<TeacherLearningHistory>;
  /** 선생님 상태 변경 */
  updateTeacherState: Teacher;
  /** 선생님 소개 영상 승인 상태 변경 */
  updateTeacherVideoLog: TeacherVideoLog;
  /** 선생님 복수 업데이트 */
  updateTeachers: Array<Teacher>;
  updateTermsOfService: TermsOfService;
  /** 유저 업데이트 */
  updateUser: User;
  /** 보호자 업데이트 */
  updateUserGuardian: UserGuardian;
  /** 보호자 복수 업데이트 */
  updateUserGuardians: Array<UserGuardian>;
  /** 추천친구 업데이트 */
  updateUserInvite: UserInvite;
  /** 유저 복수 업데이트 */
  updateUsers: Array<User>;
  /** 출금신청 업데이트 */
  updateWithdrawal: Withdrawal;
  /** 엑셀 포멧: 아이디, 결과, 관리자메모 */
  updateWithdrawalWithExcel?: Maybe<Array<Withdrawal>>;
  /** 출금신청 복수 업데이트 */
  updateWithdrawals: Array<Withdrawal>;
  /** 유저 로그아웃 */
  userSignOut: Scalars['Boolean'];
  /**
   * 사용자의 탈퇴
   * @deprecated 중복됨
   */
  userWithdrawal: User;
  /**
   * 비밀번호 찾기 이메일 인증코드 검증
   * @deprecated verifyChangePasswordPhoneVerificationCode
   */
  verifyChangePasswordEmailVerificationCode: Scalars['String'];
  /** 비밀번호 찾기 전화번호 인증코드 검증 */
  verifyChangePasswordPhoneVerificationCode: Scalars['String'];
  /** 아이디(이메일) 찾기 전화번호 인증코드 검증 */
  verifyFindEmailPhoneVerificationCode: Scalars['String'];
  /** 회원가입 이메일 인증코드 검증 */
  verifySignUpEmailVerificationCode: Scalars['String'];
  /** 회원가입 전화번호 인증코드 검증 */
  verifySignUpPhoneVerificationCode: Scalars['String'];
  /** 마스킹 안된 개인정보 전화번호 인증코드 검증 */
  verifyUnmaskedMePhoneVerificationCode: Scalars['String'];
  /**
   * 비밀번호 찾기 인증코드 검증
   * @deprecated verifyChangePasswordVerificationCode
   */
  verifyVerificationCode: Scalars['Boolean'];
};


export type MutationAdminBusinessRefundArgs = {
  data: BusinessReasonInput;
  id: Scalars['ID'];
};


export type MutationAdminOnlyBusinessRefundArgs = {
  data: BusinessReasonInput;
  id: Scalars['ID'];
};


export type MutationApproveBookRegistrationArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationAuthenticateTwoFactorArgs = {
  code: Scalars['String'];
  token: Scalars['String'];
};


export type MutationBookStudyClassArgs = {
  id: Scalars['ID'];
};


export type MutationCancelMemberGuardianRequestArgs = {
  id: Scalars['ID'];
};


export type MutationChangePasswordArgs = {
  newPassword: Scalars['Password'];
  oldPassword: Scalars['Password'];
};


export type MutationChangePasswordByEmailArgs = {
  email: Scalars['Email'];
  newPassword: Scalars['Password'];
  verifiedEmailKey: Scalars['String'];
};


export type MutationChangePasswordByPhoneArgs = {
  newPassword: Scalars['Password'];
  phone: Scalars['PhoneNumber'];
  verifiedPhoneKey: Scalars['String'];
};


export type MutationChangePasswordByVerificationCodeArgs = {
  clientUniqueKey: Scalars['String'];
  email: Scalars['Email'];
  newPassword: Scalars['Password'];
  verificationCode: Scalars['String'];
};


export type MutationCloseChatChannelArgs = {
  id: Scalars['ID'];
};


export type MutationConfirmAccountVerificationCodeArgs = {
  value: Scalars['String'];
};


export type MutationCreateAdminArgs = {
  data: UserCreateInput;
};


export type MutationCreateBookArgs = {
  data: BookCreateInput;
};


export type MutationCreateBookCategoryArgs = {
  data: BookCategoryCreateInput;
};


export type MutationCreateBookRegistrationArgs = {
  data: BookRegistrationCreateInput;
};


export type MutationCreateBookReviewArgs = {
  data: BookReviewCreateInput;
};


export type MutationCreateBrandArgs = {
  data: BrandCreateInput;
};


export type MutationCreateBusinessArgs = {
  data: BusinessCreateInput;
};


export type MutationCreateCashArgs = {
  data: CashCreateInput;
};


export type MutationCreateCashesArgs = {
  data: CashesCreateInput;
};


export type MutationCreateCashesByEmailArgs = {
  datas: Array<ExcelCashesCreateInput>;
};


export type MutationCreateChatChannelArgs = {
  data: ChatChannelCreateInput;
};


export type MutationCreateContentArgs = {
  data: ContentCreateInput;
};


export type MutationCreateFaqCategoryArgs = {
  data: FaqCategoryCreateInput;
};


export type MutationCreateForbiddenWordArgs = {
  data: ForbiddenWordCreateInput;
};


export type MutationCreateForbiddenWordUseArgs = {
  data: ForbiddenWordUseCreateInput;
};


export type MutationCreateForbiddenWordUseSanctionArgs = {
  data: SanctionCreateInput;
  forbiddenWordUseId: Scalars['ID'];
};


export type MutationCreateForbiddenWordsArgs = {
  datas: Array<ForbiddenWordCreateInput>;
};


export type MutationCreateHomeworkArgs = {
  content: Scalars['String'];
  id: Scalars['ID'];
};


export type MutationCreateInquiryArgs = {
  data: InquiryCreateInput;
};


export type MutationCreateInquiryAnswerArgs = {
  data: InquiryReplyAnswerUpdateInput;
  id: Scalars['ID'];
};


export type MutationCreateInquiryReplyArgs = {
  data: InquiryReplyCreateInput;
};


export type MutationCreateLessonArgs = {
  data: LessonCreateInput;
};


export type MutationCreateLessonAttitudeReviewArgs = {
  data: LessonAttitudeReviewUpdateInput;
  id: Scalars['ID'];
};


export type MutationCreateLessonCalculateArgs = {
  data: LessonCalculateCreateInput;
};


export type MutationCreateLessonCategoryArgs = {
  data: LessonCategoryCreateInput;
};


export type MutationCreateLessonPriceArgs = {
  data: LessonPriceCreateInput;
};


export type MutationCreateLessonReviewArgs = {
  data: LessonReviewUpdateInput;
  id: Scalars['ID'];
};


export type MutationCreateMemberBookCartItemArgs = {
  bookId: Scalars['String'];
};


export type MutationCreateMemberBookOrderArgs = {
  data: BookOrderCreateInput;
};


export type MutationCreateMemberBookmarkBookArgs = {
  bookId: Scalars['String'];
};


export type MutationCreateMemberBookmarkTeacherArgs = {
  teacherId: Scalars['String'];
};


export type MutationCreateMemberDemonLessonOrderArgs = {
  cash: Scalars['Float'];
  lessonDate: Scalars['DateTime'];
  lessonId: Scalars['ID'];
};


export type MutationCreateMemberGuardianRequestArgs = {
  userId: Scalars['ID'];
};


export type MutationCreateMemberInquiryArgs = {
  data: MemberInquiryCreateInput;
};


export type MutationCreateMemberInquiryReplyArgs = {
  content: Scalars['String'];
  inquiryId: Scalars['ID'];
};


export type MutationCreateMemberLessonOrderArgs = {
  data: LessonOrderCreateInput;
};


export type MutationCreateMemberOrderConfirmingRequestArgs = {
  merchant_uid: Scalars['String'];
  paymentKey?: InputMaybe<Scalars['String']>;
};


export type MutationCreateMemberProtegeRequestArgs = {
  userId: Scalars['ID'];
};


export type MutationCreateMemberTeacherCareersArgs = {
  datas: Array<MemberTeacherCareerCreateInput>;
};


export type MutationCreateMemberWithdrawalArgs = {
  amount: Scalars['Float'];
};


export type MutationCreateOrUpdateMemberBookReviewArgs = {
  bookId: Scalars['ID'];
  data: MemberBookReviewCreateInput;
};


export type MutationCreateOrUpdateMemberSubLessonReviewArgs = {
  gpa?: InputMaybe<Scalars['Float']>;
  gpaContent?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};


export type MutationCreateOrderInfoArgs = {
  data: OrderInfoCreateInput;
};


export type MutationCreatePartnershipCalculateArgs = {
  data: PartnershipCalculateCreateInput;
};


export type MutationCreatePaymentArgs = {
  data: PaymentCreateInput;
};


export type MutationCreatePopupArgs = {
  data: PopupCreateInput;
};


export type MutationCreatePostArgs = {
  data: PostCreateInput;
};


export type MutationCreatePublisherArgs = {
  data: PublisherCreateInput;
};


export type MutationCreatePublisherBookArgs = {
  data: PublisherBookCreateInput;
};


export type MutationCreatePublisherBrandArgs = {
  data: PublisherBrandCreateInput;
};


export type MutationCreatePublisherCalculateArgs = {
  data: PublisherCalculateCreateInput;
};


export type MutationCreateRefundHistoryArgs = {
  data: RefundHistoryCreateInput;
};


export type MutationCreateReportArgs = {
  data: ReportCreateInput;
};


export type MutationCreateReportsArgs = {
  datas: Array<ReportCreateInput>;
};


export type MutationCreateSanctionArgs = {
  data: SanctionCreateInput;
};


export type MutationCreateSubLessonArgs = {
  data: SubLessonCreateInput;
};


export type MutationCreateTeacherArgs = {
  data: TeacherCreateInput;
};


export type MutationCreateTeacherBookArgs = {
  data: TeacherBookCreateInput;
};


export type MutationCreateTeacherChangeSubLessonRequestArgs = {
  data: TeacherChangeSubLessonCreateInput;
};


export type MutationCreateTeacherLanguageQualificationArgs = {
  data: TeacherLanguageHistoryCreateInput;
};


export type MutationCreateTeacherLearningQualificationArgs = {
  data: TeacherLearningHistoryCreateInput;
};


export type MutationCreateTermsOfServiceArgs = {
  data: TermsOfServiceCreateInput;
};


export type MutationCreateUserArgs = {
  data: UserCreateInput;
};


export type MutationCreateUserGuardianArgs = {
  data: UserGuardianCreateInput;
};


export type MutationCreateUserInviteArgs = {
  data: UserInviteCreateInput;
};


export type MutationCreateWithdrawalArgs = {
  data: WithdrawalCreateInput;
};


export type MutationDeleteBookArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteBookCategoriesArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationDeleteBookCategoryArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteBookReviewArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteBookReviewsArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationDeleteBooksArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationDeleteBrandArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteBrandsArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationDeleteBusinessArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteChatMessageArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteChatMessagesArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationDeleteContentArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteFaqCategoriesArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationDeleteFaqCategoryArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteForbiddenWordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteForbiddenWordUseArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteForbiddenWordUsesArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationDeleteForbiddenWordsArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationDeleteInquiryArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteInquiryReplyArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteLessonArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteLessonCalculateArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteLessonCalculatesArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationDeleteLessonCategoriesArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationDeleteLessonCategoryArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteLessonPriceArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteLessonPricesArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationDeleteLessonsArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationDeleteMemberBlockTeacherArgs = {
  teacherId: Scalars['String'];
};


export type MutationDeleteMemberBookCartItemArgs = {
  id: Scalars['String'];
};


export type MutationDeleteMemberBookCartItemsArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationDeleteMemberBookmarkBookArgs = {
  bookId: Scalars['String'];
};


export type MutationDeleteMemberBookmarkTeacherArgs = {
  teacherId: Scalars['String'];
};


export type MutationDeleteMemberOrderArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteMemberTeacherCareersArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationDeleteOrderInfoArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteOrderInfosArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationDeletePartnershipCalculateArgs = {
  id: Scalars['ID'];
};


export type MutationDeletePartnershipCalculatesArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationDeletePaymentArgs = {
  id: Scalars['ID'];
};


export type MutationDeletePopupArgs = {
  id: Scalars['ID'];
};


export type MutationDeletePostArgs = {
  id: Scalars['ID'];
};


export type MutationDeletePostsArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationDeletePublisherArgs = {
  id: Scalars['ID'];
};


export type MutationDeletePublisherBookArgs = {
  id: Scalars['ID'];
};


export type MutationDeletePublisherBooksArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationDeletePublisherBrandArgs = {
  id: Scalars['ID'];
};


export type MutationDeletePublisherBrandsArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationDeletePublisherCalculateArgs = {
  id: Scalars['ID'];
};


export type MutationDeletePublisherCalculatesArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationDeletePublisherMeArgs = {
  reason?: InputMaybe<Scalars['String']>;
};


export type MutationDeletePublishersArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationDeleteRefundHistoryArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteRefundHistorysArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationDeleteReportArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteSubLessonArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteSubLessonAttitudeReviewArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteSubLessonReviewArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteSubLessonsArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationDeleteTeacherArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTeacherBookArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTeacherChangeSubLessonRequestArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTeacherLanguageQualificationArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTeacherLanguageQualificationsArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationDeleteTeacherLearningQualificationArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTeacherLearningQualificationsArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationDeleteTeachersArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationDeleteUserArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUserAccountArgs = {
  accountDeleteReason: Scalars['String'];
  id: Scalars['ID'];
  memo: Scalars['String'];
};


export type MutationDeleteUserAccountsArgs = {
  accountDeleteReason: Scalars['String'];
  ids: Array<Scalars['ID']>;
  memo: Scalars['String'];
};


export type MutationDeleteUserGuardianArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUserGuardiansArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationDeleteUserInviteArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUsersArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationDeleteWithdrawalArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteWithdrawalsArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationDisconnectMemberGuardianArgs = {
  id: Scalars['ID'];
};


export type MutationDisconnectMemberGuardianByUserIdArgs = {
  targetId: Scalars['ID'];
};


export type MutationEndSubLessonArgs = {
  data: SubLessonEndInput;
  id: Scalars['ID'];
};


export type MutationGetAppleTokenArgs = {
  authorizationCode: Scalars['String'];
};


export type MutationGetGoogleTokenArgs = {
  authorizationCode: Scalars['String'];
};


export type MutationGetNaverTokenArgs = {
  authorizationCode: Scalars['String'];
  state: Scalars['String'];
};


export type MutationGrantSolVookPermissionArgs = {
  bookId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationIsDuplicateEmailArgs = {
  email: Scalars['Email'];
};


export type MutationJoinChatChannelArgs = {
  id: Scalars['ID'];
};


export type MutationJoinClassArgs = {
  bookIds?: InputMaybe<Array<Scalars['ID']>>;
  id: Scalars['ID'];
  type: ClassTypeEnum;
};


export type MutationLeaveChatChannelArgs = {
  id: Scalars['ID'];
};


export type MutationLeaveChatChannelsArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationMemberBlockUserArgs = {
  isBlocked: Scalars['Boolean'];
  userId: Scalars['ID'];
};


export type MutationMemberBusinessRefundArgs = {
  data: BusinessReasonInput;
  id: Scalars['ID'];
};


export type MutationMemberCancelReportArgs = {
  id: Scalars['ID'];
};


export type MutationMemberConfirmLessonCalculateArgs = {
  id: Scalars['ID'];
};


export type MutationMemberCreateChatChannelArgs = {
  participant__userId: Scalars['ID'];
};


export type MutationMemberCreateLessonArgs = {
  data: MemberLessonCreateInput;
};


export type MutationMemberCreateReportArgs = {
  data: MemberReportCreateInput;
};


export type MutationMemberCreateTeacherArgs = {
  data: MemberTeacherCreateInput;
  teacherType: TeacherTypeEnum;
};


export type MutationMemberDeleteLessonArgs = {
  id: Scalars['ID'];
};


export type MutationMemberRequestExplanationArgs = {
  content: Scalars['String'];
  id: Scalars['ID'];
};


export type MutationMemberUpdateBusinessStateArgs = {
  id: Scalars['ID'];
  state: OrderState;
};


export type MutationMemberUpdateLessonArgs = {
  data: MemberLessonUpdateInput;
  id: Scalars['ID'];
};


export type MutationMemberUpdateManyBusinessStateArgs = {
  ids: Array<Scalars['ID']>;
  state: OrderState;
};


export type MutationMemberUpdateTeacherArgs = {
  data: MemberTeacherUpdateInput;
  id: Scalars['ID'];
};


export type MutationMemberUpdateUserArgs = {
  data: MemberUserUpdateInput;
};


export type MutationPublisherConfirmPublisherCalculateArgs = {
  id: Scalars['ID'];
};


export type MutationReadChatMessagesArgs = {
  channelId: Scalars['ID'];
};


export type MutationReadNotificationArgs = {
  id: Scalars['String'];
};


export type MutationReapplyTeacherLanguageAttachmentFileArgs = {
  fileId: Scalars['ID'];
  id: Scalars['ID'];
};


export type MutationReapplyTeacherLearningAttachmentFileArgs = {
  fileId: Scalars['ID'];
  id: Scalars['ID'];
};


export type MutationRefreshServiceArgs = {
  service: UserSocialServiceType;
};


export type MutationReissueTokenArgs = {
  refreshToken: Scalars['String'];
};


export type MutationRejectBookRegistrationArgs = {
  ids: Array<Scalars['ID']>;
  reason?: InputMaybe<Scalars['String']>;
};


export type MutationRequestExplanationArgs = {
  content: Scalars['String'];
  id: Scalars['ID'];
};


export type MutationReset2FaSecretArgs = {
  id: Scalars['ID'];
};


export type MutationSendAccountVerificationCodeArgs = {
  accountNumber: Scalars['String'];
  bankCode: Scalars['String'];
  ownerName: Scalars['String'];
};


export type MutationSendChangePasswordVerificationCodeToEmailArgs = {
  email: Scalars['Email'];
};


export type MutationSendChangePasswordVerificationCodeToPhoneArgs = {
  email: Scalars['Email'];
  phone: Scalars['PhoneNumber'];
};


export type MutationSendChatMessageArgs = {
  data: ChatMessageCreateInput;
};


export type MutationSendFindEmailVerificationCodeToPhoneArgs = {
  phone: Scalars['PhoneNumber'];
};


export type MutationSendSignUpVerificationCodeToEmailArgs = {
  email: Scalars['Email'];
};


export type MutationSendSignUpVerificationCodeToPhoneArgs = {
  phone: Scalars['PhoneNumber'];
};


export type MutationSendUnmaskedMeVerificationCodeToPhoneArgs = {
  phone: Scalars['PhoneNumber'];
};


export type MutationSendVerificationCodeToEmailArgs = {
  email: Scalars['Email'];
};


export type MutationSetBookReviewVisibleStatusArgs = {
  id: Scalars['ID'];
  isVisible: Scalars['Boolean'];
};


export type MutationSetManyPartnershipArgs = {
  data: SetPartnership;
  ids: Array<Scalars['ID']>;
};


export type MutationSetPinChatChannelArgs = {
  id: Scalars['ID'];
  isPinned: Scalars['Boolean'];
};


export type MutationSetSubLessonAttitudeReviewVisibleStatusArgs = {
  id: Scalars['ID'];
  isVisible: Scalars['Boolean'];
};


export type MutationSetSubLessonReviewVisibleStatusArgs = {
  id: Scalars['ID'];
  isVisible: Scalars['Boolean'];
};


export type MutationSetTeacherLessonPriceArgs = {
  datas: Array<LessonPriceSetInput>;
  lessonId: Scalars['ID'];
};


export type MutationSetTeacherTimeSlotsArgs = {
  datas: Array<TimeSlotCreateInput>;
  rangeEnd: Scalars['DateTime'];
  rangeStart: Scalars['DateTime'];
};


export type MutationSetTeacherWithdrawalSuspensionArgs = {
  data: TeacherWithdrawalSuspendUpdateInput;
  id: Scalars['ID'];
};


export type MutationSetTermsOfServiceAgreementArgs = {
  agreementState: Scalars['Boolean'];
  termsOfServiceId: Scalars['ID'];
};


export type MutationSignInWithEmailArgs = {
  email: Scalars['Email'];
  password: Scalars['Password'];
};


export type MutationSignInWithEmailForAdminArgs = {
  email: Scalars['Email'];
  password: Scalars['Password'];
};


export type MutationSignInWithServiceArgs = {
  accessToken?: InputMaybe<Scalars['String']>;
  identityToken?: InputMaybe<Scalars['String']>;
  refreshToken?: InputMaybe<Scalars['String']>;
  service: UserSocialServiceType;
};


export type MutationSignUpPublisherArgs = {
  data: PublisherSignupInput;
};


export type MutationSignUpUserArgs = {
  data: UserSignUpInput;
};


export type MutationSignUpWithServiceArgs = {
  accessToken?: InputMaybe<Scalars['String']>;
  data: UserSocialSignUpInput;
  identityToken?: InputMaybe<Scalars['String']>;
  refreshToken?: InputMaybe<Scalars['String']>;
  service: UserSocialServiceType;
};


export type MutationStartDirectMessageArgs = {
  userId: Scalars['ID'];
};


export type MutationTeacherCreateLessonPriceArgs = {
  data: LessonPriceCreateInput;
};


export type MutationTeacherCreateLessonPricesArgs = {
  datas: Array<LessonPriceCreateInput>;
};


export type MutationTeacherDeleteLessonPriceArgs = {
  id: Scalars['ID'];
};


export type MutationTeacherDeleteLessonPricesArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationTeacherHomeworkEvaluationArgs = {
  id: Scalars['ID'];
  state: HomeworkState;
};


export type MutationTeacherUpdateLessonPriceArgs = {
  data: LessonPriceUpdateInput;
  id: Scalars['ID'];
};


export type MutationTeacherUpdateLessonPriceMultipleArgs = {
  datas: Array<LessonPriceUpdateMultipleInput>;
};


export type MutationTeacherUpdateLessonPricesArgs = {
  data: LessonPriceUpdateInput;
  ids: Array<Scalars['ID']>;
};


export type MutationTogglePushAgreementArgs = {
  data: TogglePushAgreementInput;
};


export type MutationToggleReceivingAgreementArgs = {
  data: ToggleReceivingAgreementInput;
};


export type MutationUpdateAdminRoleArgs = {
  data: AdminRoleUpdateInput;
  id: Scalars['ID'];
};


export type MutationUpdateAdminRolesArgs = {
  data: AdminRoleUpdateInput;
  ids: Array<Scalars['ID']>;
};


export type MutationUpdateBookArgs = {
  data: BookUpdateInput;
  id: Scalars['ID'];
};


export type MutationUpdateBookCategoriesArgs = {
  data: BookCategoryUpdateInput;
  ids: Array<Scalars['ID']>;
};


export type MutationUpdateBookCategoryArgs = {
  data: BookCategoryUpdateInput;
  id: Scalars['ID'];
};


export type MutationUpdateBookCategoryPriorityArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationUpdateBookReviewArgs = {
  data: BookReviewCreateInput;
  id: Scalars['ID'];
};


export type MutationUpdateBookReviewsArgs = {
  data: BookReviewUpdateInput;
  ids: Array<Scalars['ID']>;
};


export type MutationUpdateBookStateArgs = {
  data: BookStateUpdateInput;
  id: Scalars['ID'];
};


export type MutationUpdateBookStatesArgs = {
  data: BookStateUpdateInput;
  ids: Array<Scalars['ID']>;
};


export type MutationUpdateBooksArgs = {
  data: BookUpdateInput;
  ids: Array<Scalars['ID']>;
};


export type MutationUpdateBrandArgs = {
  data: BrandUpdateInput;
  id: Scalars['ID'];
};


export type MutationUpdateBrandStateArgs = {
  id: Scalars['ID'];
  memo?: InputMaybe<BrandMemoEnum>;
  state: BrandStateType;
};


export type MutationUpdateBrandStatesArgs = {
  ids: Array<Scalars['ID']>;
  memo?: InputMaybe<BrandMemoEnum>;
  state: BrandStateType;
};


export type MutationUpdateBrandsArgs = {
  data: BrandUpdateInput;
  ids: Array<Scalars['ID']>;
};


export type MutationUpdateBusinessArgs = {
  data: BusinessUpdateInput;
  id: Scalars['ID'];
};


export type MutationUpdateChannelLastSeenNowArgs = {
  participantId: Scalars['String'];
};


export type MutationUpdateContentArgs = {
  data: ContentUpdateInput;
  id: Scalars['ID'];
};


export type MutationUpdateFaqCategoriesArgs = {
  data: FaqCategoryUpdateInput;
  ids: Array<Scalars['ID']>;
};


export type MutationUpdateFaqCategoryArgs = {
  data: FaqCategoryUpdateInput;
  id: Scalars['ID'];
};


export type MutationUpdateFaqCategoryPriorityArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationUpdateForbiddenWordArgs = {
  data: ForbiddenWordUpdateInput;
  id: Scalars['ID'];
};


export type MutationUpdateForbiddenWordUseArgs = {
  data: ForbiddenWordUseUpdateInput;
  id: Scalars['ID'];
};


export type MutationUpdateForbiddenWordUsesArgs = {
  data: ForbiddenWordUseUpdateInput;
  ids: Array<Scalars['ID']>;
};


export type MutationUpdateForbiddenWordsArgs = {
  data: ForbiddenWordUpdateInput;
  ids: Array<Scalars['ID']>;
};


export type MutationUpdateInquiryArgs = {
  data: InquiryUpdateInput;
  id: Scalars['ID'];
};


export type MutationUpdateLessonArgs = {
  data: LessonUpdateInput;
  id: Scalars['ID'];
};


export type MutationUpdateLessonCalculateArgs = {
  data: LessonCalculateUpdateInput;
  id: Scalars['ID'];
};


export type MutationUpdateLessonCalculatesArgs = {
  data: LessonCalculateUpdateInput;
  ids: Array<Scalars['ID']>;
};


export type MutationUpdateLessonCategoriesArgs = {
  data: LessonCategoryUpdateInput;
  ids: Array<Scalars['ID']>;
};


export type MutationUpdateLessonCategoryArgs = {
  data: LessonCategoryUpdateInput;
  id: Scalars['ID'];
};


export type MutationUpdateLessonCategoryPriorityArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationUpdateLessonPriceArgs = {
  data: LessonPriceUpdateInput;
  id: Scalars['ID'];
};


export type MutationUpdateLessonPricesArgs = {
  data: LessonPriceUpdateInput;
  ids: Array<Scalars['ID']>;
};


export type MutationUpdateLessonStateArgs = {
  id: Scalars['ID'];
  state: LessonStateType;
};


export type MutationUpdateLessonStatesArgs = {
  ids: Array<Scalars['ID']>;
  state: LessonStateType;
};


export type MutationUpdateLessonsArgs = {
  data: LessonUpdateInput;
  ids: Array<Scalars['ID']>;
};


export type MutationUpdateMemberBankAccountArgs = {
  data: BankAccountUpdateInput;
};


export type MutationUpdateMemberInterestBookCategoryArgs = {
  data: InterestBookCategoryUpdateInput;
};


export type MutationUpdateMemberInterestCategoryArgs = {
  data: InterestCategoryUpdateInput;
};


export type MutationUpdateMemberInterestLessonCategoryArgs = {
  data: InterestLessonCategoryUpdateInput;
};


export type MutationUpdateMemberProfileArgs = {
  data: MemberUpdateInput;
};


export type MutationUpdateMemberReceivedChangeSubLessonRequestArgs = {
  data: MemberReceivedChangeSubLessonUpdateInput;
  id: Scalars['ID'];
};


export type MutationUpdateMemberReceivedGuardianRequestArgs = {
  id: Scalars['ID'];
  state: GuardianState;
};


export type MutationUpdateMemberTeacherCareersArgs = {
  datas: Array<MemberTeacherCareerUpdateInput>;
};


export type MutationUpdateOrderInfoArgs = {
  data: OrderInfoCreateInput;
  id: Scalars['ID'];
};


export type MutationUpdateOrderInfosArgs = {
  data: OrderInfoUpdateInput;
  ids: Array<Scalars['ID']>;
};


export type MutationUpdatePartnershipCalculateArgs = {
  data: PartnershipCalculateUpdateInput;
  id: Scalars['ID'];
};


export type MutationUpdatePartnershipCalculatesArgs = {
  data: PartnershipCalculateUpdateInput;
  ids: Array<Scalars['ID']>;
};


export type MutationUpdatePaymentArgs = {
  data: PaymentUpdateInput;
  id: Scalars['ID'];
};


export type MutationUpdatePopupArgs = {
  data: PopupUpdateInput;
  id: Scalars['ID'];
};


export type MutationUpdatePostArgs = {
  data: PostUpdateInput;
  id: Scalars['ID'];
};


export type MutationUpdatePostIsHelpfulArgs = {
  data: PostHelpInput;
  id: Scalars['ID'];
};


export type MutationUpdatePostStateManyArgs = {
  ids: Array<Scalars['ID']>;
  state: PostExposureState;
};


export type MutationUpdatePublisherArgs = {
  data: PublisherUpdateInput;
  id: Scalars['ID'];
};


export type MutationUpdatePublisherBookArgs = {
  data: PublisherBookUpdateInput;
  id: Scalars['ID'];
};


export type MutationUpdatePublisherBookStatesArgs = {
  ids: Array<Scalars['ID']>;
  state: BookSaleState;
};


export type MutationUpdatePublisherBrandArgs = {
  data: PublisherBrandUpdateInput;
  id: Scalars['ID'];
};


export type MutationUpdatePublisherCalculateArgs = {
  data: PublisherCalculateUpdateInput;
  id: Scalars['ID'];
};


export type MutationUpdatePublisherCalculatesArgs = {
  data: PublisherCalculateUpdateInput;
  ids: Array<Scalars['ID']>;
};


export type MutationUpdatePublisherFeeArgs = {
  data: PublisherFeeUpdateInput;
  id: Scalars['ID'];
};


export type MutationUpdatePublisherFeeManyArgs = {
  data: PublisherFeeUpdateInput;
  ids: Array<Scalars['ID']>;
};


export type MutationUpdatePublisherMeArgs = {
  data: MemberPublisherUpdateInput;
};


export type MutationUpdatePublisherRegistrationStateArgs = {
  data: PublisherRegistrationStateUpdateInput;
  id: Scalars['ID'];
};


export type MutationUpdatePublisherRegistrationStatesArgs = {
  data: PublisherRegistrationStateUpdateInput;
  ids: Array<Scalars['ID']>;
};


export type MutationUpdatePublisherStateArgs = {
  data: PublisherStateUpdateInput;
  id: Scalars['ID'];
};


export type MutationUpdatePublishersArgs = {
  data: PublisherUpdateInput;
  ids: Array<Scalars['ID']>;
};


export type MutationUpdateRefundHistoryArgs = {
  data: RefundHistoryCreateInput;
  id: Scalars['ID'];
};


export type MutationUpdateRefundHistorysArgs = {
  data: RefundHistoryUpdateInput;
  ids: Array<Scalars['ID']>;
};


export type MutationUpdateReportArgs = {
  data: ReportUpdateInput;
  id: Scalars['ID'];
};


export type MutationUpdateReportStatesArgs = {
  data: ReportStateUpdateInput;
  ids: Array<Scalars['ID']>;
};


export type MutationUpdateReportStatesBySubLessonIdsArgs = {
  data: ReportStateUpdateInput;
  ids: Array<Scalars['ID']>;
};


export type MutationUpdateReportsArgs = {
  data: ReportUpdateInput;
  ids: Array<Scalars['ID']>;
};


export type MutationUpdateStateReApprovalRequestPublisherBrandArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateSubLessonArgs = {
  data: SubLessonUpdateInput;
  id: Scalars['ID'];
};


export type MutationUpdateSubLessonClosedAtArgs = {
  closedAt: Scalars['DateTime'];
  id: Scalars['ID'];
  type: SubLessonAttendantType;
};


export type MutationUpdateSubLessonsArgs = {
  data: SubLessonUpdateInput;
  ids: Array<Scalars['ID']>;
};


export type MutationUpdateTeacherArgs = {
  data: TeacherUpdateInput;
  id: Scalars['ID'];
};


export type MutationUpdateTeacherBookArgs = {
  data: TeacherBookUpdateInput;
  id: Scalars['ID'];
};


export type MutationUpdateTeacherChangeSubLessonRequestArgs = {
  data: TeacherChangeSubLessonUpdateInput;
  id: Scalars['ID'];
};


export type MutationUpdateTeacherFeeRateArgs = {
  data: TeacherFeeUpdateInput;
  id: Scalars['ID'];
};


export type MutationUpdateTeacherLanguageQualificationArgs = {
  data: TeacherLanguageHistoryUpdateInput;
  id: Scalars['ID'];
};


export type MutationUpdateTeacherLanguageQualificationStateArgs = {
  data: TeacherLanguageHistoryUpdateStateInput;
  id: Scalars['ID'];
};


export type MutationUpdateTeacherLanguageQualificationsArgs = {
  data: TeacherLanguageHistoryUpdateInput;
  ids: Array<Scalars['ID']>;
};


export type MutationUpdateTeacherLearningQualificationArgs = {
  data: TeacherLearningHistoryUpdateInput;
  id: Scalars['ID'];
};


export type MutationUpdateTeacherLearningQualificationStateArgs = {
  data: TeacherLearningHistoryUpdateStateInput;
  id: Scalars['ID'];
};


export type MutationUpdateTeacherLearningQualificationsArgs = {
  data: TeacherLearningHistoryUpdateInput;
  ids: Array<Scalars['ID']>;
};


export type MutationUpdateTeacherStateArgs = {
  data: TeacherStateUpdateInput;
  id: Scalars['ID'];
};


export type MutationUpdateTeacherVideoLogArgs = {
  data: TeacherVideoLogUpdateInput;
  id: Scalars['ID'];
};


export type MutationUpdateTeachersArgs = {
  data: TeacherUpdateInput;
  ids: Array<Scalars['ID']>;
};


export type MutationUpdateTermsOfServiceArgs = {
  data: TermsOfServiceUpdateInput;
  id: Scalars['ID'];
};


export type MutationUpdateUserArgs = {
  data: UserUpdateInput;
  id: Scalars['ID'];
};


export type MutationUpdateUserGuardianArgs = {
  data: UserGuardianUpdateInput;
  id: Scalars['ID'];
};


export type MutationUpdateUserGuardiansArgs = {
  data: UserGuardianUpdateInput;
  ids: Array<Scalars['ID']>;
};


export type MutationUpdateUserInviteArgs = {
  data: UserInviteUpdateInput;
  id: Scalars['ID'];
};


export type MutationUpdateUsersArgs = {
  data: UserUpdateInput;
  ids: Array<Scalars['ID']>;
};


export type MutationUpdateWithdrawalArgs = {
  data: WithdrawalUpdateInput;
  id: Scalars['ID'];
};


export type MutationUpdateWithdrawalWithExcelArgs = {
  fileId: Scalars['ID'];
};


export type MutationUpdateWithdrawalsArgs = {
  data: WithdrawalUpdateInput;
  ids: Array<Scalars['ID']>;
};


export type MutationUserWithdrawalArgs = {
  id: Scalars['ID'];
};


export type MutationVerifyChangePasswordEmailVerificationCodeArgs = {
  email: Scalars['Email'];
  verificationCode: Scalars['String'];
};


export type MutationVerifyChangePasswordPhoneVerificationCodeArgs = {
  phone: Scalars['PhoneNumber'];
  verificationCode: Scalars['String'];
};


export type MutationVerifyFindEmailPhoneVerificationCodeArgs = {
  phone: Scalars['PhoneNumber'];
  verificationCode: Scalars['String'];
};


export type MutationVerifySignUpEmailVerificationCodeArgs = {
  email: Scalars['Email'];
  verificationCode: Scalars['String'];
};


export type MutationVerifySignUpPhoneVerificationCodeArgs = {
  phone: Scalars['PhoneNumber'];
  verificationCode: Scalars['String'];
};


export type MutationVerifyUnmaskedMePhoneVerificationCodeArgs = {
  phone: Scalars['PhoneNumber'];
  verificationCode: Scalars['String'];
};


export type MutationVerifyVerificationCodeArgs = {
  clientUniqueKey: Scalars['String'];
  email: Scalars['Email'];
  verificationCode: Scalars['String'];
};

export type MyConnections = {
  __typename?: 'MyConnections';
  /** 요청 개수 */
  requestNumber: Scalars['Float'];
  users?: Maybe<Array<User>>;
};

/** 보호자/피보호자 정보 */
export type MyGuardians = {
  __typename?: 'MyGuardians';
  /** 사용자 이메일 */
  email?: Maybe<Scalars['String']>;
  /** id */
  id?: Maybe<Scalars['String']>;
  /** 사용자명 */
  name?: Maybe<Scalars['String']>;
  /** 사용자 전화번호 */
  phone?: Maybe<Scalars['String']>;
  /** 상태 */
  state?: Maybe<GuardianState>;
  /** 구분 */
  type?: Maybe<GuardianType>;
};

export enum MyPageTeacherKindEnum {
  /** 전체 선생님 */
  All = 'ALL',
  /** 즐겨찾기한 선생님 */
  Bookmarked = 'BOOKMARKED',
  /** 수업 진행중인 선생님 */
  Ongoing = 'ONGOING',
  /** 지난 선생님 */
  Past = 'PAST'
}

/** Naver 토큰 */
export type NaverLoginTokenResponse = {
  __typename?: 'NaverLoginTokenResponse';
  access_token?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  error_description?: Maybe<Scalars['String']>;
  expires_in?: Maybe<Scalars['Float']>;
  refresh_token?: Maybe<Scalars['String']>;
  token_type?: Maybe<Scalars['String']>;
};

export enum NoticeEnumType {
  /** 관리자 공지사항 */
  NoticeAdmin = 'NOTICE_ADMIN',
  /** 출판사 공지사항 */
  NoticePublisher = 'NOTICE_PUBLISHER',
  /** 선생님 공지사항 */
  NoticeTeacher = 'NOTICE_TEACHER',
  /** 사용자 공지사항 */
  NoticeUser = 'NOTICE_USER'
}

export type NoticeEnumTypeFilterInput = {
  operator: EnumFilterOperators;
  value?: InputMaybe<NoticeEnumType>;
  values?: InputMaybe<Array<NoticeEnumType>>;
};

export type Notification = {
  __typename?: 'Notification';
  /** 행동 */
  action?: Maybe<NotificationActionEnumType>;
  /** 내용 */
  content?: Maybe<Scalars['String']>;
  /** 생성 날짜/시간 */
  createdAt: Scalars['DateTime'];
  /** 삭제 날짜/시간 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** 기본 키(UUID) */
  id: Scalars['ID'];
  /** 읽은 시간 */
  readAt?: Maybe<Scalars['DateTime']>;
  /** 목적 */
  subject?: Maybe<Scalars['String']>;
  /** 이동ID */
  targetId?: Maybe<Scalars['String']>;
  /** 타입 */
  type: NotificationTypeEnumType;
  /** 수정 날짜/시간 */
  updatedAt: Scalars['DateTime'];
};

export enum NotificationActionEnumType {
  /** 소명 */
  Explanation = 'EXPLANATION',
  /** 어드민 채팅 이동 */
  MoveAdminChat = 'MOVE_ADMIN_CHAT',
  /** 교재함으로 이동 */
  MoveBookBox = 'MOVE_BOOK_BOX',
  /** 보호자/피보호자페이지 이동 */
  MoveGuardian = 'MOVE_GUARDIAN',
  /** 문의상세페이지로 이동 */
  MoveInquiry = 'MOVE_INQUIRY',
  /** 선생님 언어자격신청페이지 이동 */
  MoveLanguageNewQualification = 'MOVE_LANGUAGE_NEW_QUALIFICATION',
  MoveLanguageProfile = 'MOVE_LANGUAGE_PROFILE',
  /** 선생님 마이페이지 언어자격관리 페이지 이동 */
  MoveLanguageQualificationList = 'MOVE_LANGUAGE_QUALIFICATION_LIST',
  /** 선생님 학습자격신청페이지 이동 */
  MoveLearningNewQualification = 'MOVE_LEARNING_NEW_QUALIFICATION',
  MoveLearningProfile = 'MOVE_LEARNING_PROFILE',
  /** 선생님 마이페이지 학습자격관리 페이지 이동 */
  MoveLearningQualificationList = 'MOVE_LEARNING_QUALIFICATION_LIST',
  /** 수업 변경관련으로 수업함 이동 */
  MoveLessonChange = 'MOVE_LESSON_CHANGE',
  /** 학생 수업상세페이지로 이동 */
  MoveLessonDetailStudent = 'MOVE_LESSON_DETAIL_STUDENT',
  /** 선생님 수업상세페이지로 이동 */
  MoveLessonDetailTeacher = 'MOVE_LESSON_DETAIL_TEACHER',
  /** 학생 수업함으로 이동후 팝업창 오픈 */
  MoveLessonReviewStudent = 'MOVE_LESSON_REVIEW_STUDENT',
  /** 선생님 수업함으로 이동후 팝업창 오픈 */
  MoveLessonReviewTeacher = 'MOVE_LESSON_REVIEW_TEACHER',
  /** 수업방으로 이동 */
  MoveLessonRoom = 'MOVE_LESSON_ROOM',
  /** 교재 신고 접수 이동 */
  MoveReportBook = 'MOVE_REPORT_BOOK',
  /** 사용자 신고 접수 이동 */
  MoveReportMember = 'MOVE_REPORT_MEMBER',
  /** 교재 신고 결과 이동 */
  MoveReportResultBook = 'MOVE_REPORT_RESULT_BOOK',
  /** 사용자 신고 결과 이동 */
  MoveReportResultMember = 'MOVE_REPORT_RESULT_MEMBER',
  /** 없음 */
  None = 'NONE'
}

export type NotificationEdge = {
  __typename?: 'NotificationEdge';
  /** 커서 */
  cursor: Scalars['String'];
  /** 노드 */
  node: Notification;
};

/** 알림 필터 */
export type NotificationFilterInput = {
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** UUID */
  id?: InputMaybe<Array<IdFilterInput>>;
  /** 목적 */
  type?: InputMaybe<Array<NotificationTypeEnumFilterInput>>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Array<DateTimeFilterInput>>;
};

export type NotificationList = {
  __typename?: 'NotificationList';
  /** 페이지네이션된 데이터 목록 */
  edges: Array<Maybe<NotificationEdge>>;
  /** 페이지네이션된 페이지 정보 */
  pageInfo: PageInfo;
  /** 전체 데이터 개 수 */
  totalCount: Scalars['Int'];
};

export type NotificationTypeEnumFilterInput = {
  operator: EnumFilterOperators;
  value?: InputMaybe<NotificationTypeEnumType>;
  values?: InputMaybe<Array<NotificationTypeEnumType>>;
};

export enum NotificationTypeEnumType {
  /** 기타 */
  Etc = 'ETC',
  /** 보호자 */
  Guardian = 'GUARDIAN',
  /** 문의 */
  Inquiry = 'INQUIRY',
  /** 수업 */
  Lesson = 'LESSON',
  /** 공지 */
  Notice = 'NOTICE',
  /** 결제 */
  Payment = 'PAYMENT'
}

/** 정렬 시 null 순서 */
export enum Nulls {
  /** null 먼저 */
  First = 'FIRST',
  /** null 마지막에 */
  Last = 'LAST'
}

/** 숫자 필터 연산자 */
export enum NumberFilterOperators {
  Between = 'BETWEEN',
  Equal = 'EQUAL',
  GreaterThan = 'GREATER_THAN',
  GreaterThanEqual = 'GREATER_THAN_EQUAL',
  In = 'IN',
  IsNotNull = 'IS_NOT_NULL',
  IsNull = 'IS_NULL',
  LessThan = 'LESS_THAN',
  LessThanEqual = 'LESS_THAN_EQUAL',
  NotBetween = 'NOT_BETWEEN',
  NotEqual = 'NOT_EQUAL',
  NotIn = 'NOT_IN'
}

/** 정렬 순서 */
export enum Order {
  /** 오름차순 */
  Ascending = 'ASCENDING',
  /** 내림차순 */
  Descending = 'DESCENDING'
}

/** 수업금액옵션 */
export type OrderInfo = {
  __typename?: 'OrderInfo';
  /** 구매 당시 책 수수료  */
  BookFee?: Maybe<Scalars['Float']>;
  /** 생성 날짜/시간 */
  createdAt: Scalars['DateTime'];
  /** 삭제 날짜/시간 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** 할인금액 */
  discountPrice?: Maybe<Scalars['Float']>;
  /** 할인율 */
  discountRate?: Maybe<Scalars['Float']>;
  /** 구매 당시 구글밋 수수료  */
  googleMeetFee?: Maybe<Scalars['Float']>;
  /** 기본 키(UUID) */
  id: Scalars['ID'];
  /** 수업횟수 */
  lessonCount?: Maybe<Scalars['Float']>;
  /** 수업시간 */
  lessonTime?: Maybe<Scalars['Float']>;
  /** 1회금액 */
  onePrice?: Maybe<Scalars['Float']>;
  /** 구매 당시 페이지콜 수수료  */
  pageCallFee?: Maybe<Scalars['Float']>;
  /** 전체 수업 금액(할인전 금액) ,책 할인 전 가격  */
  totalOriginalPrice?: Maybe<Scalars['Float']>;
  /** 총금액  */
  totalPrice?: Maybe<Scalars['Float']>;
  /** 수정 날짜/시간 */
  updatedAt: Scalars['DateTime'];
};

/** 게시글 생성 */
export type OrderInfoCreateInput = {
  /** 구매 당시 책 수수료  */
  BookFee?: InputMaybe<Scalars['Float']>;
  /** 주문 */
  business__id?: InputMaybe<Scalars['ID']>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  /** 할인금액 */
  discountPrice?: InputMaybe<Scalars['Float']>;
  /** 할인율 */
  discountRate?: InputMaybe<Scalars['Float']>;
  /** 구매 당시 구글밋 수수료  */
  googleMeetFee?: InputMaybe<Scalars['Float']>;
  /** 기본 키(UUID) */
  id?: InputMaybe<Scalars['ID']>;
  /** 수업횟수 */
  lessonCount?: InputMaybe<Scalars['Float']>;
  /** 수업시간 */
  lessonTime?: InputMaybe<Scalars['Float']>;
  /** 1회금액 */
  onePrice?: InputMaybe<Scalars['Float']>;
  /** 구매 당시 페이지콜 수수료  */
  pageCallFee?: InputMaybe<Scalars['Float']>;
  /** 전체 수업 금액(할인전 금액) ,책 할인 전 가격  */
  totalOriginalPrice?: InputMaybe<Scalars['Float']>;
  /** 총금액  */
  totalPrice?: InputMaybe<Scalars['Float']>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type OrderInfoEdge = {
  __typename?: 'OrderInfoEdge';
  /** 커서 */
  cursor: Scalars['String'];
  /** 노드 */
  node: OrderInfo;
};

/** 수업금액옵션 필터 */
export type OrderInfoFilterInput = {
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** UUID */
  id?: InputMaybe<Array<IdFilterInput>>;
  /** 수업시간 */
  lessonTime?: InputMaybe<Array<StringFilterInput>>;
  /** 수업아이디 */
  lesson__id?: InputMaybe<Array<IdFilterInput>>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Array<DateTimeFilterInput>>;
};

/** 수업금액옵션 목록 */
export type OrderInfoList = {
  __typename?: 'OrderInfoList';
  /** 페이지네이션된 데이터 목록 */
  edges: Array<Maybe<OrderInfoEdge>>;
  /** 페이지네이션된 페이지 정보 */
  pageInfo: PageInfo;
  /** 전체 데이터 개 수 */
  totalCount: Scalars['Int'];
};

/** 수업금액옵션 정렬 */
export type OrderInfoOrderByInput = {
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<SortInput>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<SortInput>;
  /** 할인금액 */
  discountPrice?: InputMaybe<SortInput>;
  /** 할인율 */
  discountRate?: InputMaybe<SortInput>;
  /** UUID */
  id?: InputMaybe<SortInput>;
  /** 수업횟수 */
  lessonCount?: InputMaybe<SortInput>;
  /** 수업시간 */
  lessonTime?: InputMaybe<SortInput>;
  /** 1회금액 */
  onePrice?: InputMaybe<SortInput>;
  /** 순서 */
  priority?: InputMaybe<SortInput>;
  /** 총금액 */
  totalPrice?: InputMaybe<SortInput>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<SortInput>;
};

/** 게시글 업데이트 */
export type OrderInfoUpdateInput = {
  /** 구매 당시 책 수수료  */
  BookFee?: InputMaybe<Scalars['Float']>;
  /** 주문 */
  business__id?: InputMaybe<Scalars['ID']>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  /** 할인금액 */
  discountPrice?: InputMaybe<Scalars['Float']>;
  /** 할인율 */
  discountRate?: InputMaybe<Scalars['Float']>;
  /** 구매 당시 구글밋 수수료  */
  googleMeetFee?: InputMaybe<Scalars['Float']>;
  /** 기본 키(UUID) */
  id?: InputMaybe<Scalars['ID']>;
  /** 수업횟수 */
  lessonCount?: InputMaybe<Scalars['Float']>;
  /** 수업시간 */
  lessonTime?: InputMaybe<Scalars['Float']>;
  /** 1회금액 */
  onePrice?: InputMaybe<Scalars['Float']>;
  /** 구매 당시 페이지콜 수수료  */
  pageCallFee?: InputMaybe<Scalars['Float']>;
  /** 전체 수업 금액(할인전 금액) ,책 할인 전 가격  */
  totalOriginalPrice?: InputMaybe<Scalars['Float']>;
  /** 총금액  */
  totalPrice?: InputMaybe<Scalars['Float']>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export enum OrderState {
  /** 취소완료 */
  CancelCompleted = 'CANCEL_COMPLETED',
  /** 취소거절 */
  CancelRefusal = 'CANCEL_REFUSAL',
  /** 취소요청 */
  CancelRequest = 'CANCEL_REQUEST',
  /** 주문완료(무통장입금) */
  OrderCompleted = 'ORDER_COMPLETED',
  /** 구매확정 */
  OrderConfirmed = 'ORDER_CONFIRMED',
  /** 부분환불 */
  PartialRefund = 'PARTIAL_REFUND',
  /** 결제완료 */
  PaymentCompletion = 'PAYMENT_COMPLETION',
  /** 환불완료 */
  RefundCompleted = 'REFUND_COMPLETED',
  /** 환불거절 */
  RefundRefusal = 'REFUND_REFUSAL',
  /** 환불요청 */
  RefundRequest = 'REFUND_REQUEST'
}

/** 주문 상태 */
export type OrderStateEnumFilter = {
  operator: EnumFilterOperators;
  value?: InputMaybe<OrderState>;
  values?: InputMaybe<Array<OrderState>>;
};

export enum OrderType {
  /** 교재 */
  Book = 'BOOK',
  /** 수업 */
  Lesson = 'LESSON'
}

/** 주문 타입 */
export type OrderTypeEnumFilterInput = {
  operator: EnumFilterOperators;
  value?: InputMaybe<OrderType>;
  values?: InputMaybe<Array<OrderType>>;
};

/** 페이지 정보 */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** 현재 페이지의 마지막 데이터 커서 */
  endCursor?: Maybe<Scalars['String']>;
  /** 다음 페이지 존재 여부 */
  hasNextPage: Scalars['Boolean'];
  /** 이전 페이지 존재 여부 */
  hasPreviousPage: Scalars['Boolean'];
  /** 현재 페이지의 처음 데이터 커서 */
  startCursor?: Maybe<Scalars['String']>;
};

/** 제휴자 정산 */
export type PartnershipCalculate = {
  __typename?: 'PartnershipCalculate';
  /** 정산번호 */
  calculateCode?: Maybe<Scalars['String']>;
  /** 정산 확인 일시 */
  calculateConfirmationDate?: Maybe<Scalars['DateTime']>;
  /** 정산연월 */
  calculateDate?: Maybe<Scalars['String']>;
  /** 정산 처리 일시 */
  calculateProcessDate?: Maybe<Scalars['DateTime']>;
  /** 생성 날짜/시간 */
  createdAt: Scalars['DateTime'];
  /** 삭제 날짜/시간 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** 기본 키(UUID) */
  id: Scalars['ID'];
  /** 월간 정산 금액 */
  monthCalculate?: Maybe<Scalars['Float']>;
  /** 월간 최초 2만원 이상 결제자 수 */
  monthPurchasedUserCount?: Maybe<Scalars['Float']>;
  /** 2만원 이상 유료 결제 */
  paymentAmount?: Maybe<Scalars['Float']>;
  /** 정산 상태 */
  state?: Maybe<PartnershipCalculateState>;
  /** 수정 날짜/시간 */
  updatedAt: Scalars['DateTime'];
  /** 유저 */
  user?: Maybe<User>;
};

/** 제휴자 정산 생성 */
export type PartnershipCalculateCreateInput = {
  /** 정산번호 */
  calculateCode?: InputMaybe<Scalars['String']>;
  /** 정산 확인 일시 */
  calculateConfirmationDate?: InputMaybe<Scalars['DateTime']>;
  /** 정산연월 */
  calculateDate?: InputMaybe<Scalars['String']>;
  /** 정산 처리 일시 */
  calculateProcessDate?: InputMaybe<Scalars['DateTime']>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  /** 기본 키(UUID) */
  id?: InputMaybe<Scalars['ID']>;
  /** 월간 정산 금액 */
  monthCalculate?: InputMaybe<Scalars['Float']>;
  /** 월간 최초 2만원 이상 결제자 수 */
  monthPurchasedUserCount?: InputMaybe<Scalars['Float']>;
  /** 2만원 이상 유료 결제 */
  paymentAmount?: InputMaybe<Scalars['Float']>;
  /** 정산 상태 */
  state?: InputMaybe<PartnershipCalculateState>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** 유저 ID */
  user__id?: InputMaybe<Scalars['ID']>;
};

export type PartnershipCalculateEdge = {
  __typename?: 'PartnershipCalculateEdge';
  /** 커서 */
  cursor: Scalars['String'];
  /** 노드 */
  node: PartnershipCalculate;
};

/** 제휴 정산 필터 */
export type PartnershipCalculateFilterInput = {
  /** 정산번호 */
  calculateCode?: InputMaybe<Array<StringFilterInput>>;
  /** 정산연월 */
  calculateDate?: InputMaybe<Array<StringFilterInput>>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** UUID */
  id?: InputMaybe<Array<IdFilterInput>>;
  /** 정산 상태 */
  state?: InputMaybe<Array<PartnershipCalculateStateEnumFilter>>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 제휴자 이메일 */
  user__email?: InputMaybe<Array<StringFilterInput>>;
  /** 제휴자 아이디 */
  user__id?: InputMaybe<Array<IdFilterInput>>;
  /** 제휴자 명 */
  user__name?: InputMaybe<Array<StringFilterInput>>;
};

export type PartnershipCalculateItem = {
  __typename?: 'PartnershipCalculateItem';
  /** 정산금액 */
  calculateAmount: Scalars['Float'];
  /** 캐시 타입 */
  cashType: CashType;
  /** 생성 날짜/시간 */
  createdAt: Scalars['DateTime'];
  /** 삭제 날짜/시간 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** 기본 키(UUID) */
  id: Scalars['ID'];
  /** 제휴자 */
  partnerUser: User;
  /** 정산 대상 수업 */
  subLesson: SubLesson;
  /** 수정 날짜/시간 */
  updatedAt: Scalars['DateTime'];
};

export type PartnershipCalculateItemEdge = {
  __typename?: 'PartnershipCalculateItemEdge';
  /** 커서 */
  cursor: Scalars['String'];
  /** 노드 */
  node: PartnershipCalculateItem;
};

/** 제휴 정산 리스트 */
export type PartnershipCalculateList = {
  __typename?: 'PartnershipCalculateList';
  /** 페이지네이션된 데이터 목록 */
  edges: Array<Maybe<PartnershipCalculateEdge>>;
  /** 페이지네이션된 페이지 정보 */
  pageInfo: PageInfo;
  /** 전체 데이터 개 수 */
  totalCount: Scalars['Int'];
};

/** 정산 정렬 */
export type PartnershipCalculateOrderByInput = {
  /** 정산번호 */
  calculateCode?: InputMaybe<SortInput>;
  /** 정산연월 */
  calculateDate?: InputMaybe<SortInput>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<SortInput>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<SortInput>;
  /** UUID */
  id?: InputMaybe<SortInput>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<SortInput>;
  /** 제휴자 이메일 */
  user__email?: InputMaybe<SortInput>;
  /** 유저 이메일 */
  user__id?: InputMaybe<SortInput>;
  /** 제휴자 명 */
  user__name?: InputMaybe<SortInput>;
};

/** 제휴 정산 상태 */
export type PartnershipCalculateStateEnumFilter = {
  operator: EnumFilterOperators;
  value?: InputMaybe<PartnershipCalculateState>;
  values?: InputMaybe<Array<PartnershipCalculateState>>;
};

/** 제휴자 정산 업데이트 */
export type PartnershipCalculateUpdateInput = {
  /** 정산번호 */
  calculateCode?: InputMaybe<Scalars['String']>;
  /** 정산 확인 일시 */
  calculateConfirmationDate?: InputMaybe<Scalars['DateTime']>;
  /** 정산연월 */
  calculateDate?: InputMaybe<Scalars['String']>;
  /** 정산 처리 일시 */
  calculateProcessDate?: InputMaybe<Scalars['DateTime']>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  /** 기본 키(UUID) */
  id?: InputMaybe<Scalars['ID']>;
  /** 월간 정산 금액 */
  monthCalculate?: InputMaybe<Scalars['Float']>;
  /** 월간 최초 2만원 이상 결제자 수 */
  monthPurchasedUserCount?: InputMaybe<Scalars['Float']>;
  /** 2만원 이상 유료 결제 */
  paymentAmount?: InputMaybe<Scalars['Float']>;
  /** 정산 상태 */
  state?: InputMaybe<PartnershipCalculateState>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

/** 결제정보 */
export type Payment = {
  __typename?: 'Payment';
  /** 금액 */
  amount?: Maybe<Scalars['Float']>;
  /** 교재 주문만 */
  bookBusinesses?: Maybe<Array<Business>>;
  /** 주문 */
  businesses?: Maybe<Array<Business>>;
  /** 생성 날짜/시간 */
  createdAt: Scalars['DateTime'];
  /** 삭제 날짜/시간 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** 할인율 */
  discountPercent?: Maybe<Scalars['Float']>;
  /** 할인금액 */
  discountPrice?: Maybe<Scalars['Float']>;
  /** 내용 */
  error?: Maybe<Scalars['String']>;
  /** 기본 키(UUID) */
  id: Scalars['ID'];
  /** 수업 주문만 */
  lessonBusinesses?: Maybe<Array<Business>>;
  /** 주문번호 */
  merchant_uid?: Maybe<Scalars['String']>;
  /** 주문 번호 */
  orderNumber?: Maybe<Scalars['String']>;
  /** 결제완료일 */
  paidDate?: Maybe<Scalars['DateTime']>;
  /** 결제타입(카드,현금,무통장) */
  paymentType?: Maybe<PaymentTypeEnumType>;
  /** 영수증 */
  receipt?: Maybe<Scalars['String']>;
  /** 상태 (결제,결제대기,부분취소,취소,에러) */
  state?: Maybe<PaymentStateEnumType>;
  /** 제목 */
  title?: Maybe<Scalars['String']>;
  /** 수정 날짜/시간 */
  updatedAt: Scalars['DateTime'];
};

/** 결제 생성 */
export type PaymentCreateInput = {
  /** 금액 */
  amount?: InputMaybe<Scalars['Float']>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  /** 할인율 */
  discountPercent?: InputMaybe<Scalars['Float']>;
  /** 할인금액 */
  discountPrice?: InputMaybe<Scalars['Float']>;
  /** 내용 */
  error?: InputMaybe<Scalars['String']>;
  /** 기본 키(UUID) */
  id?: InputMaybe<Scalars['ID']>;
  /** 주문번호 */
  merchant_uid?: InputMaybe<Scalars['String']>;
  /** 주문 번호 */
  orderNumber?: InputMaybe<Scalars['String']>;
  /** 결제완료일 */
  paidDate?: InputMaybe<Scalars['DateTime']>;
  /** 결제타입(카드,현금,무통장) */
  paymentType?: InputMaybe<PaymentTypeEnumType>;
  /** 영수증 */
  receipt?: InputMaybe<Scalars['String']>;
  /** 상태 (결제,결제대기,부분취소,취소,에러) */
  state?: InputMaybe<PaymentStateEnumType>;
  /** 제목 */
  title?: InputMaybe<Scalars['String']>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PaymentEdge = {
  __typename?: 'PaymentEdge';
  /** 커서 */
  cursor: Scalars['String'];
  /** 노드 */
  node: Payment;
};

/** 결제 필터 */
export type PaymentFilterInput = {
  /** 가격 */
  amount?: InputMaybe<Array<IntFilterInput>>;
  /** 교재명 */
  book__name?: InputMaybe<Array<StringFilterInput>>;
  /** 주문 상태 */
  businesses__state?: InputMaybe<Array<OrderStateEnumFilter>>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** UUID */
  id?: InputMaybe<Array<IdFilterInput>>;
  /** 수업명 */
  lesson__lessonName?: InputMaybe<Array<StringFilterInput>>;
  /** 주문 번호 */
  orderNumber?: InputMaybe<Array<StringFilterInput>>;
  /** 결제완료일 */
  paidDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 타입 */
  paymentType?: InputMaybe<Array<PaymentTypeEnumFilter>>;
  /** 출판사명 */
  publisher__name?: InputMaybe<Array<StringFilterInput>>;
  /** 상태 */
  state?: InputMaybe<Array<PaymentStateEnumFilter>>;
  /** 선생님명 */
  teacherUser__name?: InputMaybe<Array<StringFilterInput>>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Array<DateTimeFilterInput>>;
};

/** 결제 목록 */
export type PaymentList = {
  __typename?: 'PaymentList';
  /** 페이지네이션된 데이터 목록 */
  edges: Array<Maybe<PaymentEdge>>;
  /** 페이지네이션된 페이지 정보 */
  pageInfo: PageInfo;
  /** 전체 데이터 개 수 */
  totalCount: Scalars['Int'];
};

/** 결제 정렬 */
export type PaymentOrderByInput = {
  /** 작성자 */
  amount?: InputMaybe<SortInput>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<SortInput>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<SortInput>;
  /** UUID */
  id?: InputMaybe<SortInput>;
  /** 주문 번호 */
  orderNumber?: InputMaybe<SortInput>;
  /** 결제완료일 */
  paidDate?: InputMaybe<SortInput>;
  /** 타입 */
  paymentType?: InputMaybe<SortInput>;
  /** 상태 */
  state?: InputMaybe<SortInput>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<SortInput>;
};

/** 결제 상태 enum */
export type PaymentStateEnumFilter = {
  operator: EnumFilterOperators;
  value?: InputMaybe<PaymentStateEnumType>;
  values?: InputMaybe<Array<PaymentStateEnumType>>;
};

export enum PaymentStateEnumType {
  /** 취소 */
  Cancel = 'CANCEL',
  /** 결제완료 */
  Completion = 'COMPLETION',
  /** 실패 */
  Fail = 'FAIL',
  /** 부분취소 */
  PartialCancel = 'PARTIAL_CANCEL',
  /** 결제대기 */
  Requesting = 'REQUESTING'
}

export enum PaymentTypeEnumType {
  /** 카드 */
  Card = 'CARD',
  /** 현금 */
  Cash = 'CASH',
  /** 예외 */
  Etc = 'ETC'
}

/** 결제 업데이트 */
export type PaymentUpdateInput = {
  /** 금액 */
  amount?: InputMaybe<Scalars['Float']>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  /** 할인율 */
  discountPercent?: InputMaybe<Scalars['Float']>;
  /** 할인금액 */
  discountPrice?: InputMaybe<Scalars['Float']>;
  /** 내용 */
  error?: InputMaybe<Scalars['String']>;
  /** 기본 키(UUID) */
  id?: InputMaybe<Scalars['ID']>;
  /** 주문번호 */
  merchant_uid?: InputMaybe<Scalars['String']>;
  /** 주문 번호 */
  orderNumber?: InputMaybe<Scalars['String']>;
  /** 결제완료일 */
  paidDate?: InputMaybe<Scalars['DateTime']>;
  /** 결제타입(카드,현금,무통장) */
  paymentType?: InputMaybe<PaymentTypeEnumType>;
  /** 영수증 */
  receipt?: InputMaybe<Scalars['String']>;
  /** 상태 (결제,결제대기,부분취소,취소,에러) */
  state?: InputMaybe<PaymentStateEnumType>;
  /** 제목 */
  title?: InputMaybe<Scalars['String']>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

/** 콘텐츠 */
export type Popup = {
  __typename?: 'Popup';
  /** 생성 날짜/시간 */
  createdAt: Scalars['DateTime'];
  /** 삭제 날짜/시간 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** 종료날짜 */
  endDate?: Maybe<Scalars['DateTime']>;
  /** 기본 키(UUID) */
  id: Scalars['ID'];
  /** 첨부파일 */
  image?: Maybe<File>;
  /** 링크 */
  link?: Maybe<Scalars['String']>;
  /** 게시 위치 */
  location?: Maybe<PopupLocation>;
  /** 처리 관리자 */
  processor?: Maybe<User>;
  /** 게시날짜 */
  publishedDate?: Maybe<Scalars['DateTime']>;
  /** 상태 */
  state?: Maybe<PopupState>;
  /** 제목 */
  title?: Maybe<Scalars['String']>;
  /** 타입 */
  type?: Maybe<PopupType>;
  /** 수정 날짜/시간 */
  updatedAt: Scalars['DateTime'];
  /** 작성자 */
  writer?: Maybe<User>;
};

/** 콘텐츠 생성 */
export type PopupCreateInput = {
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  /** 종료날짜 */
  endDate?: InputMaybe<Scalars['DateTime']>;
  /** 기본 키(UUID) */
  id?: InputMaybe<Scalars['ID']>;
  /** 파일 id */
  image__id?: InputMaybe<Scalars['ID']>;
  /** 링크 */
  link?: InputMaybe<Scalars['String']>;
  /** 게시 위치 */
  location?: InputMaybe<PopupLocation>;
  /** 게시날짜 */
  publishedDate?: InputMaybe<Scalars['DateTime']>;
  /** 상태 */
  state?: InputMaybe<PopupState>;
  /** 제목 */
  title?: InputMaybe<Scalars['String']>;
  /** 타입 */
  type?: InputMaybe<PopupType>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** 작성자 id */
  writer__id?: InputMaybe<Scalars['ID']>;
};

export type PopupEdge = {
  __typename?: 'PopupEdge';
  /** 커서 */
  cursor: Scalars['String'];
  /** 노드 */
  node: Popup;
};

/** 콘텐츠 필터 */
export type PopupFilterInput = {
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 종료 날짜 */
  endDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** UUID */
  id?: InputMaybe<Array<IdFilterInput>>;
  /** 종류 */
  location?: InputMaybe<Array<PopupLocationEnumFilter>>;
  /** 처리 관리자 email */
  processor__email?: InputMaybe<Array<StringFilterInput>>;
  /** 처리 관리자 id */
  processor__id?: InputMaybe<Array<IdFilterInput>>;
  /** 처리 관리자 이름 */
  processor__name?: InputMaybe<Array<StringFilterInput>>;
  /** 게시 날짜 */
  publishedDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 노출 상태 */
  state?: InputMaybe<Array<PopupStateEnumFilter>>;
  /** 제목 */
  title?: InputMaybe<Array<StringFilterInput>>;
  /** 타입 */
  type?: InputMaybe<Array<PopupTypeEnumFilter>>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 작성자이메일 */
  writer__email?: InputMaybe<Array<StringFilterInput>>;
  /** 작성자 */
  writer__id?: InputMaybe<Array<IdFilterInput>>;
  /** 작성자이름 */
  writer__name?: InputMaybe<Array<StringFilterInput>>;
};

/** 게시판 목록 */
export type PopupList = {
  __typename?: 'PopupList';
  /** 페이지네이션된 데이터 목록 */
  edges: Array<Maybe<PopupEdge>>;
  /** 페이지네이션된 페이지 정보 */
  pageInfo: PageInfo;
  /** 전체 데이터 개 수 */
  totalCount: Scalars['Int'];
};

export enum PopupLocation {
  /** 사용자 비로그인 메인 */
  NotLoginMain = 'NOT_LOGIN_MAIN',
  /** 출판사 대쉬보드 */
  PublisherDashBoard = 'PUBLISHER_DASH_BOARD',
  /** 선생님 대쉬보드 */
  TeacherDashBoard = 'TEACHER_DASH_BOARD',
  /** 사용자 대쉬보드 */
  UserDashBoard = 'USER_DASH_BOARD'
}

/** 콘텐츠 게시위치 enum */
export type PopupLocationEnumFilter = {
  operator: EnumFilterOperators;
  value?: InputMaybe<PopupLocation>;
  values?: InputMaybe<Array<PopupLocation>>;
};

/** 콘텐츠 정렬 */
export type PopupOrderByInput = {
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<SortInput>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<SortInput>;
  /** UUID */
  id?: InputMaybe<SortInput>;
  /** 처리 관리자 이메일 */
  processor__email?: InputMaybe<SortInput>;
  /** 제목 */
  title?: InputMaybe<SortInput>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<SortInput>;
  /** 작성자메일 */
  writer__email?: InputMaybe<SortInput>;
  /** 작성자 */
  writer__id?: InputMaybe<SortInput>;
  /** 작성자 */
  writer__name?: InputMaybe<SortInput>;
};

export enum PopupState {
  /** 노출 */
  Exposure = 'EXPOSURE',
  /** 비노출 */
  NotExposure = 'NOT_EXPOSURE'
}

/** 콘텐츠 상태 enum */
export type PopupStateEnumFilter = {
  operator: EnumFilterOperators;
  value?: InputMaybe<PopupState>;
  values?: InputMaybe<Array<PopupState>>;
};

export enum PopupType {
  /** 모바일 */
  Mobile = 'MOBILE',
  /** 테블릿 */
  Tablet = 'TABLET',
  /** 웹 */
  Web = 'WEB'
}

/** 콘텐츠 타입 enum */
export type PopupTypeEnumFilter = {
  operator: EnumFilterOperators;
  value?: InputMaybe<PopupType>;
  values?: InputMaybe<Array<PopupType>>;
};

/** 콘텐츠 업데이트 */
export type PopupUpdateInput = {
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  /** 종료날짜 */
  endDate?: InputMaybe<Scalars['DateTime']>;
  /** 기본 키(UUID) */
  id?: InputMaybe<Scalars['ID']>;
  /** 파일 id */
  image__id?: InputMaybe<Scalars['ID']>;
  /** 링크 */
  link?: InputMaybe<Scalars['String']>;
  /** 게시 위치 */
  location?: InputMaybe<PopupLocation>;
  /** 게시날짜 */
  publishedDate?: InputMaybe<Scalars['DateTime']>;
  /** 상태 */
  state?: InputMaybe<PopupState>;
  /** 제목 */
  title?: InputMaybe<Scalars['String']>;
  /** 타입 */
  type?: InputMaybe<PopupType>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

/** 게시판 */
export type Post = {
  __typename?: 'Post';
  /** 첨부파일 */
  attachment?: Maybe<File>;
  /** faq 카테고리 */
  category?: Maybe<Array<FaqCategory>>;
  /** 내용 */
  content?: Maybe<Scalars['String']>;
  /** 생성 날짜/시간 */
  createdAt: Scalars['DateTime'];
  /** 삭제 날짜/시간 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** 종료날짜 */
  expiredDate?: Maybe<Scalars['DateTime']>;
  /**
   * 도움여부
   * @deprecated 사용 안함
   */
  helpStatus?: Maybe<Scalars['Boolean']>;
  /** 기본 키(UUID) */
  id: Scalars['ID'];
  /** 도움 여부 수 (good/bad) */
  isHelpfulCount?: Maybe<Scalars['String']>;
  /** 링크 */
  link?: Maybe<Scalars['String']>;
  /** 게시날짜 */
  publishedDate?: Maybe<Scalars['DateTime']>;
  /** 게시글 노출 상태 */
  state?: Maybe<PostExposureState>;
  /** 썸네일 */
  thumbnail?: Maybe<File>;
  /** 제목 */
  title?: Maybe<Scalars['String']>;
  /** 게시글 타입(공지, faq) */
  type?: Maybe<PostType>;
  /** 수정 날짜/시간 */
  updatedAt: Scalars['DateTime'];
  /** 조회수 */
  views?: Maybe<Scalars['Float']>;
  /** 작성자 */
  writer?: Maybe<User>;
};

/** 게시글 생성 */
export type PostCreateInput = {
  /** 첨부파일 id */
  attachment__id?: InputMaybe<Scalars['ID']>;
  /** 카테고리 id */
  category__id?: InputMaybe<Scalars['ID']>;
  /** 내용 */
  content?: InputMaybe<Scalars['String']>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  /** 종료날짜 */
  expiredDate?: InputMaybe<Scalars['DateTime']>;
  /** 도움여부 */
  helpStatus?: InputMaybe<Scalars['Boolean']>;
  /** 기본 키(UUID) */
  id?: InputMaybe<Scalars['ID']>;
  /** 링크 */
  link?: InputMaybe<Scalars['String']>;
  /** 게시날짜 */
  publishedDate?: InputMaybe<Scalars['DateTime']>;
  /** 게시글 노출 상태 */
  state?: InputMaybe<PostExposureState>;
  /** 썸네일파일 id */
  thumbnail__id?: InputMaybe<Scalars['ID']>;
  /** 제목 */
  title?: InputMaybe<Scalars['String']>;
  /** 게시글 타입(공지, faq) */
  type?: InputMaybe<PostType>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** 조회수 */
  views?: InputMaybe<Scalars['Float']>;
};

export type PostEdge = {
  __typename?: 'PostEdge';
  /** 커서 */
  cursor: Scalars['String'];
  /** 노드 */
  node: Post;
};

/** 게시글 노출 상태 enum */
export type PostExposureStateEnumFilter = {
  operator: EnumFilterOperators;
  value?: InputMaybe<PostExposureState>;
  values?: InputMaybe<Array<PostExposureState>>;
};

/** 게시글 필터 */
export type PostFilterInput = {
  /** 카테고리 id */
  category__id?: InputMaybe<Array<IdFilterInput>>;
  /** 카테고리 이름 */
  category__name?: InputMaybe<Array<StringFilterInput>>;
  /** 내용 */
  content?: InputMaybe<Array<StringFilterInput>>;
  /** 내용 (순수 텍스트) */
  contentOfPlainText?: InputMaybe<Array<StringFilterInput>>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 종료날짜 */
  expiredDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 도움여부 */
  helpStatus?: InputMaybe<Array<BooleanFilterInput>>;
  /** UUID */
  id?: InputMaybe<Array<IdFilterInput>>;
  /** 링크 */
  link?: InputMaybe<Array<StringFilterInput>>;
  /** 게시 날짜 */
  publishedDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 노출 상태 */
  state?: InputMaybe<Array<PostExposureStateEnumFilter>>;
  /** 제목 */
  title?: InputMaybe<Array<StringFilterInput>>;
  /** 게시글 타입 */
  type?: InputMaybe<Array<PostTypeEnumFilter>>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 조회수 */
  views?: InputMaybe<Array<IntFilterInput>>;
  /** 작성자 */
  writer__id?: InputMaybe<Array<IdFilterInput>>;
};

/** 게시글 도움 여부 */
export type PostHelpInput = {
  /** 도움여부 */
  helpStatus?: InputMaybe<Scalars['Boolean']>;
};

/** 게시판 목록 */
export type PostList = {
  __typename?: 'PostList';
  /** 페이지네이션된 데이터 목록 */
  edges: Array<Maybe<PostEdge>>;
  /** 페이지네이션된 페이지 정보 */
  pageInfo: PageInfo;
  /** 전체 데이터 개 수 */
  totalCount: Scalars['Int'];
};

/** 게시글 정렬 */
export type PostOrderByInput = {
  /** 내용 */
  content?: InputMaybe<SortInput>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<SortInput>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<SortInput>;
  /** 도움여부 */
  helpStatus?: InputMaybe<SortInput>;
  /** UUID */
  id?: InputMaybe<SortInput>;
  /** 링크 */
  link?: InputMaybe<SortInput>;
  /** 게시날짜 */
  publishedDate?: InputMaybe<SortInput>;
  /** 제목 */
  title?: InputMaybe<SortInput>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<SortInput>;
  /** 조회수 */
  views?: InputMaybe<SortInput>;
  /** 게시자 이메일 */
  writer__email?: InputMaybe<SortInput>;
  /** 작성자 */
  writer__id?: InputMaybe<SortInput>;
  /** 게시자 이름 */
  writer__name?: InputMaybe<SortInput>;
};

/** 게시글 타입 enum */
export type PostTypeEnumFilter = {
  operator: EnumFilterOperators;
  value?: InputMaybe<PostType>;
  values?: InputMaybe<Array<PostType>>;
};

/** 게시글 업데이트 */
export type PostUpdateInput = {
  /** 첨부파일 id */
  attachment__id?: InputMaybe<Scalars['ID']>;
  /** 카테고리 id */
  category__id?: InputMaybe<Scalars['ID']>;
  /** 내용 */
  content?: InputMaybe<Scalars['String']>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  /** 종료날짜 */
  expiredDate?: InputMaybe<Scalars['DateTime']>;
  /** 도움여부 */
  helpStatus?: InputMaybe<Scalars['Boolean']>;
  /** 기본 키(UUID) */
  id?: InputMaybe<Scalars['ID']>;
  /** 링크 */
  link?: InputMaybe<Scalars['String']>;
  /** 게시날짜 */
  publishedDate?: InputMaybe<Scalars['DateTime']>;
  /** 게시글 노출 상태 */
  state?: InputMaybe<PostExposureState>;
  /** 썸네일파일 id */
  thumbnail__id?: InputMaybe<Scalars['ID']>;
  /** 제목 */
  title?: InputMaybe<Scalars['String']>;
  /** 게시글 타입(공지, faq) */
  type?: InputMaybe<PostType>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** 조회수 */
  views?: InputMaybe<Scalars['Float']>;
};

/** 출판사 */
export type Publisher = {
  __typename?: 'Publisher';
  /** 계정 탈퇴 관리자 메모 */
  accountDeleteAdminMemo?: Maybe<Scalars['String']>;
  /** 계정 탈퇴 사유 */
  accountDeleteReason?: Maybe<SanctionReasonEnum>;
  /** 정산 계좌명 */
  accountName?: Maybe<Scalars['String']>;
  /** 계좌번호 */
  accountNumber?: Maybe<Scalars['String']>;
  /** 승인날짜 */
  approvalDate?: Maybe<Scalars['DateTime']>;
  /** 통장 사본 */
  bankBookFile?: Maybe<File>;
  /** 정산 은행 */
  bankName?: Maybe<Scalars['String']>;
  /** 등록 교재수 */
  bookNumber?: Maybe<Scalars['Float']>;
  /** 브랜드 목록 */
  brandList?: Maybe<Array<Brand>>;
  /** 브랜드수 */
  brandNumber?: Maybe<Scalars['Float']>;
  /** 사업자명 */
  businessName?: Maybe<Scalars['String']>;
  /** 사업자등록증 */
  businessRegistrationFile?: Maybe<File>;
  /** 사업자 번호 */
  businessRegistrationNumber?: Maybe<Scalars['String']>;
  /** 승인 완료일 */
  completionDate?: Maybe<Scalars['DateTime']>;
  /** 생성 날짜/시간 */
  createdAt: Scalars['DateTime'];
  /** 삭제 날짜/시간 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** 수수료 */
  fee?: Maybe<Scalars['Float']>;
  /** 기본 키(UUID) */
  id: Scalars['ID'];
  /** 마지막 로그인 */
  lastLogin?: Maybe<Scalars['DateTime']>;
  /** 최근 30일 판매수 */
  lastThirtyDaysSellingNumber?: Maybe<Scalars['Float']>;
  /** 담당자 이메일 */
  managerEmail?: Maybe<Scalars['String']>;
  /** 담당자 이름 */
  managerName?: Maybe<Scalars['String']>;
  /** 담당자 전화번호 */
  managerPhone?: Maybe<Scalars['String']>;
  /** 등록 거절, 보완 메시지 */
  memo?: Maybe<Scalars['String']>;
  /** 출판사 이름 */
  name?: Maybe<Scalars['String']>;
  /** 기본 정책 */
  policy?: Maybe<Scalars['String']>;
  /** 출판사 히스토리 */
  publisherHistory?: Maybe<Array<PublisherHistory>>;
  /** 등록 상태(대기, 승인, 거절, 보완) */
  registrationState?: Maybe<PublisherRegistrationStateType>;
  /** 대표자 이메일 */
  representativeEmail?: Maybe<Scalars['String']>;
  /** 대표자 이름 */
  representativeName?: Maybe<Scalars['String']>;
  /** 대표자 전화번호 */
  representativePhone?: Maybe<Scalars['String']>;
  /** 승인 요청일 */
  requestDate?: Maybe<Scalars['DateTime']>;
  /** 누적 판매수 */
  sellingNumber?: Maybe<Scalars['Float']>;
  /** 정렬 필드 */
  sortField?: Maybe<CommonSortView>;
  /** 상태(활성화, 비활성화) */
  state?: Maybe<PublisherStateType>;
  /** 보완 요청일 */
  supplementationDate?: Maybe<Scalars['DateTime']>;
  /** 수정 날짜/시간 */
  updatedAt: Scalars['DateTime'];
  /** 출판사 유저 */
  user?: Maybe<User>;
};

/** 출판사 교재 생성 */
export type PublisherBookCreateInput = {
  /** 홍보 문구 */
  advertisingText?: InputMaybe<Scalars['String']>;
  /** 정답 파일 */
  answerFile__ids?: InputMaybe<Array<Scalars['ID']>>;
  /** 저자명 */
  author?: InputMaybe<Scalars['String']>;
  /** 브랜드명 */
  brand__id?: InputMaybe<Scalars['ID']>;
  /** 카테고리 */
  category__id?: InputMaybe<Scalars['ID']>;
  /** 콘텐츠 파일 */
  contentFile__ids?: InputMaybe<Array<Scalars['ID']>>;
  /** 교재 설명 */
  descriptionText?: InputMaybe<Scalars['String']>;
  /** 할인할 금액 */
  discountPrice?: InputMaybe<Scalars['Float']>;
  /** 할인률 */
  discountRate?: InputMaybe<Scalars['Float']>;
  /** 목차 파일 */
  indexFile__id?: InputMaybe<Scalars['ID']>;
  /** 소개 문구 */
  introductionText?: InputMaybe<Scalars['String']>;
  /** ISBN10 0-000-00000-0 */
  isbn10?: InputMaybe<Scalars['String']>;
  /** ISBN13 000-0-000-00000-0 */
  isbn13?: InputMaybe<Scalars['String']>;
  /** 교재 제목 */
  name?: InputMaybe<Scalars['String']>;
  /** 미리보기 파일 */
  previewFile__id?: InputMaybe<Scalars['ID']>;
  /** 교재 금액 */
  price?: InputMaybe<Scalars['Float']>;
  /** 출판일자 */
  publicationDate?: InputMaybe<Scalars['DateTime']>;
  /** 개정일자 */
  revisedDate?: InputMaybe<Scalars['DateTime']>;
  /** 판매정책 */
  salesPolicy?: InputMaybe<Scalars['String']>;
  /** 썸네일 파일 */
  thumbnailFile__id?: InputMaybe<Scalars['ID']>;
  /** 교제 타입 */
  type?: InputMaybe<BookTypeEnum>;
};

/** 출판사 교재 필터 */
export type PublisherBookFilterInput = {
  /** 홍보 문구 */
  advertisingText?: InputMaybe<Array<StringFilterInput>>;
  /** 저자명 */
  author?: InputMaybe<Array<StringFilterInput>>;
  /** 브랜드 id */
  brand__id?: InputMaybe<Array<IdFilterInput>>;
  /** 브랜드 이름 */
  brand__name?: InputMaybe<Array<StringFilterInput>>;
  /** 카테고리 코드 */
  category__code?: InputMaybe<Array<StringFilterInput>>;
  /** 카테고리 id */
  category__id?: InputMaybe<Array<IdFilterInput>>;
  /** 카테고리 이름 */
  category__name?: InputMaybe<Array<StringFilterInput>>;
  /** 승인일 */
  completionDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 교재 설명 */
  descriptionTextOfPlainText?: InputMaybe<Array<StringFilterInput>>;
  /** UUID */
  id?: InputMaybe<Array<IdFilterInput>>;
  /** 소개 문구 */
  introductionText?: InputMaybe<Array<StringFilterInput>>;
  /** 교재 이름 */
  name?: InputMaybe<Array<StringFilterInput>>;
  /** 처리 관리자 id */
  processor__id?: InputMaybe<Array<IdFilterInput>>;
  /** 출판일자 */
  publicationDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 거절일 */
  refusalDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 거절 요청 사유 */
  refusalReason?: InputMaybe<Array<BookRefusalReasonEnumFilter>>;
  /** 승인요청일 */
  requestDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 리뷰수 */
  reviewCount?: InputMaybe<Array<StringFilterInput>>;
  /** 개정일자 */
  revisedDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 책 상태 */
  state?: InputMaybe<Array<BookStateEnumFilter>>;
  /** 보완요청일 */
  supplementationDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 보완 요청 사유 */
  supplementationReason?: InputMaybe<Array<BookSupplementationReasonEnumFilter>>;
  /** 선생님 id */
  teacher__id?: InputMaybe<Array<IdFilterInput>>;
  /** 교재 상태 */
  type?: InputMaybe<Array<BookTypeEnumFilter>>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Array<DateTimeFilterInput>>;
};

/** 출판사 교재 수정 */
export type PublisherBookUpdateInput = {
  /** 홍보 문구 */
  advertisingText?: InputMaybe<Scalars['String']>;
  /** 정답 파일 */
  answerFile__ids?: InputMaybe<Array<Scalars['ID']>>;
  /** 저자명 */
  author?: InputMaybe<Scalars['String']>;
  /** 브랜드명 */
  brand__id?: InputMaybe<Scalars['ID']>;
  /** 카테고리 */
  category__id?: InputMaybe<Scalars['ID']>;
  /** 콘텐츠 파일 */
  contentFile__ids?: InputMaybe<Array<Scalars['ID']>>;
  /** 교재 설명 */
  descriptionText?: InputMaybe<Scalars['String']>;
  /** 할인할 금액 */
  discountPrice?: InputMaybe<Scalars['Float']>;
  /** 할인률 */
  discountRate?: InputMaybe<Scalars['Float']>;
  /** 목차 파일 */
  indexFile__id?: InputMaybe<Scalars['ID']>;
  /** 소개 문구 */
  introductionText?: InputMaybe<Scalars['String']>;
  /** ISBN10 0-000-00000-0 */
  isbn10?: InputMaybe<Scalars['String']>;
  /** ISBN13 000-0-000-00000-0 */
  isbn13?: InputMaybe<Scalars['String']>;
  /** 교재 제목 */
  name?: InputMaybe<Scalars['String']>;
  /** 미리보기 파일 */
  previewFile__id?: InputMaybe<Scalars['ID']>;
  /** 교재 금액 */
  price?: InputMaybe<Scalars['Float']>;
  /** 출판일자 */
  publicationDate?: InputMaybe<Scalars['DateTime']>;
  /** 개정일자 */
  revisedDate?: InputMaybe<Scalars['DateTime']>;
  /** 판매정책 */
  salesPolicy?: InputMaybe<Scalars['String']>;
  /** 썸네일 파일 */
  thumbnailFile__id?: InputMaybe<Scalars['ID']>;
  /** 교제 타입 */
  type?: InputMaybe<BookTypeEnum>;
};

/** 출판사 브랜드 생성 */
export type PublisherBrandCreateInput = {
  /** 첨부파일 */
  attachment__id?: InputMaybe<Scalars['ID']>;
  /** 브랜드 이름 */
  name?: InputMaybe<Scalars['String']>;
};

/** 출판사 브랜드 필터 */
export type PublisherBrandFilterInput = {
  /** 승인완료일 */
  completionDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** UUID */
  id?: InputMaybe<Array<IdFilterInput>>;
  /** 등록 거절, 보완 메시지 */
  memo?: InputMaybe<Array<BrandMemoEnumFilterInput>>;
  /** 브랜드 이름 */
  name?: InputMaybe<Array<StringFilterInput>>;
  /** 승인요청일 */
  requestDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 브랜드 상태 */
  state?: InputMaybe<Array<BrandStateEnumFilter>>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Array<DateTimeFilterInput>>;
};

/** 출판사 브랜드 수정 */
export type PublisherBrandUpdateInput = {
  /** 첨부파일 */
  attachment__id?: InputMaybe<Scalars['ID']>;
  /** 브랜드 이름 */
  name?: InputMaybe<Scalars['String']>;
};

/** 출판사 주문 필터 */
export type PublisherBusinessFilterInput = {
  /** 교재 카테고리 id */
  bookCategory__id?: InputMaybe<Array<IdFilterInput>>;
  /** 교재 카테고리 이름 */
  bookCategory__name?: InputMaybe<Array<StringFilterInput>>;
  /** 교재 열람일 */
  bookReadDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 평점 */
  bookReview__bookgpa?: InputMaybe<Array<FloatFilterInput>>;
  /** 리뷰 내용 */
  bookReview__bookgpaContent?: InputMaybe<Array<StringFilterInput>>;
  /** 교재 리뷰 노출 여부 */
  bookReview__isBookGPAVisible?: InputMaybe<Array<BooleanFilterInput>>;
  /** 책 저자명 */
  book__author?: InputMaybe<Array<StringFilterInput>>;
  /** 교재 id */
  book__id?: InputMaybe<Array<IdFilterInput>>;
  /** 교재 이름 */
  book__name?: InputMaybe<Array<StringFilterInput>>;
  /** 브랜드 id */
  brand__id?: InputMaybe<Array<IdFilterInput>>;
  /** 브랜드명 */
  brand__name?: InputMaybe<Array<StringFilterInput>>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** UUID */
  id?: InputMaybe<Array<IdFilterInput>>;
  /** 소득공제 여부 */
  isDeducted?: InputMaybe<Array<BooleanFilterInput>>;
  /** 수업 카테고리 */
  lesson__category?: InputMaybe<Array<StringFilterInput>>;
  /** 수업 id */
  lesson__id?: InputMaybe<Array<IdFilterInput>>;
  /** 수업명 */
  lesson__lessonName?: InputMaybe<Array<StringFilterInput>>;
  /** 주문확정일 */
  orderConfirmedDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 할인 금액 */
  orderInfo__discountPrice?: InputMaybe<Array<FloatFilterInput>>;
  /** 할인률 */
  orderInfo__discountRate?: InputMaybe<Array<FloatFilterInput>>;
  /** 구매 금액 */
  orderInfo__price?: InputMaybe<Array<IntFilterInput>>;
  /** 할인전 금액 */
  orderInfo__totalOriginalPrice?: InputMaybe<Array<FloatFilterInput>>;
  /** 주문 번호 */
  payment__orderNumber?: InputMaybe<Array<StringFilterInput>>;
  /** 구매일 */
  payment__paidDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 정산id */
  publisherCalculate__id?: InputMaybe<Array<IdFilterInput>>;
  /** 취소/환불 사유 */
  refundHiestory__reason?: InputMaybe<Array<BusinessCancellationReasonEnumFilter>>;
  /** 취소/환불 처리일 */
  refundHistory__CompletionDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 취소/환불 요청일 */
  refundHistory__createdAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 관리자 이메일 */
  refundHistory__processor__email?: InputMaybe<Array<StringFilterInput>>;
  /** 주문 상태 */
  state?: InputMaybe<Array<BusinessStateEnumFilter>>;
  /** 선생님명 */
  teacherUser__name?: InputMaybe<Array<StringFilterInput>>;
  /** 선생님아이디 */
  teacher__id?: InputMaybe<Array<IdFilterInput>>;
  /** 상품 타입 */
  type?: InputMaybe<Array<OrderTypeEnumFilterInput>>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 사용한 캐시 합 */
  usedAllCash?: InputMaybe<Array<IntFilterInput>>;
  /** 사용한 현금성 캐시 */
  usedMainCash?: InputMaybe<Array<IntFilterInput>>;
  /** 사용한 비현금성 캐시 */
  usedSubCash?: InputMaybe<Array<IntFilterInput>>;
  /** 유저 email */
  user__email?: InputMaybe<Array<StringFilterInput>>;
  /** 유저 아이디 */
  user__id?: InputMaybe<Array<IdFilterInput>>;
  /** 유저 이름 */
  user__name?: InputMaybe<Array<StringFilterInput>>;
};

/** 출판사 정산 */
export type PublisherCalculate = {
  __typename?: 'PublisherCalculate';
  /** 주문 */
  business?: Maybe<Array<Business>>;
  /** 정산번호 */
  calculateCode?: Maybe<Scalars['String']>;
  /** 정산 확인 일시 */
  calculateConfirmationDate?: Maybe<Scalars['DateTime']>;
  /** 정산연월 */
  calculateDate?: Maybe<Scalars['String']>;
  /** 정산 처리 일시 */
  calculateProcessDate?: Maybe<Scalars['DateTime']>;
  /** 생성 날짜/시간 */
  createdAt: Scalars['DateTime'];
  /** 삭제 날짜/시간 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** 수수료 */
  fee?: Maybe<Scalars['Float']>;
  /** 기본 키(UUID) */
  id: Scalars['ID'];
  /** 월간 교재 구매수 */
  monthBookCount?: Maybe<Scalars['Float']>;
  /** 월간 교재 구매자 수 */
  monthBookUserCount?: Maybe<Scalars['Float']>;
  /** 월간 정산 금액 */
  monthCalculate?: Maybe<Scalars['Float']>;
  /** 월매출 */
  monthlySales?: Maybe<Scalars['Float']>;
  /** 출판사 */
  publisher?: Maybe<Publisher>;
  /** 정산 상태 */
  state?: Maybe<PublisherCalculateState>;
  /** 수정 날짜/시간 */
  updatedAt: Scalars['DateTime'];
};

/** 출판사 정산 생성 */
export type PublisherCalculateCreateInput = {
  /** 정산번호 */
  calculateCode?: InputMaybe<Scalars['String']>;
  /** 정산 확인 일시 */
  calculateConfirmationDate?: InputMaybe<Scalars['DateTime']>;
  /** 정산연월 */
  calculateDate?: InputMaybe<Scalars['String']>;
  /** 정산 처리 일시 */
  calculateProcessDate?: InputMaybe<Scalars['DateTime']>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  /** 수수료 */
  fee?: InputMaybe<Scalars['Float']>;
  /** 기본 키(UUID) */
  id?: InputMaybe<Scalars['ID']>;
  /** 월간 교재 구매수 */
  monthBookCount?: InputMaybe<Scalars['Float']>;
  /** 월간 교재 구매자 수 */
  monthBookUserCount?: InputMaybe<Scalars['Float']>;
  /** 월간 정산 금액 */
  monthCalculate?: InputMaybe<Scalars['Float']>;
  /** 월매출 */
  monthlySales?: InputMaybe<Scalars['Float']>;
  /** 유저 ID */
  publisher__id?: InputMaybe<Scalars['ID']>;
  /** 정산 상태 */
  state?: InputMaybe<PublisherCalculateState>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

/** 출판사 대시보드 */
export type PublisherCalculateDashboard = {
  __typename?: 'PublisherCalculateDashboard';
  /** 다음 정산 금액 */
  nextCalculateAmount?: Maybe<Scalars['Float']>;
  /** 다음 정산 일자 */
  nextCalculateDate?: Maybe<Scalars['DateTime']>;
  /** 이전 정산 금액 */
  previousCalculateAmount?: Maybe<Scalars['Float']>;
  /** 이전 정산 일자 */
  previousCalculateDate?: Maybe<Scalars['DateTime']>;
};

export type PublisherCalculateEdge = {
  __typename?: 'PublisherCalculateEdge';
  /** 커서 */
  cursor: Scalars['String'];
  /** 노드 */
  node: PublisherCalculate;
};

/** 수업 정산 필터 */
export type PublisherCalculateFilterInput = {
  /** 정산번호 */
  calculateCode?: InputMaybe<Array<StringFilterInput>>;
  /** 정산연월 */
  calculateDate?: InputMaybe<Array<StringFilterInput>>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** UUID */
  id?: InputMaybe<Array<IdFilterInput>>;
  /** 월매출 */
  monthlySales?: InputMaybe<Array<IntFilterInput>>;
  /** 출판사 아이디 */
  publisher__id?: InputMaybe<Array<IdFilterInput>>;
  /** 출판사 명 */
  publisher__name?: InputMaybe<Array<StringFilterInput>>;
  /** 정산 상태 */
  state?: InputMaybe<Array<PublisherCalculateStateEnumFilter>>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Array<DateTimeFilterInput>>;
};

/** 수업 정산 리스트 */
export type PublisherCalculateList = {
  __typename?: 'PublisherCalculateList';
  /** 페이지네이션된 데이터 목록 */
  edges: Array<Maybe<PublisherCalculateEdge>>;
  /** 페이지네이션된 페이지 정보 */
  pageInfo: PageInfo;
  /** 전체 데이터 개 수 */
  totalCount: Scalars['Int'];
};

/** 정산 정렬 */
export type PublisherCalculateOrderByInput = {
  /** 정산 번호 */
  calculateCode?: InputMaybe<SortInput>;
  /** 정산연월 */
  calculateDate?: InputMaybe<SortInput>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<SortInput>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<SortInput>;
  /** UUID */
  id?: InputMaybe<SortInput>;
  /** 월매출 */
  monthlySales?: InputMaybe<SortInput>;
  /** 출판사 id */
  publisher__id?: InputMaybe<SortInput>;
  /** 출판사명 */
  publisher__name?: InputMaybe<SortInput>;
  /** 정산 상태 */
  state?: InputMaybe<SortInput>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<SortInput>;
};

/** 수업 정산 상태 */
export type PublisherCalculateStateEnumFilter = {
  operator: EnumFilterOperators;
  value?: InputMaybe<PublisherCalculateState>;
  values?: InputMaybe<Array<PublisherCalculateState>>;
};

/** 출판사 정산 업데이트 */
export type PublisherCalculateUpdateInput = {
  /** 정산번호 */
  calculateCode?: InputMaybe<Scalars['String']>;
  /** 정산 확인 일시 */
  calculateConfirmationDate?: InputMaybe<Scalars['DateTime']>;
  /** 정산연월 */
  calculateDate?: InputMaybe<Scalars['String']>;
  /** 정산 처리 일시 */
  calculateProcessDate?: InputMaybe<Scalars['DateTime']>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  /** 수수료 */
  fee?: InputMaybe<Scalars['Float']>;
  /** 기본 키(UUID) */
  id?: InputMaybe<Scalars['ID']>;
  /** 월간 교재 구매수 */
  monthBookCount?: InputMaybe<Scalars['Float']>;
  /** 월간 교재 구매자 수 */
  monthBookUserCount?: InputMaybe<Scalars['Float']>;
  /** 월간 정산 금액 */
  monthCalculate?: InputMaybe<Scalars['Float']>;
  /** 월매출 */
  monthlySales?: InputMaybe<Scalars['Float']>;
  /** 정산 상태 */
  state?: InputMaybe<PublisherCalculateState>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

/** 출판사 생성 */
export type PublisherCreateInput = {
  /** 계정 탈퇴 관리자 메모 */
  accountDeleteAdminMemo?: InputMaybe<Scalars['String']>;
  /** 계정 탈퇴 사유 */
  accountDeleteReason?: InputMaybe<SanctionReasonEnum>;
  /** 정산 계좌명 */
  accountName?: InputMaybe<Scalars['String']>;
  /** 계좌번호 */
  accountNumber?: InputMaybe<Scalars['String']>;
  /** 승인날짜 */
  approvalDate?: InputMaybe<Scalars['DateTime']>;
  /** 통장 사본 파일 ID */
  bankBookFile__id?: InputMaybe<Scalars['ID']>;
  /** 정산 은행 */
  bankName?: InputMaybe<Scalars['String']>;
  /** 사업자명 */
  businessName?: InputMaybe<Scalars['String']>;
  /** 사업자 등록증 파일 ID */
  businessRegistrationFile__id?: InputMaybe<Scalars['ID']>;
  /** 사업자 번호 */
  businessRegistrationNumber?: InputMaybe<Scalars['String']>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  /** 수수료 */
  fee?: InputMaybe<Scalars['Float']>;
  /** 기본 키(UUID) */
  id?: InputMaybe<Scalars['ID']>;
  /** 마지막 로그인 */
  lastLogin?: InputMaybe<Scalars['DateTime']>;
  /** 담당자 이메일 */
  managerEmail?: InputMaybe<Scalars['String']>;
  /** 담당자 이름 */
  managerName?: InputMaybe<Scalars['String']>;
  /** 담당자 전화번호 */
  managerPhone?: InputMaybe<Scalars['String']>;
  /** 출판사 이름 */
  name?: InputMaybe<Scalars['String']>;
  /** 비밀번호 */
  password?: InputMaybe<Scalars['Password']>;
  /** 기본 정책 */
  policy?: InputMaybe<Scalars['String']>;
  /** 프로필 이미지 파일 ID */
  profileImageFile__id?: InputMaybe<Scalars['ID']>;
  /** 대표자 이메일 */
  representativeEmail?: InputMaybe<Scalars['String']>;
  /** 대표자 이름 */
  representativeName?: InputMaybe<Scalars['String']>;
  /** 대표자 전화번호 */
  representativePhone?: InputMaybe<Scalars['String']>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PublisherEdge = {
  __typename?: 'PublisherEdge';
  /** 커서 */
  cursor: Scalars['String'];
  /** 노드 */
  node: Publisher;
};

/** 출판사 수수료 변경 */
export type PublisherFeeUpdateInput = {
  /** 수수료 */
  fee?: InputMaybe<Scalars['Float']>;
};

/** 출판사 필터 */
export type PublisherFilterInput = {
  /** 계정 탈퇴 사유 */
  accountDeleteReason?: InputMaybe<Array<ReportReasonEnumFilter>>;
  /** 정산 계좌명 */
  accountName?: InputMaybe<Array<StringFilterInput>>;
  /** 계좌번호 */
  accountNumber?: InputMaybe<Array<StringFilterInput>>;
  /** 승인날짜 */
  approvalDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 정산 은행 */
  bankName?: InputMaybe<Array<StringFilterInput>>;
  /** 브랜드 등록 상태 */
  brandList__state?: InputMaybe<Array<BrandStateEnumFilter>>;
  /** 사업자명 */
  businessName?: InputMaybe<Array<StringFilterInput>>;
  /** 사업자 번호 */
  businessRegistrationNumber?: InputMaybe<Array<StringFilterInput>>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** UUID */
  id?: InputMaybe<Array<IdFilterInput>>;
  /** 마지막 로그인 */
  lastLogin?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 담당자 이메일 */
  managerEmail?: InputMaybe<Array<StringFilterInput>>;
  /** 담당자 이름 */
  managerName?: InputMaybe<Array<StringFilterInput>>;
  /** 담당자 전화번호 */
  managerPhone?: InputMaybe<Array<StringFilterInput>>;
  /** 등록 거절, 보완 메시지 */
  memo?: InputMaybe<Array<StringFilterInput>>;
  /** 출판사 이름 */
  name?: InputMaybe<Array<StringFilterInput>>;
  /** 처리자 email */
  processor__email?: InputMaybe<Array<StringFilterInput>>;
  /** 처리자 id */
  processor__id?: InputMaybe<Array<IdFilterInput>>;
  /** 처리자 이름 */
  processor__name?: InputMaybe<Array<StringFilterInput>>;
  /** 출판사 신청 내역 바뀐 상태 */
  publisherHistory__changedState?: InputMaybe<Array<PublisherRegistrationStateEnumFilter>>;
  /** 출판사 신청 내역 생성 날짜 */
  publisherHistory__createdAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 등록 상태(대기, 승인, 거절, 보완) */
  registrationState?: InputMaybe<Array<PublisherRegistrationStateEnumFilter>>;
  /** 대표자 이메일 */
  representativeEmail?: InputMaybe<Array<StringFilterInput>>;
  /** 대표자 이름 */
  representativeName?: InputMaybe<Array<StringFilterInput>>;
  /** 대표자 전화번호 */
  representativePhone?: InputMaybe<Array<StringFilterInput>>;
  /** 정산 상태 */
  settlementState?: InputMaybe<Array<PublisherSettlementStateEnumFilter>>;
  /** 상태(활성화, 비활성화) */
  state?: InputMaybe<Array<PublisherStateEnumFilter>>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Array<DateTimeFilterInput>>;
};

/** 출판사 히스토리 */
export type PublisherHistory = {
  __typename?: 'PublisherHistory';
  /** 생성 날짜/시간 */
  createdAt: Scalars['DateTime'];
  /** 삭제 날짜/시간 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** 기본 키(UUID) */
  id: Scalars['ID'];
  /** 보완 메시지 */
  memo?: Maybe<Scalars['String']>;
  /** 처리자 */
  processor?: Maybe<User>;
  /** 이전 상태 */
  registrationState?: Maybe<PublisherRegistrationStateType>;
  /** 수정 날짜/시간 */
  updatedAt: Scalars['DateTime'];
};

/** 출판사 목록 */
export type PublisherList = {
  __typename?: 'PublisherList';
  /** 페이지네이션된 데이터 목록 */
  edges: Array<Maybe<PublisherEdge>>;
  /** 페이지네이션된 페이지 정보 */
  pageInfo: PageInfo;
  /** 전체 데이터 개 수 */
  totalCount: Scalars['Int'];
};

/** 출판사 정렬 */
export type PublisherOrderByInput = {
  /** 계정 탈퇴 사유 */
  accountDeleteReason?: InputMaybe<SortInput>;
  /** 승인날짜 */
  approvalDate?: InputMaybe<SortInput>;
  /** 사업자명 */
  businessName?: InputMaybe<SortInput>;
  /** 사업자 번호 */
  businessRegistrationNumber?: InputMaybe<SortInput>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<SortInput>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<SortInput>;
  /** UUID */
  id?: InputMaybe<SortInput>;
  /** 마지막 로그인 */
  lastLogin?: InputMaybe<SortInput>;
  /** 담당자 이메일 */
  managerEmail?: InputMaybe<SortInput>;
  /** 담당자명 */
  managerName?: InputMaybe<SortInput>;
  /** 담당자 연락처 */
  managerPhone?: InputMaybe<SortInput>;
  /** 출판사명 */
  name?: InputMaybe<SortInput>;
  /** 즐겨찾기순 */
  publisherSort__bookmarkCount?: InputMaybe<SortInput>;
  /** 구매순 */
  publisherSort__businessCount?: InputMaybe<SortInput>;
  /** 평점순(평점 평균) */
  publisherSort__gpaAvg?: InputMaybe<SortInput>;
  /** 리뷰순(리뷰수) */
  publisherSort__gpaCount?: InputMaybe<SortInput>;
  /** 인기순 */
  publisherSort__popular?: InputMaybe<SortInput>;
  /** 등록 상태 */
  registrationState?: InputMaybe<RegistrationStateSortInput>;
  /** 대표 이메일 */
  representativeEmail?: InputMaybe<SortInput>;
  /** 대표자명 */
  representativeName?: InputMaybe<SortInput>;
  /** 대표 연락처 */
  representativePhone?: InputMaybe<SortInput>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<SortInput>;
};

/** 출판사 출판사 정산 필터 */
export type PublisherPublisherCalculateFilterInput = {
  /** 정산번호 */
  calculateCode?: InputMaybe<Array<StringFilterInput>>;
  /** 정산연월 */
  calculateDate?: InputMaybe<Array<StringFilterInput>>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** UUID */
  id?: InputMaybe<Array<IdFilterInput>>;
  /** 월매출 */
  monthlySales?: InputMaybe<Array<IntFilterInput>>;
  /** 정산 상태 */
  state?: InputMaybe<Array<PublisherCalculateStateEnumFilter>>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Array<DateTimeFilterInput>>;
};

/** 출판사 승인 상태 변경 */
export type PublisherRegistrationStateUpdateInput = {
  /** 등록 거절, 보완 메시지 */
  memo?: InputMaybe<Scalars['String']>;
  /** 등록 상태(대기, 승인, 거절, 보완) */
  registrationState?: InputMaybe<PublisherRegistrationStateType>;
};

/** 출판사 회원가입 */
export type PublisherSignupInput = {
  /** 정산 계좌명 */
  accountName?: InputMaybe<Scalars['String']>;
  /** 계좌번호 */
  accountNumber?: InputMaybe<Scalars['String']>;
  /** 통장 사본 */
  bankBookFile__id?: InputMaybe<Scalars['ID']>;
  /** 정산 은행 */
  bankName?: InputMaybe<Scalars['String']>;
  /** 사업자명 */
  businessName?: InputMaybe<Scalars['String']>;
  /** 사업자 등록증 */
  businessRegistrationFile__id?: InputMaybe<Scalars['ID']>;
  /** 사업자 번호 */
  businessRegistrationNumber?: InputMaybe<Scalars['String']>;
  /** 담당자 이메일 */
  managerEmail?: InputMaybe<Scalars['String']>;
  /** 담당자 이름 */
  managerName?: InputMaybe<Scalars['String']>;
  /** 담당자 전화번호 */
  managerPhone?: InputMaybe<Scalars['String']>;
  /** 출판사 이름 */
  name?: InputMaybe<Scalars['String']>;
  /** 비밀번호 */
  password?: InputMaybe<Scalars['Password']>;
  /** 기본 정책 */
  policy?: InputMaybe<Scalars['String']>;
  /** 프로필 이미지 */
  profileImageFile__id?: InputMaybe<Scalars['ID']>;
  /** 대표자 이메일 */
  representativeEmail?: InputMaybe<Scalars['String']>;
  /** 대표자 이름 */
  representativeName?: InputMaybe<Scalars['String']>;
  /** 대표자 전화번호 */
  representativePhone?: InputMaybe<Scalars['String']>;
};

/** 출판사 상태 변경 */
export type PublisherStateUpdateInput = {
  /** 상태(활성화, 비활성화) */
  state?: InputMaybe<PublisherStateType>;
};

/** 출판사 업데이트 */
export type PublisherUpdateInput = {
  /** 계정 탈퇴 관리자 메모 */
  accountDeleteAdminMemo?: InputMaybe<Scalars['String']>;
  /** 계정 탈퇴 사유 */
  accountDeleteReason?: InputMaybe<SanctionReasonEnum>;
  /** 정산 계좌명 */
  accountName?: InputMaybe<Scalars['String']>;
  /** 계좌번호 */
  accountNumber?: InputMaybe<Scalars['String']>;
  /** 승인날짜 */
  approvalDate?: InputMaybe<Scalars['DateTime']>;
  /** 통장 사본 파일 ID */
  bankBookFile__id?: InputMaybe<Scalars['ID']>;
  /** 정산 은행 */
  bankName?: InputMaybe<Scalars['String']>;
  /** 사업자명 */
  businessName?: InputMaybe<Scalars['String']>;
  /** 사업자 등록증 파일 ID */
  businessRegistrationFile__id?: InputMaybe<Scalars['ID']>;
  /** 사업자 번호 */
  businessRegistrationNumber?: InputMaybe<Scalars['String']>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  /** 수수료 */
  fee?: InputMaybe<Scalars['Float']>;
  /** 기본 키(UUID) */
  id?: InputMaybe<Scalars['ID']>;
  /** 마지막 로그인 */
  lastLogin?: InputMaybe<Scalars['DateTime']>;
  /** 담당자 이메일 */
  managerEmail?: InputMaybe<Scalars['String']>;
  /** 담당자 이름 */
  managerName?: InputMaybe<Scalars['String']>;
  /** 담당자 전화번호 */
  managerPhone?: InputMaybe<Scalars['String']>;
  /** 등록 거절, 보완 메시지 */
  memo?: InputMaybe<Scalars['String']>;
  /** 출판사 이름 */
  name?: InputMaybe<Scalars['String']>;
  /** 기본 정책 */
  policy?: InputMaybe<Scalars['String']>;
  /** 프로필 이미지 파일 ID */
  profileImageFile__id?: InputMaybe<Scalars['ID']>;
  /** 등록 상태(대기, 승인, 거절, 보완) */
  registrationState?: InputMaybe<PublisherRegistrationStateType>;
  /** 대표자 이메일 */
  representativeEmail?: InputMaybe<Scalars['String']>;
  /** 대표자 이름 */
  representativeName?: InputMaybe<Scalars['String']>;
  /** 대표자 전화번호 */
  representativePhone?: InputMaybe<Scalars['String']>;
  /** 상태(활성화, 비활성화) */
  state?: InputMaybe<PublisherStateType>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PurchaseLedger = {
  __typename?: 'PurchaseLedger';
  /** 건수 */
  number: Scalars['Float'];
  /** 공급가액 */
  supplyValue: Scalars['Float'];
  /** vat */
  valueAddedTax: Scalars['Float'];
  /** 연월 */
  yearMonth: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  /** 제휴자 정산 조회 */
  AllPartnershipCalculate: AllPartnershipCalculate;
  /** 전체 출판사 정산 조회 */
  allPublisherCalculate: AllPublisherCalculate;
  /** 선생님 정산 정보 합 */
  allTeacherCalculate: AllTeacherCalculate;
  bankCodes: Array<BankCode>;
  /** 단일 교재 조회 */
  book: Book;
  /** isbn으로 교재 조회 */
  bookByISBN: FindBookByIsbnModel;
  /** 교재 카테고리 조회 */
  bookCategories: Array<TreeBookCategory>;
  /** 교재 등록 조회 - 관리자 */
  bookRegistrationForAdmin: BookRegistration;
  /** 교재 등록 신청 리스트 - 관리자 */
  bookRegistrationsForAdmin: BookRegistrationList;
  /** 구입한 책 리뷰 조회 */
  bookReview: BookReview;
  /** 구입한 책 리뷰 리스트 조회 */
  bookReviews: BookReviewList;
  bookSalesDashboard: BookSalesDashboard;
  /** 교재 리스트 조회 */
  books: BookList;
  /** 브랜드 조회 */
  brand: Brand;
  /** 브랜드 리스트 조회 */
  brands: BrandList;
  /** 주문 단일 조회 */
  business: Business;
  /** 주문 리스트 조회 */
  businesses: BusinessList;
  /** 캐쉬 조회 */
  cash: Cash;
  /** 캐쉬 보드 정보 */
  cashInfo: CashInfo;
  /** 캐쉬 리스트 조회 */
  cashes: CashList;
  /** 채팅방 조회 */
  chatChannel: ChatChannel;
  /** 채팅방 목록 조회 */
  chatChannels: ChatChannelList;
  /** 하위 교재 카테고리 조회 */
  childrenBookCategory: TreeBookCategory;
  /** 하위 FAQ 카테고리 조회 */
  childrenFAQCategory: TreeFaqCategory;
  /** 하위 수업 카테고리 조회 */
  childrenLessonCategory: TreeLessonCategory;
  content: Content;
  contents: ContentList;
  /** FAQ 카테고리 조회 */
  faqCategories: Array<TreeFaqCategory>;
  /** 아이디(이메일) 찾기 */
  findMyEmail: FindMyEmail;
  /** 금칙어 조회 */
  forbiddenWord: ForbiddenWord;
  /** 금칙어 사용 내역 조회 */
  forbiddenWordUse: ForbiddenWordUse;
  /** 금칙어 사용 내역 리스트 조회 */
  forbiddenWordUses: ForbiddenWordUseList;
  /** 금칙어 리스트 조회 */
  forbiddenWords: ForbiddenWordList;
  inquiries: InquiryList;
  inquiry: Inquiry;
  inquiryDashboard: InquiryDashboard;
  inquiryPreviewList: Array<InquiryPreview>;
  inquiryReply: InquiryReply;
  inquiryReplys: InquiryReplyList;
  /** 언어 하위 카테고리 */
  languageCategorySubTree: TreeLessonCategory;
  /** 학습 하위 카테고리 */
  learningCategorySubTree: TreeLessonCategory;
  /**
   * 학습 선생님 조회
   * @deprecated memberLearningTeacher로 변경
   */
  learningTeacher?: Maybe<TeacherList>;
  /** 수업 단일 조회 */
  lesson: Lesson;
  /** 수업 정산 조회 */
  lessonCalculate: LessonCalculate;
  /** 수업정산 리스트 조회 */
  lessonCalculates: LessonCalculateList;
  /** 수업 카테고리 조회 */
  lessonCategories: Array<TreeLessonCategory>;
  lessonMonitoring: Array<LessonMonitoring>;
  /** 수업금액옵션 조회 */
  lessonPrice: LessonPrice;
  /** 수업금액옵션 리스트 조회 */
  lessonPrices: LessonPriceList;
  /** 수업 리스트 조회 */
  lessons: LessonList;
  /** 로그인한 사용자 조회 */
  me: User;
  /** 차단한 사용자 리스트 조회 */
  memberBlockUsers: UserList;
  memberBook: Book;
  memberBookCarts: BookCartList;
  /** 교재 id에 대한 리뷰 리스트 */
  memberBookReview: MemberBookReviewList;
  /** 사용자가 찜한 교재 조회 */
  memberBookmarkBooks: BookList;
  /** 교재 리스트 조회 */
  memberBooks: BookList;
  /** 사용자 주문 리스트 조회 */
  memberBusinesses: BusinessList;
  /** 사용자 캐쉬 리스트 조회 */
  memberCashes: CashList;
  /** 수업변경요청 단일 조회 */
  memberChangeSubLesson: ChangeSubLesson;
  /** 학생이 받은 수업요청변경신청 */
  memberChangeSubLessons: ChangeSubLessonList;
  /** 사용자용 채팅방 조회 */
  memberChatChannel: ChatChannel;
  memberContent: Content;
  memberContents: ContentList;
  memberFAQs: PostList;
  memberInquiries: InquiryList;
  /** 초대한 친구 */
  memberInvitedUsers: MemberInvitedUserList;
  /** 언어 선생님 조회 */
  memberLanguageTeacher: TeacherList;
  /** 학습 선생님 조회 */
  memberLearningTeacher?: Maybe<TeacherList>;
  memberLesson: Lesson;
  memberLessonCalculates: MemberLessonCalculateList;
  memberLessons: LessonList;
  memberMainPageBusinesses: MemberMainPageBusinessList;
  memberMe: MemberProfile;
  /** 나의 보호자/피보호자 리스트 */
  memberMyConnections: MyConnections;
  /** 사용자의 보호자 정보 (해당 사용자가 피보호자 역할) */
  memberMyGuardians: UserGuardianList;
  memberMyPageTeachers: MemberMyPageTeacherList;
  /** 사용자의 피보호자 정보 (해당 사용자가 보호자 역할) */
  memberMyProteges: UserGuardianList;
  memberNotices: PostList;
  memberNotifications: NotificationList;
  memberOrders: PaymentList;
  memberPartnershipCalculateDashboard?: Maybe<MemberPartnershipCalculateDashboard>;
  memberPartnershipCalculates: MemberPartnershipCalculateList;
  memberPopup: Popup;
  memberPopups: PopupList;
  memberRecommendedBook: Array<Book>;
  /** 추천 강사 */
  memberRecommendedTeacher: Array<Teacher>;
  /** 신고 보기 */
  memberReport: Report;
  memberSubLessonReviews: MemberSubLessonReviewList;
  memberSubLessons: SubLessonList;
  /** 선생님 조회 */
  memberTeacher: Teacher;
  /** 해당 사용자가 신청한 언어 자격 단건 조회 */
  memberTeacherLanguageHistory: TeacherLanguageHistory;
  /** 해당 사용자가 신청한 학습 자격 단건 조회 */
  memberTeacherLearningHistory: TeacherLearningHistory;
  /** 사용자용 선생님의 강의 리스트 */
  memberTeacherSubLessons: SubLessonList;
  memberTermsOfService: MemberTermsOfService;
  memberTermsOfServices: MemberTermsOfServiceList;
  memberTimeSlots: TimeSlotList;
  memberUserBlockings: UserBlockingList;
  memberUserByIdentityData: User;
  /** 내가 받은/보낸 요청 */
  memberUserGuardianRequests: UserGuardianList;
  /** 채팅 메시지 조회 */
  message: ChatMessage;
  /** 채팅 메시지 목록 조회 */
  messages: ChatMessageList;
  /** 특정 채널 채팅 메시지 목록 조회 */
  messagesByChannel: ChatMessageList;
  /** 내 채팅방 목록 조회 */
  myChatChannels?: Maybe<Array<ChatChannel>>;
  /** 마이페이지 내학생 */
  myPageTeacherStudentBusinesses: UserList;
  myTeacherPurchaseLedgers: Array<PurchaseLedger>;
  myTeacherSalesLedgers: Array<SalesLedger>;
  /** 사용자의 선생님 정보 테이블 */
  myTeachers: TeacherList;
  /** 마스킹 안된 내 정보 조회 */
  noMaskedMe: User;
  /** 주문 취소/환불 조회 */
  orderInfo: OrderInfo;
  /** 주문 취소/환불 리스트 조회 */
  orderInfos: OrderInfoList;
  /** pagecall에 업로드한 교재 */
  pagecallUploadedBooks?: Maybe<Array<Book>>;
  /** 상위 교재 카테고리 조회 */
  parentBookCategory: Array<BookCategory>;
  /** 상위 FAQ 카테고리 조회 */
  parentFAQCategory: Array<FaqCategory>;
  /** 상위 수업 카테고리 조회 */
  parentLessonCategory: Array<LessonCategory>;
  /** 참여자 리스트 조회 */
  participants: ChatChannelParticipantList;
  /** 제휴자 정산 조회 */
  partnershipCalculate: PartnershipCalculate;
  /** 제휴자 정산 리스트 조회 */
  partnershipCalculates: PartnershipCalculateList;
  payment: Payment;
  payments: PaymentList;
  popup: Popup;
  popups: PopupList;
  post: Post;
  posts: PostList;
  /** 출판사 조회 */
  publisher: Publisher;
  /** 전체 출판사 정산 조회 */
  publisherAllPublisherCalculate: AllPublisherCalculate;
  /** 단일 교재 조회 */
  publisherBook: Book;
  /** 출판사 교재 카테고리 조회 */
  publisherBookCategories: Array<TreeBookCategory>;
  publisherBookSalesDashboard: BookSalesDashboard;
  /** 교재 리스트 조회 */
  publisherBooks: BookList;
  /** 출판사 브랜드 조회 */
  publisherBrand: Brand;
  /** 출판사 브랜드 리스트 조회 */
  publisherBrands: BrandList;
  /** 주문 단일 조회 */
  publisherBusiness: Business;
  /** 출판사 주문 리스트 조회 */
  publisherBusinesses: BusinessList;
  /** 출판사 정산 조회 */
  publisherCalculate: PublisherCalculate;
  /** 출판사 대시보드 */
  publisherCalculateDashboard?: Maybe<PublisherCalculateDashboard>;
  /** 출판사 정산 리스트 조회 */
  publisherCalculates: PublisherCalculateList;
  /** 출판사 하위 교재 카테고리 조회 */
  publisherChildrenBookCategory: TreeBookCategory;
  /** 출판사용 하위 FAQ 카테고리 조회 */
  publisherChildrenFAQCategory: TreeFaqCategory;
  publisherExposingFaqs: PostList;
  publisherExposingNotices: PostList;
  /** 출판사용 FAQ 카테고리 조회 */
  publisherFaqCategories: Array<TreeFaqCategory>;
  /** 현재 로그인된 출판사 정보 반환 */
  publisherMe: Publisher;
  /** 출판사 상위 교재 카테고리 조회 */
  publisherParentBookCategory: Array<BookCategory>;
  /** 출판사용 상위 FAQ 카테고리 조회 */
  publisherParentFAQCategory: Array<FaqCategory>;
  publisherPost: Post;
  publisherPosts: PostList;
  /** 출판사 정산 조회 */
  publisherPublisherCalculate: PublisherCalculate;
  /** 출판사 대시보드 */
  publisherPublisherCalculateDashboard?: Maybe<PublisherCalculateDashboard>;
  /** 출판사 정산 리스트 조회 */
  publisherPublisherCalculates: PublisherCalculateList;
  /** 출판사 정렬된 교재 카테고리 조회 */
  publisherSortedBookCategories: Array<TreeBookCategory>;
  /** 출판사용 정렬된 FAQ 카테고리 조회 */
  publisherSortedFaqCategories: Array<TreeFaqCategory>;
  /** 출판사 리스트 조회 */
  publishers: PublisherList;
  /** 주문 취소/환불 조회 */
  refundHistory: RefundHistory;
  /** 주문 취소/환불 리스트 조회 */
  refundHistorys: RefundHistoryList;
  /** 신고/정지 조회 */
  report: Report;
  /** 신고/정지 리스트 조회 */
  reports: ReportList;
  /** 내가 받을 수업 예정 리스트 */
  scheduledLesson: ScheduledSubLessonList;
  solVook: SolVookModel;
  solVooks: SolVookList;
  /** 정렬된 교재 카테고리 조회 */
  sortedBookCategories: Array<TreeBookCategory>;
  /** 정렬된 FAQ 카테고리 조회 */
  sortedFaqCategories: Array<TreeFaqCategory>;
  /** 정렬된 수업 카테고리 조회 */
  sortedLessonCategories: Array<TreeLessonCategory>;
  /** 학생의 교재 리스트(선생, 학생만 조회 가능) */
  studentBooks: BookList;
  /** 학생 리스트 */
  studentList: UserList;
  /** 공부하기 리스트 조회 */
  studyList: Array<Business>;
  /** 개별 수업 조회 */
  subLesson: SubLesson;
  /** 개별 수업 리스트 조회 */
  subLessons: SubLessonList;
  /** 선생님 조회 */
  teacher: Teacher;
  /** 선생님의 판매 내역 */
  teacherBusinesses: BusinessList;
  /** 선생님 정산 정보 */
  teacherCalculate: Array<TeacherCalculate>;
  teacherCareers: TeacherCareerList;
  /** 선생님이 요청한 수업요청변경신청 */
  teacherChangeSubLessons: ChangeSubLessonList;
  teacherLanguageLevels: TeacherLanguageLevelList;
  /** 선생님 언어 자격 조회 */
  teacherLanguageQualification: TeacherLanguageHistory;
  /** 선생님 언어 자격 리스트 히스토리(마지막 신청) 조회 */
  teacherLanguageQualificationHistory: TeacherLanguageHistoryList;
  /** 선생님 언어 자격 리스트 조회 */
  teacherLanguageQualifications: TeacherLanguageHistoryList;
  /** 선생님 학습 자격 조회 */
  teacherLearningQualification: TeacherLearningHistory;
  /** 선생님 학습 자격 리스트 히스토리 조회 */
  teacherLearningQualificationHistory: TeacherLearningHistoryList;
  /** 선생님 학습 자격 리스트 조회 */
  teacherLearningQualifications: TeacherLearningHistoryList;
  /** 수업 단일 조회 */
  teacherLesson: Lesson;
  /** 수업금액옵션 조회 */
  teacherLessonPrice: LessonPrice;
  /** 수업금액옵션 리스트 조회 */
  teacherLessonPrices: LessonPriceList;
  /** 선생님 수업 리스트 조회 */
  teacherLessons: LessonList;
  /** 마이페이지의 내 언어 자격 신청 내역 */
  teacherMyPageLanguageQualificationHistories: TeacherLanguageHistoryList;
  /** 마이페이지의 내 학습 자격 신청 내역 */
  teacherMyPageLearningQualificationHistories: TeacherLearningHistoryList;
  /** 선생님이 등록한 책 리스트 */
  teacherRegisteredBooks: BookList;
  /** 선생님 요청 정리 조회 */
  teacherRequest: RequestTotal;
  /** 선생님의 강의 리스트 */
  teacherSubLessons: SubLessonList;
  /** 해당 선생님과 사용자강의 리스트 */
  teacherUserSubLessons: SubLessonList;
  teacherVideoLogs: TeacherVideoLogList;
  /** 선생님 리스트 조회 */
  teachers: TeacherList;
  termsOfService: TermsOfService;
  termsOfServices: TermsOfServiceList;
  /** 사용하는 언어 카테고리만 가져오기 */
  usedLanguageCategorySubTree?: Maybe<Array<LessonCategory>>;
  /** 사용하는 학습 카테고리만 가져오기 */
  usedLearningCategorySubTree?: Maybe<Array<LessonCategory>>;
  /** 유저 조회 */
  user: User;
  /** 보호자 조회 */
  userGuardian: UserGuardian;
  /** 보호자 리스트 조회 */
  userGuardians: UserGuardianList;
  /** 추천친구 조회 */
  userInvite: UserInvite;
  /** 추천친구 리스트 조회 */
  userInvites: UserInviteList;
  /** 유저 리스트 조회 */
  users: UserList;
  /** 출금신청 조회 */
  withdrawal: Withdrawal;
  /** 출금신청 조회 */
  withdrawalInfo: WithdrawalInfo;
  /** 출금신청 리스트 조회 */
  withdrawals: WithdrawalList;
};


export type QueryBookArgs = {
  id: Scalars['ID'];
};


export type QueryBookByIsbnArgs = {
  isbn: Scalars['String'];
};


export type QueryBookRegistrationForAdminArgs = {
  id: Scalars['ID'];
};


export type QueryBookRegistrationsForAdminArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<BookRegistrationFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<BookRegistrationSortInput>>;
};


export type QueryBookReviewArgs = {
  id: Scalars['ID'];
};


export type QueryBookReviewsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<BookReviewFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<BookReviewOrderByInput>>;
};


export type QueryBooksArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<BookFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  requestedDate?: InputMaybe<Scalars['DateTime']>;
  sort?: InputMaybe<Array<BookOrderByInput>>;
};


export type QueryBrandArgs = {
  id: Scalars['ID'];
};


export type QueryBrandsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<BrandFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<BrandOrderByInput>>;
};


export type QueryBusinessArgs = {
  id: Scalars['ID'];
};


export type QueryBusinessesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<BusinessFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  requestedDate?: InputMaybe<Scalars['DateTime']>;
  sort?: InputMaybe<Array<BusinessOrderByInput>>;
};


export type QueryCashArgs = {
  id: Scalars['ID'];
};


export type QueryCashesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<CashFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<CashOrderByInput>>;
};


export type QueryChatChannelArgs = {
  id: Scalars['ID'];
};


export type QueryChatChannelsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<ChatChannelFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<ChatChannelOrderByInput>>;
};


export type QueryChildrenBookCategoryArgs = {
  id: Scalars['ID'];
};


export type QueryChildrenFaqCategoryArgs = {
  id: Scalars['ID'];
};


export type QueryChildrenLessonCategoryArgs = {
  id: Scalars['ID'];
};


export type QueryContentArgs = {
  id: Scalars['ID'];
};


export type QueryContentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<ContentFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<ContentOrderByInput>>;
};


export type QueryFindMyEmailArgs = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone: Scalars['PhoneNumber'];
  verifiedPhoneKey: Scalars['String'];
};


export type QueryForbiddenWordArgs = {
  id: Scalars['ID'];
};


export type QueryForbiddenWordUseArgs = {
  id: Scalars['ID'];
};


export type QueryForbiddenWordUsesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<ForbiddenWordUseFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<ForbiddenWordUseOrderByInput>>;
};


export type QueryForbiddenWordsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<ForbiddenWordFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<ForbiddenWordOrderByInput>>;
};


export type QueryInquiriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<InquiryFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InquiryOrderByInput>>;
};


export type QueryInquiryArgs = {
  id: Scalars['ID'];
};


export type QueryInquiryPreviewListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<InquiryPreviewFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InquiryPreviewOrderByInput>>;
};


export type QueryInquiryReplyArgs = {
  id: Scalars['ID'];
};


export type QueryInquiryReplysArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<InquiryReplyFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InquiryReplyOrderByInput>>;
};


export type QueryLearningTeacherArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<LearningTeacherFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryLessonArgs = {
  id: Scalars['ID'];
};


export type QueryLessonCalculateArgs = {
  id: Scalars['ID'];
};


export type QueryLessonCalculatesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<LessonCalculateFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<LessonCalculateOrderByInput>>;
};


export type QueryLessonMonitoringArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<LessonMonitoringFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<LessonMonitoringOrderByInput>>;
};


export type QueryLessonPriceArgs = {
  id: Scalars['ID'];
};


export type QueryLessonPricesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<LessonPriceFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<LessonPriceOrderByInput>>;
};


export type QueryLessonsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<LessonFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  requestedDate?: InputMaybe<Scalars['DateTime']>;
  sort?: InputMaybe<Array<LessonOrderByInput>>;
};


export type QueryMemberBlockUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<MemberUserFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  requestedDate?: InputMaybe<Scalars['DateTime']>;
  sort?: InputMaybe<Array<MemberUserOrderByInput>>;
};


export type QueryMemberBookArgs = {
  id: Scalars['ID'];
};


export type QueryMemberBookCartsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<BookCartFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<BookCartOrderByInput>>;
};


export type QueryMemberBookReviewArgs = {
  bookId: Scalars['ID'];
};


export type QueryMemberBookmarkBooksArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  bookCategoryIds?: InputMaybe<Array<Scalars['ID']>>;
  filter?: InputMaybe<Array<MemberBookFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  requestedDate?: InputMaybe<Scalars['DateTime']>;
  sort?: InputMaybe<Array<MemberBookOrderByInput>>;
};


export type QueryMemberBooksArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  bookCategoryIds?: InputMaybe<Array<Scalars['ID']>>;
  filter?: InputMaybe<Array<MemberBookFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  requestedDate?: InputMaybe<Scalars['DateTime']>;
  sort?: InputMaybe<Array<MemberBookOrderByInput>>;
};


export type QueryMemberBusinessesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  bookCategoryIds?: InputMaybe<Array<Scalars['ID']>>;
  filter?: InputMaybe<Array<MemberBusinessFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  lessonCategoryIds?: InputMaybe<Array<Scalars['ID']>>;
  offset?: InputMaybe<Scalars['Int']>;
  requestedDate?: InputMaybe<Scalars['DateTime']>;
  sort?: InputMaybe<Array<BusinessOrderByInput>>;
};


export type QueryMemberCashesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<CashFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<CashOrderByInput>>;
};


export type QueryMemberChangeSubLessonArgs = {
  id: Scalars['ID'];
};


export type QueryMemberChangeSubLessonsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<MemberChangeSubLessonFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<ChangeSubLessonOrderByInput>>;
};


export type QueryMemberChatChannelArgs = {
  id: Scalars['ID'];
};


export type QueryMemberContentArgs = {
  id: Scalars['ID'];
};


export type QueryMemberContentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<ContentFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<ContentOrderByInput>>;
};


export type QueryMemberFaQsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<MemberFaqFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<PostOrderByInput>>;
};


export type QueryMemberInquiriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<InquiryFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InquiryOrderByInput>>;
};


export type QueryMemberInvitedUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryMemberLanguageTeacherArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<LanguageTeacherFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  languageCategoryIds?: InputMaybe<Array<Scalars['ID']>>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  requestedDate?: InputMaybe<Scalars['DateTime']>;
  sort?: InputMaybe<Array<TeacherOrderByInput>>;
  timeArgs?: InputMaybe<MemberTeacherLessonTimeFilterInput>;
};


export type QueryMemberLearningTeacherArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<MemberLearningTeacherFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  learningCategoryIds?: InputMaybe<Array<Scalars['ID']>>;
  offset?: InputMaybe<Scalars['Int']>;
  requestedDate?: InputMaybe<Scalars['DateTime']>;
  sort?: InputMaybe<Array<TeacherOrderByInput>>;
  timeArgs?: InputMaybe<MemberTeacherLessonTimeFilterInput>;
};


export type QueryMemberLessonArgs = {
  id: Scalars['String'];
};


export type QueryMemberLessonCalculatesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<MemberLessonCalculateFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<MemberLessonCalculateOrderByInput>>;
};


export type QueryMemberLessonsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  categoryIds?: InputMaybe<Array<Scalars['ID']>>;
  filter?: InputMaybe<Array<LessonFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  requestedDate?: InputMaybe<Scalars['DateTime']>;
  sort?: InputMaybe<Array<LessonOrderByInput>>;
};


export type QueryMemberMainPageBusinessesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  kind: MainPageTeacherKindEnum;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryMemberMyGuardiansArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<UserGuardianFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<UserGuardianOrderByInput>>;
};


export type QueryMemberMyPageTeachersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<MemberMyTeacherFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  kind: MyPageTeacherKindEnum;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<DefaultSortInput>>;
};


export type QueryMemberMyProtegesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<UserGuardianFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<UserGuardianOrderByInput>>;
};


export type QueryMemberNoticesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<MemberNoticeFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<PostOrderByInput>>;
};


export type QueryMemberNotificationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<NotificationFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<DefaultSortInput>>;
};


export type QueryMemberOrdersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<PaymentFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<PaymentOrderByInput>>;
  type?: InputMaybe<OrderType>;
};


export type QueryMemberPartnershipCalculateDashboardArgs = {
  yearMonth: Scalars['String'];
};


export type QueryMemberPartnershipCalculatesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  yearMonth?: InputMaybe<Scalars['String']>;
};


export type QueryMemberPopupArgs = {
  id: Scalars['ID'];
};


export type QueryMemberPopupsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<PopupFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<PopupOrderByInput>>;
};


export type QueryMemberRecommendedTeacherArgs = {
  requestedDate?: InputMaybe<Scalars['DateTime']>;
};


export type QueryMemberReportArgs = {
  id: Scalars['ID'];
};


export type QueryMemberSubLessonReviewsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  teacherId: Scalars['ID'];
};


export type QueryMemberSubLessonsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<LessonCategoryRootEnumType>;
  filter?: InputMaybe<Array<MemberSubLessonFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<MemberSubLessonOrderByInput>>;
  userId?: InputMaybe<Scalars['ID']>;
};


export type QueryMemberTeacherArgs = {
  id: Scalars['ID'];
};


export type QueryMemberTeacherLanguageHistoryArgs = {
  id: Scalars['ID'];
};


export type QueryMemberTeacherLearningHistoryArgs = {
  id: Scalars['ID'];
};


export type QueryMemberTeacherSubLessonsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<LessonCategoryRootEnumType>;
  filter?: InputMaybe<Array<MemberTeacherSubLessonFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<MemberSubLessonOrderByInput>>;
};


export type QueryMemberTermsOfServiceArgs = {
  id: Scalars['ID'];
};


export type QueryMemberTermsOfServicesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<MemberTermsOfServiceFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<MemberTermsOfServiceOrderByInput>>;
};


export type QueryMemberTimeSlotsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<TimeSlotFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<TimeSlotOrderByInput>>;
};


export type QueryMemberUserBlockingsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<MemberUserBlockingFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<DefaultSortInput>>;
};


export type QueryMemberUserByIdentityDataArgs = {
  guardianType: GuardianType;
  input: Scalars['String'];
};


export type QueryMemberUserGuardianRequestsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<UserGuardianFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<UserGuardianOrderByInput>>;
};


export type QueryMessageArgs = {
  id: Scalars['ID'];
};


export type QueryMessagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<ChatMessageFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<ChatMessageOrderByInput>>;
};


export type QueryMessagesByChannelArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  channelId: Scalars['ID'];
  filter?: InputMaybe<Array<ChatMessageFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<ChatMessageOrderByInput>>;
};


export type QueryMyChatChannelsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryMyPageTeacherStudentBusinessesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  categoryIds?: InputMaybe<Array<Scalars['ID']>>;
  filter?: InputMaybe<Array<MemberUserFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<MemberUserOrderByInput>>;
};


export type QueryMyTeacherPurchaseLedgersArgs = {
  filter?: InputMaybe<LedgerFilterInput>;
};


export type QueryMyTeacherSalesLedgersArgs = {
  filter?: InputMaybe<LedgerFilterInput>;
};


export type QueryMyTeachersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<TeacherFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  requestedDate?: InputMaybe<Scalars['DateTime']>;
  sort?: InputMaybe<Array<TeacherOrderByInput>>;
};


export type QueryNoMaskedMeArgs = {
  password?: InputMaybe<Scalars['String']>;
  phoneKey?: InputMaybe<Scalars['String']>;
};


export type QueryOrderInfoArgs = {
  id: Scalars['ID'];
};


export type QueryOrderInfosArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<OrderInfoFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<OrderInfoOrderByInput>>;
};


export type QueryPagecallUploadedBooksArgs = {
  id: Scalars['ID'];
};


export type QueryParentBookCategoryArgs = {
  id: Scalars['ID'];
};


export type QueryParentFaqCategoryArgs = {
  id: Scalars['ID'];
};


export type QueryParentLessonCategoryArgs = {
  id: Scalars['ID'];
};


export type QueryParticipantsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<ChatChannelParticipantFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<ChatChannelParticipantOrderByInput>>;
};


export type QueryPartnershipCalculateArgs = {
  id: Scalars['ID'];
};


export type QueryPartnershipCalculatesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<PartnershipCalculateFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<PartnershipCalculateOrderByInput>>;
};


export type QueryPaymentArgs = {
  id: Scalars['ID'];
};


export type QueryPaymentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<PaymentFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<PaymentOrderByInput>>;
};


export type QueryPopupArgs = {
  id: Scalars['ID'];
};


export type QueryPopupsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<PopupFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<PopupOrderByInput>>;
};


export type QueryPostArgs = {
  id: Scalars['ID'];
};


export type QueryPostsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<PostFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<PostOrderByInput>>;
};


export type QueryPublisherArgs = {
  id: Scalars['ID'];
};


export type QueryPublisherBookArgs = {
  id: Scalars['ID'];
};


export type QueryPublisherBooksArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<PublisherBookFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  requestedDate?: InputMaybe<Scalars['DateTime']>;
  sort?: InputMaybe<Array<BookOrderByInput>>;
};


export type QueryPublisherBrandArgs = {
  id: Scalars['ID'];
};


export type QueryPublisherBrandsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<PublisherBrandFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<BrandOrderByInput>>;
};


export type QueryPublisherBusinessArgs = {
  id: Scalars['ID'];
};


export type QueryPublisherBusinessesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<PublisherBusinessFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  requestedDate?: InputMaybe<Scalars['DateTime']>;
  sort?: InputMaybe<Array<BusinessOrderByInput>>;
};


export type QueryPublisherCalculateArgs = {
  id: Scalars['ID'];
};


export type QueryPublisherCalculatesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<PublisherCalculateFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<PublisherCalculateOrderByInput>>;
};


export type QueryPublisherChildrenBookCategoryArgs = {
  id: Scalars['ID'];
};


export type QueryPublisherChildrenFaqCategoryArgs = {
  id: Scalars['ID'];
};


export type QueryPublisherExposingFaqsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<PostFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<PostOrderByInput>>;
};


export type QueryPublisherExposingNoticesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<PostFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<PostOrderByInput>>;
};


export type QueryPublisherParentBookCategoryArgs = {
  id: Scalars['ID'];
};


export type QueryPublisherParentFaqCategoryArgs = {
  id: Scalars['ID'];
};


export type QueryPublisherPostArgs = {
  id: Scalars['ID'];
};


export type QueryPublisherPostsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<PostFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<PostOrderByInput>>;
};


export type QueryPublisherPublisherCalculateArgs = {
  id: Scalars['ID'];
};


export type QueryPublisherPublisherCalculatesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<PublisherPublisherCalculateFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<PublisherCalculateOrderByInput>>;
};


export type QueryPublishersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<PublisherFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  requestedDate?: InputMaybe<Scalars['DateTime']>;
  sort?: InputMaybe<Array<PublisherOrderByInput>>;
};


export type QueryRefundHistoryArgs = {
  id: Scalars['ID'];
};


export type QueryRefundHistorysArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<RefundhistoryFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<RefundHistoryOrderByInput>>;
};


export type QueryReportArgs = {
  id: Scalars['ID'];
};


export type QueryReportsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<ReportFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<ReportOrderByInput>>;
};


export type QueryScheduledLessonArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ScheduledSubLessonFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QuerySolVookArgs = {
  id: Scalars['ID'];
};


export type QuerySolVooksArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<SolVookFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<SolVookSortInput>>;
};


export type QueryStudentBooksArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<MemberBookFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<MemberBookOrderByInput>>;
  studentId: Scalars['String'];
};


export type QueryStudentListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<UserFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  requestedDate?: InputMaybe<Scalars['DateTime']>;
  sort?: InputMaybe<Array<UserOrderByInput>>;
};


export type QueryStudyListArgs = {
  bookId: Scalars['ID'];
};


export type QuerySubLessonArgs = {
  id: Scalars['ID'];
};


export type QuerySubLessonsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<SubLessonFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  requestedDate?: InputMaybe<Scalars['DateTime']>;
  sort?: InputMaybe<Array<SubLessonOrderByInput>>;
};


export type QueryTeacherArgs = {
  id: Scalars['ID'];
};


export type QueryTeacherBusinessesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<TeacherBusinessFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  requestedDate?: InputMaybe<Scalars['DateTime']>;
  sort?: InputMaybe<Array<BusinessOrderByInput>>;
};


export type QueryTeacherCareersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<TeacherCareerFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<TeacherCareerOrderByInput>>;
};


export type QueryTeacherChangeSubLessonsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<TeacherChangeSubLessonFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<ChangeSubLessonOrderByInput>>;
};


export type QueryTeacherLanguageLevelsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<TeacherLanguageLevelFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<TeacherLanguageLevelOrderByInput>>;
};


export type QueryTeacherLanguageQualificationArgs = {
  id: Scalars['ID'];
};


export type QueryTeacherLanguageQualificationHistoryArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<TeacherLanguageHistoryFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<TeacherLanguageHistoryOrderByInput>>;
};


export type QueryTeacherLanguageQualificationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<TeacherLanguageHistoryFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<TeacherLanguageHistoryOrderByInput>>;
};


export type QueryTeacherLearningQualificationArgs = {
  id: Scalars['ID'];
};


export type QueryTeacherLearningQualificationHistoryArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<TeacherLearningHistoryFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<TeacherLearningHistoryOrderByInput>>;
};


export type QueryTeacherLearningQualificationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<TeacherLearningHistoryFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<TeacherLearningHistoryOrderByInput>>;
};


export type QueryTeacherLessonArgs = {
  id: Scalars['ID'];
};


export type QueryTeacherLessonPriceArgs = {
  id: Scalars['ID'];
};


export type QueryTeacherLessonPricesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<LessonPriceFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<LessonPriceOrderByInput>>;
};


export type QueryTeacherLessonsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  categoryIds?: InputMaybe<Array<Scalars['ID']>>;
  filter?: InputMaybe<Array<LessonFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  requestedDate?: InputMaybe<Scalars['DateTime']>;
  sort?: InputMaybe<Array<LessonOrderByInput>>;
};


export type QueryTeacherMyPageLanguageQualificationHistoriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<TeacherMyPageLanguageQualificationFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<DefaultSortInput>>;
};


export type QueryTeacherMyPageLearningQualificationHistoriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<TeacherMyPageLearningQualificationFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<DefaultSortInput>>;
};


export type QueryTeacherRegisteredBooksArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  bookCategoryIds?: InputMaybe<Array<Scalars['ID']>>;
  filter?: InputMaybe<Array<MemberBookFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  requestedDate?: InputMaybe<Scalars['DateTime']>;
  sort?: InputMaybe<Array<MemberBookOrderByInput>>;
};


export type QueryTeacherSubLessonsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<LessonCategoryRootEnumType>;
  filter?: InputMaybe<Array<TeacherSubLessonFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<MemberSubLessonOrderByInput>>;
};


export type QueryTeacherUserSubLessonsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<LessonCategoryRootEnumType>;
  filter?: InputMaybe<Array<TeacherSubLessonFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<MemberSubLessonOrderByInput>>;
  userId: Scalars['ID'];
};


export type QueryTeacherVideoLogsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<TeacherVideoLogFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<TeacherVideoLogSortInput>>;
};


export type QueryTeachersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<TeacherFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  requestedDate?: InputMaybe<Scalars['DateTime']>;
  sort?: InputMaybe<Array<TeacherOrderByInput>>;
};


export type QueryTermsOfServiceArgs = {
  id: Scalars['ID'];
};


export type QueryTermsOfServicesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<TermsOfServiceFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<TermsOfServiceOrderByInput>>;
};


export type QueryUserArgs = {
  id: Scalars['ID'];
};


export type QueryUserGuardianArgs = {
  id: Scalars['ID'];
};


export type QueryUserGuardiansArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<UserGuardianFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<UserGuardianOrderByInput>>;
};


export type QueryUserInviteArgs = {
  id: Scalars['ID'];
};


export type QueryUserInvitesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<UserInviteFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<UserInviteOrderByInput>>;
};


export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<UserFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  requestedDate?: InputMaybe<Scalars['DateTime']>;
  sort?: InputMaybe<Array<UserOrderByInput>>;
};


export type QueryWithdrawalArgs = {
  id: Scalars['ID'];
};


export type QueryWithdrawalsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<WithdrawalFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<WithdrawalOrderByInput>>;
};

/** 주문 취소/환불 히스토리 */
export type RefundHistory = {
  __typename?: 'RefundHistory';
  /** 취소/환불 완료일 */
  cancelCompletionDate?: Maybe<Scalars['DateTime']>;
  /** 생성 날짜/시간 */
  createdAt: Scalars['DateTime'];
  /** 삭제 날짜/시간 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** 기본 키(UUID) */
  id: Scalars['ID'];
  /** 처리 관리자 */
  processor?: Maybe<User>;
  /** 취소/환불 사유 */
  reason?: Maybe<Scalars['String']>;
  /** 수정 날짜/시간 */
  updatedAt: Scalars['DateTime'];
};

/** 주문 취소/환불 생성 */
export type RefundHistoryCreateInput = {
  /** 수업 */
  business__id?: InputMaybe<Scalars['ID']>;
  /** 취소/환불 완료일 */
  cancelCompletionDate?: InputMaybe<Scalars['DateTime']>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  /** 기본 키(UUID) */
  id?: InputMaybe<Scalars['ID']>;
  /** 관리자 아이디 */
  processor__id?: InputMaybe<Scalars['ID']>;
  /** 취소/환불 사유 */
  reason?: InputMaybe<Scalars['String']>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type RefundHistoryEdge = {
  __typename?: 'RefundHistoryEdge';
  /** 커서 */
  cursor: Scalars['String'];
  /** 노드 */
  node: RefundHistory;
};

/** 주문 취소/환불 목록 */
export type RefundHistoryList = {
  __typename?: 'RefundHistoryList';
  /** 페이지네이션된 데이터 목록 */
  edges: Array<Maybe<RefundHistoryEdge>>;
  /** 페이지네이션된 페이지 정보 */
  pageInfo: PageInfo;
  /** 전체 데이터 개 수 */
  totalCount: Scalars['Int'];
};

/** 주문 취소/환불 정렬 */
export type RefundHistoryOrderByInput = {
  /** 주문확정일 */
  CompletionDate?: InputMaybe<SortInput>;
  /** 취소/환불 사유 */
  Reason?: InputMaybe<SortInput>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<SortInput>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<SortInput>;
  /** UUID */
  id?: InputMaybe<SortInput>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<SortInput>;
};

/** 주문 취소/환불 업데이트 */
export type RefundHistoryUpdateInput = {
  /** 수업 */
  business__id?: InputMaybe<Scalars['ID']>;
  /** 취소/환불 완료일 */
  cancelCompletionDate?: InputMaybe<Scalars['DateTime']>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  /** 기본 키(UUID) */
  id?: InputMaybe<Scalars['ID']>;
  /** 관리자 아이디 */
  processor__id?: InputMaybe<Scalars['ID']>;
  /** 취소/환불 사유 */
  reason?: InputMaybe<Scalars['String']>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

/** 주문 취소/환불 로그 필터 */
export type RefundhistoryFilterInput = {
  /** 비지니스아이디 */
  business__id?: InputMaybe<Array<IdFilterInput>>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** UUID */
  id?: InputMaybe<Array<IdFilterInput>>;
  /** 관리자 이메일 */
  processor__email?: InputMaybe<Array<StringFilterInput>>;
  /** 관리자 아이디 */
  processor__id?: InputMaybe<Array<IdFilterInput>>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Array<DateTimeFilterInput>>;
};

/** 등록 상태 정렬 */
export type RegistrationStateSortInput = {
  case?: InputMaybe<Array<PublisherRegistrationStateType>>;
  nulls?: InputMaybe<Nulls>;
  order: Order;
};

/** 신고/정지 */
export type Report = {
  __typename?: 'Report';
  /** 교재 */
  book?: Maybe<Book>;
  /** 생성 날짜/시간 */
  createdAt: Scalars['DateTime'];
  /** 삭제 날짜/시간 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** 소명 일시 */
  explanationAt?: Maybe<Scalars['DateTime']>;
  /** 소명 내용 */
  explanationContent?: Maybe<Scalars['String']>;
  /** 소명 요청 일시 */
  explanationRequestAt?: Maybe<Scalars['DateTime']>;
  /** 소명 요청 내용 */
  explanationRequestContent?: Maybe<Scalars['String']>;
  /**
   * 소명 상태
   * @deprecated state와 중복
   */
  explanationState?: Maybe<ExplanationStateEnum>;
  /** 기본 키(UUID) */
  id: Scalars['ID'];
  /** 영구정지 해제일 */
  permanentBanReleasedAt?: Maybe<Scalars['DateTime']>;
  /** 이슈 처리 일시 */
  processedAt?: Maybe<Scalars['DateTime']>;
  /** 이슈 처리 내용 */
  processedContent?: Maybe<Scalars['String']>;
  /** 처리자 */
  processor?: Maybe<User>;
  /** 관리자가 입력한 메모 */
  processorMemo?: Maybe<Scalars['String']>;
  /** 신고일시 */
  reportDate?: Maybe<Scalars['DateTime']>;
  /** 신고 사유 */
  reportReason?: Maybe<SanctionReasonEnum>;
  /** 신고인 */
  reporter?: Maybe<User>;
  /** 사용자가 입력한 메모 */
  reporterMemo?: Maybe<Scalars['String']>;
  /** 제재항목 */
  sanction?: Maybe<ReportSanction>;
  /** 페널티 등록 날짜 */
  sanctionRegistrationDate?: Maybe<Scalars['DateTime']>;
  /** 정지 해제일 */
  sanctionReleasedDate?: Maybe<Scalars['DateTime']>;
  /** 상태 */
  state?: Maybe<ReportStateEnum>;
  /** 신고 수업 */
  subLesson?: Maybe<SubLesson>;
  /** 피신고인 */
  suspect?: Maybe<User>;
  /** 신고 타입 */
  type?: Maybe<ReportTypeEnum>;
  /** 수정 날짜/시간 */
  updatedAt: Scalars['DateTime'];
};

/** 신고/정지 생성 */
export type ReportCreateInput = {
  /** 피신고 교재 ID */
  book__id?: InputMaybe<Scalars['ID']>;
  /** 이슈 처리 일시 */
  processedAt?: InputMaybe<Scalars['DateTime']>;
  /** 이슈 처리 내용 */
  processedContent?: InputMaybe<Scalars['String']>;
  /** 관리자가 입력한 메모 */
  processorMemo?: InputMaybe<Scalars['String']>;
  /** 신고일시 */
  reportDate?: InputMaybe<Scalars['DateTime']>;
  /** 신고 사유 */
  reportReason?: InputMaybe<SanctionReasonEnum>;
  /** 사용자가 입력한 메모 */
  reporterMemo?: InputMaybe<Scalars['String']>;
  /** 신고인 ID */
  reporter__id?: InputMaybe<Scalars['ID']>;
  /** 제재항목 */
  sanction?: InputMaybe<ReportSanction>;
  /** 수업 ID */
  subLesson__id?: InputMaybe<Scalars['ID']>;
  /** 피신고인 ID */
  suspect__id?: InputMaybe<Scalars['ID']>;
  /** 신고 타입 */
  type: ReportTypeEnum;
};

export type ReportEdge = {
  __typename?: 'ReportEdge';
  /** 커서 */
  cursor: Scalars['String'];
  /** 노드 */
  node: Report;
};

/** 신고/정지 필터 */
export type ReportFilterInput = {
  /** 정지 내용 */
  banContent?: InputMaybe<Array<StringFilterInput>>;
  /** 교재 카테고리 id */
  book__categoryId?: InputMaybe<Array<IdFilterInput>>;
  /** 피신고 교재 */
  book__id?: InputMaybe<Array<IdFilterInput>>;
  /** 신고한 교재 이름 */
  book__name?: InputMaybe<Array<StringFilterInput>>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 소명 상태 */
  explanationState?: InputMaybe<ExplanationStateEnumFilter>;
  /** UUID */
  id?: InputMaybe<Array<IdFilterInput>>;
  /** 수업 카테고리 id */
  lesson__categoryId?: InputMaybe<Array<IdFilterInput>>;
  /** 신고한 수업 id */
  lesson__id?: InputMaybe<Array<IdFilterInput>>;
  /** 신고한 수업 이름 */
  lesson__lessonName?: InputMaybe<Array<StringFilterInput>>;
  /** 관리자 이메일 */
  processor__email?: InputMaybe<Array<StringFilterInput>>;
  /** 출판사 id */
  publisher__id?: InputMaybe<Array<IdFilterInput>>;
  /** 출판사명 */
  publisher__name?: InputMaybe<Array<StringFilterInput>>;
  /** 신고 내용 */
  reportContent?: InputMaybe<Array<StringFilterInput>>;
  /** 신고일시 */
  reportDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 신고 사유 */
  reportReason?: InputMaybe<Array<ReportReasonEnumFilter>>;
  /** 신고인 */
  reporter__id?: InputMaybe<Array<IdFilterInput>>;
  /** 신고인 이름 */
  reporter__name?: InputMaybe<Array<StringFilterInput>>;
  /** 제재항목 */
  sanction?: InputMaybe<Array<SanctionEnumFilter>>;
  /** 정지 해제일 */
  sanctionReleasedDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 신고 상태 */
  state?: InputMaybe<Array<ReportStateEnumFilter>>;
  /** 수업일 */
  subLesson__lessonDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 피신고인 */
  suspect__id?: InputMaybe<Array<IdFilterInput>>;
  /** 피신고인 이름 */
  suspect__name?: InputMaybe<Array<StringFilterInput>>;
  /** 유저 역할 */
  suspect__role?: InputMaybe<Array<UserRoleEnumFilter>>;
  /** 선생님 이름 */
  teacherUser__name?: InputMaybe<Array<StringFilterInput>>;
  /** 신고 타입 */
  type?: InputMaybe<Array<ReportTypeEnumFilter>>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Array<DateTimeFilterInput>>;
};

/** 신고/정지 리스트 */
export type ReportList = {
  __typename?: 'ReportList';
  /** 페이지네이션된 데이터 목록 */
  edges: Array<Maybe<ReportEdge>>;
  /** 페이지네이션된 페이지 정보 */
  pageInfo: PageInfo;
  /** 전체 데이터 개 수 */
  totalCount: Scalars['Int'];
};

/** 정지 정렬 */
export type ReportOrderByInput = {
  /** 신고한 교재 이름 */
  book__name?: InputMaybe<SortInput>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<SortInput>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<SortInput>;
  /** 소명 일시 */
  explanationAt?: InputMaybe<SortInput>;
  /** 소명 요청일 */
  explanationRequestAt?: InputMaybe<SortInput>;
  /** UUID */
  id?: InputMaybe<SortInput>;
  /** 수업 카테고리 */
  lessonCategory__name?: InputMaybe<SortInput>;
  /** 신고한 수업 이름 */
  lesson__lessonName?: InputMaybe<SortInput>;
  /** 영구정지 해제일 */
  permanentBanReleasedAt?: InputMaybe<SortInput>;
  /** 이슈 처리 일시 */
  processedAt?: InputMaybe<SortInput>;
  /** 신고한 출판사 이름 */
  publisher__name?: InputMaybe<SortInput>;
  /** 신고일시 */
  reportDate?: InputMaybe<SortInput>;
  /** 신고자 이름 */
  reporter__name?: InputMaybe<SortInput>;
  /** 제재항목 */
  sanction?: InputMaybe<SortInput>;
  /** 페널티 등록 날짜 */
  sanctionRegistrationDate?: InputMaybe<SortInput>;
  /** 정지 해제일 */
  sanctionReleasedDate?: InputMaybe<SortInput>;
  /** 신고 상태 */
  state?: InputMaybe<SortInput>;
  /** 학생 이름 */
  student__name?: InputMaybe<SortInput>;
  /** 피신고자 이름 */
  suspect__name?: InputMaybe<SortInput>;
  /** 선생님 이름 */
  teacherUser__name?: InputMaybe<SortInput>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<SortInput>;
};

/** 신고/정지 사유 */
export type ReportReasonEnumFilter = {
  operator: EnumFilterOperators;
  value?: InputMaybe<SanctionReasonEnum>;
  values?: InputMaybe<Array<SanctionReasonEnum>>;
};

/** 신고 상태 */
export type ReportStateEnumFilter = {
  operator: EnumFilterOperators;
  value?: InputMaybe<ReportStateEnum>;
  values?: InputMaybe<Array<ReportStateEnum>>;
};

/** 신고/정지 상태 변경 */
export type ReportStateUpdateInput = {
  /** 소명 요청 일시 */
  explanationRequestAt?: InputMaybe<Scalars['DateTime']>;
  /** 소명 요청 내용 */
  explanationRequestContent?: InputMaybe<Scalars['String']>;
  /** 소명 상태 */
  explanationState?: InputMaybe<ExplanationStateEnum>;
  /** 이슈 처리 내용 */
  processedContent?: InputMaybe<Scalars['String']>;
  /** 관리자가 입력한 메모 */
  processorMemo?: InputMaybe<Scalars['String']>;
  /** 제재항목 */
  sanction?: InputMaybe<ReportSanction>;
  /** 상태 */
  state?: InputMaybe<ReportStateEnum>;
};

export enum ReportTypeEnum {
  /** 책 */
  Book = 'BOOK',
  /** 수업 */
  Lesson = 'LESSON',
  /** 출판사 */
  Publisher = 'PUBLISHER',
  /** 선생님 */
  Teacher = 'TEACHER',
  /** 사용자 */
  User = 'USER'
}

/** 신고 타입 */
export type ReportTypeEnumFilter = {
  operator: EnumFilterOperators;
  value?: InputMaybe<ReportTypeEnum>;
  values?: InputMaybe<Array<ReportTypeEnum>>;
};

/** 신고/정지 업데이트 */
export type ReportUpdateInput = {
  /** 피신고 교재 ID */
  book__id?: InputMaybe<Scalars['ID']>;
  /** 소명 요청 일시 */
  explanationRequestAt?: InputMaybe<Scalars['DateTime']>;
  /** 소명 요청 내용 */
  explanationRequestContent?: InputMaybe<Scalars['String']>;
  /** 소명 상태 */
  explanationState?: InputMaybe<ExplanationStateEnum>;
  /** 이슈 처리 일시 */
  processedAt?: InputMaybe<Scalars['DateTime']>;
  /** 이슈 처리 내용 */
  processedContent?: InputMaybe<Scalars['String']>;
  /** 관리자가 입력한 메모 */
  processorMemo?: InputMaybe<Scalars['String']>;
  /** 신고일시 */
  reportDate?: InputMaybe<Scalars['DateTime']>;
  /** 신고 사유 */
  reportReason?: InputMaybe<SanctionReasonEnum>;
  /** 사용자가 입력한 메모 */
  reporterMemo?: InputMaybe<Scalars['String']>;
  /** 신고인 ID */
  reporter__id?: InputMaybe<Scalars['ID']>;
  /** 제재항목 */
  sanction?: InputMaybe<ReportSanction>;
  /** 상태 */
  state?: InputMaybe<ReportStateEnum>;
  /** 수업 ID */
  subLesson__id?: InputMaybe<Scalars['ID']>;
  /** 피신고인 ID */
  suspect__id?: InputMaybe<Scalars['ID']>;
  /** 신고 타입 */
  type?: InputMaybe<ReportTypeEnum>;
};

/** 요청중인 건수 */
export type RequestTotal = {
  __typename?: 'RequestTotal';
  /** 언어 승인 요청중인 건 수 */
  languageApproval?: Maybe<Scalars['Float']>;
  /** 언어 보완 요청중인 건 수 */
  languagesupplementation?: Maybe<Scalars['Float']>;
  /** 학습 승인 요청중인 건 수 */
  learningApproval?: Maybe<Scalars['Float']>;
  /** 학습 보완 요청중인 건 수 */
  learningsupplementation?: Maybe<Scalars['Float']>;
};

export type SalesLedger = {
  __typename?: 'SalesLedger';
  /** 현금 결제액 */
  cash: Scalars['Float'];
  /** 신용카드 결제액 */
  credit: Scalars['Float'];
  /** 선불전자 결제액 */
  electronic: Scalars['Float'];
  /** 기타 결제액 */
  etc: Scalars['Float'];
  /** 휴대폰 결제액 */
  mobile: Scalars['Float'];
  /** 총 매출액 */
  revenue: Scalars['Float'];
  /** 연월 */
  yearMonth: Scalars['String'];
};

/** 패널티 생성 */
export type SanctionCreateInput = {
  /** 관리자가 입력한 메모 */
  processorMemo?: InputMaybe<Scalars['String']>;
  /** 제재 항목 */
  reportReason?: InputMaybe<SanctionReasonEnum>;
  /** 제재 항목 */
  sanction?: InputMaybe<ReportSanction>;
  /** 회원 ids */
  suspect__ids: Array<Scalars['ID']>;
};

/** 제제 항목 */
export type SanctionEnumFilter = {
  operator: EnumFilterOperators;
  value?: InputMaybe<ReportSanction>;
  values?: InputMaybe<Array<ReportSanction>>;
};

export type ScheduledSubLesson = {
  __typename?: 'ScheduledSubLesson';
  /** 수업 날짜 */
  lessonDate?: Maybe<Scalars['DateTime']>;
  /** 수업명 */
  lessonName?: Maybe<Scalars['String']>;
  /** 수업 시간(분) */
  lessonTime?: Maybe<Scalars['Float']>;
  /** 이전 수업 숙제 상태 */
  previousHomeworkState?: Maybe<HomeworkState>;
  /** 선생님명 */
  teacherName?: Maybe<Scalars['String']>;
};

export type ScheduledSubLessonEdge = {
  __typename?: 'ScheduledSubLessonEdge';
  /** 커서 */
  cursor: Scalars['String'];
  /** 노드 */
  node: ScheduledSubLesson;
};

/** 예정 수업 필터 */
export type ScheduledSubLessonFilterInput = {
  /** 수업 예정일 */
  lessonDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 수업 종료 예정일 */
  lessonEndDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 이전 수업 숙제 상태 */
  previous__homeworkState?: InputMaybe<Array<HomeWorkStateEnumFilter>>;
};

export type ScheduledSubLessonList = {
  __typename?: 'ScheduledSubLessonList';
  /** 페이지네이션된 데이터 목록 */
  edges: Array<Maybe<ScheduledSubLessonEdge>>;
  /** 페이지네이션된 페이지 정보 */
  pageInfo: PageInfo;
  /** 전체 데이터 개 수 */
  totalCount: Scalars['Int'];
};

/** 제휴 설정 */
export type SetPartnership = {
  /** 제휴회원체크 */
  partnershipCheck?: InputMaybe<Scalars['Boolean']>;
};

export type SolVookFilterInput = {
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** UUID */
  id?: InputMaybe<Array<IdFilterInput>>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Array<DateTimeFilterInput>>;
};

export type SolVookList = {
  __typename?: 'SolVookList';
  /** 페이지네이션된 데이터 목록 */
  edges: Array<Maybe<SolVookModelEdge>>;
  /** 페이지네이션된 페이지 정보 */
  pageInfo: PageInfo;
  /** 전체 데이터 개 수 */
  totalCount: Scalars['Int'];
};

export type SolVookModel = {
  __typename?: 'SolVookModel';
  book?: Maybe<Book>;
  /** 생성 날짜/시간 */
  createdAt: Scalars['DateTime'];
  /** 삭제 날짜/시간 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** 기본 키(UUID) */
  id: Scalars['ID'];
  isGrantedByAdmin: Scalars['Boolean'];
  type: SolvookTypeEnumType;
  /** 수정 날짜/시간 */
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
};

export type SolVookModelEdge = {
  __typename?: 'SolVookModelEdge';
  /** 커서 */
  cursor: Scalars['String'];
  /** 노드 */
  node: SolVookModel;
};

export type SolVookSortInput = {
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<SortInput>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<SortInput>;
  /** UUID */
  id?: InputMaybe<SortInput>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<SortInput>;
};

export enum SolvookTypeEnumType {
  /** BOOK_DELETED_BY_STAFF */
  BookDeletedByStaff = 'BOOK_DELETED_BY_STAFF',
  /** BOOK_REGISTERED_BY_STAFF */
  BookRegisteredByStaff = 'BOOK_REGISTERED_BY_STAFF',
  /** BOOK_REGISTRATION_APPROVED */
  BookRegistrationApproved = 'BOOK_REGISTRATION_APPROVED',
  /** EBOOK_DEACTIVATED */
  EbookDeactivated = 'EBOOK_DEACTIVATED',
  /** EBOOK_PURCHASED */
  EbookPurchased = 'EBOOK_PURCHASED',
  /** MATCH_DEACTIVATED */
  MatchDeactivated = 'MATCH_DEACTIVATED'
}

/** 정렬 */
export type SortInput = {
  nulls?: InputMaybe<Nulls>;
  order: Order;
};

/** 문자열(String) 필터 */
export type StringFilterInput = {
  operator: StringFilterOperators;
  value?: InputMaybe<Scalars['String']>;
  values?: InputMaybe<Array<Scalars['ID']>>;
};

/** 문자열 필터 연산자 */
export enum StringFilterOperators {
  Equal = 'EQUAL',
  Ilike = 'ILIKE',
  In = 'IN',
  IsNotNull = 'IS_NOT_NULL',
  IsNull = 'IS_NULL',
  Like = 'LIKE',
  NotEqual = 'NOT_EQUAL',
  NotIlike = 'NOT_ILIKE',
  NotIn = 'NOT_IN',
  NotLike = 'NOT_LIKE'
}

/** 개별 수업 */
export type SubLesson = {
  __typename?: 'SubLesson';
  /** 수업 태도 리뷰 작성 시간 */
  attitudeReviewWriteDate?: Maybe<Scalars['DateTime']>;
  /** 주문 */
  business?: Maybe<Business>;
  /** 수업변경이력 */
  changeSubLessons?: Maybe<Array<ChangeSubLesson>>;
  /** 생성 날짜/시간 */
  createdAt: Scalars['DateTime'];
  /** 삭제 날짜/시간 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** 할인금액 */
  discountPrice?: Maybe<Scalars['Float']>;
  /** 할인율 */
  discountRate?: Maybe<Scalars['Float']>;
  /** 평점 */
  gpa?: Maybe<Scalars['Float']>;
  /** 평점내용 */
  gpaContent?: Maybe<Scalars['String']>;
  /** 숙제 */
  homework?: Maybe<Scalars['String']>;
  /** 숙제진행도 */
  homeworkProgress?: Maybe<Scalars['Int']>;
  /** 숙제상태 */
  homeworkState?: Maybe<HomeworkState>;
  /** 기본 키(UUID) */
  id: Scalars['ID'];
  /** 수업 태도 리뷰 노출 여부 */
  isUserGPAVisible?: Maybe<Scalars['Boolean']>;
  /** 리뷰 노출 여부 */
  isgpaVisible?: Maybe<Scalars['Boolean']>;
  /** 주 수업 */
  lesson?: Maybe<Lesson>;
  /** 수업 종료 시간 */
  lessonCloseAt?: Maybe<Scalars['DateTime']>;
  /** 수업횟수 */
  lessonCount?: Maybe<Scalars['Float']>;
  /** 수업 날짜 */
  lessonDate?: Maybe<Scalars['DateTime']>;
  /** 수업이 종료된 날짜 */
  lessonEndAt?: Maybe<Scalars['DateTime']>;
  /** 수업 종료 예상 날짜 */
  lessonEndDate?: Maybe<Scalars['DateTime']>;
  /** 수업 회차 */
  lessonRound?: Maybe<Scalars['Float']>;
  /** 수업 시간 */
  lessonTime?: Maybe<Scalars['Float']>;
  /** 수업 접속 로그 */
  logs?: Maybe<Array<SubLessonLog>>;
  /** 다음 수업 */
  nextSubLesson?: Maybe<SubLesson>;
  /** 1회금액 */
  onePrice?: Maybe<Scalars['Float']>;
  /** 수업 신고 */
  reported?: Maybe<Report>;
  /** 상태 */
  reviewState?: Maybe<SubLessonReviewStateType>;
  /** 리뷰 작성 시간 */
  reviewWriteDate?: Maybe<Scalars['DateTime']>;
  /** 상태 */
  state?: Maybe<SubLessonStateType>;
  /** 학생 참석 시간 */
  studentJoinAt?: Maybe<Scalars['DateTime']>;
  /** 학생 종료 시간 */
  studentLeaveAt?: Maybe<Scalars['DateTime']>;
  /** 선생님의 수업 내용 */
  teacherContent?: Maybe<Scalars['String']>;
  /** 선생님의 수업 피드백 */
  teacherFeedback?: Maybe<Scalars['String']>;
  /** 선생 참석 시간 */
  teacherJoinAt?: Maybe<Scalars['DateTime']>;
  /** 선생 종료 시간 */
  teacherLeaveAt?: Maybe<Scalars['DateTime']>;
  /** 총금액  */
  totalPrice?: Maybe<Scalars['Float']>;
  /**
   * 수업 타입(정규, 시범)
   * @deprecated lesson으로 이동
   */
  type?: Maybe<Scalars['String']>;
  /** 수정 날짜/시간 */
  updatedAt: Scalars['DateTime'];
  /** pagecall에 업로드된 교재 */
  uploadedBooks?: Maybe<Array<Book>>;
  /** 학생 정보 */
  user?: Maybe<User>;
  /** 수업 태도 평점 */
  usergpa?: Maybe<Scalars['Float']>;
  /** 수업 태도 평점 내용 */
  usergpaContent?: Maybe<Scalars['String']>;
};

export enum SubLessonAttendantType {
  /** 학생 */
  Student = 'student',
  /** 선생님 */
  Teacher = 'teacher'
}

/** 개별 수업 생성 */
export type SubLessonCreateInput = {
  /** 수업 태도 리뷰 작성 시간 */
  attitudeReviewWriteDate?: InputMaybe<Scalars['DateTime']>;
  /** 주문 id */
  business__id?: InputMaybe<Scalars['ID']>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  /** 할인금액 */
  discountPrice?: InputMaybe<Scalars['Float']>;
  /** 할인율 */
  discountRate?: InputMaybe<Scalars['Float']>;
  /** 평점 */
  gpa?: InputMaybe<Scalars['Float']>;
  /** 평점내용 */
  gpaContent?: InputMaybe<Scalars['String']>;
  /** 숙제 */
  homework?: InputMaybe<Scalars['String']>;
  /** 숙제진행도 */
  homeworkProgress?: InputMaybe<Scalars['Int']>;
  /** 숙제상태 */
  homeworkState?: InputMaybe<HomeworkState>;
  /** 기본 키(UUID) */
  id?: InputMaybe<Scalars['ID']>;
  /** 수업 태도 리뷰 노출 여부 */
  isUserGPAVisible?: InputMaybe<Scalars['Boolean']>;
  /** 리뷰 노출 여부 */
  isgpaVisible?: InputMaybe<Scalars['Boolean']>;
  /** 수업 종료 시간 */
  lessonCloseAt?: InputMaybe<Scalars['DateTime']>;
  /** 수업횟수 */
  lessonCount?: InputMaybe<Scalars['Float']>;
  /** 수업 날짜 */
  lessonDate?: InputMaybe<Scalars['DateTime']>;
  /** 수업이 종료된 날짜 */
  lessonEndAt?: InputMaybe<Scalars['DateTime']>;
  /** 수업 종료 예상 날짜 */
  lessonEndDate?: InputMaybe<Scalars['DateTime']>;
  /** 수업 회차 */
  lessonRound?: InputMaybe<Scalars['Float']>;
  /** 수업 시간 */
  lessonTime?: InputMaybe<Scalars['Float']>;
  /** 수업 id */
  lesson__id?: InputMaybe<Scalars['ID']>;
  /** 1회금액 */
  onePrice?: InputMaybe<Scalars['Float']>;
  /** 상태 */
  reviewState?: InputMaybe<SubLessonReviewStateType>;
  /** 리뷰 작성 시간 */
  reviewWriteDate?: InputMaybe<Scalars['DateTime']>;
  /** 상태 */
  state?: InputMaybe<SubLessonStateType>;
  /** 학생 참석 시간 */
  studentJoinAt?: InputMaybe<Scalars['DateTime']>;
  /** 학생 종료 시간 */
  studentLeaveAt?: InputMaybe<Scalars['DateTime']>;
  /** 선생님의 수업 내용 */
  teacherContent?: InputMaybe<Scalars['String']>;
  /** 선생님의 수업 피드백 */
  teacherFeedback?: InputMaybe<Scalars['String']>;
  /** 선생 참석 시간 */
  teacherJoinAt?: InputMaybe<Scalars['DateTime']>;
  /** 선생 종료 시간 */
  teacherLeaveAt?: InputMaybe<Scalars['DateTime']>;
  /** 총금액  */
  totalPrice?: InputMaybe<Scalars['Float']>;
  /** 수업 타입(정규, 시범) */
  type?: InputMaybe<Scalars['String']>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** 수업 태도 평점 */
  usergpa?: InputMaybe<Scalars['Float']>;
  /** 수업 태도 평점 내용 */
  usergpaContent?: InputMaybe<Scalars['String']>;
};

export type SubLessonEdge = {
  __typename?: 'SubLessonEdge';
  /** 커서 */
  cursor: Scalars['String'];
  /** 노드 */
  node: SubLesson;
};

export type SubLessonEndInput = {
  /** 수업 내용 */
  content: Scalars['String'];
  /** 피드백 */
  feedback: Scalars['String'];
  /** 다음 숙제 */
  nextHomework?: InputMaybe<Scalars['String']>;
  /** 이전 숙제 진행도, null이면 숙제 없음 */
  previousHomeworkProgress?: InputMaybe<Scalars['Int']>;
};

/** 개별 수업 필터 */
export type SubLessonFilterInput = {
  /** 수업 태도 리뷰 작성 시간 */
  attitudeReviewWriteDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 평점 */
  gpa?: InputMaybe<Array<FloatFilterInput>>;
  /** 평점 내용 */
  gpaContent?: InputMaybe<Array<StringFilterInput>>;
  /** 숙제 */
  homework?: InputMaybe<Array<StringFilterInput>>;
  /** 숙제 상태 */
  homeworkState?: InputMaybe<Array<HomeWorkStateEnumFilter>>;
  /** UUID */
  id?: InputMaybe<Array<IdFilterInput>>;
  /** 수업 태도 리뷰 노출 여부 */
  isUserGPAVisible?: InputMaybe<Array<BookFilterInput>>;
  /** 리뷰 노출 여부 */
  isgpaVisible?: InputMaybe<Array<BooleanFilterInput>>;
  /** 전체 수업 횟수 */
  lessonCount?: InputMaybe<Array<IntFilterInput>>;
  /** 수업 날짜 */
  lessonDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 수업 종료일 */
  lessonEndDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 1회당 수업 시간 */
  lessonTime?: InputMaybe<Array<IntFilterInput>>;
  /** 수업 아이디 */
  lesson__id?: InputMaybe<Array<IdFilterInput>>;
  /** 수업 이름 */
  lesson__name?: InputMaybe<Array<StringFilterInput>>;
  /** 회당 가격 */
  price?: InputMaybe<Array<IntFilterInput>>;
  /** 신고 사유 */
  reported__reportReason?: InputMaybe<Array<ReportReasonEnumFilter>>;
  /** 신고자 이름 */
  reporter__name?: InputMaybe<Array<StringFilterInput>>;
  /** 리뷰 작성 날짜 */
  reviewWriteDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 상태 */
  state?: InputMaybe<Array<SubLessonStateEnumFilter>>;
  /** 선생님 이름 */
  teacherUser__name?: InputMaybe<Array<StringFilterInput>>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 유저 아이디 */
  user__id?: InputMaybe<Array<IdFilterInput>>;
  /** 구매자명 */
  user__name?: InputMaybe<Array<StringFilterInput>>;
  /** 수업 태도 평점 */
  usergpa?: InputMaybe<Array<FloatFilterInput>>;
  /** 수업 태도 평점 내용 */
  usergpaContent?: InputMaybe<Array<StringFilterInput>>;
};

/** 개별 수업 리스트 */
export type SubLessonList = {
  __typename?: 'SubLessonList';
  /** 페이지네이션된 데이터 목록 */
  edges: Array<Maybe<SubLessonEdge>>;
  /** 페이지네이션된 페이지 정보 */
  pageInfo: PageInfo;
  /** 전체 데이터 개 수 */
  totalCount: Scalars['Int'];
};

/** 수업 로그 */
export type SubLessonLog = {
  __typename?: 'SubLessonLog';
  /** 수업 로그 액션 */
  action?: Maybe<SubLessonLogActionEnum>;
  /** 생성 날짜/시간 */
  createdAt: Scalars['DateTime'];
  /** 삭제 날짜/시간 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** 기본 키(UUID) */
  id: Scalars['ID'];
  /** 수정 날짜/시간 */
  updatedAt: Scalars['DateTime'];
  user: User;
};

export enum SubLessonLogActionEnum {
  /** 학생 접속 */
  StudentJoin = 'STUDENT_JOIN',
  /** 학생 나감 */
  StudentLeave = 'STUDENT_LEAVE',
  /** 선생님 접속 */
  TeacherJoin = 'TEACHER_JOIN',
  /** 선생님 나감 */
  TeacherLeave = 'TEACHER_LEAVE'
}

export type SubLessonLogEdge = {
  __typename?: 'SubLessonLogEdge';
  /** 커서 */
  cursor: Scalars['String'];
  /** 노드 */
  node: SubLessonLog;
};

/** 개별 수업 정렬 */
export type SubLessonOrderByInput = {
  /** 평점순 */
  accumulategpa?: InputMaybe<SortInput>;
  /** 수업 태도 리뷰 작성 시간 */
  attitudeReviewWriteDate?: InputMaybe<SortInput>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<SortInput>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<SortInput>;
  /** 할인금액 */
  discountPrice?: InputMaybe<SortInput>;
  /** 할인율 */
  discountRate?: InputMaybe<SortInput>;
  /** 리뷰내용 */
  gpaContent?: InputMaybe<SortInput>;
  /** UUID */
  id?: InputMaybe<SortInput>;
  /** 수업횟수 */
  lessonCount?: InputMaybe<SortInput>;
  /** 수업날짜순 */
  lessonDate?: InputMaybe<SortInput>;
  /** 수업 종료일 */
  lessonEndDate?: InputMaybe<SortInput>;
  /** 수업명 */
  lesson__lessonName?: InputMaybe<SortInput>;
  /** 1회금액 */
  onePrice?: InputMaybe<SortInput>;
  /** 회당 가격 */
  price?: InputMaybe<SortInput>;
  /** 신고 사유 */
  reported__reportReason?: InputMaybe<SortInput>;
  /** 신고자 이름 */
  reporter__name?: InputMaybe<SortInput>;
  /** 리뷰날짜 */
  reviewWriteDate?: InputMaybe<SortInput>;
  /** 리뷰순 */
  reviewsCount?: InputMaybe<SortInput>;
  /** 선생님명 */
  teacherUser__name?: InputMaybe<SortInput>;
  /** 총금액 */
  totalPrice?: InputMaybe<SortInput>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<SortInput>;
  /** 구매자명 */
  user__name?: InputMaybe<SortInput>;
};

/** 상태 */
export type SubLessonStateEnumFilter = {
  operator: EnumFilterOperators;
  value?: InputMaybe<SubLessonStateType>;
  values?: InputMaybe<Array<SubLessonStateType>>;
};

export enum SubLessonStateType {
  /** 정산됨 */
  CalculateCompleted = 'CALCULATE_COMPLETED',
  /** 정산 예정 */
  CalculateScheduled = 'CALCULATE_SCHEDULED',
  /** 취소 */
  Canceled = 'CANCELED',
  /** 완료 */
  Completion = 'COMPLETION',
  /** 종료 */
  End = 'END',
  /** 진행중 */
  Ongoing = 'ONGOING',
  Refunded = 'REFUNDED',
  /** 일정 변경 요청 */
  RescheduleRequest = 'RESCHEDULE_REQUEST',
  /** 예약됨 (결제 전 주문 상태) */
  Reserved = 'RESERVED',
  /** 예정 */
  Scheduled = 'SCHEDULED',
  /** 진행 중단 */
  Stop = 'STOP',
  /** 미진행 */
  Unprogressed = 'UNPROGRESSED'
}

/** 개별 수업 업데이트 */
export type SubLessonUpdateInput = {
  /** 수업 태도 리뷰 작성 시간 */
  attitudeReviewWriteDate?: InputMaybe<Scalars['DateTime']>;
  /** 주문 id */
  business__id?: InputMaybe<Scalars['ID']>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  /** 할인금액 */
  discountPrice?: InputMaybe<Scalars['Float']>;
  /** 할인율 */
  discountRate?: InputMaybe<Scalars['Float']>;
  /** 평점 */
  gpa?: InputMaybe<Scalars['Float']>;
  /** 평점내용 */
  gpaContent?: InputMaybe<Scalars['String']>;
  /** 숙제 */
  homework?: InputMaybe<Scalars['String']>;
  /** 숙제진행도 */
  homeworkProgress?: InputMaybe<Scalars['Int']>;
  /** 숙제상태 */
  homeworkState?: InputMaybe<HomeworkState>;
  /** 기본 키(UUID) */
  id?: InputMaybe<Scalars['ID']>;
  /** 수업 태도 리뷰 노출 여부 */
  isUserGPAVisible?: InputMaybe<Scalars['Boolean']>;
  /** 리뷰 노출 여부 */
  isgpaVisible?: InputMaybe<Scalars['Boolean']>;
  /** 수업 종료 시간 */
  lessonCloseAt?: InputMaybe<Scalars['DateTime']>;
  /** 수업횟수 */
  lessonCount?: InputMaybe<Scalars['Float']>;
  /** 수업 날짜 */
  lessonDate?: InputMaybe<Scalars['DateTime']>;
  /** 수업이 종료된 날짜 */
  lessonEndAt?: InputMaybe<Scalars['DateTime']>;
  /** 수업 종료 예상 날짜 */
  lessonEndDate?: InputMaybe<Scalars['DateTime']>;
  /** 수업 회차 */
  lessonRound?: InputMaybe<Scalars['Float']>;
  /** 수업 시간 */
  lessonTime?: InputMaybe<Scalars['Float']>;
  /** 수업 id */
  lesson__id?: InputMaybe<Scalars['ID']>;
  /** 1회금액 */
  onePrice?: InputMaybe<Scalars['Float']>;
  /** 상태 */
  reviewState?: InputMaybe<SubLessonReviewStateType>;
  /** 리뷰 작성 시간 */
  reviewWriteDate?: InputMaybe<Scalars['DateTime']>;
  /** 상태 */
  state?: InputMaybe<SubLessonStateType>;
  /** 학생 참석 시간 */
  studentJoinAt?: InputMaybe<Scalars['DateTime']>;
  /** 학생 종료 시간 */
  studentLeaveAt?: InputMaybe<Scalars['DateTime']>;
  /** 선생님의 수업 내용 */
  teacherContent?: InputMaybe<Scalars['String']>;
  /** 선생님의 수업 피드백 */
  teacherFeedback?: InputMaybe<Scalars['String']>;
  /** 선생 참석 시간 */
  teacherJoinAt?: InputMaybe<Scalars['DateTime']>;
  /** 선생 종료 시간 */
  teacherLeaveAt?: InputMaybe<Scalars['DateTime']>;
  /** 총금액  */
  totalPrice?: InputMaybe<Scalars['Float']>;
  /** 수업 타입(정규, 시범) */
  type?: InputMaybe<Scalars['String']>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** 수업 태도 평점 */
  usergpa?: InputMaybe<Scalars['Float']>;
  /** 수업 태도 평점 내용 */
  usergpaContent?: InputMaybe<Scalars['String']>;
};

/** 수업 진행상태 */
export type SubLessonsStateEnumFilter = {
  operator: EnumFilterOperators;
  value?: InputMaybe<SubLessonStateType>;
  values?: InputMaybe<Array<SubLessonStateType>>;
};

export type Subscription = {
  __typename?: 'Subscription';
  /** 채팅 상대방 변경 수신(메시지 읽음 등) */
  receiveChatChannelParticipant: ChatChannelParticipant;
  /** 채팅 메시지 수신 */
  receiveChatMessage: ChatMessage;
};


export type SubscriptionReceiveChatChannelParticipantArgs = {
  channelId: Scalars['String'];
};

/** 선생님 */
export type Teacher = {
  __typename?: 'Teacher';
  /** 인증 기관 */
  CA?: Maybe<Scalars['String']>;
  /** 선생님 출석율 */
  attendanceRate?: Maybe<Scalars['Float']>;
  /** 선생님이 등록한 책 리스트 */
  bookList?: Maybe<Array<Book>>;
  /** 선생님 즐겨찾기 수 */
  bookmarkNumber?: Maybe<Scalars['Float']>;
  /** 북마크한 유저 */
  bookmarkingUsers?: Maybe<Array<User>>;
  /** 사업자등록증 파일 */
  businessRegistrationCertificateFile?: Maybe<File>;
  /** 사업자 등록 번호 */
  businessRegistrationNumber?: Maybe<Scalars['String']>;
  /** 사업자 개업연월일, yyyyMMdd */
  businessStartDate?: Maybe<Scalars['String']>;
  /** 이력 */
  careers?: Maybe<Array<TeacherCareer>>;
  /**
   * 승인 완료일
   * @deprecated 미사용
   */
  completionDate?: Maybe<Scalars['DateTime']>;
  /** 생성 날짜/시간 */
  createdAt: Scalars['DateTime'];
  /**
   * 누적 시범 수업 수
   * @deprecated demo lesson으로 변경
   */
  cumulativeSneakPeekLessonCount?: Maybe<Scalars['Float']>;
  /** 누적 학생 수 */
  cumulativeStudentCount?: Maybe<Scalars['Float']>;
  /** 삭제 날짜/시간 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** 시범수업 */
  demoLesson?: Maybe<Lesson>;
  /** 시범 수업 누적 평점 */
  demoLessonCumulativeGPA?: Maybe<Scalars['Float']>;
  /** 누적 시범 수업 수 */
  demoLessonCumulativeLessonCount?: Maybe<Scalars['Float']>;
  /** 학습 자격 */
  education?: Maybe<Teachereducation>;
  /** 최초 수업일 */
  firstLessonDate?: Maybe<Scalars['DateTime']>;
  /** 구글밋수수료율 */
  googleMeetFeeRate?: Maybe<Scalars['Float']>;
  /** 등급(일반 선생님, 전문 선생님) */
  grade?: Maybe<TeacherGradeEnum>;
  /** 대학원 */
  graduateSchool?: Maybe<Scalars['String']>;
  /** 언어 자격 보유 여부 */
  hasLanguageQualification?: Maybe<Scalars['Boolean']>;
  /** 학습 자격 보유 여부 */
  hasLearningQualification?: Maybe<Scalars['Boolean']>;
  /** 고등학교 */
  highschool?: Maybe<Scalars['String']>;
  /** 기본 키(UUID) */
  id: Scalars['ID'];
  /** 신원 파일 */
  identityFile?: Maybe<File>;
  isBookmarked?: Maybe<Scalars['Boolean']>;
  /** 사업자 여부 */
  isBusinessProprietor?: Maybe<Scalars['Boolean']>;
  /** 신원 인증 여부 */
  isIdentityVerified?: Maybe<Scalars['Boolean']>;
  /** 출금 정지 여부 */
  isWithdrawalSuspended?: Maybe<Scalars['Boolean']>;
  /** 언어 소개 사진 */
  languageIntroductionPhotoFile?: Maybe<File>;
  /** 언어소개글 */
  languageIntroductionText?: Maybe<Scalars['String']>;
  /** 마지막 승인된 언어 소개 영상 */
  languageIntroductionVideoFile?: Maybe<File>;
  /** 선생님 언어/레벨 */
  languageLevels?: Maybe<Array<TeacherLanguageLevel>>;
  /** 언어 자격 */
  languageQualification?: Maybe<Array<TeacherLanguageHistory>>;
  /** 언어 자격 신청 내역 */
  languageQualificationHistory?: Maybe<Array<TeacherLanguageHistory>>;
  /** 가장 마지막에 한 언어 수업 */
  lastLanguageLesson?: Maybe<Lesson>;
  /** 언어 선생님 소개 영상 마지막 신청 로그 */
  lastLanguageTeacherIntroductionVideoLog?: Maybe<TeacherVideoLog>;
  /** 가장 마지막에 한 학습 수업 */
  lastLearningLesson?: Maybe<Lesson>;
  /** 학습 선생님 소개 영상 마지막 신청 로그 */
  lastLearningTeacherIntroductionVideoLog?: Maybe<TeacherVideoLog>;
  /** 언어선생님이 등록한 마지막 수업 */
  lastRegisteredLanguageLesson?: Maybe<Lesson>;
  /** 학습선생님이 등록한 마지막 수업 */
  lastRegisteredLearningLesson?: Maybe<Lesson>;
  /** 선생님 지각율 */
  latenessRate?: Maybe<Scalars['Float']>;
  /** 학습 소개 사진 */
  learningIntroductionPhotoFile?: Maybe<File>;
  /** 학습소개글 */
  learningIntroductionText?: Maybe<Scalars['String']>;
  /** 마지막 승인된 학습 소개 영상 */
  learningIntroductionVideoFile?: Maybe<File>;
  /** 학습 자격 */
  learningQualification?: Maybe<TeacherLearningHistory>;
  /** 학습 자격 신청 내역 */
  learningQualificationHistory?: Maybe<Array<TeacherLearningHistory>>;
  /** 마스터 수업 */
  lessons?: Maybe<Array<Lesson>>;
  /** 소재지 */
  location?: Maybe<Scalars['String']>;
  /** 언어 주 1 */
  mainLanguageA?: Maybe<Scalars['String']>;
  /** 언어 주 1 레벨 */
  mainLanguageALevel?: Maybe<TeacherLanguageLevelEnum>;
  /** 언어 주 2 */
  mainLanguageB?: Maybe<Scalars['String']>;
  /** 언어 주 2 레벨 */
  mainLanguageBLevel?: Maybe<TeacherLanguageLevelEnum>;
  /** 전공 */
  major?: Maybe<Scalars['String']>;
  /** 마스터 수업 수 */
  masterLessonsCount?: Maybe<Scalars['Float']>;
  /** 사용자가 구매한 마지막 수업 */
  memberLastPurchasedLesson?: Maybe<Lesson>;
  /** @deprecated memberLastPurchasedLesson으로 수정 */
  membersLastLesson?: Maybe<Lesson>;
  /** 내 학생 */
  myStudentNumber?: Maybe<Scalars['Float']>;
  /** 출신 국가 */
  originCountry?: Maybe<Scalars['String']>;
  /** 페이지콜수수료율 */
  pageCallFeeRate?: Maybe<Scalars['Float']>;
  /** 기타자격명 */
  qualificationName?: Maybe<Scalars['String']>;
  /** 최근 수업일 */
  recentLessonDate?: Maybe<Scalars['DateTime']>;
  /** 누적 환불수 */
  refundingCount?: Maybe<Scalars['Float']>;
  /** 대표자명 */
  representativeName?: Maybe<Scalars['String']>;
  /**
   * 승인 요청일
   * @deprecated 미사용
   */
  requestDate?: Maybe<Scalars['DateTime']>;
  /** 거주 국가 */
  residenceCountry?: Maybe<Scalars['String']>;
  /** 요청 응답률 */
  responseRate?: Maybe<Scalars['Float']>;
  /** 선생님의 예정 수업 수 */
  scheduledLessonNumber?: Maybe<Scalars['Float']>;
  /** 누적 판매수 */
  sellingCount?: Maybe<Scalars['Float']>;
  /**
   * 시범 수업 누적 평점
   * @deprecated demo lesson으로 변경
   */
  sneakPeekCumulativeGPA?: Maybe<Scalars['Float']>;
  /**
   * 시범 수업 횟수
   * @deprecated lesson으로 이동
   */
  sneakPeekNumber?: Maybe<Scalars['Float']>;
  /**
   * 시범 수업 금액
   * @deprecated lesson으로 이동
   */
  sneakPeekPrice?: Maybe<Scalars['Float']>;
  /**
   * 시범 수업 시간
   * @deprecated lesson으로 이동
   */
  sneakPeekTime?: Maybe<Scalars['Float']>;
  /** 정렬 필드 */
  sortField?: Maybe<TeacherSortView>;
  /** 선생님 등록 신청 상태 */
  state?: Maybe<TeacherRegistrationState>;
  /** 통계 */
  statistics?: Maybe<TeacherStatistics>;
  /** 언어 부 1 */
  subLanguageA?: Maybe<Scalars['String']>;
  /** 언어 부 1 레벨 */
  subLanguageALevel?: Maybe<TeacherLanguageLevelEnum>;
  /** 언어 부 2 */
  subLanguageB?: Maybe<Scalars['String']>;
  /** 언어 부 2 레벨 */
  subLanguageBLevel?: Maybe<TeacherLanguageLevelEnum>;
  /** 학습 과목 */
  subject?: Maybe<Array<TeacherSubjectEnum>>;
  /**
   * 보완 요청일
   * @deprecated 미사용
   */
  supplementationDate?: Maybe<Scalars['DateTime']>;
  /** 선생님 등록 거절, 보완 메시지 */
  teacherMemo?: Maybe<TeacherMemoEnum>;
  /** 학습 대상 */
  teachingTarget?: Maybe<Array<UserType>>;
  /** 최근 30일 환불수 */
  thirtyDaysRefundingCount?: Maybe<Scalars['Float']>;
  /** 최근 30일 판매수 */
  thirtyDaysSellingCount?: Maybe<Scalars['Float']>;
  /** 대학교 */
  university?: Maybe<Scalars['String']>;
  /** 대학교 타입 */
  universityType?: Maybe<Scalars['String']>;
  /** 수정 날짜/시간 */
  updatedAt: Scalars['DateTime'];
  /** 선생님이 수업에서 이용가능한 교재 리스트(선생님이 구매한 책 + 등록한 책) */
  usableBookList?: Maybe<Array<Book>>;
  /** 선생님 유저 정보 */
  user?: Maybe<User>;
};

export type TeacherBookCreateInput = {
  /** answer file id */
  answerFile__ids?: InputMaybe<Array<Scalars['ID']>>;
  /** category id */
  category__id: Scalars['ID'];
  /** content file id */
  contentFile__ids: Array<Scalars['ID']>;
  /** index file id */
  indexFile__id: Scalars['ID'];
  /** book name */
  name: Scalars['String'];
  /** 미리보기 file id */
  previewFile__id?: InputMaybe<Scalars['ID']>;
  /** thumbnail file id */
  thumbnailFile__id: Scalars['ID'];
};

export type TeacherBookUpdateInput = {
  /** answer file id */
  answerFile__ids?: InputMaybe<Array<Scalars['ID']>>;
  /** category id */
  category__id?: InputMaybe<Scalars['ID']>;
  /** content file id */
  contentFile__ids?: InputMaybe<Array<Scalars['ID']>>;
  /** index file id */
  indexFile__id?: InputMaybe<Scalars['ID']>;
  /** book name */
  name?: InputMaybe<Scalars['String']>;
  /** 미리보기 file id */
  previewFile__id?: InputMaybe<Scalars['ID']>;
  /** thumbnail file id */
  thumbnailFile__id?: InputMaybe<Scalars['ID']>;
};

export type TeacherBusinessFilterInput = {
  /** 소득공제 여부 */
  isDeducted?: InputMaybe<Array<BooleanFilterInput>>;
  /** 수업 id */
  lesson__id?: InputMaybe<Array<IdFilterInput>>;
  /** 수업명 */
  lesson__lessonName?: InputMaybe<Array<StringFilterInput>>;
  /** 할인 금액 */
  orderInfo__discountPrice?: InputMaybe<Array<FloatFilterInput>>;
  /** 할인전 금액 */
  orderInfo__totalOriginalPrice?: InputMaybe<Array<FloatFilterInput>>;
  /** 주문 상태 */
  state?: InputMaybe<Array<BusinessStateEnumFilter>>;
  /** 상품 타입 */
  type?: InputMaybe<Array<OrderTypeEnumFilterInput>>;
  /** 유저 email */
  user__email?: InputMaybe<Array<StringFilterInput>>;
  /** 유저 아이디 */
  user__id?: InputMaybe<Array<IdFilterInput>>;
  /** 유저 이름 */
  user__name?: InputMaybe<Array<StringFilterInput>>;
};

export type TeacherCalculate = {
  __typename?: 'TeacherCalculate';
  /** 일간 정산 대상 수업 수 */
  dayCalculateLessonCount?: Maybe<Scalars['Float']>;
  /** 일 정산 금액 */
  dayCalculatePrice?: Maybe<Scalars['Float']>;
  /** D + 1 정산 금액 */
  dayPlusOneCalculatePrice?: Maybe<Scalars['Float']>;
  /** D + 2 정산 금액 */
  dayPlusTwoCalculatePrice?: Maybe<Scalars['Float']>;
  /** 월간 정산 대상 수업 수 */
  monthCalculateLessonCount?: Maybe<Scalars['Float']>;
  /** 월간 누적 정산 금액 */
  monthCalculatePrice?: Maybe<Scalars['Float']>;
  /** 월간 누적 수수료 */
  monthCommission?: Maybe<Scalars['Float']>;
  /** 선생님 id */
  teacher_id?: Maybe<Scalars['String']>;
  /** 주간 정산 대상 수업 수 */
  weekCalculateLessonCount?: Maybe<Scalars['Float']>;
  /** 주간 누적 정산 금액 */
  weekCalculatePrice?: Maybe<Scalars['Float']>;
  /** 주간 누적 수수료 */
  weekCommission?: Maybe<Scalars['Float']>;
};

export type TeacherCareer = {
  __typename?: 'TeacherCareer';
  content: Scalars['String'];
  /** 생성 날짜/시간 */
  createdAt: Scalars['DateTime'];
  /** 삭제 날짜/시간 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** 종료연도 */
  endYear?: Maybe<Scalars['Float']>;
  /** 기본 키(UUID) */
  id: Scalars['ID'];
  /** 소재지 */
  location: Scalars['String'];
  /** 시작연도 */
  startYear?: Maybe<Scalars['Float']>;
  teacher: Teacher;
  title: Scalars['String'];
  /** 수정 날짜/시간 */
  updatedAt: Scalars['DateTime'];
};

export type TeacherCareerEdge = {
  __typename?: 'TeacherCareerEdge';
  /** 커서 */
  cursor: Scalars['String'];
  /** 노드 */
  node: TeacherCareer;
};

export type TeacherCareerFilterInput = {
  content?: InputMaybe<Array<StringFilterInput>>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  endYear?: InputMaybe<Array<IntFilterInput>>;
  /** UUID */
  id?: InputMaybe<Array<IdFilterInput>>;
  location?: InputMaybe<Array<StringFilterInput>>;
  startYear?: InputMaybe<Array<IntFilterInput>>;
  teacher__id?: InputMaybe<Array<IdFilterInput>>;
  title?: InputMaybe<Array<StringFilterInput>>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Array<DateTimeFilterInput>>;
};

export type TeacherCareerList = {
  __typename?: 'TeacherCareerList';
  /** 페이지네이션된 데이터 목록 */
  edges: Array<Maybe<TeacherCareerEdge>>;
  /** 페이지네이션된 페이지 정보 */
  pageInfo: PageInfo;
  /** 전체 데이터 개 수 */
  totalCount: Scalars['Int'];
};

export type TeacherCareerOrderByInput = {
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<SortInput>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<SortInput>;
  endYear?: InputMaybe<SortInput>;
  /** UUID */
  id?: InputMaybe<SortInput>;
  startYear?: InputMaybe<SortInput>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<SortInput>;
};

export type TeacherChangeSubLessonCreateInput = {
  /** 변경 사유 */
  changeReason: ChangeSubLessonChangeReasonEnumType;
  /** 변경할 시간 */
  lessonTimeToChange?: InputMaybe<Scalars['DateTime']>;
  /** 직접 입력 */
  personalChangeReason?: InputMaybe<Scalars['String']>;
  /** sublesson id */
  subLesson__id: Scalars['ID'];
};

export type TeacherChangeSubLessonFilterInput = {
  /** 변경 전 시간 */
  beforeLessonTime?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 변경 사유 */
  changeReason?: InputMaybe<Array<ChangeSubLessonChangeReasonEnumFilter>>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** UUID */
  id?: InputMaybe<Array<IdFilterInput>>;
  /** 변경할 시간 */
  lessonTimeToChange?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 거절 사유 */
  refusalReason?: InputMaybe<Array<ChangeSubLessonRefusalReasonEnumFilter>>;
  /** 상태 */
  state?: InputMaybe<Array<ChangeSubLessonStateEnumFilter>>;
  /** sublesson id */
  subLesson__id?: InputMaybe<Array<IdFilterInput>>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 학생 id */
  user__id?: InputMaybe<Array<IdFilterInput>>;
};

export type TeacherChangeSubLessonUpdateInput = {
  /** 변경 사유 */
  changeReason: ChangeSubLessonChangeReasonEnumType;
  /** 변경할 시간 */
  lessonTimeToChange?: InputMaybe<Scalars['DateTime']>;
  /** 직접 입력 */
  personalChangeReason?: InputMaybe<Scalars['String']>;
};

/** 선생님 생성 */
export type TeacherCreateInput = {
  /** 인증 기관 */
  CA?: InputMaybe<Scalars['String']>;
  /** 사업자 등록 번호 */
  businessRegistrationNumber?: InputMaybe<Scalars['String']>;
  /** 사업자 개업연월일, yyyyMMdd */
  businessStartDate?: InputMaybe<Scalars['String']>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  /** 학습 자격 */
  education?: InputMaybe<Teachereducation>;
  /** 최초 수업일 */
  firstLessonDate?: InputMaybe<Scalars['DateTime']>;
  /** 구글밋수수료율 */
  googleMeetFeeRate?: InputMaybe<Scalars['Float']>;
  /** 등급(일반 선생님, 전문 선생님) */
  grade?: InputMaybe<TeacherGradeEnum>;
  /** 대학원 */
  graduateSchool?: InputMaybe<Scalars['String']>;
  /** 언어 자격 보유 여부 */
  hasLanguageQualification?: InputMaybe<Scalars['Boolean']>;
  /** 학습 자격 보유 여부 */
  hasLearningQualification?: InputMaybe<Scalars['Boolean']>;
  /** 고등학교 */
  highschool?: InputMaybe<Scalars['String']>;
  /** 기본 키(UUID) */
  id?: InputMaybe<Scalars['ID']>;
  /** 신원 인증 파일 id */
  identityFile__id?: InputMaybe<Scalars['ID']>;
  /** 사업자 여부 */
  isBusinessProprietor?: InputMaybe<Scalars['Boolean']>;
  /** 신원 인증 여부 */
  isIdentityVerified?: InputMaybe<Scalars['Boolean']>;
  /** 출금 정지 여부 */
  isWithdrawalSuspended?: InputMaybe<Scalars['Boolean']>;
  /** 언어소개 사진 id */
  languageIntroductionPhotoFile__id?: InputMaybe<Scalars['ID']>;
  /** 언어소개글 */
  languageIntroductionText?: InputMaybe<Scalars['String']>;
  /** 언어 소개 동영상 id */
  languageIntroductionVideoFile__id?: InputMaybe<Scalars['ID']>;
  /** 학습소개 사진 id */
  learningIntroductionPhotoFile__id?: InputMaybe<Scalars['ID']>;
  /** 학습소개글 */
  learningIntroductionText?: InputMaybe<Scalars['String']>;
  /** 학습 소개 동영상 id */
  learningIntroductionVideoFile__id?: InputMaybe<Scalars['ID']>;
  /** 소재지 */
  location?: InputMaybe<Scalars['String']>;
  /** 언어 주 1 */
  mainLanguageA?: InputMaybe<Scalars['String']>;
  /** 언어 주 1 레벨 */
  mainLanguageALevel?: InputMaybe<TeacherLanguageLevelEnum>;
  /** 언어 주 2 */
  mainLanguageB?: InputMaybe<Scalars['String']>;
  /** 언어 주 2 레벨 */
  mainLanguageBLevel?: InputMaybe<TeacherLanguageLevelEnum>;
  /** 전공 */
  major?: InputMaybe<Scalars['String']>;
  /** 출신 국가 */
  originCountry?: InputMaybe<Scalars['String']>;
  /** 페이지콜수수료율 */
  pageCallFeeRate?: InputMaybe<Scalars['Float']>;
  /** 기타자격명 */
  qualificationName?: InputMaybe<Scalars['String']>;
  /** 대표자명 */
  representativeName?: InputMaybe<Scalars['String']>;
  /** 거주 국가 */
  residenceCountry?: InputMaybe<Scalars['String']>;
  /** 요청 응답률 */
  responseRate?: InputMaybe<Scalars['Float']>;
  /** 시범 수업 횟수 */
  sneakPeekNumber?: InputMaybe<Scalars['Float']>;
  /** 시범 수업 금액 */
  sneakPeekPrice?: InputMaybe<Scalars['Float']>;
  /** 시범 수업 시간 */
  sneakPeekTime?: InputMaybe<Scalars['Float']>;
  /** 선생님 등록 신청 상태 */
  state?: InputMaybe<TeacherRegistrationState>;
  /** 언어 부 1 */
  subLanguageA?: InputMaybe<Scalars['String']>;
  /** 언어 부 1 레벨 */
  subLanguageALevel?: InputMaybe<TeacherLanguageLevelEnum>;
  /** 언어 부 2 */
  subLanguageB?: InputMaybe<Scalars['String']>;
  /** 언어 부 2 레벨 */
  subLanguageBLevel?: InputMaybe<TeacherLanguageLevelEnum>;
  /** 학습 과목 */
  subject?: InputMaybe<Array<TeacherSubjectEnum>>;
  /** 선생님 등록 거절, 보완 메시지 */
  teacherMemo?: InputMaybe<TeacherMemoEnum>;
  /** 학습 대상 */
  teachingTarget?: InputMaybe<Array<UserType>>;
  /** 대학교 */
  university?: InputMaybe<Scalars['String']>;
  /** 대학교 타입 */
  universityType?: InputMaybe<Scalars['String']>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** 선생님 ID */
  user__id?: InputMaybe<Scalars['ID']>;
};

export type TeacherEdge = {
  __typename?: 'TeacherEdge';
  /** 커서 */
  cursor: Scalars['String'];
  /** 노드 */
  node: Teacher;
};

/** 선생님 학습 자격 */
export type TeacherEducationEnumFilter = {
  operator: EnumFilterOperators;
  value?: InputMaybe<Teachereducation>;
  values?: InputMaybe<Array<Teachereducation>>;
};

/** 수수료율 변경 */
export type TeacherFeeUpdateInput = {
  /** 구글밋수수료율 */
  googleMeetFeeRate?: InputMaybe<Scalars['Float']>;
  /** 페이지콜수수료율 */
  pageCallFeeRate?: InputMaybe<Scalars['Float']>;
};

/** 선생님 필터 */
export type TeacherFilterInput = {
  /** 사업자 등록 번호 */
  businessRegistrationNumber?: InputMaybe<Array<StringFilterInput>>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 학습 자격 */
  education?: InputMaybe<Array<TeacherEducationEnumFilter>>;
  /** 최초 수업일 */
  firstLessonDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 구글밋 수수료율 */
  googleMeetFeeRate?: InputMaybe<Array<IntFilterInput>>;
  /** 등급(일반 선생님, 전문 선생님) */
  grade?: InputMaybe<Array<TeacherGradeEnumFilter>>;
  /** 언어 자격 보유 여부 */
  hasLanguageQualification?: InputMaybe<Array<BooleanFilterInput>>;
  /** 학습 자격 보유 여부 */
  hasLearningQualification?: InputMaybe<Array<BooleanFilterInput>>;
  /** UUID */
  id?: InputMaybe<Array<IdFilterInput>>;
  /** 소개글 */
  introductionText?: InputMaybe<Array<StringFilterInput>>;
  /** 사업자 여부 */
  isBusinessProprietor?: InputMaybe<Array<BooleanFilterInput>>;
  /** 신원 인증 여부 */
  isIdentityVerified?: InputMaybe<Array<BooleanFilterInput>>;
  /** 출금 정지 여부 */
  isWithdrawalSuspended?: InputMaybe<Array<BooleanFilterInput>>;
  /** 언어처리관리자 이메일 */
  languageProcessorEmail?: InputMaybe<Array<StringFilterInput>>;
  /** 언어자격 아이디 */
  languageQualificationHistory__id?: InputMaybe<Array<IdFilterInput>>;
  /** 언어 자격 */
  languageQualificationHistory__qualification?: InputMaybe<Array<StringFilterInput>>;
  /** 언어자격 상태 */
  languageQualificationHistory__state?: InputMaybe<Array<StringFilterInput>>;
  /** 학습처리관리자 이메일 */
  learningProcessorEmail?: InputMaybe<Array<StringFilterInput>>;
  /** 학습자격 아이디 */
  learningQualificationHistory__id?: InputMaybe<Array<IdFilterInput>>;
  /** 학습 자격 */
  learningQualificationHistory__requestQualification?: InputMaybe<Array<StringFilterInput>>;
  /** 학습자격상태 */
  learningQualificationHistory__state?: InputMaybe<Array<StringFilterInput>>;
  /** 소재지 */
  location?: InputMaybe<Array<StringFilterInput>>;
  /** 언어 주 1 */
  mainLanguageA?: InputMaybe<Array<StringFilterInput>>;
  /** 언어 주 1 레벨 */
  mainLanguageALevel?: InputMaybe<Array<TeacherLanguageLevelEnumFilter>>;
  /** 언어 주 2 */
  mainLanguageB?: InputMaybe<Array<StringFilterInput>>;
  /** 언어 주 2 레벨 */
  mainLanguageBLevel?: InputMaybe<Array<TeacherLanguageLevelEnumFilter>>;
  /** 전공 */
  major?: InputMaybe<Array<StringFilterInput>>;
  /** 출신 국가 */
  originCountry?: InputMaybe<Array<StringFilterInput>>;
  /** 페이지콜 수수료율 */
  pageCallFeeRate?: InputMaybe<Array<IntFilterInput>>;
  /** 대표자명 */
  representativeName?: InputMaybe<Array<StringFilterInput>>;
  /** 거주 국가 */
  residenceCountry?: InputMaybe<Array<StringFilterInput>>;
  /** 시범 수업 횟수 */
  sneakPeekNumber?: InputMaybe<Array<IntFilterInput>>;
  /** 시범 수업 금액 */
  sneakPeekPrice?: InputMaybe<Array<IntFilterInput>>;
  /** 시범 수업 시간 */
  sneakPeekTime?: InputMaybe<Array<IntFilterInput>>;
  /** 선생님 등록, 신청 상태 */
  state?: InputMaybe<Array<TeacherStateEnumFilter>>;
  /** 학생 생년월일 */
  student__birthDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 학생 이메일 */
  student__email?: InputMaybe<Array<StringFilterInput>>;
  /** 학생 이름 */
  student__name?: InputMaybe<Array<StringFilterInput>>;
  /** 학생 전화번호 */
  student__phone?: InputMaybe<Array<StringFilterInput>>;
  /** 학생 유형 */
  student__type?: InputMaybe<Array<UserTypeEnumFilter>>;
  /** 언어 부 1 */
  subLanguageA?: InputMaybe<Array<StringFilterInput>>;
  /** 언어 부 1 레벨 */
  subLanguageALevel?: InputMaybe<Array<TeacherLanguageLevelEnumFilter>>;
  /** 언어 부 2 */
  subLanguageB?: InputMaybe<Array<StringFilterInput>>;
  /** 언어 부 2 레벨 */
  subLanguageBLevel?: InputMaybe<Array<TeacherLanguageLevelEnumFilter>>;
  /** 학습 과목 */
  subject?: InputMaybe<Array<TeacherSubjectArrayFilter>>;
  /** 선생님 등록 거절, 보완 메시지 */
  teacherMemo?: InputMaybe<Array<TeacherMemoEnumFilter>>;
  /** 학습 대상 */
  teachingTarget?: InputMaybe<Array<TeacherTeachingTargetArrayFilter>>;
  /** 대학교 */
  university?: InputMaybe<Array<StringFilterInput>>;
  /** 대학교 타입 */
  universityType?: InputMaybe<Array<StringFilterInput>>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 생년월일 */
  user__birthDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 이메일 */
  user__email?: InputMaybe<Array<StringFilterInput>>;
  /** 최근 로그인 */
  user__lastLogin?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 이름 */
  user__name?: InputMaybe<Array<StringFilterInput>>;
  /** 전화번호 */
  user__phone?: InputMaybe<Array<StringFilterInput>>;
};

export enum TeacherGradeEnum {
  /** 일반 선생님 */
  CommonTeacher = 'COMMON_TEACHER',
  /** 전문 선생님 */
  ProfessionalTeacher = 'PROFESSIONAL_TEACHER'
}

/** 선생님 자격 */
export type TeacherGradeEnumFilter = {
  operator: EnumFilterOperators;
  value?: InputMaybe<TeacherGradeEnum>;
  values?: InputMaybe<Array<TeacherGradeEnum>>;
};

export type TeacherLanguageHistory = {
  __typename?: 'TeacherLanguageHistory';
  /** 인증 기관 */
  CA?: Maybe<Scalars['String']>;
  /** 첨부파일 */
  attachmentFile?: Maybe<File>;
  /** 승인일 */
  completionDate?: Maybe<Scalars['DateTime']>;
  /** 생성 날짜/시간 */
  createdAt: Scalars['DateTime'];
  /** 삭제 날짜/시간 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** 기본 키(UUID) */
  id: Scalars['ID'];
  /** 언어 */
  language?: Maybe<Scalars['String']>;
  /** 승인 상태 */
  lastState?: Maybe<TeacherRegistrationState>;
  /** 신청 레벨 */
  level?: Maybe<TeacherLanguageLevelEnum>;
  /** 소재지 */
  location?: Maybe<Scalars['String']>;
  /** 전공 */
  major?: Maybe<Scalars['String']>;
  /** 메모 */
  memo?: Maybe<Scalars['String']>;
  /** 출신 국가 */
  originCountry?: Maybe<Scalars['String']>;
  /** 처리자 */
  processor?: Maybe<User>;
  /** 자격명 */
  qualificationName?: Maybe<Scalars['String']>;
  /** 거절일 */
  refusalDate?: Maybe<Scalars['DateTime']>;
  /** 거절/보완요청 사유 */
  refusalReason?: Maybe<Scalars['String']>;
  /** 승인요청일 */
  requestDate?: Maybe<Scalars['DateTime']>;
  /** 거주 국가 */
  residenceCountry?: Maybe<Scalars['String']>;
  /** 인증선택 */
  selectAuthentication?: Maybe<Scalars['String']>;
  /** 승인 상태 */
  state?: Maybe<TeacherRegistrationState>;
  /** 보완요청일 */
  supplementationDate?: Maybe<Scalars['DateTime']>;
  /** 선생님 */
  teacher?: Maybe<Teacher>;
  /** 대학교 */
  university?: Maybe<Scalars['String']>;
  /** 대학교 타입 */
  universityType?: Maybe<Scalars['String']>;
  /** 수정 날짜/시간 */
  updatedAt: Scalars['DateTime'];
};

/** 선생님 언어 신청 생성 */
export type TeacherLanguageHistoryCreateInput = {
  /** 인증 기관 */
  CA?: InputMaybe<Scalars['String']>;
  /** 첨부파일 */
  attachmentFile__id?: InputMaybe<Scalars['ID']>;
  /** 승인일 */
  completionDate?: InputMaybe<Scalars['DateTime']>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  /** 기본 키(UUID) */
  id?: InputMaybe<Scalars['ID']>;
  /** 언어 */
  language?: InputMaybe<Scalars['String']>;
  /** 신청 레벨 */
  level?: InputMaybe<TeacherLanguageLevelEnum>;
  /** 소재지 */
  location?: InputMaybe<Scalars['String']>;
  /** 전공 */
  major?: InputMaybe<Scalars['String']>;
  /** 메모 */
  memo?: InputMaybe<Scalars['String']>;
  /** 출신 국가 */
  originCountry?: InputMaybe<Scalars['String']>;
  /** 자격명 */
  qualificationName?: InputMaybe<Scalars['String']>;
  /** 거절일 */
  refusalDate?: InputMaybe<Scalars['DateTime']>;
  /** 거절/보완요청 사유 */
  refusalReason?: InputMaybe<Scalars['String']>;
  /** 승인요청일 */
  requestDate?: InputMaybe<Scalars['DateTime']>;
  /** 거주 국가 */
  residenceCountry?: InputMaybe<Scalars['String']>;
  /** 인증선택 */
  selectAuthentication?: InputMaybe<Scalars['String']>;
  /** 승인 상태 */
  state?: InputMaybe<TeacherRegistrationState>;
  /** 보완요청일 */
  supplementationDate?: InputMaybe<Scalars['DateTime']>;
  /** 선생님 id */
  teacher__id?: InputMaybe<Scalars['ID']>;
  /** 대학교 */
  university?: InputMaybe<Scalars['String']>;
  /** 대학교 타입 */
  universityType?: InputMaybe<Scalars['String']>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TeacherLanguageHistoryEdge = {
  __typename?: 'TeacherLanguageHistoryEdge';
  /** 커서 */
  cursor: Scalars['String'];
  /** 노드 */
  node: TeacherLanguageHistory;
};

/** 선생님 언어 신청 필터 */
export type TeacherLanguageHistoryFilterInput = {
  /** 인증 기관 */
  CA?: InputMaybe<Array<StringFilterInput>>;
  /** 승인일 */
  completionDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** UUID */
  id?: InputMaybe<Array<IdFilterInput>>;
  /** 언어 */
  language?: InputMaybe<Array<StringFilterInput>>;
  /** 신청 레벨 */
  level?: InputMaybe<Array<TeacherLanguageLevelEnumFilter>>;
  /** 소재지 */
  location?: InputMaybe<Array<StringFilterInput>>;
  /** 메모 */
  memo?: InputMaybe<Array<StringFilterInput>>;
  /** 관리자 이메일 */
  processor__email?: InputMaybe<Array<StringFilterInput>>;
  /** 검증 요청 자격 */
  qualification?: InputMaybe<Array<StringFilterInput>>;
  /** 거절일 */
  refusalDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 거절/보완요청 사유 */
  refusalReason?: InputMaybe<Array<StringFilterInput>>;
  /** 승인요청일 */
  requestDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 승인 상태 */
  state?: InputMaybe<Array<TeacherLanguageStateEnumFilter>>;
  /** 보완요청일 */
  supplementationDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 이메일 */
  teacherUser__email?: InputMaybe<Array<StringFilterInput>>;
  /** 선생님명 */
  teacherUser__name?: InputMaybe<Array<StringFilterInput>>;
  /** 전화번호 */
  teacherUser__phone?: InputMaybe<Array<StringFilterInput>>;
  /** 선생님 아이디 */
  teacher__id?: InputMaybe<Array<IdFilterInput>>;
  /** 신원확인 여부 */
  teacher__isIdentityVerified?: InputMaybe<Array<BooleanFilterInput>>;
  /** 전공 */
  teacher__major?: InputMaybe<Array<StringFilterInput>>;
  /** 대학교 */
  university?: InputMaybe<Array<StringFilterInput>>;
  /** 대학교 타입 */
  universityType?: InputMaybe<Array<StringFilterInput>>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Array<DateTimeFilterInput>>;
};

export type TeacherLanguageHistoryList = {
  __typename?: 'TeacherLanguageHistoryList';
  /** 페이지네이션된 데이터 목록 */
  edges: Array<Maybe<TeacherLanguageHistoryEdge>>;
  /** 페이지네이션된 페이지 정보 */
  pageInfo: PageInfo;
  /** 전체 데이터 개 수 */
  totalCount: Scalars['Int'];
};

/** 선생님 언어 신청 정렬 */
export type TeacherLanguageHistoryOrderByInput = {
  /** 인증 기관 */
  CA?: InputMaybe<SortInput>;
  /** 승인일 */
  completionDate?: InputMaybe<SortInput>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<SortInput>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<SortInput>;
  /** UUID */
  id?: InputMaybe<SortInput>;
  /** 언어 */
  language?: InputMaybe<SortInput>;
  /** 신청 레벨 */
  level?: InputMaybe<SortInput>;
  /** 소재지 */
  location?: InputMaybe<SortInput>;
  /** 메모 */
  memo?: InputMaybe<SortInput>;
  /** 처리일 */
  processedAt?: InputMaybe<SortInput>;
  /** 처리 관리자 이름 */
  processor__name?: InputMaybe<SortInput>;
  /** 검증 요청 자격 */
  qualification?: InputMaybe<SortInput>;
  /** 거절일 */
  refusalDate?: InputMaybe<SortInput>;
  /** 거절/보완요청 사유 */
  refusalReason?: InputMaybe<SortInput>;
  /** 요청일 */
  requestAt?: InputMaybe<SortInput>;
  /** 승인요청일 */
  requestDate?: InputMaybe<SortInput>;
  /** 승인 상태 */
  state?: InputMaybe<SortInput>;
  /** 보완요청일 */
  supplementationDate?: InputMaybe<SortInput>;
  /** 이메일 */
  teacherUser__email?: InputMaybe<SortInput>;
  /** 이름 */
  teacherUser__name?: InputMaybe<SortInput>;
  /** 전화번호 */
  teacherUser__phone?: InputMaybe<SortInput>;
  /** 대학교 */
  teacher__university?: InputMaybe<SortInput>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<SortInput>;
};

/** 선생님 언어 신청 업데이트 */
export type TeacherLanguageHistoryUpdateInput = {
  /** 인증 기관 */
  CA?: InputMaybe<Scalars['String']>;
  /** 첨부파일 */
  attachmentFile__id?: InputMaybe<Scalars['ID']>;
  /** 승인일 */
  completionDate?: InputMaybe<Scalars['DateTime']>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  /** 기본 키(UUID) */
  id?: InputMaybe<Scalars['ID']>;
  /** 언어 */
  language?: InputMaybe<Scalars['String']>;
  /** 신청 레벨 */
  level?: InputMaybe<TeacherLanguageLevelEnum>;
  /** 소재지 */
  location?: InputMaybe<Scalars['String']>;
  /** 전공 */
  major?: InputMaybe<Scalars['String']>;
  /** 메모 */
  memo?: InputMaybe<Scalars['String']>;
  /** 출신 국가 */
  originCountry?: InputMaybe<Scalars['String']>;
  /** 자격명 */
  qualificationName?: InputMaybe<Scalars['String']>;
  /** 거절일 */
  refusalDate?: InputMaybe<Scalars['DateTime']>;
  /** 거절/보완요청 사유 */
  refusalReason?: InputMaybe<Scalars['String']>;
  /** 승인요청일 */
  requestDate?: InputMaybe<Scalars['DateTime']>;
  /** 거주 국가 */
  residenceCountry?: InputMaybe<Scalars['String']>;
  /** 인증선택 */
  selectAuthentication?: InputMaybe<Scalars['String']>;
  /** 승인 상태 */
  state?: InputMaybe<TeacherRegistrationState>;
  /** 보완요청일 */
  supplementationDate?: InputMaybe<Scalars['DateTime']>;
  /** 선생님 id */
  teacher__id?: InputMaybe<Scalars['ID']>;
  /** 대학교 */
  university?: InputMaybe<Scalars['String']>;
  /** 대학교 타입 */
  universityType?: InputMaybe<Scalars['String']>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

/** 선생님 언어 신청 수락/거절/보완요청 */
export type TeacherLanguageHistoryUpdateStateInput = {
  /** 거절/보완요청 사유 */
  refusalReason?: InputMaybe<Scalars['String']>;
  /** 승인 상태 */
  state?: InputMaybe<TeacherRegistrationState>;
};

export type TeacherLanguageLevel = {
  __typename?: 'TeacherLanguageLevel';
  /** 생성 날짜/시간 */
  createdAt: Scalars['DateTime'];
  /** 삭제 날짜/시간 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** 기본 키(UUID) */
  id: Scalars['ID'];
  /** 언어 */
  language?: Maybe<Scalars['String']>;
  /** 레벨 */
  level?: Maybe<Scalars['Float']>;
  teacher: Teacher;
  /** 수정 날짜/시간 */
  updatedAt: Scalars['DateTime'];
};

export type TeacherLanguageLevelEdge = {
  __typename?: 'TeacherLanguageLevelEdge';
  /** 커서 */
  cursor: Scalars['String'];
  /** 노드 */
  node: TeacherLanguageLevel;
};

export enum TeacherLanguageLevelEnum {
  /** 1 */
  Level1 = 'LEVEL1',
  /** 2 */
  Level2 = 'LEVEL2',
  /** 3 */
  Level3 = 'LEVEL3',
  /** 4 */
  Level4 = 'LEVEL4',
  /** 5 */
  Level5 = 'LEVEL5'
}

/** 선생님 언어 레벨 */
export type TeacherLanguageLevelEnumFilter = {
  operator: EnumFilterOperators;
  value?: InputMaybe<TeacherLanguageLevelEnum>;
  values?: InputMaybe<Array<TeacherLanguageLevelEnum>>;
};

export type TeacherLanguageLevelFilterInput = {
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** UUID */
  id?: InputMaybe<Array<IdFilterInput>>;
  language?: InputMaybe<Array<StringFilterInput>>;
  level?: InputMaybe<Array<TeacherLanguageLevelEnumFilter>>;
  teacher__id?: InputMaybe<Array<IdFilterInput>>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Array<DateTimeFilterInput>>;
};

export type TeacherLanguageLevelList = {
  __typename?: 'TeacherLanguageLevelList';
  /** 페이지네이션된 데이터 목록 */
  edges: Array<Maybe<TeacherLanguageLevelEdge>>;
  /** 페이지네이션된 페이지 정보 */
  pageInfo: PageInfo;
  /** 전체 데이터 개 수 */
  totalCount: Scalars['Int'];
};

export type TeacherLanguageLevelOrderByInput = {
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<SortInput>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<SortInput>;
  /** UUID */
  id?: InputMaybe<SortInput>;
  language?: InputMaybe<SortInput>;
  level?: InputMaybe<SortInput>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<SortInput>;
};

/** 선생님 요청 상태 */
export type TeacherLanguageStateEnumFilter = {
  operator: EnumFilterOperators;
  value?: InputMaybe<TeacherRegistrationState>;
  values?: InputMaybe<Array<TeacherRegistrationState>>;
};

/** 선생님 학습 자격 신청 */
export type TeacherLearningHistory = {
  __typename?: 'TeacherLearningHistory';
  /** 인증기관 */
  CA?: Maybe<Scalars['String']>;
  /** 첨부파일 */
  attachmentFile?: Maybe<File>;
  /** 검증요청 카테고리 */
  category?: Maybe<Scalars['String']>;
  /** 승인일 */
  completionDate?: Maybe<Scalars['DateTime']>;
  /** 생성 날짜/시간 */
  createdAt: Scalars['DateTime'];
  /** 삭제 날짜/시간 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** 대학원 */
  graduateSchool?: Maybe<Scalars['String']>;
  /** 기본 키(UUID) */
  id: Scalars['ID'];
  /** 승인 상태 */
  lastState?: Maybe<TeacherRegistrationState>;
  /** 소재지 */
  location?: Maybe<Scalars['String']>;
  /** 전공 */
  major?: Maybe<Scalars['String']>;
  /** 메모 */
  memo?: Maybe<Scalars['String']>;
  /** 처리자 */
  processor?: Maybe<User>;
  /** 자격명 */
  qualificationName?: Maybe<Scalars['String']>;
  /** 거절일 */
  refusalDate?: Maybe<Scalars['DateTime']>;
  /** 거절/보완요청 사유 */
  refusalReason?: Maybe<Scalars['String']>;
  /** 승인요청일 */
  requestDate?: Maybe<Scalars['DateTime']>;
  /** 승인 상태 */
  state?: Maybe<TeacherRegistrationState>;
  /** 보완요청일 */
  supplementationDate?: Maybe<Scalars['DateTime']>;
  /** 선생님 */
  teacher?: Maybe<Teacher>;
  /** 대학교 */
  university?: Maybe<Scalars['String']>;
  /** 대학교 타입 */
  universityType?: Maybe<Scalars['String']>;
  /** 수정 날짜/시간 */
  updatedAt: Scalars['DateTime'];
};

/** 선생님 학습 자격 신청 생성 */
export type TeacherLearningHistoryCreateInput = {
  /** 인증기관 */
  CA?: InputMaybe<Scalars['String']>;
  /** 첨부파일 id */
  attachmentFile__id?: InputMaybe<Scalars['ID']>;
  /** 검증요청 카테고리 */
  category?: InputMaybe<Scalars['String']>;
  /** 승인일 */
  completionDate?: InputMaybe<Scalars['DateTime']>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  /** 대학원 */
  graduateSchool?: InputMaybe<Scalars['String']>;
  /** 기본 키(UUID) */
  id?: InputMaybe<Scalars['ID']>;
  /** 소재지 */
  location?: InputMaybe<Scalars['String']>;
  /** 전공 */
  major?: InputMaybe<Scalars['String']>;
  /** 메모 */
  memo?: InputMaybe<Scalars['String']>;
  /** 자격명 */
  qualificationName?: InputMaybe<Scalars['String']>;
  /** 거절일 */
  refusalDate?: InputMaybe<Scalars['DateTime']>;
  /** 거절/보완요청 사유 */
  refusalReason?: InputMaybe<Scalars['String']>;
  /** 승인요청일 */
  requestDate?: InputMaybe<Scalars['DateTime']>;
  /** 승인 상태 */
  state?: InputMaybe<TeacherRegistrationState>;
  /** 보완요청일 */
  supplementationDate?: InputMaybe<Scalars['DateTime']>;
  /** 선생님 id */
  teacher__id?: InputMaybe<Scalars['ID']>;
  /** 대학교 */
  university?: InputMaybe<Scalars['String']>;
  /** 대학교 타입 */
  universityType?: InputMaybe<Scalars['String']>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TeacherLearningHistoryEdge = {
  __typename?: 'TeacherLearningHistoryEdge';
  /** 커서 */
  cursor: Scalars['String'];
  /** 노드 */
  node: TeacherLearningHistory;
};

/** 선생님 학습 자격 필터 */
export type TeacherLearningHistoryFilterInput = {
  /** 인증기관 */
  CA?: InputMaybe<Array<StringFilterInput>>;
  /** 검증요청 카테고리 */
  category?: InputMaybe<Array<StringFilterInput>>;
  /** 승인일 */
  completionDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** UUID */
  id?: InputMaybe<Array<IdFilterInput>>;
  /** 소재지 */
  location?: InputMaybe<Array<StringFilterInput>>;
  /** 전공 */
  major?: InputMaybe<Array<StringFilterInput>>;
  /** 메모 */
  memo?: InputMaybe<Array<StringFilterInput>>;
  /** 관리자 이메일 */
  processor__email?: InputMaybe<Array<StringFilterInput>>;
  /** 자격명 */
  qualificationName?: InputMaybe<Array<StringFilterInput>>;
  /** 거절일 */
  refusalDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 거절/보완요청 사유 */
  refusalReason?: InputMaybe<Array<StringFilterInput>>;
  /** 승인요청일 */
  requestDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 승인 상태 */
  state?: InputMaybe<Array<TeacherLearningStateEnumFilter>>;
  /** 보완요청일 */
  supplementationDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 이메일 */
  teacherUser__email?: InputMaybe<Array<StringFilterInput>>;
  /** 선생님명 */
  teacherUser__name?: InputMaybe<Array<StringFilterInput>>;
  /** 전화번호 */
  teacherUser__phone?: InputMaybe<Array<StringFilterInput>>;
  /** 선생님 아이디 */
  teacher__id?: InputMaybe<Array<IdFilterInput>>;
  /** 신원확인 여부 */
  teacher__isIdentityVerified?: InputMaybe<Array<BooleanFilterInput>>;
  /** 대학교 */
  university?: InputMaybe<Array<StringFilterInput>>;
  /** 대학교 타입 */
  universityType?: InputMaybe<Array<StringFilterInput>>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Array<DateTimeFilterInput>>;
};

/** 선생님 학습 자격 신청 리스트 */
export type TeacherLearningHistoryList = {
  __typename?: 'TeacherLearningHistoryList';
  /** 페이지네이션된 데이터 목록 */
  edges: Array<Maybe<TeacherLearningHistoryEdge>>;
  /** 페이지네이션된 페이지 정보 */
  pageInfo: PageInfo;
  /** 전체 데이터 개 수 */
  totalCount: Scalars['Int'];
};

/** 선생님 학습 자격 신청 정렬 */
export type TeacherLearningHistoryOrderByInput = {
  /** 인증기관 */
  CA?: InputMaybe<SortInput>;
  /** 검증요청 카테고리 */
  category?: InputMaybe<SortInput>;
  /** 승인일 */
  completionDate?: InputMaybe<SortInput>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<SortInput>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<SortInput>;
  /** UUID */
  id?: InputMaybe<SortInput>;
  /** 소재지 */
  location?: InputMaybe<SortInput>;
  /** 전공 */
  major?: InputMaybe<SortInput>;
  /** 메모 */
  memo?: InputMaybe<SortInput>;
  /** 처리일 */
  processedAt?: InputMaybe<SortInput>;
  /** 자격명 */
  qualificationName?: InputMaybe<SortInput>;
  /** 거절일 */
  refusalDate?: InputMaybe<SortInput>;
  /** 거절/보완요청 사유 */
  refusalReason?: InputMaybe<SortInput>;
  /** 요청일 */
  requestAt?: InputMaybe<SortInput>;
  /** 승인요청일 */
  requestDate?: InputMaybe<SortInput>;
  /** 승인 상태 */
  state?: InputMaybe<SortInput>;
  /** 보완요청일 */
  supplementationDate?: InputMaybe<SortInput>;
  /** 이메일 */
  teacherUser__email?: InputMaybe<SortInput>;
  /** 이름 */
  teacherUser__name?: InputMaybe<SortInput>;
  /** 전화번호 */
  teacherUser__phone?: InputMaybe<SortInput>;
  /** 대학교 */
  university?: InputMaybe<SortInput>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<SortInput>;
};

/** 선생님 학습 자격 신청 생성 */
export type TeacherLearningHistoryUpdateInput = {
  /** 인증기관 */
  CA?: InputMaybe<Scalars['String']>;
  /** 첨부파일 id */
  attachmentFile__id?: InputMaybe<Scalars['ID']>;
  /** 검증요청 카테고리 */
  category?: InputMaybe<Scalars['String']>;
  /** 승인일 */
  completionDate?: InputMaybe<Scalars['DateTime']>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  /** 대학원 */
  graduateSchool?: InputMaybe<Scalars['String']>;
  /** 기본 키(UUID) */
  id?: InputMaybe<Scalars['ID']>;
  /** 소재지 */
  location?: InputMaybe<Scalars['String']>;
  /** 전공 */
  major?: InputMaybe<Scalars['String']>;
  /** 메모 */
  memo?: InputMaybe<Scalars['String']>;
  /** 자격명 */
  qualificationName?: InputMaybe<Scalars['String']>;
  /** 거절일 */
  refusalDate?: InputMaybe<Scalars['DateTime']>;
  /** 거절/보완요청 사유 */
  refusalReason?: InputMaybe<Scalars['String']>;
  /** 승인요청일 */
  requestDate?: InputMaybe<Scalars['DateTime']>;
  /** 승인 상태 */
  state?: InputMaybe<TeacherRegistrationState>;
  /** 보완요청일 */
  supplementationDate?: InputMaybe<Scalars['DateTime']>;
  /** 선생님 id */
  teacher__id?: InputMaybe<Scalars['ID']>;
  /** 대학교 */
  university?: InputMaybe<Scalars['String']>;
  /** 대학교 타입 */
  universityType?: InputMaybe<Scalars['String']>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

/** 선생님 학습 신청 수락/거절/보완요청 */
export type TeacherLearningHistoryUpdateStateInput = {
  /** 거절/보완요청 사유 */
  refusalReason?: InputMaybe<Scalars['String']>;
  /** 승인 상태 */
  state?: InputMaybe<TeacherRegistrationState>;
};

/** 선생님 요청 상태 */
export type TeacherLearningStateEnumFilter = {
  operator: EnumFilterOperators;
  value?: InputMaybe<TeacherRegistrationState>;
  values?: InputMaybe<Array<TeacherRegistrationState>>;
};

export type TeacherLessonTimeRangeFilter = {
  /** 종료 시간 */
  endTime: TimeSlotEnumType;
  /** 시작 시간 */
  startTime: TimeSlotEnumType;
};

/** 선생님 목록 */
export type TeacherList = {
  __typename?: 'TeacherList';
  /** 페이지네이션된 데이터 목록 */
  edges: Array<Maybe<TeacherEdge>>;
  /** 페이지네이션된 페이지 정보 */
  pageInfo: PageInfo;
  /** 가장 높은 금액(필터용) */
  teacherMaxPrice?: Maybe<Scalars['Float']>;
  /** 가장 작은 금액(필터용) */
  teacherMinPrice?: Maybe<Scalars['Float']>;
  /** 전체 데이터 개 수 */
  totalCount: Scalars['Int'];
};

export enum TeacherMemoEnum {
  /** 추가자료 요청 */
  AdditionalDataRequired = 'ADDITIONAL_DATA_REQUIRED',
  /** 본인 확인 불가 */
  CantIdentified = 'CANT_IDENTIFIED',
  /** 위조/불법 자료 */
  Forged = 'FORGED',
  /** 문서 화질 문제 */
  LowQualityFile = 'LOW_QUALITY_FILE',
  /** 타인 서류 */
  Steal = 'STEAL',
  /** 미인증 인증기관 */
  UncertifiedCa = 'UNCERTIFIED_CA'
}

/** 선생님 거절/보완요청 사유 */
export type TeacherMemoEnumFilter = {
  operator: EnumFilterOperators;
  value?: InputMaybe<TeacherMemoEnum>;
  values?: InputMaybe<Array<TeacherMemoEnum>>;
};

export type TeacherMyPageLanguageQualificationFilterInput = {
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 언어 */
  language?: InputMaybe<Array<StringFilterInput>>;
  /** 승인 상태 */
  state?: InputMaybe<Array<TeacherLanguageStateEnumFilter>>;
};

export type TeacherMyPageLearningQualificationFilterInput = {
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 승인 상태 */
  state?: InputMaybe<Array<TeacherLearningStateEnumFilter>>;
};

/** 선생님 정렬 */
export type TeacherOrderByInput = {
  /** 사업자 등록 번호 */
  businessRegistrationNumber?: InputMaybe<SortInput>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<SortInput>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<SortInput>;
  /** 학습 자격 */
  education?: InputMaybe<SortInput>;
  /** 구글밋 수수료율 */
  googleMeetFeeRate?: InputMaybe<SortInput>;
  /** 등급(일반 선생님, 전문 선생님) */
  grade?: InputMaybe<SortInput>;
  /** 언어 자격 보유 여부 */
  hasLanguageQualification?: InputMaybe<SortInput>;
  /** 학습 자격 보유 여부 */
  hasLearningQualification?: InputMaybe<SortInput>;
  /** 고등학교 */
  highschool?: InputMaybe<SortInput>;
  /** UUID */
  id?: InputMaybe<SortInput>;
  /** 사업자 여부 */
  isBusinessProprietor?: InputMaybe<SortInput>;
  /** 신원 인증 여부 */
  isIdentityVerified?: InputMaybe<SortInput>;
  /** 출금 정지 여부 */
  isWithdrawalSuspended?: InputMaybe<SortInput>;
  /** 언어소개글 */
  languageIntroductionText?: InputMaybe<SortInput>;
  /** 학습소개글 */
  learningIntroductionText?: InputMaybe<SortInput>;
  /** 소재지 */
  location?: InputMaybe<SortInput>;
  /** 언어 주 1 */
  mainLanguageA?: InputMaybe<SortInput>;
  /** 언어 주 1 레벨 */
  mainLanguageALevel?: InputMaybe<SortInput>;
  /** 언어 주 2 */
  mainLanguageB?: InputMaybe<SortInput>;
  /** 언어 주 2 레벨 */
  mainLanguageBLevel?: InputMaybe<SortInput>;
  /** 전공 */
  major?: InputMaybe<SortInput>;
  /** 출신 국가 */
  originCountry?: InputMaybe<SortInput>;
  /** 페이지콜 수수료율 */
  pageCallFeeRate?: InputMaybe<SortInput>;
  /** 대표자명 */
  representativeName?: InputMaybe<SortInput>;
  /** 거주 국가 */
  residenceCountry?: InputMaybe<SortInput>;
  /** 시범 수업 횟수 */
  sneakPeekNumber?: InputMaybe<SortInput>;
  /** 시범 수업 금액 */
  sneakPeekPrice?: InputMaybe<SortInput>;
  /** 시범 수업 시간 */
  sneakPeekTime?: InputMaybe<SortInput>;
  /** 선생님 등록, 신청 상태 */
  state?: InputMaybe<SortInput>;
  /** 언어 부 1 */
  subLanguageA?: InputMaybe<SortInput>;
  /** 언어 부 1 레벨 */
  subLanguageALevel?: InputMaybe<SortInput>;
  /** 언어 부 2 */
  subLanguageB?: InputMaybe<SortInput>;
  /** 언어 부 2 레벨 */
  subLanguageBLevel?: InputMaybe<SortInput>;
  /** 학습 과목 */
  subject?: InputMaybe<SortInput>;
  /** 선생님 등록 거절, 보완 메시지 */
  teacherMemo?: InputMaybe<SortInput>;
  /** 즐겨찾기순 */
  teacherSort__bookmarkCount?: InputMaybe<SortInput>;
  /** 구매순 */
  teacherSort__businessCount?: InputMaybe<SortInput>;
  /** 수업 진행 횟수 */
  teacherSort__cumulativeLessonCount?: InputMaybe<SortInput>;
  /** 평점순(평점 평균) */
  teacherSort__gpaAvg?: InputMaybe<SortInput>;
  /** 리뷰순(리뷰수) */
  teacherSort__gpaCount?: InputMaybe<SortInput>;
  /** 수업 최대 가격 */
  teacherSort__maxPrice?: InputMaybe<SortInput>;
  /** 수업 최소 가격 */
  teacherSort__minPrice?: InputMaybe<SortInput>;
  /** 인기순 */
  teacherSort__popular?: InputMaybe<SortInput>;
  /** 강의 횟수 */
  teacherSort__subLessonCount?: InputMaybe<SortInput>;
  /** 학습 대상 */
  teachingTarget?: InputMaybe<SortInput>;
  /** 대학교 */
  university?: InputMaybe<SortInput>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<SortInput>;
  /** 생년월일 */
  user__birthDate?: InputMaybe<SortInput>;
  /** 이메일 */
  user__email?: InputMaybe<SortInput>;
  /** 이름 */
  user__name?: InputMaybe<SortInput>;
  /** 전화번호 */
  user__phone?: InputMaybe<SortInput>;
  /** 선생님 역할 */
  user__role?: InputMaybe<SortInput>;
  /** 선생님 가입 서비스 */
  user__service?: InputMaybe<SortInput>;
};

export enum TeacherRegistrationState {
  /** 확인중 */
  Checking = 'CHECKING',
  /** 확인완료 */
  Completion = 'COMPLETION',
  /** 탈퇴 */
  Quit = 'QUIT',
  /** 거절 */
  Refusal = 'REFUSAL',
  /** 재승인요청 */
  Rerequest = 'REREQUEST',
  /** 보완요청중 */
  Supplementation = 'SUPPLEMENTATION'
}

/** 선생님 정렬용 가공 필드 */
export type TeacherSortView = {
  __typename?: 'TeacherSortView';
  /** 즐겨찾기수 */
  bookmarkCount?: Maybe<Scalars['Float']>;
  /** 판매수 */
  businessCount?: Maybe<Scalars['Float']>;
  /** 수업 진행 횟수 */
  cumulativeLessonCount?: Maybe<Scalars['Float']>;
  /** 평점 평균 */
  gpaAvg?: Maybe<Scalars['Float']>;
  /** 리뷰수 */
  gpaCount?: Maybe<Scalars['Float']>;
  /** 아이디 */
  id?: Maybe<Scalars['String']>;
  /** 최대가격 */
  maxPrice?: Maybe<Scalars['Float']>;
  /** 최소가격 */
  minPrice?: Maybe<Scalars['Float']>;
  /** 인기 */
  popular?: Maybe<Scalars['Float']>;
  /** 강의 횟수 */
  subLessonCount?: Maybe<Scalars['Float']>;
};

/** 선생님 신청 상태 변경 */
export type TeacherStateUpdateInput = {
  /** 선생님 등록 신청 상태 */
  state?: InputMaybe<TeacherRegistrationState>;
  /** 선생님 등록 거절, 보완 메시지 */
  teacherMemo?: InputMaybe<TeacherMemoEnum>;
};

export type TeacherStatistics = {
  __typename?: 'TeacherStatistics';
  /** 1달 전 진행 수업 수 */
  oneMonthAgoProgressedLessonNumber?: Maybe<Scalars['Float']>;
  /** 1달 전 수업 만족도 */
  oneMonthAgoTeacherAttendanceRate?: Maybe<Scalars['Float']>;
  /** 수업 만족도 */
  satisfaction?: Maybe<Scalars['Float']>;
  /** 학생 수 */
  studentNumber?: Maybe<Scalars['Float']>;
  /** 이번 달 진행 수업 수 */
  thisMonthProgressedLessonNumber?: Maybe<Scalars['Float']>;
  /** 이번 달 수업 만족도 */
  thisMonthTeacherAttendanceRate?: Maybe<Scalars['Float']>;
  /** 2달 전 진행 수업 수 */
  twoMonthsAgoProgressedLessonNumber?: Maybe<Scalars['Float']>;
  /** 2달 전 수업 만족도 */
  twoMonthsAgoTeacherAttendanceRate?: Maybe<Scalars['Float']>;
};

/** 선생님용 수업 필터 */
export type TeacherSubLessonFilterInput = {
  /** 숙제 상태 */
  homeworkState?: InputMaybe<Array<HomeWorkStateEnumFilter>>;
  /** subLesson id */
  id?: InputMaybe<Array<IdFilterInput>>;
  /** 수업일 */
  lessonDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 수업 종료일 */
  lessonEndDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 수업명 */
  lesson__lessonName?: InputMaybe<Array<StringFilterInput>>;
  /** 수업 타입(정규/시범) */
  lesson__type?: InputMaybe<Array<LessonTypeEnumFilterInput>>;
  /** 수업 상태 */
  state?: InputMaybe<Array<SubLessonStateEnumFilter>>;
  /** 학생 입장 시각 */
  studentJoinAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 선생님 입장 시각 */
  teacherJoinAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 선생님 id */
  teacher__id?: InputMaybe<Array<IdFilterInput>>;
  /** 학생 id */
  user__id?: InputMaybe<Array<IdFilterInput>>;
  /** 학생명 */
  user__name?: InputMaybe<Array<StringFilterInput>>;
};

/** 과목 배열 */
export type TeacherSubjectArrayFilter = {
  operator: EnumFilterOperators;
  selector: FilterSelectors;
  value?: InputMaybe<TeacherSubjectEnum>;
  value2?: InputMaybe<TeacherSubjectEnum>;
  values?: InputMaybe<Array<TeacherSubjectEnum>>;
};

export enum TeacherSubjectEnum {
  /** 한문 */
  ClassicalChinese = 'CLASSICAL_CHINESE',
  /** 영어 */
  English = 'ENGLISH',
  /** 한국사 */
  KoreanHistory = 'KOREAN_HISTORY',
  /** 국어 */
  KoreanLanguage = 'KOREAN_LANGUAGE',
  /** 수학 */
  Math = 'MATH',
  /** 과학탐구 */
  Science = 'SCIENCE',
  /** 제2외국어 */
  SecondForeignLanguage = 'SECOND_FOREIGN_LANGUAGE',
  /** 사회탐구 */
  Society = 'SOCIETY',
  /** 직업탐구 */
  Work = 'WORK'
}

/** 학습 대상 배열 */
export type TeacherTeachingTargetArrayFilter = {
  operator: EnumFilterOperators;
  selector: FilterSelectors;
  value?: InputMaybe<UserType>;
  value2?: InputMaybe<UserType>;
  values?: InputMaybe<Array<UserType>>;
};

export enum TeacherTypeEnum {
  /** 언어 */
  Language = 'LANGUAGE',
  /** 학습 */
  Learning = 'LEARNING'
}

export type TeacherTypeEnumFilterInput = {
  operator: EnumFilterOperators;
  value?: InputMaybe<TeacherTypeEnum>;
  values?: InputMaybe<Array<TeacherTypeEnum>>;
};

/** 선생님 수정 */
export type TeacherUpdateInput = {
  /** 인증 기관 */
  CA?: InputMaybe<Scalars['String']>;
  /** 사업자 등록 번호 */
  businessRegistrationNumber?: InputMaybe<Scalars['String']>;
  /** 사업자 개업연월일, yyyyMMdd */
  businessStartDate?: InputMaybe<Scalars['String']>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  /** 학습 자격 */
  education?: InputMaybe<Teachereducation>;
  /** 최초 수업일 */
  firstLessonDate?: InputMaybe<Scalars['DateTime']>;
  /** 구글밋수수료율 */
  googleMeetFeeRate?: InputMaybe<Scalars['Float']>;
  /** 등급(일반 선생님, 전문 선생님) */
  grade?: InputMaybe<TeacherGradeEnum>;
  /** 대학원 */
  graduateSchool?: InputMaybe<Scalars['String']>;
  /** 언어 자격 보유 여부 */
  hasLanguageQualification?: InputMaybe<Scalars['Boolean']>;
  /** 학습 자격 보유 여부 */
  hasLearningQualification?: InputMaybe<Scalars['Boolean']>;
  /** 고등학교 */
  highschool?: InputMaybe<Scalars['String']>;
  /** 기본 키(UUID) */
  id?: InputMaybe<Scalars['ID']>;
  /** 신원 인증 파일 id */
  identityFile__id?: InputMaybe<Scalars['ID']>;
  /** 사업자 여부 */
  isBusinessProprietor?: InputMaybe<Scalars['Boolean']>;
  /** 신원 인증 여부 */
  isIdentityVerified?: InputMaybe<Scalars['Boolean']>;
  /** 출금 정지 여부 */
  isWithdrawalSuspended?: InputMaybe<Scalars['Boolean']>;
  /** 언어소개 사진 id */
  languageIntroductionPhotoFile__id?: InputMaybe<Scalars['ID']>;
  /** 언어소개글 */
  languageIntroductionText?: InputMaybe<Scalars['String']>;
  /** 언어 소개 동영상 id */
  languageIntroductionVideoFile__id?: InputMaybe<Scalars['ID']>;
  /** 학습소개 사진 id */
  learningIntroductionPhotoFile__id?: InputMaybe<Scalars['ID']>;
  /** 학습소개글 */
  learningIntroductionText?: InputMaybe<Scalars['String']>;
  /** 학습 소개 동영상 id */
  learningIntroductionVideoFile__id?: InputMaybe<Scalars['ID']>;
  /** 소재지 */
  location?: InputMaybe<Scalars['String']>;
  /** 언어 주 1 */
  mainLanguageA?: InputMaybe<Scalars['String']>;
  /** 언어 주 1 레벨 */
  mainLanguageALevel?: InputMaybe<TeacherLanguageLevelEnum>;
  /** 언어 주 2 */
  mainLanguageB?: InputMaybe<Scalars['String']>;
  /** 언어 주 2 레벨 */
  mainLanguageBLevel?: InputMaybe<TeacherLanguageLevelEnum>;
  /** 전공 */
  major?: InputMaybe<Scalars['String']>;
  /** 출신 국가 */
  originCountry?: InputMaybe<Scalars['String']>;
  /** 페이지콜수수료율 */
  pageCallFeeRate?: InputMaybe<Scalars['Float']>;
  /** 기타자격명 */
  qualificationName?: InputMaybe<Scalars['String']>;
  /** 대표자명 */
  representativeName?: InputMaybe<Scalars['String']>;
  /** 거주 국가 */
  residenceCountry?: InputMaybe<Scalars['String']>;
  /** 요청 응답률 */
  responseRate?: InputMaybe<Scalars['Float']>;
  /** 시범 수업 횟수 */
  sneakPeekNumber?: InputMaybe<Scalars['Float']>;
  /** 시범 수업 금액 */
  sneakPeekPrice?: InputMaybe<Scalars['Float']>;
  /** 시범 수업 시간 */
  sneakPeekTime?: InputMaybe<Scalars['Float']>;
  /** 선생님 등록 신청 상태 */
  state?: InputMaybe<TeacherRegistrationState>;
  /** 언어 부 1 */
  subLanguageA?: InputMaybe<Scalars['String']>;
  /** 언어 부 1 레벨 */
  subLanguageALevel?: InputMaybe<TeacherLanguageLevelEnum>;
  /** 언어 부 2 */
  subLanguageB?: InputMaybe<Scalars['String']>;
  /** 언어 부 2 레벨 */
  subLanguageBLevel?: InputMaybe<TeacherLanguageLevelEnum>;
  /** 학습 과목 */
  subject?: InputMaybe<Array<TeacherSubjectEnum>>;
  /** 선생님 등록 거절, 보완 메시지 */
  teacherMemo?: InputMaybe<TeacherMemoEnum>;
  /** 학습 대상 */
  teachingTarget?: InputMaybe<Array<UserType>>;
  /** 대학교 */
  university?: InputMaybe<Scalars['String']>;
  /** 대학교 타입 */
  universityType?: InputMaybe<Scalars['String']>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** 선생님 ID */
  user__id?: InputMaybe<Scalars['ID']>;
};

export type TeacherVideoLog = {
  __typename?: 'TeacherVideoLog';
  /** 생성 날짜/시간 */
  createdAt: Scalars['DateTime'];
  /** 삭제 날짜/시간 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  file: File;
  /** 기본 키(UUID) */
  id: Scalars['ID'];
  /** 거절 사유 */
  refusalReason?: Maybe<Scalars['String']>;
  /** 상태 */
  state: TeacherVideoStateEnum;
  teacher: Teacher;
  /** 언어/학습 */
  type: TeacherTypeEnum;
  /** 수정 날짜/시간 */
  updatedAt: Scalars['DateTime'];
  /** 유튜브 영상 링크 */
  youtubeLink?: Maybe<Scalars['String']>;
};

export type TeacherVideoLogEdge = {
  __typename?: 'TeacherVideoLogEdge';
  /** 커서 */
  cursor: Scalars['String'];
  /** 노드 */
  node: TeacherVideoLog;
};

export type TeacherVideoLogFilterInput = {
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** UUID */
  id?: InputMaybe<Array<IdFilterInput>>;
  /** 거절 사유 */
  refusalReason?: InputMaybe<Array<StringFilterInput>>;
  /** 상태 */
  state?: InputMaybe<Array<TeacherVideoStateFilterInput>>;
  /** 선생님 id */
  teacher__id?: InputMaybe<Array<IdFilterInput>>;
  /** 언어/학습 */
  type?: InputMaybe<Array<TeacherTypeEnumFilterInput>>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 유튜브 영상 링크 */
  youtubeLink?: InputMaybe<Array<StringFilterInput>>;
};

export type TeacherVideoLogList = {
  __typename?: 'TeacherVideoLogList';
  /** 페이지네이션된 데이터 목록 */
  edges: Array<Maybe<TeacherVideoLogEdge>>;
  /** 페이지네이션된 페이지 정보 */
  pageInfo: PageInfo;
  /** 전체 데이터 개 수 */
  totalCount: Scalars['Int'];
};

export type TeacherVideoLogSortInput = {
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<SortInput>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<SortInput>;
  /** UUID */
  id?: InputMaybe<SortInput>;
  /** 거절 사유 */
  refusalReason?: InputMaybe<SortInput>;
  /** 상태 */
  state?: InputMaybe<SortInput>;
  /** 언어/학습 */
  type?: InputMaybe<SortInput>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<SortInput>;
};

export type TeacherVideoLogUpdateInput = {
  /** 거절 사유 */
  refusalReason?: InputMaybe<Scalars['String']>;
  /** 상태 */
  state: TeacherVideoStateEnum;
  /** 유튜브 영상 링크 */
  youtubeLink?: InputMaybe<Scalars['String']>;
};

export enum TeacherVideoStateEnum {
  /** 승인 */
  Completion = 'COMPLETION',
  /** 대기중 */
  Pending = 'PENDING',
  /** 거절 */
  Refusal = 'REFUSAL'
}

export type TeacherVideoStateFilterInput = {
  operator: EnumFilterOperators;
  value?: InputMaybe<TeacherVideoStateEnum>;
  values?: InputMaybe<Array<TeacherVideoStateEnum>>;
};

/** 선생님 출금 정지 변경 */
export type TeacherWithdrawalSuspendUpdateInput = {
  /** 출금 정지 여부 */
  isWithdrawalSuspended?: InputMaybe<Scalars['Boolean']>;
};

export enum Teachereducation {
  /** 기타 */
  Etc = 'ETC',
  /** 대학원 */
  Graduateschool = 'GRADUATESCHOOL',
  /** 대학원수료 */
  GraduateschoolCompletion = 'GRADUATESCHOOL_COMPLETION',
  /** 대학원졸업 */
  GraduateschoolGraduation = 'GRADUATESCHOOL_GRADUATION',
  /** 전문강사 */
  Instructor = 'INSTRUCTOR',
  /** 일반인 */
  Person = 'PERSON',
  /** 대학교 */
  University = 'UNIVERSITY',
  /** 대학교졸업 */
  UniversityGraduation = 'UNIVERSITY_GRADUATION',
  /** 대학재학생 */
  UniversityStudent = 'UNIVERSITY_STUDENT'
}

export type TermsOfService = {
  __typename?: 'TermsOfService';
  agreements: Array<TermsOfServiceAgreement>;
  /** 약관 내용 */
  content: Scalars['String'];
  /** 생성 날짜/시간 */
  createdAt: Scalars['DateTime'];
  /** 삭제 날짜/시간 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** 기본 키(UUID) */
  id: Scalars['ID'];
  /** 필수 여부 */
  isEssential: Scalars['Boolean'];
  /** 약관 제목 */
  title: Scalars['String'];
  /** 수정 날짜/시간 */
  updatedAt: Scalars['DateTime'];
};

export type TermsOfServiceAgreement = {
  __typename?: 'TermsOfServiceAgreement';
  /** 생성 날짜/시간 */
  createdAt: Scalars['DateTime'];
  /** 삭제 날짜/시간 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** 기본 키(UUID) */
  id: Scalars['ID'];
  /** 동의 여부 */
  isAgreed: Scalars['Boolean'];
  termsOfService: TermsOfService;
  /** 수정 날짜/시간 */
  updatedAt: Scalars['DateTime'];
  user: User;
};

export type TermsOfServiceCreateInput = {
  /** 약관 내용 */
  content?: InputMaybe<Scalars['String']>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  /** 기본 키(UUID) */
  id?: InputMaybe<Scalars['ID']>;
  /** 필수 여부 */
  isEssential?: InputMaybe<Scalars['Boolean']>;
  /** 약관 제목 */
  title?: InputMaybe<Scalars['String']>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TermsOfServiceEdge = {
  __typename?: 'TermsOfServiceEdge';
  /** 커서 */
  cursor: Scalars['String'];
  /** 노드 */
  node: TermsOfService;
};

export type TermsOfServiceFilterInput = {
  /** 약관 내용 */
  content?: InputMaybe<Array<StringFilterInput>>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** UUID */
  id?: InputMaybe<Array<IdFilterInput>>;
  /** 필수 여부 */
  isEssential?: InputMaybe<Array<BooleanFilterInput>>;
  /** 약관 제목 */
  title?: InputMaybe<Array<StringFilterInput>>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Array<DateTimeFilterInput>>;
};

export type TermsOfServiceList = {
  __typename?: 'TermsOfServiceList';
  /** 페이지네이션된 데이터 목록 */
  edges: Array<Maybe<TermsOfServiceEdge>>;
  /** 페이지네이션된 페이지 정보 */
  pageInfo: PageInfo;
  /** 전체 데이터 개 수 */
  totalCount: Scalars['Int'];
};

export type TermsOfServiceOrderByInput = {
  /** 약관 내용 */
  content?: InputMaybe<SortInput>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<SortInput>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<SortInput>;
  /** UUID */
  id?: InputMaybe<SortInput>;
  /** 필수 여부 */
  isEssential?: InputMaybe<SortInput>;
  /** 약관 제목 */
  title?: InputMaybe<SortInput>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<SortInput>;
};

export type TermsOfServiceUpdateInput = {
  /** 약관 내용 */
  content?: InputMaybe<Scalars['String']>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  /** 기본 키(UUID) */
  id?: InputMaybe<Scalars['ID']>;
  /** 필수 여부 */
  isEssential?: InputMaybe<Scalars['Boolean']>;
  /** 약관 제목 */
  title?: InputMaybe<Scalars['String']>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TimeSlot = {
  __typename?: 'TimeSlot';
  /** 생성 날짜/시간 */
  createdAt: Scalars['DateTime'];
  /** 삭제 날짜/시간 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** 종료일 */
  endDate: Scalars['DateTime'];
  /** 기본 키(UUID) */
  id: Scalars['ID'];
  /** 시작일 */
  startDate: Scalars['DateTime'];
  teacher: Teacher;
  /** 수정 날짜/시간 */
  updatedAt: Scalars['DateTime'];
};

export type TimeSlotCreateInput = {
  /** 종료일 */
  endDate: Scalars['DateTime'];
  /** 시작일 */
  startDate: Scalars['DateTime'];
};

export type TimeSlotEdge = {
  __typename?: 'TimeSlotEdge';
  /** 커서 */
  cursor: Scalars['String'];
  /** 노드 */
  node: TimeSlot;
};

export enum TimeSlotEnumType {
  /** 00:00 */
  T00_00 = 'T00_00',
  /** 00:30 */
  T00_30 = 'T00_30',
  /** 01:00 */
  T01_00 = 'T01_00',
  /** 01:30 */
  T01_30 = 'T01_30',
  /** 02:00 */
  T02_00 = 'T02_00',
  /** 02:30 */
  T02_30 = 'T02_30',
  /** 03:00 */
  T03_00 = 'T03_00',
  /** 03:30 */
  T03_30 = 'T03_30',
  /** 04:00 */
  T04_00 = 'T04_00',
  /** 04:30 */
  T04_30 = 'T04_30',
  /** 05:00 */
  T05_00 = 'T05_00',
  /** 05:30 */
  T05_30 = 'T05_30',
  /** 06:00 */
  T06_00 = 'T06_00',
  /** 06:30 */
  T06_30 = 'T06_30',
  /** 07:00 */
  T07_00 = 'T07_00',
  /** 07:30 */
  T07_30 = 'T07_30',
  /** 08:00 */
  T08_00 = 'T08_00',
  /** 08:30 */
  T08_30 = 'T08_30',
  /** 09:00 */
  T09_00 = 'T09_00',
  /** 09:30 */
  T09_30 = 'T09_30',
  /** 10:00 */
  T10_00 = 'T10_00',
  /** 10:30 */
  T10_30 = 'T10_30',
  /** 11:00 */
  T11_00 = 'T11_00',
  /** 11:30 */
  T11_30 = 'T11_30',
  /** 12:00 */
  T12_00 = 'T12_00',
  /** 12:30 */
  T12_30 = 'T12_30',
  /** 13:00 */
  T13_00 = 'T13_00',
  /** 13:30 */
  T13_30 = 'T13_30',
  /** 14:00 */
  T14_00 = 'T14_00',
  /** 14:30 */
  T14_30 = 'T14_30',
  /** 15:00 */
  T15_00 = 'T15_00',
  /** 15:30 */
  T15_30 = 'T15_30',
  /** 16:00 */
  T16_00 = 'T16_00',
  /** 16:30 */
  T16_30 = 'T16_30',
  /** 17:00 */
  T17_00 = 'T17_00',
  /** 17:30 */
  T17_30 = 'T17_30',
  /** 18:00 */
  T18_00 = 'T18_00',
  /** 18:30 */
  T18_30 = 'T18_30',
  /** 19:00 */
  T19_00 = 'T19_00',
  /** 19:30 */
  T19_30 = 'T19_30',
  /** 20:00 */
  T20_00 = 'T20_00',
  /** 20:30 */
  T20_30 = 'T20_30',
  /** 21:00 */
  T21_00 = 'T21_00',
  /** 21:30 */
  T21_30 = 'T21_30',
  /** 22:00 */
  T22_00 = 'T22_00',
  /** 22:30 */
  T22_30 = 'T22_30',
  /** 23:00 */
  T23_00 = 'T23_00',
  /** 23:30 */
  T23_30 = 'T23_30',
  /** 24:00 */
  T24_00 = 'T24_00'
}

export type TimeSlotFilterInput = {
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 종료일 */
  endDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** UUID */
  id?: InputMaybe<Array<IdFilterInput>>;
  /** 시작일 */
  startDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 선생님 id */
  teacher__id?: InputMaybe<Array<IdFilterInput>>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Array<DateTimeFilterInput>>;
};

export type TimeSlotList = {
  __typename?: 'TimeSlotList';
  /** 페이지네이션된 데이터 목록 */
  edges: Array<Maybe<TimeSlotEdge>>;
  /** 페이지네이션된 페이지 정보 */
  pageInfo: PageInfo;
  /** 전체 데이터 개 수 */
  totalCount: Scalars['Int'];
};

export type TimeSlotOrderByInput = {
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<SortInput>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<SortInput>;
  /** 종료일 */
  endDate?: InputMaybe<SortInput>;
  /** UUID */
  id?: InputMaybe<SortInput>;
  /** 시작일 */
  startDate?: InputMaybe<SortInput>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<SortInput>;
};

/** 트리카테고리 */
export type TreeBookCategory = {
  __typename?: 'TreeBookCategory';
  /** 카테고리 이름 */
  children?: Maybe<Array<TreeBookCategory>>;
  /** 생성 날짜/시간 */
  createdAt: Scalars['DateTime'];
  /** 삭제 날짜/시간 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** 기본 키(UUID) */
  id: Scalars['ID'];
  /** 카테고리 이름 */
  name?: Maybe<Scalars['String']>;
  /** 카테고리 이름 */
  parent?: Maybe<TreeBookCategory>;
  /** 순서 */
  priority?: Maybe<Scalars['Float']>;
  /** 수정 날짜/시간 */
  updatedAt: Scalars['DateTime'];
};

/** 트리카테고리 */
export type TreeFaqCategory = {
  __typename?: 'TreeFAQCategory';
  /** 카테고리 이름 */
  children?: Maybe<Array<TreeFaqCategory>>;
  /** 생성 날짜/시간 */
  createdAt: Scalars['DateTime'];
  /** 삭제 날짜/시간 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** 기본 키(UUID) */
  id: Scalars['ID'];
  /** 카테고리 이름 */
  name?: Maybe<Scalars['String']>;
  /** 카테고리 이름 */
  parent?: Maybe<TreeFaqCategory>;
  /** 순서 */
  priority?: Maybe<Scalars['Float']>;
  /** 수정 날짜/시간 */
  updatedAt: Scalars['DateTime'];
};

/** 트리카테고리 */
export type TreeLessonCategory = {
  __typename?: 'TreeLessonCategory';
  /** 카테고리 이름 */
  children?: Maybe<Array<TreeLessonCategory>>;
  /** 생성 날짜/시간 */
  createdAt: Scalars['DateTime'];
  /** 삭제 날짜/시간 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** 기본 키(UUID) */
  id: Scalars['ID'];
  /** 카테고리 이름 */
  name?: Maybe<Scalars['String']>;
  /** 카테고리 이름 */
  parent?: Maybe<TreeLessonCategory>;
  /** 순서 */
  priority?: Maybe<Scalars['Float']>;
  /** 수정 날짜/시간 */
  updatedAt: Scalars['DateTime'];
};

/** 유저 */
export type User = {
  __typename?: 'User';
  /** 계정 탈퇴 관리자 메모 */
  accountDeleteAdminMemo?: Maybe<Scalars['String']>;
  /** 계정 탈퇴 사유 */
  accountDeleteReason?: Maybe<Scalars['String']>;
  /** 관리자 역할 */
  adminRole?: Maybe<AdminRole>;
  /** 광고 푸시 알림 동의 */
  advertisementPushAgreement?: Maybe<Scalars['Boolean']>;
  /** 광고 푸시 알림 동의 변경일 */
  advertisementPushAgreementUpdatedAt?: Maybe<Scalars['DateTime']>;
  bankAccount?: Maybe<BankAccount>;
  /** 생년월일 */
  birthDate?: Maybe<Scalars['DateTime']>;
  /** 차단한 사용자 */
  blockedUsers?: Maybe<Array<User>>;
  bookCart?: Maybe<Array<BookCart>>;
  /** 구매한 교재 수 */
  bookPurchaseNumber?: Maybe<Scalars['Float']>;
  /** 교재 즐겨찾기 수 */
  bookmarkedBookNumber?: Maybe<Scalars['Float']>;
  /** 교재 즐겨찾기 */
  bookmarkedBooks?: Maybe<Array<Book>>;
  /** 선생님 즐겨찾기 수 */
  bookmarkedTeacherNumber?: Maybe<Scalars['Float']>;
  /** 선생님 즐겨찾기 */
  bookmarkedTeachers?: Maybe<Array<Teacher>>;
  /** 내 주문 목록 */
  businesses?: Maybe<Array<Business>>;
  /** 정산 정보 */
  calculate?: Maybe<TeacherCalculate>;
  /** 캐쉬 히스토리 */
  cashHistory?: Maybe<Array<Cash>>;
  /** 진행 완료 수업수 */
  completedLessonNumber?: Maybe<Scalars['Float']>;
  /** 국가 */
  country?: Maybe<Scalars['String']>;
  /** 생성 날짜/시간 */
  createdAt: Scalars['DateTime'];
  /** 삭제 날짜/시간 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** 시법수업 진행수 */
  demonstrationLessonNumber?: Maybe<Scalars['Float']>;
  /** 이메일 */
  email?: Maybe<Scalars['String']>;
  /** 이름 */
  firstName?: Maybe<Scalars['String']>;
  /** 2만원 이상 최초 결제 일자 */
  firstPurchasedDate?: Maybe<Scalars['DateTime']>;
  /** 성별 */
  gender?: Maybe<GenderEnumType>;
  /** 보호자 */
  guardian?: Maybe<User>;
  /** 로그인한 사용자를 중심으로 작동. 값이 sent라면 로그인한 사용자가 요청을 보낸것임 */
  guardianRequest?: Maybe<GuardianRequest>;
  /**
   * 로그인한 사용자를 중심으로 작동. 값이 sent라면 로그인한 사용자가 요청을 보낸것임
   * @deprecated guardianRequest로 변경. {guardian id + state }
   */
  guardianRequestState?: Maybe<GuardianRequestStateEnumType>;
  /** 내가 받은 보호자 요청 */
  guardianRequestee?: Maybe<Array<UserGuardian>>;
  /** 나의 보호자 요청 */
  guardianRequester?: Maybe<Array<UserGuardian>>;
  /** 보호자/피보호자 여부, 둘 다 아니면 null */
  guardianType?: Maybe<GuardianType>;
  /** 기본 키(UUID) */
  id: Scalars['ID'];
  /** 미완료 수업수 */
  incompleteLessonNumber?: Maybe<Scalars['Float']>;
  /** 관심 교재 카테고리 */
  interestBookCategory1?: Maybe<BookCategory>;
  /** 관심 교재 카테고리 */
  interestBookCategory2?: Maybe<BookCategory>;
  /** 관심 교재 카테고리 */
  interestBookCategory3?: Maybe<BookCategory>;
  /** 관심 수업 카테고리 */
  interestLessonCategory1?: Maybe<LessonCategory>;
  /** 관심 수업 카테고리 */
  interestLessonCategory2?: Maybe<LessonCategory>;
  /** 관심 수업 카테고리 */
  interestLessonCategory3?: Maybe<LessonCategory>;
  /** 내가 초대한 가입자 숫자 */
  inviteCount?: Maybe<Scalars['Float']>;
  /** 추천인 중 2만원 이상 결제한 수 */
  invitePurchasedCount?: Maybe<Scalars['Float']>;
  /** 날 초대한 회원 */
  invitee?: Maybe<User>;
  /** 내가 초대한 회원들 */
  inviter?: Maybe<Array<User>>;
  /** 해당 사용자가 나에게 신고를 당했는지 여부 */
  isReportedByMe?: Maybe<Scalars['Boolean']>;
  /** 선생이 학생을 신고했는지 여부 */
  isTeacherReportStudent?: Maybe<Scalars['Boolean']>;
  /** 가입날짜 */
  joinDate?: Maybe<Scalars['DateTime']>;
  /** 마지막 들은 수업명 */
  lastLessonName?: Maybe<Scalars['String']>;
  /** 최근 수업 구매일자 */
  lastLessonPurchaseDate?: Maybe<Scalars['DateTime']>;
  /** 마지막 로그인 */
  lastLogin?: Maybe<Scalars['DateTime']>;
  /** 성 */
  lastName?: Maybe<Scalars['String']>;
  /** 최근 구매 수업 카테고리명 */
  lastPurchasedLessonCategoryName?: Maybe<Scalars['String']>;
  /** 최근 구매 수업명 */
  lastPurchasedLessonName?: Maybe<Scalars['String']>;
  /** 최근 30일 수업 구매 횟수 */
  lastThirtyDaysLessonPurchaseNumber?: Maybe<Scalars['Float']>;
  /** 학생 지각 횟수 */
  latenessNumber?: Maybe<Scalars['Float']>;
  /** 학습자격(재학생,대학교졸업,대학원수료,대학원졸업,전문강사,일반인) */
  learningCheck?: Maybe<Scalars['String']>;
  /** 수업 구매 횟수 */
  lessonBusinessCount?: Maybe<Scalars['Float']>;
  /**
   * 오늘 정산 금액
   * @deprecated calculate로 이동
   */
  lessonCalculate?: Maybe<Scalars['Float']>;
  /** 최근 수업일 */
  lessonDate?: Maybe<Scalars['DateTime']>;
  /** 현금성 캐쉬 */
  mainCash?: Maybe<Scalars['Float']>;
  /** 학습주카테고리/언어주카테고리 */
  mainCategory?: Maybe<Scalars['String']>;
  /**
   * 한달 정산 금액
   * @deprecated calculate로 이동
   */
  monthLessonCalculate?: Maybe<Scalars['Float']>;
  /** 이번 달 누적 수업시간 */
  monthlyCumulativeLessonHours?: Maybe<Scalars['Float']>;
  /** 이번 달 총 숙제 수 */
  monthlyHomeworkNumber?: Maybe<Scalars['Float']>;
  /** 이번 달 미완료 숙제 수 */
  monthlyInCompletedHomeworkNumber?: Maybe<Scalars['Float']>;
  /** 보호자/피보호자 정보 */
  myGuardians?: Maybe<Array<MyGuardians>>;
  /** 나의 선생님 */
  myTeacher?: Maybe<Array<Teacher>>;
  /** 나의 선생님 수 */
  myTeacherCount?: Maybe<Scalars['Float']>;
  /** 전체 이름 */
  name?: Maybe<Scalars['String']>;
  /** 제휴회원체크 */
  partnershipCheck?: Maybe<Scalars['Boolean']>;
  /** 2만원이상 결제 일자 */
  paymentAt?: Maybe<Scalars['DateTime']>;
  /** 결제내역 */
  payments?: Maybe<Array<Payment>>;
  /** 정지 해제일 */
  penaltyReleasedDate?: Maybe<Scalars['DateTime']>;
  /** 핸드폰 전화번호 */
  phone?: Maybe<Scalars['String']>;
  /** 개인정보처리방침 동의 */
  privacyPolicyAgreedAt?: Maybe<Scalars['DateTime']>;
  /** 내가 처리한 신고 목록 */
  processedReportList?: Maybe<Array<Report>>;
  /** 프로필 파일 */
  profileFile?: Maybe<File>;
  /** 피보호자 */
  protege?: Maybe<Array<User>>;
  /** 로그인한 사용자를 중심으로 작동. 값이 sent라면 로그인한 사용자가 요청을 보낸것임 */
  protegeRequest?: Maybe<GuardianRequest>;
  /**
   * 로그인한 사용자를 중심으로 작동. 값이 sent라면 로그인한 사용자가 요청을 보낸것임
   * @deprecated protegeRequest로 변경. {protege id + state }
   */
  protegeRequestState?: Maybe<GuardianRequestStateEnumType>;
  /** 출판사 */
  publisher?: Maybe<Publisher>;
  purchasedBookCount?: Maybe<Scalars['Float']>;
  /** 수업정보 및 서비스정보 수신 동의 */
  receivingClassAndServiceInformationAgreement?: Maybe<Scalars['Boolean']>;
  /** 수업정보 및 서비스정보 수신 동의 변경일 */
  receivingClassAndServiceInformationAgreementUpdatedAt?: Maybe<Scalars['DateTime']>;
  /** sms 수업정보 수신 동의 */
  receivingClassInformationToSmsAgreement?: Maybe<Scalars['Boolean']>;
  /** sms 수업정보 수신 동의 변경일 */
  receivingClassInformationToSmsAgreementUpdatedAt?: Maybe<Scalars['DateTime']>;
  /** 이메일 광고 및 이벤트 정보 수신 동의 */
  receivingEventInformationAgreement?: Maybe<Scalars['Boolean']>;
  /** 이메일 광고 및 이벤트 정보 수신 동의 변경일 */
  receivingEventInformationAgreementUpdatedAt?: Maybe<Scalars['DateTime']>;
  /** 가장 마지막 재제 */
  recentSanction?: Maybe<Report>;
  refundHistory?: Maybe<Array<RefundHistory>>;
  /** 내가 신고한 목록 */
  reportList?: Maybe<Array<Report>>;
  /** 내가 신고당한 목록 */
  reported?: Maybe<Array<Report>>;
  /** 권한 (학생,선생님,학부모 등) */
  role?: Maybe<UserRole>;
  /** 수업 만족도(평균 평점) */
  satisfaction?: Maybe<Scalars['Float']>;
  /** 진행 대기 수업수 */
  scheduledLessonNumber?: Maybe<Scalars['Float']>;
  /** 서비스(카카오,구글 */
  service?: Maybe<UserSocialServiceType>;
  /** 서비스 푸시 알림 동의 */
  servicePushAgreement?: Maybe<Scalars['Boolean']>;
  /** 서비스 푸시 알림 동의 변경일 */
  servicePushAgreementUpdatedAt?: Maybe<Scalars['DateTime']>;
  /** 상태 (활성화,비활성화,탈퇴) */
  state?: Maybe<UserState>;
  /** 비현금성 캐쉬 */
  subCash?: Maybe<Scalars['Float']>;
  /** 학습부카테고리/언어부카테고리 */
  subCategory?: Maybe<Scalars['String']>;
  /** 내가 수강하는 수업 목록 */
  subLessons?: Maybe<Array<SubLesson>>;
  /** 연결된 선생님 */
  teacher?: Maybe<Teacher>;
  /** 해당 선생님 수업 구매수 */
  teacherLessonCount?: Maybe<Scalars['String']>;
  /** 이용약관 동의 */
  termsOfServiceAgreedAt?: Maybe<Scalars['DateTime']>;
  /** 약관 동의 리스트 */
  termsOfServiceAgreements?: Maybe<Array<TermsOfServiceAgreement>>;
  /**
   * 내일 정산 예상 금액
   * @deprecated calculate로 이동
   */
  tomorrowLessonCalculate?: Maybe<Scalars['Float']>;
  /** 초,중,고 등 etc 구분 */
  type?: Maybe<UserType>;
  /** 수정 날짜/시간 */
  updatedAt: Scalars['DateTime'];
  /** 받은 수업 태도 평점 평균 */
  usergpaAverage?: Maybe<Scalars['Float']>;
  /** 받은 수업 태도 리뷰 수 */
  usergpaCount?: Maybe<Scalars['Float']>;
  /**
   * 한주 정산 금액
   * @deprecated calculate로 이동
   */
  weekLessonCalculate?: Maybe<Scalars['Float']>;
  /** 이번 주 누적 수업시간 */
  weeklyCumulativeLessonHours?: Maybe<Scalars['Float']>;
};


/** 유저 */
export type UserCashHistoryArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<DefaultFilterInput>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<UserCashOrderByInput>>;
};

export type UserBlocking = {
  __typename?: 'UserBlocking';
  /** 차단당한 사람 */
  blockedUser: User;
  /** 차단한 사람 */
  blockingUser: User;
  /** 생성 날짜/시간 */
  createdAt: Scalars['DateTime'];
  /** 삭제 날짜/시간 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** 기본 키(UUID) */
  id: Scalars['ID'];
  /** 수정 날짜/시간 */
  updatedAt: Scalars['DateTime'];
};

export type UserBlockingEdge = {
  __typename?: 'UserBlockingEdge';
  /** 커서 */
  cursor: Scalars['String'];
  /** 노드 */
  node: UserBlocking;
};

export type UserBlockingList = {
  __typename?: 'UserBlockingList';
  /** 페이지네이션된 데이터 목록 */
  edges: Array<Maybe<UserBlockingEdge>>;
  /** 페이지네이션된 페이지 정보 */
  pageInfo: PageInfo;
  /** 전체 데이터 개 수 */
  totalCount: Scalars['Int'];
};

/** 캐시 정렬 */
export type UserCashOrderByInput = {
  /** 생성일 */
  cashHistory__createdAt?: InputMaybe<SortInput>;
};

/** 유저 생성 */
export type UserCreateInput = {
  /** 계정 탈퇴 관리자 메모 */
  accountDeleteAdminMemo?: InputMaybe<Scalars['String']>;
  /** 계정 탈퇴 사유 */
  accountDeleteReason?: InputMaybe<Scalars['String']>;
  /** 광고 푸시 알림 동의 */
  advertisementPushAgreement?: InputMaybe<Scalars['Boolean']>;
  /** 광고 푸시 알림 동의 변경일 */
  advertisementPushAgreementUpdatedAt?: InputMaybe<Scalars['DateTime']>;
  /** 생년월일 */
  birthDate?: InputMaybe<Scalars['DateTime']>;
  /** 국가 */
  country?: InputMaybe<Scalars['String']>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  /** 이메일 */
  email?: InputMaybe<Scalars['String']>;
  /** 이름 */
  firstName?: InputMaybe<Scalars['String']>;
  /** 2만원 이상 최초 결제 일자 */
  firstPurchasedDate?: InputMaybe<Scalars['DateTime']>;
  /** 성별 */
  gender?: InputMaybe<GenderEnumType>;
  /** 보호자/피보호자 여부, 둘 다 아니면 null */
  guardianType?: InputMaybe<GuardianType>;
  /** 기본 키(UUID) */
  id?: InputMaybe<Scalars['ID']>;
  /** 내가 초대한 가입자 숫자 */
  inviteCount?: InputMaybe<Scalars['Float']>;
  /** 추천인 중 2만원 이상 결제한 수 */
  invitePurchasedCount?: InputMaybe<Scalars['Float']>;
  /** 마지막 로그인 */
  lastLogin?: InputMaybe<Scalars['DateTime']>;
  /** 성 */
  lastName?: InputMaybe<Scalars['String']>;
  /** 학습자격(재학생,대학교졸업,대학원수료,대학원졸업,전문강사,일반인) */
  learningCheck?: InputMaybe<Scalars['String']>;
  /** 학습주카테고리/언어주카테고리 */
  mainCategory?: InputMaybe<Scalars['String']>;
  /** 전체 이름 */
  name?: InputMaybe<Scalars['String']>;
  /** 제휴회원체크 */
  partnershipCheck?: InputMaybe<Scalars['Boolean']>;
  /** 비밀번호 */
  password?: InputMaybe<Scalars['Password']>;
  /** 2만원이상 결제 일자 */
  paymentAt?: InputMaybe<Scalars['DateTime']>;
  /** 정지 해제일 */
  penaltyReleasedDate?: InputMaybe<Scalars['DateTime']>;
  /** 핸드폰 전화번호 */
  phone?: InputMaybe<Scalars['String']>;
  /** 개인정보처리방침 동의 */
  privacyPolicyAgreedAt?: InputMaybe<Scalars['DateTime']>;
  /** 아바타 파일 ID */
  profileFile__id?: InputMaybe<Scalars['ID']>;
  /** 수업정보 및 서비스정보 수신 동의 */
  receivingClassAndServiceInformationAgreement?: InputMaybe<Scalars['Boolean']>;
  /** 수업정보 및 서비스정보 수신 동의 변경일 */
  receivingClassAndServiceInformationAgreementUpdatedAt?: InputMaybe<Scalars['DateTime']>;
  /** sms 수업정보 수신 동의 */
  receivingClassInformationToSmsAgreement?: InputMaybe<Scalars['Boolean']>;
  /** sms 수업정보 수신 동의 변경일 */
  receivingClassInformationToSmsAgreementUpdatedAt?: InputMaybe<Scalars['DateTime']>;
  /** 이메일 광고 및 이벤트 정보 수신 동의 */
  receivingEventInformationAgreement?: InputMaybe<Scalars['Boolean']>;
  /** 이메일 광고 및 이벤트 정보 수신 동의 변경일 */
  receivingEventInformationAgreementUpdatedAt?: InputMaybe<Scalars['DateTime']>;
  /** 권한 (학생,선생님,학부모 등) */
  role?: InputMaybe<UserRole>;
  /** 서비스(카카오,구글 */
  service?: InputMaybe<UserSocialServiceType>;
  /** 서비스 푸시 알림 동의 */
  servicePushAgreement?: InputMaybe<Scalars['Boolean']>;
  /** 서비스 푸시 알림 동의 변경일 */
  servicePushAgreementUpdatedAt?: InputMaybe<Scalars['DateTime']>;
  /** 학습부카테고리/언어부카테고리 */
  subCategory?: InputMaybe<Scalars['String']>;
  /** 이용약관 동의 */
  termsOfServiceAgreedAt?: InputMaybe<Scalars['DateTime']>;
  /** 초,중,고 등 etc 구분 */
  type?: InputMaybe<UserType>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserEdge = {
  __typename?: 'UserEdge';
  /** 커서 */
  cursor: Scalars['String'];
  /** 노드 */
  node: User;
};

/** 유저 필터 */
export type UserFilterInput = {
  /** 계정 탈퇴 사유 */
  accountDeleteReason?: InputMaybe<Array<StringFilterInput>>;
  /** 권한관리 */
  adminRole__authorizationManagement?: InputMaybe<Array<AdminRoleEnumFilter>>;
  /** 교재관리 */
  adminRole__bookManagement?: InputMaybe<Array<AdminRoleEnumFilter>>;
  /** 정산관리 */
  adminRole__calculationManagement?: InputMaybe<Array<AdminRoleEnumFilter>>;
  /** 채팅상담권한 */
  adminRole__chatCounselling?: InputMaybe<Array<BooleanFilterInput>>;
  /** 디자인관리 */
  adminRole__designManagement?: InputMaybe<Array<AdminRoleEnumFilter>>;
  /** 문의권한 */
  adminRole__faq?: InputMaybe<Array<BooleanFilterInput>>;
  /** 수업관리 */
  adminRole__lessonManagement?: InputMaybe<Array<AdminRoleEnumFilter>>;
  /** 운영관리 */
  adminRole__operationManagement?: InputMaybe<Array<AdminRoleEnumFilter>>;
  /** 승인요청관리 */
  adminRole__requestManagement?: InputMaybe<Array<AdminRoleEnumFilter>>;
  /** 통계관리 */
  adminRole__statisticManagement?: InputMaybe<Array<AdminRoleEnumFilter>>;
  /** 사용자관리 */
  adminRole__userManagement?: InputMaybe<Array<AdminRoleEnumFilter>>;
  /** 광고 푸시 알림 동의 */
  advertisementPushAgreement?: InputMaybe<Array<BooleanFilterInput>>;
  /** 광고 푸시 알림 동의 변경일 */
  advertisementPushAgreementUpdatedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 생년월일 */
  birthDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 주문 상태 */
  businesses__state?: InputMaybe<Array<OrderStateEnumFilter>>;
  /** 캐시 지급 사유 */
  cashHistory__reason?: InputMaybe<Array<CashReasonEnumFilter>>;
  /** 국가 */
  country?: InputMaybe<Array<StringFilterInput>>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 이메일 */
  email?: InputMaybe<Array<StringFilterInput>>;
  /** 2만원 이상 최초 결제 일시 */
  firstPurchasedDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 성별 */
  gender?: InputMaybe<Array<UserGenderEnumFilter>>;
  /** 보호자/피보호자 여부, 둘 다 아니면 null */
  guardianType?: InputMaybe<Array<GuardianTypeEnumFilter>>;
  /** 보호자명 */
  guardian__name?: InputMaybe<Array<StringFilterInput>>;
  /** UUID */
  id?: InputMaybe<Array<IdFilterInput>>;
  /** 나를 초대한 제휴 회원 */
  invitee__id?: InputMaybe<Array<IdFilterInput>>;
  /** 내가 초대한 제휴 회원 */
  inviter__id?: InputMaybe<Array<IdFilterInput>>;
  /** 가입날짜 */
  joinDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 언어자격 아이디 */
  languageQualificationHistory__id?: InputMaybe<Array<IdFilterInput>>;
  /** 언어 자격 */
  languageQualificationHistory__qualification?: InputMaybe<Array<StringFilterInput>>;
  /** 언어자격 상태 */
  languageQualificationHistory__state?: InputMaybe<Array<TeacherLanguageStateEnumFilter>>;
  /** 마지막 로그인 */
  lastLogin?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 학습자격(재학생,대학교졸업,대학원수료,대학원졸업,전문강사,일반인) */
  learningCheck?: InputMaybe<Array<StringFilterInput>>;
  /** 학습자격 아이디 */
  learningQualificationHistory__id?: InputMaybe<Array<IdFilterInput>>;
  /** 학습 자격 */
  learningQualificationHistory__requestQualification?: InputMaybe<Array<StringFilterInput>>;
  /** 학습자격상태 */
  learningQualificationHistory__state?: InputMaybe<Array<TeacherLearningStateEnumFilter>>;
  /** 최근 수업일 */
  lessonDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 현금성 캐쉬 */
  mainCash?: InputMaybe<Array<StringFilterInput>>;
  /** 학습주카테고리/언어주카테고리 */
  mainCategory?: InputMaybe<Array<StringFilterInput>>;
  /** 전체 이름 */
  name?: InputMaybe<Array<StringFilterInput>>;
  /** 제휴회원체크 */
  partnershipCheck?: InputMaybe<Array<BooleanFilterInput>>;
  /** 정지 해제일 */
  penaltyReleasedDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 핸드폰 전화번호 */
  phone?: InputMaybe<Array<StringFilterInput>>;
  /** 개인정보처리방침 동의 */
  privacyPolicyAgreedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 수업정보 및 서비스정보 수신 동의 */
  receivingClassAndServiceInformationAgreement?: InputMaybe<Array<BooleanFilterInput>>;
  /** 수업정보 및 서비스정보 수신 동의 변경일 */
  receivingClassAndServiceInformationAgreementUpdatedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** sms 수업정보 수신 동의 */
  receivingClassInformationToSmsAgreement?: InputMaybe<Array<BooleanFilterInput>>;
  /** sms 수업정보 수신 동의 변경일 */
  receivingClassInformationToSmsAgreementUpdatedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 이메일 광고 및 이벤트 정보 수신 동의 */
  receivingEventInformationAgreement?: InputMaybe<Array<BooleanFilterInput>>;
  /** 이메일 광고 및 이벤트 정보 수신 동의 변경일 */
  receivingEventInformationAgreementUpdatedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 내가 받은 신고 id */
  reported__id?: InputMaybe<Array<IdFilterInput>>;
  /** 내가 받은 재제 항목 */
  reported__sanction?: InputMaybe<Array<SanctionEnumFilter>>;
  /** 내가 받은 신고 상태 */
  reported__state?: InputMaybe<Array<ReportStateEnumFilter>>;
  /** 권한 (학생,선생님,학부모 등) */
  role?: InputMaybe<Array<UserRoleEnumFilter>>;
  /** 유저 가입 소셜 타입 */
  service?: InputMaybe<Array<UserSocialServiceEnumFilter>>;
  /** 서비스 푸시 알림 동의 */
  servicePushAgreement?: InputMaybe<Array<BooleanFilterInput>>;
  /** 서비스 푸시 알림 동의 변경일 */
  servicePushAgreementUpdatedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 상태 (활성화,비활성화,탈퇴) */
  state?: InputMaybe<Array<UserStateEnumFilter>>;
  /** 비현금성 캐쉬 */
  subCash?: InputMaybe<Array<StringFilterInput>>;
  /** 학습부카테고리/언어부카테고리 */
  subCategory?: InputMaybe<Array<StringFilterInput>>;
  /** 수업 종료 시간 */
  subLessons__lessonCloseAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 수업 상태 */
  subLessons__state?: InputMaybe<Array<SubLessonStateEnumFilter>>;
  /** 사업자 등록 번호 */
  teacher__businessRegistrationNumber?: InputMaybe<Array<StringFilterInput>>;
  /** 학습 자격 */
  teacher__education?: InputMaybe<Array<TeacherEducationEnumFilter>>;
  /** 최초 수업일 */
  teacher__firstLessonDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 구글밋 수수료율 */
  teacher__googleMeetFeeRate?: InputMaybe<Array<IntFilterInput>>;
  /** 누적 평점 */
  teacher__gpa?: InputMaybe<Array<IntFilterInput>>;
  /** 소개글 */
  teacher__introductionText?: InputMaybe<Array<StringFilterInput>>;
  /** 사업자 여부 */
  teacher__isBusinessProprietor?: InputMaybe<Array<BooleanFilterInput>>;
  /** 신원 인증 여부 */
  teacher__isIdentityVerified?: InputMaybe<Array<BooleanFilterInput>>;
  /** 수업 진행 횟수 */
  teacher__lessonsNumber?: InputMaybe<Array<IntFilterInput>>;
  /** 소재지 */
  teacher__location?: InputMaybe<Array<StringFilterInput>>;
  /** 언어 주 1 */
  teacher__mainLanguageA?: InputMaybe<Array<StringFilterInput>>;
  /** 언어 주 1 레벨 */
  teacher__mainLanguageALevel?: InputMaybe<Array<TeacherLanguageLevelEnumFilter>>;
  /** 언어 주 2 */
  teacher__mainLanguageB?: InputMaybe<Array<StringFilterInput>>;
  /** 언어 주 2 레벨 */
  teacher__mainLanguageBLevel?: InputMaybe<Array<TeacherLanguageLevelEnumFilter>>;
  /** 전공 */
  teacher__major?: InputMaybe<Array<StringFilterInput>>;
  /** 출신 국가 */
  teacher__originCountry?: InputMaybe<Array<StringFilterInput>>;
  /** 페이지콜수수료율 */
  teacher__pageCallFeeRate?: InputMaybe<Array<IntFilterInput>>;
  /** 대표자명 */
  teacher__representativeName?: InputMaybe<Array<StringFilterInput>>;
  /** 거주 국가 */
  teacher__residenceCountry?: InputMaybe<Array<StringFilterInput>>;
  /** 선생님 등록, 신청 상태 */
  teacher__state?: InputMaybe<Array<TeacherStateEnumFilter>>;
  /** 수업 진행 학생수 */
  teacher__studentsNumber?: InputMaybe<Array<IntFilterInput>>;
  /** 언어 부 1 */
  teacher__subLanguageA?: InputMaybe<Array<StringFilterInput>>;
  /** 언어 부 1 레벨 */
  teacher__subLanguageALevel?: InputMaybe<Array<TeacherLanguageLevelEnumFilter>>;
  /** 언어 부 2 */
  teacher__subLanguageB?: InputMaybe<Array<StringFilterInput>>;
  /** 언어 부 2 레벨 */
  teacher__subLanguageBLevel?: InputMaybe<Array<TeacherLanguageLevelEnumFilter>>;
  /** 학습 과목 */
  teacher__subject?: InputMaybe<Array<TeacherSubjectArrayFilter>>;
  /** 선생님 등록 거절, 보완 메시지 */
  teacher__teacherMemo?: InputMaybe<Array<StringFilterInput>>;
  /** 학습 대상 */
  teacher__teachingTarget?: InputMaybe<Array<StringFilterInput>>;
  /** 대학교 */
  teacher__university?: InputMaybe<Array<StringFilterInput>>;
  /** 이용약관 동의 */
  termsOfServiceAgreedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 초,중,고 등 etc 구분 */
  type?: InputMaybe<Array<UserTypeEnumFilter>>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Array<DateTimeFilterInput>>;
};

/** 성별 타입 */
export type UserGenderEnumFilter = {
  operator: EnumFilterOperators;
  value?: InputMaybe<GenderEnumType>;
  values?: InputMaybe<Array<GenderEnumType>>;
};

/** 보호자 */
export type UserGuardian = {
  __typename?: 'UserGuardian';
  /** 생성 날짜/시간 */
  createdAt: Scalars['DateTime'];
  /** 삭제 날짜/시간 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** 보호자 */
  guardian?: Maybe<User>;
  /** 기본 키(UUID) */
  id: Scalars['ID'];
  /** 피보호자 */
  protege?: Maybe<User>;
  /** 요청받은 사람 */
  requestee?: Maybe<User>;
  /** 요청한 사람 */
  requester?: Maybe<User>;
  /** 상태 */
  state?: Maybe<GuardianState>;
  /** 수정 날짜/시간 */
  updatedAt: Scalars['DateTime'];
};

/** 보호자 생성 */
export type UserGuardianCreateInput = {
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  /** 보호자 */
  guardian__id?: InputMaybe<Scalars['ID']>;
  /** 기본 키(UUID) */
  id?: InputMaybe<Scalars['ID']>;
  /** 피보호자 */
  protege__id?: InputMaybe<Scalars['ID']>;
  /** 피요청자 */
  requestee__id?: InputMaybe<Scalars['ID']>;
  /** 요청자 */
  requester__id?: InputMaybe<Scalars['ID']>;
  /** 상태 */
  state?: InputMaybe<GuardianState>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserGuardianEdge = {
  __typename?: 'UserGuardianEdge';
  /** 커서 */
  cursor: Scalars['String'];
  /** 노드 */
  node: UserGuardian;
};

/** 보호자 필터 */
export type UserGuardianFilterInput = {
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 보호자 이메일 */
  guardian__email?: InputMaybe<Array<StringFilterInput>>;
  /** 보호자 ID */
  guardian__id?: InputMaybe<Array<IdFilterInput>>;
  /** 보호자 이름 */
  guardian__name?: InputMaybe<Array<StringFilterInput>>;
  /** UUID */
  id?: InputMaybe<Array<IdFilterInput>>;
  /** 피보호자 이메일 */
  protege__email?: InputMaybe<Array<StringFilterInput>>;
  /** 피보호자 ID */
  protege__id?: InputMaybe<Array<IdFilterInput>>;
  /** 피보호자 이름 */
  protege__name?: InputMaybe<Array<StringFilterInput>>;
  /** 피요청자 이메일 */
  requestee__email?: InputMaybe<Array<StringFilterInput>>;
  /** 피요청자 ID */
  requestee__id?: InputMaybe<Array<IdFilterInput>>;
  /** 피요청자 이름 */
  requestee__name?: InputMaybe<Array<StringFilterInput>>;
  /** 요청자 이메일 */
  requester__email?: InputMaybe<Array<StringFilterInput>>;
  /** 요청자 ID */
  requester__id?: InputMaybe<Array<IdFilterInput>>;
  /** 요청자 이름 */
  requester__name?: InputMaybe<Array<StringFilterInput>>;
  /** 보호자 상태 필터 */
  state?: InputMaybe<Array<GuardianStateEnumFilter>>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Array<DateTimeFilterInput>>;
};

/** 보호자 리스트 */
export type UserGuardianList = {
  __typename?: 'UserGuardianList';
  /** 페이지네이션된 데이터 목록 */
  edges: Array<Maybe<UserGuardianEdge>>;
  /** 페이지네이션된 페이지 정보 */
  pageInfo: PageInfo;
  /** 전체 데이터 개 수 */
  totalCount: Scalars['Int'];
};

/** 보호자 정렬 */
export type UserGuardianOrderByInput = {
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<SortInput>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<SortInput>;
  /** 보호자 이메일 */
  guardian__email?: InputMaybe<SortInput>;
  /** 보호자 이름 */
  guardian__name?: InputMaybe<SortInput>;
  /** UUID */
  id?: InputMaybe<SortInput>;
  /** 피보호자 이메일 */
  protege__email?: InputMaybe<SortInput>;
  /** 피보호자 이름 */
  protege__name?: InputMaybe<SortInput>;
  /** 피요청자 이메일 */
  requestee__email?: InputMaybe<SortInput>;
  /** 피요청자 이름 */
  requestee__name?: InputMaybe<SortInput>;
  /** 요청자 이메일 */
  requester__email?: InputMaybe<SortInput>;
  /** 요청자 이름 */
  requester__name?: InputMaybe<SortInput>;
  /** 보호자 상태 정렬 */
  state?: InputMaybe<SortInput>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<SortInput>;
};

/** 보호자 생성 */
export type UserGuardianUpdateInput = {
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  /** 보호자 */
  guardian__id?: InputMaybe<Scalars['ID']>;
  /** 기본 키(UUID) */
  id?: InputMaybe<Scalars['ID']>;
  /** 피보호자 */
  protege__id?: InputMaybe<Scalars['ID']>;
  /** 피요청자 */
  requestee__id?: InputMaybe<Scalars['ID']>;
  /** 요청자 */
  requester__id?: InputMaybe<Scalars['ID']>;
  /** 상태 */
  state?: InputMaybe<GuardianState>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

/** 추천 친구 */
export type UserInvite = {
  __typename?: 'UserInvite';
  /** 제휴/초대 정산 아이템 */
  calculateItem?: Maybe<PartnershipCalculateItem>;
  /** 생성 날짜/시간 */
  createdAt: Scalars['DateTime'];
  /** 삭제 날짜/시간 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** 기본 키(UUID) */
  id: Scalars['ID'];
  /** 초대받은 유저 */
  invitee?: Maybe<User>;
  /**
   * 가입 캐시
   * @deprecated 기획 수정으로 미사용
   */
  inviteeCash?: Maybe<Cash>;
  /** 초대한 유저 */
  inviter?: Maybe<User>;
  /**
   * 초대 캐시
   * @deprecated 기획 수정으로 미사용
   */
  inviterCash?: Maybe<Cash>;
  /** 수정 날짜/시간 */
  updatedAt: Scalars['DateTime'];
};

/** 추천 친구 생성 */
export type UserInviteCreateInput = {
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  /** 기본 키(UUID) */
  id?: InputMaybe<Scalars['ID']>;
  /** 초대받은 유저 ID */
  invitee__id: Scalars['ID'];
  /** 초대한 유저 ID */
  inviter__id: Scalars['ID'];
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserInviteEdge = {
  __typename?: 'UserInviteEdge';
  /** 커서 */
  cursor: Scalars['String'];
  /** 노드 */
  node: UserInvite;
};

/** 추천 친구 필터 */
export type UserInviteFilterInput = {
  /** 지급 캐시 */
  inviteeCash__amount?: InputMaybe<Array<IntFilterInput>>;
  /** 캐시 지급 날짜 */
  inviteeCash__createdAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 캐시 속성 */
  inviteeCash__property?: InputMaybe<Array<CashTypeEnumFilter>>;
  /** 캐시 지급 사유 */
  inviteeCash__reason?: InputMaybe<Array<CashReasonEnumFilter>>;
  /** 초대받은 유저 이메일 */
  invitee__email?: InputMaybe<Array<StringFilterInput>>;
  /** 초대받은 유저 ID */
  invitee__id?: InputMaybe<Array<IdFilterInput>>;
  /** 가입일시 */
  invitee__joinDate?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 초대받은 유저 이름 */
  invitee__name?: InputMaybe<Array<StringFilterInput>>;
  /** 초대받은 유저 파트너쉽 여부 */
  invitee__partnershipCheck?: InputMaybe<Array<BooleanFilterInput>>;
  /** 초대받은 유저 휴대폰 번호 */
  invitee__phone?: InputMaybe<Array<StringFilterInput>>;
  /** 지급 캐시 */
  inviterCash__amount?: InputMaybe<Array<IntFilterInput>>;
  /** 캐시 지급 날짜 */
  inviterCash__createdAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 캐시 속성 */
  inviterCash__property?: InputMaybe<Array<CashTypeEnumFilter>>;
  /** 캐시 지급 사유 */
  inviterCash__reason?: InputMaybe<Array<CashReasonEnumFilter>>;
  /** 초대한 유저 이메일 */
  inviter__email?: InputMaybe<Array<StringFilterInput>>;
  /** 초대한 유저 ID */
  inviter__id?: InputMaybe<Array<IdFilterInput>>;
  /** 초대한 유저 이름 */
  inviter__name?: InputMaybe<Array<StringFilterInput>>;
  /** 초대한 유저 파트너쉽 여부 */
  inviter__partnershipCheck?: InputMaybe<Array<BooleanFilterInput>>;
  /** 초대한 유저 휴대폰 번호 */
  inviter__phone?: InputMaybe<Array<StringFilterInput>>;
};

/** 추천 친구 리스트 */
export type UserInviteList = {
  __typename?: 'UserInviteList';
  /** 페이지네이션된 데이터 목록 */
  edges: Array<Maybe<UserInviteEdge>>;
  /** 페이지네이션된 페이지 정보 */
  pageInfo: PageInfo;
  /** 전체 데이터 개 수 */
  totalCount: Scalars['Int'];
};

/** 추천 친구 정렬 */
export type UserInviteOrderByInput = {
  /** id */
  id?: InputMaybe<SortInput>;
  /** 초대받은 유저 이메일 */
  invitee__email?: InputMaybe<SortInput>;
  /** 초대받은 유저 가입일시 */
  invitee__joinDate?: InputMaybe<SortInput>;
  /** 초대받은 유저 이름 */
  invitee__name?: InputMaybe<SortInput>;
  /** 초대받은 유저 휴대폰 번호 */
  invitee__phone?: InputMaybe<SortInput>;
  /** 초대한 유저 이메일 */
  inviter__email?: InputMaybe<SortInput>;
  /** 초대한 유저 가입일시 */
  inviter__joinDate?: InputMaybe<SortInput>;
  /** 초대한 유저 이름 */
  inviter__name?: InputMaybe<SortInput>;
  /** 초대한 유저 휴대폰 번호 */
  inviter__phone?: InputMaybe<SortInput>;
};

/** 추천 친구 수정 */
export type UserInviteUpdateInput = {
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  /** 기본 키(UUID) */
  id?: InputMaybe<Scalars['ID']>;
  /** 가입 캐시 ID */
  inviteeCash__id: Scalars['ID'];
  /** 초대받은 유저 ID */
  invitee__id?: InputMaybe<Scalars['ID']>;
  /** 초대 캐시 ID */
  inviterCash__id: Scalars['ID'];
  /** 초대한 유저 ID */
  inviter__id?: InputMaybe<Scalars['ID']>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

/** 유저 목록 */
export type UserList = {
  __typename?: 'UserList';
  /** 페이지네이션된 데이터 목록 */
  edges: Array<Maybe<UserEdge>>;
  /** 페이지네이션된 페이지 정보 */
  pageInfo: PageInfo;
  /** 전체 데이터 개 수 */
  totalCount: Scalars['Int'];
};

/** 유저 정렬 */
export type UserOrderByInput = {
  /** 계정 탈퇴 사유 */
  accountDeleteReason?: InputMaybe<SortInput>;
  /** 광고 푸시 알림 동의 */
  advertisementPushAgreement?: InputMaybe<SortInput>;
  /** 광고 푸시 알림 동의 변경일 */
  advertisementPushAgreementUpdatedAt?: InputMaybe<SortInput>;
  /** 생년월일 */
  birthDate?: InputMaybe<SortInput>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<SortInput>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<SortInput>;
  /** 이메일 */
  email?: InputMaybe<SortInput>;
  /** 성별 */
  gender?: InputMaybe<SortInput>;
  /** UUID */
  id?: InputMaybe<SortInput>;
  /** 내가 초대한 가입자 숫자 */
  inviteCount?: InputMaybe<SortInput>;
  /** 추천인 중 2만원 이상 결제한 수 */
  invitePurchasedCount?: InputMaybe<SortInput>;
  /** 가입순 */
  joinDate?: InputMaybe<SortInput>;
  /** 마지막 로그인 */
  lastLogin?: InputMaybe<SortInput>;
  /** 이름 */
  name?: InputMaybe<SortInput>;
  /** 정지 해제일 */
  penaltyReleasedDate?: InputMaybe<SortInput>;
  /** 휴대폰번호 */
  phone?: InputMaybe<SortInput>;
  /** 개인정보처리방침 동의 */
  privacyPolicyAgreedAt?: InputMaybe<SortInput>;
  /** 수업정보 및 서비스정보 수신 동의 */
  receivingClassAndServiceInformationAgreement?: InputMaybe<SortInput>;
  /** 수업정보 및 서비스정보 수신 동의 변경일 */
  receivingClassAndServiceInformationAgreementUpdatedAt?: InputMaybe<SortInput>;
  /** sms 수업정보 수신 동의 */
  receivingClassInformationToSmsAgreement?: InputMaybe<SortInput>;
  /** sms 수업정보 수신 동의 변경일 */
  receivingClassInformationToSmsAgreementUpdatedAt?: InputMaybe<SortInput>;
  /** 이메일 광고 및 이벤트 정보 수신 동의 */
  receivingEventInformationAgreement?: InputMaybe<SortInput>;
  /** 이메일 광고 및 이벤트 정보 수신 동의 변경일 */
  receivingEventInformationAgreementUpdatedAt?: InputMaybe<SortInput>;
  /** sns 가입 서비스 */
  service?: InputMaybe<SortInput>;
  /** 서비스 푸시 알림 동의 */
  servicePushAgreement?: InputMaybe<SortInput>;
  /** 서비스 푸시 알림 동의 변경일 */
  servicePushAgreementUpdatedAt?: InputMaybe<SortInput>;
  /** 상태 */
  state?: InputMaybe<SortInput>;
  /** 수업 종료 시간 */
  subLessons__lessonCloseAt?: InputMaybe<SortInput>;
  /** 수업 상태 */
  subLessons__state?: InputMaybe<SortInput>;
  /** 즐겨찾기순 */
  teacherSort__bookmarkCount?: InputMaybe<SortInput>;
  /** 구매순 */
  teacherSort__businessCount?: InputMaybe<SortInput>;
  /** 평점순(평점 평균) */
  teacherSort__gpaAvg?: InputMaybe<SortInput>;
  /** 리뷰순(리뷰수) */
  teacherSort__gpaCount?: InputMaybe<SortInput>;
  /** 인기순 */
  teacherSort__popular?: InputMaybe<SortInput>;
  /** 학습 자격 */
  teacher__education?: InputMaybe<SortInput>;
  /** 고등학교 */
  teacher__highSchool?: InputMaybe<SortInput>;
  /** 전공 */
  teacher__major?: InputMaybe<SortInput>;
  /** 대학교 */
  teacher__university?: InputMaybe<SortInput>;
  /** 이용약관 동의 */
  termsOfServiceAgreedAt?: InputMaybe<SortInput>;
  /** 사용자 유형(초,중,고...) */
  type?: InputMaybe<SortInput>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<SortInput>;
  /** 수업 구매 순 */
  userSort__lessonPurchaseNumber?: InputMaybe<SortInput>;
};

export enum UserRole {
  /** 일반관리자 */
  Admin = 'ADMIN',
  Member = 'MEMBER',
  /** 출판사 */
  Publisher = 'PUBLISHER',
  /** 학생 */
  Student = 'STUDENT',
  /** 슈퍼관리자 */
  Superadmin = 'SUPERADMIN',
  /** 선생님 */
  Teacher = 'TEACHER'
}

/** 유저 권한 */
export type UserRoleEnumFilter = {
  operator: EnumFilterOperators;
  value?: InputMaybe<UserRole>;
  values?: InputMaybe<Array<UserRole>>;
};

/** 사용자 회원가입 입력 데이터 */
export type UserSignUpInput = {
  /** 동의하는 약관 ids */
  agreedTermsOfService__ids?: InputMaybe<Array<Scalars['ID']>>;
  /** 생년월일 */
  birthDate?: InputMaybe<Scalars['DateTime']>;
  /** 이메일 */
  email: Scalars['String'];
  /** 이메일 인증 uuid */
  emailKey: Scalars['String'];
  /** 이름 */
  firstName: Scalars['String'];
  /** 성별 */
  gender?: InputMaybe<GenderEnumType>;
  /** 날 초대한 유저 id */
  inviter__id?: InputMaybe<Scalars['ID']>;
  /** 개인정보처리방침 동의 */
  isPrivacyPolicyAgreed?: InputMaybe<Scalars['Boolean']>;
  /** 수업정보 및 서비스정보 수신 동의 */
  isReceivingClassAndServiceInformationAgreed?: InputMaybe<Scalars['Boolean']>;
  /** sms 수업정보 수신 동의 */
  isReceivingClassInformationToSmsAgreed?: InputMaybe<Scalars['Boolean']>;
  /** 이메일 광고 및 이벤트 정보 수신 동의 */
  isReceivingEventInformationAgreed?: InputMaybe<Scalars['Boolean']>;
  /** 이용약관 동의 */
  isTermsOfServiceAgreed?: InputMaybe<Scalars['Boolean']>;
  /** 성 */
  lastName: Scalars['String'];
  /** 비밀번호 */
  password: Scalars['Password'];
  /** 전화번호 */
  phone: Scalars['String'];
  /** 전화번호 인증 uuid */
  phoneKey: Scalars['String'];
  /** 프로필파일 ID */
  profileFile__id?: InputMaybe<Scalars['ID']>;
  /** 사용자 유형 */
  type?: InputMaybe<UserType>;
};

/** 유저 가입 타입 */
export type UserSocialServiceEnumFilter = {
  operator: EnumFilterOperators;
  value?: InputMaybe<UserSocialServiceType>;
  values?: InputMaybe<Array<UserSocialServiceType>>;
};

/** 소셜 서비스 */
export enum UserSocialServiceType {
  /** 애플 */
  Apple = 'APPLE',
  /** 이메일 */
  Email = 'EMAIL',
  /** 페이스북 */
  Facebook = 'FACEBOOK',
  /** 구글 */
  Google = 'GOOGLE',
  /** 인스타그램 */
  Instagram = 'INSTAGRAM',
  /** 카카오 */
  Kakao = 'KAKAO',
  /** 네이버 */
  Naver = 'NAVER',
  /** 비메오 */
  Vimeo = 'VIMEO',
  /** 유튜브 */
  Youtube = 'YOUTUBE'
}

/** 사용자 소셜 회원가입 */
export type UserSocialSignUpInput = {
  /** 동의하는 약관 ids */
  agreedTermsOfService__ids?: InputMaybe<Array<Scalars['ID']>>;
  /** 생년월일 */
  birthDate?: InputMaybe<Scalars['DateTime']>;
  /** 이름 */
  firstName: Scalars['String'];
  /** 성별 */
  gender?: InputMaybe<GenderEnumType>;
  /** 날 초대한 유저 id */
  inviter__id?: InputMaybe<Scalars['ID']>;
  /** 개인정보처리방침 동의 */
  isPrivacyPolicyAgreed?: InputMaybe<Scalars['Boolean']>;
  /** 수업정보 및 서비스정보 수신 동의 */
  isReceivingClassAndServiceInformationAgreed?: InputMaybe<Scalars['Boolean']>;
  /** sms 수업정보 수신 동의 */
  isReceivingClassInformationToSmsAgreed?: InputMaybe<Scalars['Boolean']>;
  /** 이메일 광고 및 이벤트 정보 수신 동의 */
  isReceivingEventInformationAgreed?: InputMaybe<Scalars['Boolean']>;
  /** 이용약관 동의 */
  isTermsOfServiceAgreed?: InputMaybe<Scalars['Boolean']>;
  /** 성 */
  lastName: Scalars['String'];
  /** 전화번호 */
  phone: Scalars['String'];
  /** 전화번호 인증 uuid */
  phoneKey: Scalars['String'];
  /** 프로필파일 ID */
  profileFile__id?: InputMaybe<Scalars['ID']>;
  /** 사용자 유형 */
  type?: InputMaybe<UserType>;
};

export enum UserState {
  /** 활성화됨 */
  Active = 'ACTIVE',
  /** 비활성화됨 */
  Inactive = 'INACTIVE',
  /** 영구정지됨 */
  PermanentlyBanned = 'PERMANENTLY_BANNED',
  /** 탈퇴됨 */
  Quit = 'QUIT',
  /** 대기중 */
  Requesting = 'REQUESTING'
}

/** 유저 상태 */
export type UserStateEnumFilter = {
  operator: EnumFilterOperators;
  value?: InputMaybe<UserState>;
  values?: InputMaybe<Array<UserState>>;
};

export enum UserType {
  /** 유아 */
  Baby = 'BABY',
  /** 대학생 */
  College = 'COLLEGE',
  /** 초등학생 */
  Elementary = 'ELEMENTARY',
  /** 고등(예체능) */
  HighArt = 'HIGH_ART',
  /** 고등(공통) */
  HighCommon = 'HIGH_COMMON',
  /** 고등(문과) */
  HighLiberal = 'HIGH_LIBERAL',
  /** 고등(이과) */
  HighSciences = 'HIGH_SCIENCES',
  /** 고등(특성화) */
  HighSpecial = 'HIGH_SPECIAL',
  /** 중학생 */
  Middle = 'MIDDLE',
  /** 일반인 */
  Ordinary = 'ORDINARY'
}

/** 유저 타입 */
export type UserTypeEnumFilter = {
  operator: EnumFilterOperators;
  value?: InputMaybe<UserType>;
  values?: InputMaybe<Array<UserType>>;
};

/** 유저 업데이트 */
export type UserUpdateInput = {
  /** 계정 탈퇴 관리자 메모 */
  accountDeleteAdminMemo?: InputMaybe<Scalars['String']>;
  /** 계정 탈퇴 사유 */
  accountDeleteReason?: InputMaybe<Scalars['String']>;
  /** 광고 푸시 알림 동의 */
  advertisementPushAgreement?: InputMaybe<Scalars['Boolean']>;
  /** 광고 푸시 알림 동의 변경일 */
  advertisementPushAgreementUpdatedAt?: InputMaybe<Scalars['DateTime']>;
  /** 생년월일 */
  birthDate?: InputMaybe<Scalars['DateTime']>;
  /** 즐겨찾기한 교재 */
  bookmarkedBook__ids?: InputMaybe<Array<Scalars['ID']>>;
  /** 즐겨찾기한 선생님 id */
  bookmarkedTeacher__ids?: InputMaybe<Array<Scalars['ID']>>;
  /** 국가 */
  country?: InputMaybe<Scalars['String']>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  /** 이메일 */
  email?: InputMaybe<Scalars['String']>;
  /** 이름 */
  firstName?: InputMaybe<Scalars['String']>;
  /** 2만원 이상 최초 결제 일자 */
  firstPurchasedDate?: InputMaybe<Scalars['DateTime']>;
  /** 성별 */
  gender?: InputMaybe<GenderEnumType>;
  /** 보호자/피보호자 여부, 둘 다 아니면 null */
  guardianType?: InputMaybe<GuardianType>;
  /** 기본 키(UUID) */
  id?: InputMaybe<Scalars['ID']>;
  /** 내가 초대한 가입자 숫자 */
  inviteCount?: InputMaybe<Scalars['Float']>;
  /** 추천인 중 2만원 이상 결제한 수 */
  invitePurchasedCount?: InputMaybe<Scalars['Float']>;
  /** 가입날짜 */
  joinDate?: InputMaybe<Scalars['DateTime']>;
  /** 마지막 로그인 */
  lastLogin?: InputMaybe<Scalars['DateTime']>;
  /** 성 */
  lastName?: InputMaybe<Scalars['String']>;
  /** 학습자격(재학생,대학교졸업,대학원수료,대학원졸업,전문강사,일반인) */
  learningCheck?: InputMaybe<Scalars['String']>;
  /** 최근 수업일 */
  lessonDate?: InputMaybe<Scalars['DateTime']>;
  /** 현금성 캐쉬 */
  mainCash?: InputMaybe<Scalars['Float']>;
  /** 학습주카테고리/언어주카테고리 */
  mainCategory?: InputMaybe<Scalars['String']>;
  /** 전체 이름 */
  name?: InputMaybe<Scalars['String']>;
  /** 제휴회원체크 */
  partnershipCheck?: InputMaybe<Scalars['Boolean']>;
  /** 2만원이상 결제 일자 */
  paymentAt?: InputMaybe<Scalars['DateTime']>;
  /** 정지 해제일 */
  penaltyReleasedDate?: InputMaybe<Scalars['DateTime']>;
  /** 핸드폰 전화번호 */
  phone?: InputMaybe<Scalars['String']>;
  /** 개인정보처리방침 동의 */
  privacyPolicyAgreedAt?: InputMaybe<Scalars['DateTime']>;
  /** 아바타 파일 ID */
  profileFile__id?: InputMaybe<Scalars['ID']>;
  /** 수업정보 및 서비스정보 수신 동의 */
  receivingClassAndServiceInformationAgreement?: InputMaybe<Scalars['Boolean']>;
  /** 수업정보 및 서비스정보 수신 동의 변경일 */
  receivingClassAndServiceInformationAgreementUpdatedAt?: InputMaybe<Scalars['DateTime']>;
  /** sms 수업정보 수신 동의 */
  receivingClassInformationToSmsAgreement?: InputMaybe<Scalars['Boolean']>;
  /** sms 수업정보 수신 동의 변경일 */
  receivingClassInformationToSmsAgreementUpdatedAt?: InputMaybe<Scalars['DateTime']>;
  /** 이메일 광고 및 이벤트 정보 수신 동의 */
  receivingEventInformationAgreement?: InputMaybe<Scalars['Boolean']>;
  /** 이메일 광고 및 이벤트 정보 수신 동의 변경일 */
  receivingEventInformationAgreementUpdatedAt?: InputMaybe<Scalars['DateTime']>;
  /** 권한 (학생,선생님,학부모 등) */
  role?: InputMaybe<UserRole>;
  /** 서비스(카카오,구글 */
  service?: InputMaybe<UserSocialServiceType>;
  /** 서비스 푸시 알림 동의 */
  servicePushAgreement?: InputMaybe<Scalars['Boolean']>;
  /** 서비스 푸시 알림 동의 변경일 */
  servicePushAgreementUpdatedAt?: InputMaybe<Scalars['DateTime']>;
  /** 상태 (활성화,비활성화,탈퇴) */
  state?: InputMaybe<UserState>;
  /** 비현금성 캐쉬 */
  subCash?: InputMaybe<Scalars['Float']>;
  /** 학습부카테고리/언어부카테고리 */
  subCategory?: InputMaybe<Scalars['String']>;
  /** 이용약관 동의 */
  termsOfServiceAgreedAt?: InputMaybe<Scalars['DateTime']>;
  /** 초,중,고 등 etc 구분 */
  type?: InputMaybe<UserType>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

/** 출금 신청 */
export type Withdrawal = {
  __typename?: 'Withdrawal';
  /** 금액 */
  amount?: Maybe<Scalars['Float']>;
  /** 은행 */
  bank?: Maybe<Scalars['String']>;
  /** 계좌번호 */
  bankNumber?: Maybe<Scalars['String']>;
  /** 생성 날짜/시간 */
  createdAt: Scalars['DateTime'];
  /** 삭제 날짜/시간 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** 비고 */
  etc?: Maybe<Scalars['String']>;
  /** 기본 키(UUID) */
  id: Scalars['ID'];
  /** 현금성캐쉬 */
  mainCash?: Maybe<Scalars['Float']>;
  /** 계좌주 */
  owner?: Maybe<Scalars['String']>;
  /** 속성 */
  state?: Maybe<WithdrawalState>;
  /** 수정 날짜/시간 */
  updatedAt: Scalars['DateTime'];
  /** 사용자 */
  user?: Maybe<User>;
  /** 출금 완료 일시 */
  withdrawalCompletionAt?: Maybe<Scalars['DateTime']>;
  /** 출금 신청 일시 */
  withdrawalRequestAt?: Maybe<Scalars['DateTime']>;
};

/** 출금신청 생성 */
export type WithdrawalCreateInput = {
  /** 금액 */
  amount?: InputMaybe<Scalars['Float']>;
  /** 은행 */
  bank?: InputMaybe<Scalars['String']>;
  /** 계좌번호 */
  bankNumber?: InputMaybe<Scalars['String']>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  /** 비고 */
  etc?: InputMaybe<Scalars['String']>;
  /** 기본 키(UUID) */
  id?: InputMaybe<Scalars['ID']>;
  /** 현금성캐쉬 */
  mainCash?: InputMaybe<Scalars['Float']>;
  /** 계좌주 */
  owner?: InputMaybe<Scalars['String']>;
  /** 속성 */
  state?: InputMaybe<WithdrawalState>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** 유저 ID */
  user__id?: InputMaybe<Scalars['ID']>;
  /** 출금 완료 일시 */
  withdrawalCompletionAt?: InputMaybe<Scalars['DateTime']>;
  /** 출금 신청 일시 */
  withdrawalRequestAt?: InputMaybe<Scalars['DateTime']>;
};

export type WithdrawalEdge = {
  __typename?: 'WithdrawalEdge';
  /** 커서 */
  cursor: Scalars['String'];
  /** 노드 */
  node: Withdrawal;
};

/** 출근 신청 필터 */
export type WithdrawalFilterInput = {
  /** 금액 */
  amount?: InputMaybe<Array<StringFilterInput>>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** UUID */
  id?: InputMaybe<Array<IdFilterInput>>;
  /** 출금상태 */
  state?: InputMaybe<Array<WithdrawalStateEnumFilter>>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 이메일 */
  user__email?: InputMaybe<Array<StringFilterInput>>;
  /** 이름 */
  user__name?: InputMaybe<Array<StringFilterInput>>;
  /** 유저 역할 */
  user__role?: InputMaybe<Array<UserRoleEnumFilter>>;
  /** 출금 완료 일시 */
  withdrawalCompletionAt?: InputMaybe<Array<DateTimeFilterInput>>;
  /** 출금 신청 일시 */
  withdrawalRequestAt?: InputMaybe<Array<DateTimeFilterInput>>;
};

/** 출금신청 리스트 */
export type WithdrawalInfo = {
  __typename?: 'WithdrawalInfo';
  /** 현금성 캐시 금액 */
  mainCash?: Maybe<Scalars['Float']>;
  /** 현금성 캐시 보유자 수 */
  mainCashCount?: Maybe<Scalars['Float']>;
  /** 출금신청자 수 */
  withdrawalCount?: Maybe<Scalars['Float']>;
  /** 츌금신청한 금액 */
  withdrawalPrice?: Maybe<Scalars['Float']>;
};

/** 출금신청 리스트 */
export type WithdrawalList = {
  __typename?: 'WithdrawalList';
  /** 페이지네이션된 데이터 목록 */
  edges: Array<Maybe<WithdrawalEdge>>;
  /** 페이지네이션된 페이지 정보 */
  pageInfo: PageInfo;
  /** 전체 데이터 개 수 */
  totalCount: Scalars['Int'];
};

/** 출금신청 정렬 */
export type WithdrawalOrderByInput = {
  /** 금액 */
  amount?: InputMaybe<SortInput>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<SortInput>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<SortInput>;
  /** UUID */
  id?: InputMaybe<SortInput>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<SortInput>;
  /** 이메일 */
  user__email?: InputMaybe<SortInput>;
  /** 이름 */
  user__name?: InputMaybe<SortInput>;
};

export enum WithdrawalState {
  /** 입금완료 */
  Completed = 'COMPLETED',
  /** 입금중 */
  Depositing = 'DEPOSITING',
  /** 실패/거절 */
  Refusal = 'REFUSAL',
  /** 요청 */
  Request = 'REQUEST'
}

/** 출근 신청 상태 */
export type WithdrawalStateEnumFilter = {
  operator: EnumFilterOperators;
  value?: InputMaybe<WithdrawalState>;
  values?: InputMaybe<Array<WithdrawalState>>;
};

/** 출금신청 업데이트 */
export type WithdrawalUpdateInput = {
  /** 금액 */
  amount?: InputMaybe<Scalars['Float']>;
  /** 은행 */
  bank?: InputMaybe<Scalars['String']>;
  /** 계좌번호 */
  bankNumber?: InputMaybe<Scalars['String']>;
  /** 생성 날짜/시간 */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** 삭제 날짜/시간 */
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  /** 비고 */
  etc?: InputMaybe<Scalars['String']>;
  /** 기본 키(UUID) */
  id?: InputMaybe<Scalars['ID']>;
  /** 현금성캐쉬 */
  mainCash?: InputMaybe<Scalars['Float']>;
  /** 계좌주 */
  owner?: InputMaybe<Scalars['String']>;
  /** 속성 */
  state?: InputMaybe<WithdrawalState>;
  /** 수정 날짜/시간 */
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** 출금 완료 일시 */
  withdrawalCompletionAt?: InputMaybe<Scalars['DateTime']>;
  /** 출금 신청 일시 */
  withdrawalRequestAt?: InputMaybe<Scalars['DateTime']>;
};

export type WriterInfo = {
  __typename?: 'WriterInfo';
  /** 이름 */
  name?: Maybe<Scalars['String']>;
  /** 프로필 파일 */
  profileFile?: Maybe<File>;
};

export enum AdminPermissionEnum {
  /** 읽기 */
  Read = 'READ',
  /** 쓰기 */
  Write = 'WRITE'
}

export enum BookStateType {
  /** 판매중 */
  Continued = 'CONTINUED',
  /** 판매 중지 */
  Discontinued = 'DISCONTINUED',
  /** 거절 */
  Refusal = 'REFUSAL',
  /** 대기 */
  Requesting = 'REQUESTING',
  /** 재승인 요청 */
  ReApprovalRequest = 'RE_APPROVAL_REQUEST',
  /** 보완 요청 */
  Supplementation = 'SUPPLEMENTATION'
}

export enum BrandStateType {
  /** 승인 */
  Acceptance = 'ACCEPTANCE',
  /** 거절 */
  Refusal = 'REFUSAL',
  /** 승인요청 */
  Requesting = 'REQUESTING',
  /** 재승인 요청 */
  ReApprovalRequest = 'RE_APPROVAL_REQUEST',
  /** 보완 요청 */
  Supplementation = 'SUPPLEMENTATION'
}

/** 주문 */
export type Business = {
  __typename?: 'business';
  /** 교재 정보 */
  book?: Maybe<Book>;
  /** 최종 정산 금액(교재) */
  bookFinalCalculatedAmount?: Maybe<Scalars['Float']>;
  /** 교재 열람일 */
  bookReadDate?: Maybe<Scalars['DateTime']>;
  /** 리뷰정보 */
  bookReview?: Maybe<BookReview>;
  /** 생성 날짜/시간 */
  createdAt: Scalars['DateTime'];
  /** 삭제 날짜/시간 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** 기본 키(UUID) */
  id: Scalars['ID'];
  /** 소득공제 여부 */
  isDeducted?: Maybe<Scalars['Boolean']>;
  isTeacherReportingStudent: Scalars['Boolean'];
  /** 수업 정보 */
  lesson?: Maybe<Lesson>;
  /** 주문확정일 */
  orderConfirmedDate?: Maybe<Scalars['DateTime']>;
  /** 주문정보 */
  orderInfo?: Maybe<OrderInfo>;
  /** 결제 정보 */
  payment?: Maybe<Payment>;
  /** 진행수업회차 */
  progressedLesson?: Maybe<Scalars['String']>;
  /** 출판사 정산 */
  publisherCalculate?: Maybe<PublisherCalculate>;
  /** 취소/환불 정보 */
  refundHistory?: Maybe<RefundHistory>;
  /** 주문 상태 */
  state?: Maybe<OrderState>;
  /** subLesson */
  subLesson?: Maybe<Array<SubLesson>>;
  /** 주문 타입 */
  type?: Maybe<OrderType>;
  /** 수정 날짜/시간 */
  updatedAt: Scalars['DateTime'];
  /** 사용한 캐시 합 */
  usedAllCash?: Maybe<Scalars['Float']>;
  /** 사용한 현금성 캐시 */
  usedMainCash?: Maybe<Scalars['Float']>;
  /** 사용한 비현금성 캐시 */
  usedSubCash?: Maybe<Scalars['Float']>;
  /** 구매자 */
  user?: Maybe<User>;
};

/** 캐쉬 타입 */
export type CashTypeEnumFilter = {
  operator: EnumFilterOperators;
  value?: InputMaybe<CashType>;
  values?: InputMaybe<Array<CashType>>;
};

/** 정렬용 view model */
export type CommonSortView = {
  __typename?: 'commonSortView';
  /** 즐겨찾기수 */
  bookmarkCount?: Maybe<Scalars['Float']>;
  /** 판매수 */
  businessCount?: Maybe<Scalars['Float']>;
  /** 평점 평균 */
  gpaAvg?: Maybe<Scalars['Float']>;
  /** 리뷰수 */
  gpaCount?: Maybe<Scalars['Float']>;
  /** 아이디 */
  id?: Maybe<Scalars['String']>;
  /** 인기 */
  popular?: Maybe<Scalars['Float']>;
};

export enum ContentCategory {
  /** 교재 */
  Book = 'BOOK',
  /** 언어 */
  Language = 'LANGUAGE',
  /** 학습 */
  Learning = 'LEARNING',
  /** 메인 */
  Main = 'MAIN'
}

export enum ContentKind {
  /** 하단 라인 */
  Lower = 'LOWER',
  /** 메인 */
  Main = 'MAIN'
}

export enum ContentState {
  /** 노출 */
  Exposure = 'EXPOSURE',
  /** 비노출 */
  NotExposure = 'NOT_EXPOSURE'
}

export enum ContentType {
  /** 모바일 */
  Mobile = 'MOBILE',
  /** 테블릿 */
  Tablet = 'TABLET',
  /** 웹 */
  Web = 'WEB'
}

export enum ExplanationStateEnum {
  /** 접수 */
  Completion = 'COMPLETION',
  /** 요청 */
  Requesting = 'REQUESTING'
}

export enum LessonCalculateState {
  /** 정산완료 */
  Completed = 'COMPLETED',
  /** 환불 */
  Refund = 'REFUND',
  /** 정산예정 */
  Schedule = 'SCHEDULE'
}

/** 수업 정산 상태 */
export type LessonCalculateStateEnumFilter = {
  operator: EnumFilterOperators;
  value?: InputMaybe<LessonCalculateState>;
  values?: InputMaybe<Array<LessonCalculateState>>;
};

export enum PartnershipCalculateState {
  /** 관리자 확정 */
  AdminConfirmed = 'ADMIN_CONFIRMED',
  /** 정산완료 */
  Completed = 'COMPLETED',
  /** 확정 */
  Confirmed = 'CONFIRMED',
  /** 정산대상아님 */
  NotIncluded = 'NOT_INCLUDED',
  /** 환불 */
  Refund = 'REFUND',
  /** 정산예정 */
  Schedule = 'SCHEDULE',
  /** 미확인 */
  Unconfirmed = 'UNCONFIRMED'
}

/** 결제타입 enum */
export type PaymentTypeEnumFilter = {
  operator: EnumFilterOperators;
  value?: InputMaybe<PaymentTypeEnumType>;
  values?: InputMaybe<Array<PaymentTypeEnumType>>;
};

export enum PostExposureState {
  /** 노출 */
  Exposure = 'EXPOSURE',
  /** 비노출 */
  NotExposure = 'NOT_EXPOSURE'
}

export enum PostType {
  /** 관리자 FAQ */
  FaqAdmin = 'FAQ_ADMIN',
  /** 출판사 FAQ */
  FaqPublisher = 'FAQ_PUBLISHER',
  /** 선생님 FAQ */
  FaqTeacher = 'FAQ_TEACHER',
  /** 사용자 FAQ */
  FaqUser = 'FAQ_USER',
  /** 관리자 공지사항 */
  NoticeAdmin = 'NOTICE_ADMIN',
  /** 출판사 공지사항 */
  NoticePublisher = 'NOTICE_PUBLISHER',
  /** 선생님 공지사항 */
  NoticeTeacher = 'NOTICE_TEACHER',
  /** 사용자 공지사항 */
  NoticeUser = 'NOTICE_USER'
}

export enum PublisherCalculateState {
  /** 관리자 확정 */
  AdminConfirmed = 'ADMIN_CONFIRMED',
  /** 정산완료 */
  Completed = 'COMPLETED',
  /** 확정 */
  Confirmed = 'CONFIRMED',
  /** 정산대상아님 */
  NotIncluded = 'NOT_INCLUDED',
  /** 미확인 */
  Unconfirmed = 'UNCONFIRMED'
}

/** 출판사 회원 상태 */
export type PublisherRegistrationStateEnumFilter = {
  operator: EnumFilterOperators;
  value?: InputMaybe<PublisherRegistrationStateType>;
  values?: InputMaybe<Array<PublisherRegistrationStateType>>;
};

export enum PublisherRegistrationStateType {
  /** 승인 */
  Acceptance = 'ACCEPTANCE',
  /** 거절 */
  Refusal = 'REFUSAL',
  /** 대기중 */
  Requesting = 'REQUESTING',
  /** 재승인 요청 */
  ReApprovalRequest = 'RE_APPROVAL_REQUEST',
  /** 보완 요청 */
  Supplementation = 'SUPPLEMENTATION'
}

/** 정산 상태 */
export type PublisherSettlementStateEnumFilter = {
  operator: EnumFilterOperators;
  value?: InputMaybe<PublisherSettlementStateType>;
  values?: InputMaybe<Array<PublisherSettlementStateType>>;
};

export enum PublisherSettlementStateType {
  /** 관리자 확정 */
  AdminConfirmed = 'ADMIN_CONFIRMED',
  /** 정산 완료 */
  Completion = 'COMPLETION',
  /** 확정 */
  Confirmed = 'CONFIRMED',
  /** 미확인 */
  Unconfirmed = 'UNCONFIRMED'
}

/** 출판사 회원 상태 */
export type PublisherStateEnumFilter = {
  operator: EnumFilterOperators;
  value?: InputMaybe<PublisherStateType>;
  values?: InputMaybe<Array<PublisherStateType>>;
};

export enum PublisherStateType {
  /** 활성화 */
  Active = 'ACTIVE',
  /** 비활성화 */
  Inactive = 'INACTIVE',
  /** 탈퇴 */
  Quit = 'QUIT'
}

export enum ReportSanction {
  /** 일반 */
  Normal = 'NORMAL',
  /** 1일 정지 */
  OneDayBanned = 'ONE_DAY_BANNED',
  /** 7일 정지 */
  OneWeekBanned = 'ONE_WEEK_BANNED',
  /** 영구 정지 */
  Permanent = 'PERMANENT',
  /** 영구 정지 해제 */
  PermanentRelease = 'PERMANENT_RELEASE',
  /** 3일 정지 */
  ThirdDayBanned = 'THIRD_DAY_BANNED',
  /** 경고 */
  Warning = 'WARNING'
}

export enum ReportStateEnum {
  /** 신고 철회 */
  Cancelation = 'CANCELATION',
  /** 접수완료 */
  Completion = 'COMPLETION',
  /**
   * 확인
   * @deprecated 중복
   */
  Confirm = 'CONFIRM',
  /** 소명접수 */
  ExplanationCompletion = 'EXPLANATION_COMPLETION',
  /** 소명요청 */
  ExplanationRequest = 'EXPLANATION_REQUEST',
  /**
   * 패널티
   * @deprecated 중복
   */
  Penalty = 'PENALTY',
  /** 접수중 */
  Requesting = 'REQUESTING'
}

export enum SanctionReasonEnum {
  /** 비정상적인 사용, 매크로 */
  Abnormal = 'ABNORMAL',
  /** 교재품질 */
  BookQuality = 'BOOK_QUALITY',
  /** 기타사유(직접 입력) */
  Etc = 'ETC',
  /** 허위정보로 회원가입 */
  FalseInformationSignUp = 'FALSE_INFORMATION_SIGN_UP',
  /** 상습적인 지각, 무단 캔슬 */
  HabitualLateness = 'HABITUAL_LATENESS',
  /** 관련법상 금지된 행동 */
  Illegality = 'ILLEGALITY',
  /** 성범죄 */
  SexualAssault = 'SEXUAL_ASSAULT',
  /** 타인 비방, 개인정보누설 등 공공안전 저해 */
  Slander = 'SLANDER',
  /** 제목과 내용이 다름 */
  TitleContentDifferent = 'TITLE_CONTENT_DIFFERENT'
}

export enum SubLessonReviewStateType {
  /** 활성화 */
  Active = 'ACTIVE',
  /** 비활성화 */
  Inactive = 'INACTIVE'
}

/** 선생님 요청 상태 */
export type TeacherStateEnumFilter = {
  operator: EnumFilterOperators;
  value?: InputMaybe<TeacherRegistrationState>;
  values?: InputMaybe<Array<TeacherRegistrationState>>;
};

export type TogglePushAgreementInput = {
  /** 광고 푸시 알림 동의 */
  isAdvertisementPushAgreed?: InputMaybe<Scalars['Boolean']>;
  /** 서비스 푸시 알림 동의 */
  isServicePushAgreed?: InputMaybe<Scalars['Boolean']>;
};

export type ToggleReceivingAgreementInput = {
  /** 수업정보 및 서비스정보 수신 동의 */
  isReceivingClassAndServiceInformationAgreed?: InputMaybe<Scalars['Boolean']>;
  /** sms 수업정보 수신 동의 */
  isReceivingClassInformationToSmsAgreed?: InputMaybe<Scalars['Boolean']>;
  /** 이메일 광고 및 이벤트 정보 수신 동의 */
  isReceivingEventInformationAgreed?: InputMaybe<Scalars['Boolean']>;
};

export type BookCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type BookCategoriesQuery = { __typename?: 'Query', bookCategories: Array<{ __typename?: 'TreeBookCategory', id: string, name?: string | null, children?: Array<{ __typename?: 'TreeBookCategory', id: string, name?: string | null, children?: Array<{ __typename?: 'TreeBookCategory', id: string, name?: string | null, children?: Array<{ __typename?: 'TreeBookCategory', id: string, name?: string | null, children?: Array<{ __typename?: 'TreeBookCategory', id: string, name?: string | null }> | null }> | null }> | null }> | null }> };

export type BookBrandListQueryVariables = Exact<{ [key: string]: never; }>;


export type BookBrandListQuery = { __typename?: 'Query', publisherBrands: { __typename?: 'BrandList', edges: Array<{ __typename?: 'BrandEdge', node: { __typename?: 'Brand', id: string, name?: string | null } } | null> } };

export type BookContentPopupQueryVariables = Exact<{
  businessId: Scalars['ID'];
}>;


export type BookContentPopupQuery = { __typename?: 'Query', publisherBusiness: { __typename?: 'business', id: string, book?: { __typename?: 'Book', id: string, name?: string | null } | null, user?: { __typename?: 'User', id: string, email?: string | null } | null, bookReview?: { __typename?: 'BookReview', bookReviewWriteDate?: any | null, bookgpa?: number | null, bookgpaContent?: string | null } | null } };

export type BookDetailQueryVariables = Exact<{
  bookId: Scalars['ID'];
}>;


export type BookDetailQuery = { __typename?: 'Query', publisherBook: { __typename?: 'Book', id: string, name?: string | null, author?: string | null, price?: number | null, discountRate?: number | null, discountPrice?: number | null, introductionText?: string | null, descriptionText?: string | null, isbn10?: string | null, isbn13?: string | null, state?: BookStateType | null, publicationDate?: any | null, revisedDate?: any | null, salesPolicy?: string | null, advertisingText?: string | null, type: BookTypeEnum, memo?: string | null, sortField?: { __typename?: 'commonSortView', gpaAvg?: number | null, gpaCount?: number | null, businessCount?: number | null } | null, thumbnailFile?: { __typename?: 'File', relativeURL?: string | null, filename: string, url: string, id: string } | null, category?: Array<{ __typename?: 'BookCategory', name?: string | null, id: string }> | null, brand?: { __typename?: 'Brand', id: string, name?: string | null, publisher?: { __typename?: 'Publisher', id: string, name?: string | null } | null } | null, indexFile?: { __typename?: 'File', filename: string, relativeURL?: string | null, url: string, id: string } | null, contentFiles?: Array<{ __typename?: 'File', filename: string, relativeURL?: string | null, url: string, id: string }> | null, answerFiles?: Array<{ __typename?: 'File', filename: string, relativeURL?: string | null, url: string, id: string }> | null, previewFile?: { __typename?: 'File', id: string, filename: string, url: string, relativeURL?: string | null } | null } };

export type PublisherBooksQueryVariables = Exact<{
  filter?: InputMaybe<Array<PublisherBookFilterInput> | PublisherBookFilterInput>;
  sort?: InputMaybe<Array<BookOrderByInput> | BookOrderByInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
}>;


export type PublisherBooksQuery = { __typename?: 'Query', publisherBooks: { __typename?: 'BookList', totalCount: number, edges: Array<{ __typename?: 'BookEdge', node: { __typename?: 'Book', id: string, name?: string | null, price?: number | null, discountPrice?: number | null, discountRate?: number | null, state?: BookStateType | null, brand?: { __typename?: 'Brand', name?: string | null } | null, sortField?: { __typename?: 'commonSortView', gpaAvg?: number | null, gpaCount?: number | null, businessCount?: number | null } | null } } | null> } };

export type PublisherBrandDetailQueryVariables = Exact<{
  brandId: Scalars['ID'];
}>;


export type PublisherBrandDetailQuery = { __typename?: 'Query', publisherBrand: { __typename?: 'Brand', id: string, createdAt: any, name?: string | null, state?: BrandStateType | null, memo?: BrandMemoEnum | null, attachment?: { __typename?: 'File', id: string, url: string, relativeURL?: string | null, filename: string } | null, publisher?: { __typename?: 'Publisher', id: string, name?: string | null } | null } };

export type PublisherBrandsQueryVariables = Exact<{
  filter?: InputMaybe<Array<PublisherBrandFilterInput> | PublisherBrandFilterInput>;
  sort?: InputMaybe<Array<BrandOrderByInput> | BrandOrderByInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
}>;


export type PublisherBrandsQuery = { __typename?: 'Query', publisherBrands: { __typename?: 'BrandList', totalCount: number, edges: Array<{ __typename?: 'BrandEdge', cursor: string, node: { __typename?: 'Brand', id: string, name?: string | null, state?: BrandStateType | null, memo?: BrandMemoEnum | null, attachment?: { __typename?: 'File', filename: string } | null } } | null> } };

export type BookCategoryQueryVariables = Exact<{ [key: string]: never; }>;


export type BookCategoryQuery = { __typename?: 'Query', sortedBookCategories: Array<{ __typename?: 'TreeBookCategory', id: string, name?: string | null, children?: Array<{ __typename?: 'TreeBookCategory', id: string, name?: string | null, children?: Array<{ __typename?: 'TreeBookCategory', id: string, name?: string | null, children?: Array<{ __typename?: 'TreeBookCategory', id: string, name?: string | null, children?: Array<{ __typename?: 'TreeBookCategory', id: string, name?: string | null }> | null }> | null }> | null }> | null }> };

export type CreatePublisherBookMutationVariables = Exact<{
  data: PublisherBookCreateInput;
}>;


export type CreatePublisherBookMutation = { __typename?: 'Mutation', createPublisherBook: { __typename?: 'Book', id: string } };

export type CreatePublisherBrandMutationVariables = Exact<{
  data: PublisherBrandCreateInput;
}>;


export type CreatePublisherBrandMutation = { __typename?: 'Mutation', createPublisherBrand: { __typename?: 'Brand', id: string } };

export type DeletePublisherBookMutationVariables = Exact<{
  deletePublisherBookId: Scalars['ID'];
}>;


export type DeletePublisherBookMutation = { __typename?: 'Mutation', deletePublisherBook: { __typename?: 'Book', id: string } };

export type ReviewManagementQueryVariables = Exact<{
  filter?: InputMaybe<Array<PublisherBusinessFilterInput> | PublisherBusinessFilterInput>;
  sort?: InputMaybe<Array<BusinessOrderByInput> | BusinessOrderByInput>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}>;


export type ReviewManagementQuery = { __typename?: 'Query', publisherBusinesses: { __typename?: 'BusinessList', totalCount: number, edges: Array<{ __typename?: 'BusinessEdge', node: { __typename?: 'business', id: string, createdAt: any, book?: { __typename?: 'Book', name?: string | null, id: string, category?: Array<{ __typename?: 'BookCategory', id: string, name?: string | null }> | null, sortField?: { __typename?: 'commonSortView', gpaAvg?: number | null, gpaCount?: number | null, businessCount?: number | null } | null } | null, user?: { __typename?: 'User', id: string, email?: string | null } | null, payment?: { __typename?: 'Payment', id: string, paidDate?: any | null } | null, bookReview?: { __typename?: 'BookReview', bookReviewWriteDate?: any | null, bookgpa?: number | null, bookgpaContent?: string | null } | null } } | null> } };

export type UpdateBookStateMutationVariables = Exact<{
  id: Scalars['ID'];
  data: BookStateUpdateInput;
}>;


export type UpdateBookStateMutation = { __typename?: 'Mutation', updateBookState: { __typename?: 'Book', id: string } };

export type UpdateBookStatesMutationVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
  state: BookSaleState;
}>;


export type UpdateBookStatesMutation = { __typename?: 'Mutation', updatePublisherBookStates: Array<{ __typename?: 'Book', id: string }> };

export type UpdatePublisherBookMutationVariables = Exact<{
  data: PublisherBookUpdateInput;
  id: Scalars['ID'];
}>;


export type UpdatePublisherBookMutation = { __typename?: 'Mutation', updatePublisherBook: { __typename?: 'Book', id: string } };

export type UpdatePublisherBrandMutationVariables = Exact<{
  data: PublisherBrandUpdateInput;
  updatePublisherBrandId: Scalars['ID'];
}>;


export type UpdatePublisherBrandMutation = { __typename?: 'Mutation', updatePublisherBrand: { __typename?: 'Brand', id: string } };

export type UpdateStateReApprovalRequestPublisherBrandMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UpdateStateReApprovalRequestPublisherBrandMutation = { __typename?: 'Mutation', updateStateReApprovalRequestPublisherBrand: { __typename?: 'Brand', id: string } };

export type BrandListQueryVariables = Exact<{ [key: string]: never; }>;


export type BrandListQuery = { __typename?: 'Query', publisherBrands: { __typename?: 'BrandList', edges: Array<{ __typename?: 'BrandEdge', node: { __typename?: 'Brand', id: string, name?: string | null } } | null> } };

export type PublisherBusinessesQueryVariables = Exact<{
  filter?: InputMaybe<Array<PublisherBusinessFilterInput> | PublisherBusinessFilterInput>;
  sort?: InputMaybe<Array<BusinessOrderByInput> | BusinessOrderByInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
}>;


export type PublisherBusinessesQuery = { __typename?: 'Query', publisherBusinesses: { __typename?: 'BusinessList', totalCount: number, edges: Array<{ __typename?: 'BusinessEdge', node: { __typename?: 'business', id: string, state?: OrderState | null, bookFinalCalculatedAmount?: number | null, orderInfo?: { __typename?: 'OrderInfo', totalOriginalPrice?: number | null, totalPrice?: number | null, onePrice?: number | null, discountPrice?: number | null, googleMeetFee?: number | null, pageCallFee?: number | null } | null, payment?: { __typename?: 'Payment', orderNumber?: string | null, paidDate?: any | null, amount?: number | null } | null, book?: { __typename?: 'Book', name?: string | null, category?: Array<{ __typename?: 'BookCategory', id: string, name?: string | null }> | null } | null, user?: { __typename?: 'User', id: string, email?: string | null, createdAt: any } | null } } | null> } };

export type PublisherPublisherCalculatesQueryVariables = Exact<{
  filter?: InputMaybe<Array<PublisherPublisherCalculateFilterInput> | PublisherPublisherCalculateFilterInput>;
  sort?: InputMaybe<Array<PublisherCalculateOrderByInput> | PublisherCalculateOrderByInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
}>;


export type PublisherPublisherCalculatesQuery = { __typename?: 'Query', publisherPublisherCalculates: { __typename?: 'PublisherCalculateList', totalCount: number, edges: Array<{ __typename?: 'PublisherCalculateEdge', node: { __typename?: 'PublisherCalculate', id: string, calculateCode?: string | null, createdAt: any, calculateDate?: string | null, monthBookUserCount?: number | null, monthBookCount?: number | null, monthCalculate?: number | null, state?: PublisherCalculateState | null, calculateConfirmationDate?: any | null, calculateProcessDate?: any | null } } | null> } };

export type PublisherPublisherCalculateQueryVariables = Exact<{
  publisherPublisherCalculateId: Scalars['ID'];
}>;


export type PublisherPublisherCalculateQuery = { __typename?: 'Query', publisherPublisherCalculate: { __typename?: 'PublisherCalculate', id: string, state?: PublisherCalculateState | null, calculateDate?: string | null, monthBookUserCount?: number | null, monthBookCount?: number | null, monthlySales?: number | null, monthCalculate?: number | null, publisher?: { __typename?: 'Publisher', bookNumber?: number | null } | null } };

export type PublisherConfirmPublisherCalculateMutationVariables = Exact<{
  publisherConfirmPublisherCalculateId: Scalars['ID'];
}>;


export type PublisherConfirmPublisherCalculateMutation = { __typename?: 'Mutation', publisherConfirmPublisherCalculate: { __typename?: 'PublisherCalculate', id: string } };

export type DashboardQueryVariables = Exact<{ [key: string]: never; }>;


export type DashboardQuery = { __typename?: 'Query', publisherBookSalesDashboard: { __typename?: 'BookSalesDashboard', dailySalesVolume?: number | null, weeklySalesVolume?: number | null, monthlySalesVolume?: number | null, cumulativeSales?: number | null }, publisherPublisherCalculateDashboard?: { __typename?: 'PublisherCalculateDashboard', previousCalculateDate?: any | null, previousCalculateAmount?: number | null, nextCalculateDate?: any | null, nextCalculateAmount?: number | null } | null };

export type RegistrationResultPanelQueryVariables = Exact<{ [key: string]: never; }>;


export type RegistrationResultPanelQuery = { __typename?: 'Query', publisherBrands: { __typename?: 'BrandList', edges: Array<{ __typename?: 'BrandEdge', node: { __typename?: 'Brand', id: string, name?: string | null, state?: BrandStateType | null } } | null> }, publisherBooks: { __typename?: 'BookList', edges: Array<{ __typename?: 'BookEdge', node: { __typename?: 'Book', id: string, name?: string | null, state?: BookStateType | null, brand?: { __typename?: 'Brand', id: string, name?: string | null } | null } } | null> } };

export type LoginMutationVariables = Exact<{
  email: Scalars['Email'];
  password: Scalars['Password'];
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'AuthToken', accessToken?: string | null, refreshToken?: string | null } };

export type AccountInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type AccountInfoQuery = { __typename?: 'Query', publisherMe: { __typename?: 'Publisher', id: string, bankName?: string | null, accountName?: string | null, accountNumber?: string | null, fee?: number | null, bankBookFile?: { __typename?: 'File', id: string, filename: string, mimetype: string, url: string } | null } };

export type ChangePublisherPasswordMutationVariables = Exact<{
  oldPassword: Scalars['Password'];
  newPassword: Scalars['Password'];
}>;


export type ChangePublisherPasswordMutation = { __typename?: 'Mutation', changePassword: { __typename?: 'User', id: string } };

export type IsDuplicatePublisherEmailMutationVariables = Exact<{
  email: Scalars['Email'];
}>;


export type IsDuplicatePublisherEmailMutation = { __typename?: 'Mutation', isDuplicateEmail: boolean };

export type DeletePublisherMeMutationVariables = Exact<{ [key: string]: never; }>;


export type DeletePublisherMeMutation = { __typename?: 'Mutation', deletePublisherMe: { __typename?: 'Publisher', id: string } };

export type PublisherExposingFaqsQueryVariables = Exact<{
  filter?: InputMaybe<Array<PostFilterInput> | PostFilterInput>;
  sort?: InputMaybe<Array<PostOrderByInput> | PostOrderByInput>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}>;


export type PublisherExposingFaqsQuery = { __typename?: 'Query', publisherExposingFaqs: { __typename?: 'PostList', totalCount: number, edges: Array<{ __typename?: 'PostEdge', node: { __typename?: 'Post', id: string, title?: string | null, publishedDate?: any | null, attachment?: { __typename?: 'File', id: string, filename: string, url: string } | null, category?: Array<{ __typename?: 'FAQCategory', id: string, name?: string | null }> | null } } | null> } };

export type PublisherExposingNoticesQueryVariables = Exact<{
  filter?: InputMaybe<Array<PostFilterInput> | PostFilterInput>;
  sort?: InputMaybe<Array<PostOrderByInput> | PostOrderByInput>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}>;


export type PublisherExposingNoticesQuery = { __typename?: 'Query', publisherExposingNotices: { __typename?: 'PostList', totalCount: number, edges: Array<{ __typename?: 'PostEdge', node: { __typename?: 'Post', id: string, title?: string | null, publishedDate?: any | null, expiredDate?: any | null, writer?: { __typename?: 'User', id: string, name?: string | null, email?: string | null, profileFile?: { __typename?: 'File', url: string } | null } | null } } | null> } };

export type PublisherFaqCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type PublisherFaqCategoriesQuery = { __typename?: 'Query', publisherFaqCategories: Array<{ __typename?: 'TreeFAQCategory', id: string, createdAt: any, deletedAt?: any | null, updatedAt: any, name?: string | null, priority?: number | null, children?: Array<{ __typename?: 'TreeFAQCategory', id: string, name?: string | null, children?: Array<{ __typename?: 'TreeFAQCategory', id: string, name?: string | null, children?: Array<{ __typename?: 'TreeFAQCategory', id: string, name?: string | null, children?: Array<{ __typename?: 'TreeFAQCategory', id: string, name?: string | null }> | null }> | null }> | null }> | null }> };

export type PublisherInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type PublisherInfoQuery = { __typename?: 'Query', publisherMe: { __typename?: 'Publisher', id: string, name?: string | null, representativeName?: string | null, representativeEmail?: string | null, representativePhone?: string | null, managerName?: string | null, managerEmail?: string | null, managerPhone?: string | null, businessName?: string | null, businessRegistrationNumber?: string | null, policy?: string | null, createdAt: any, lastLogin?: any | null, registrationState?: PublisherRegistrationStateType | null, bankName?: string | null, accountName?: string | null, accountNumber?: string | null, fee?: number | null, memo?: string | null, businessRegistrationFile?: { __typename?: 'File', id: string, filename: string, mimetype: string, url: string } | null, bankBookFile?: { __typename?: 'File', id: string, filename: string, mimetype: string, url: string } | null, user?: { __typename?: 'User', id: string, role?: UserRole | null, profileFile?: { __typename?: 'File', id: string, filename: string, mimetype: string, url: string } | null } | null } };

export type PublisherPostQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PublisherPostQuery = { __typename?: 'Query', publisherPost: { __typename?: 'Post', id: string, title?: string | null, content?: string | null, link?: string | null, publishedDate?: any | null, thumbnail?: { __typename?: 'File', id: string, filename: string, url: string } | null, attachment?: { __typename?: 'File', id: string, filename: string, url: string } | null, category?: Array<{ __typename?: 'FAQCategory', id: string, name?: string | null }> | null } };

export type UpdatePublisherMeMutationVariables = Exact<{
  data: MemberPublisherUpdateInput;
}>;


export type UpdatePublisherMeMutation = { __typename?: 'Mutation', updatePublisherMe: { __typename?: 'Publisher', id: string } };

export type RefreshTokenMutationVariables = Exact<{
  refreshToken: Scalars['String'];
}>;


export type RefreshTokenMutation = { __typename?: 'Mutation', reissueToken: { __typename?: 'AuthToken', accessToken?: string | null, refreshToken?: string | null } };

export type OrderStatusDetailQueryVariables = Exact<{
  businessId: Scalars['ID'];
}>;


export type OrderStatusDetailQuery = { __typename?: 'Query', publisherBusiness: { __typename?: 'business', id: string, state?: OrderState | null, bookReview?: { __typename?: 'BookReview', bookgpa?: number | null, bookgpaContent?: string | null } | null, orderInfo?: { __typename?: 'OrderInfo', onePrice?: number | null, discountPrice?: number | null, discountRate?: number | null, totalOriginalPrice?: number | null, totalPrice?: number | null } | null, refundHistory?: { __typename?: 'RefundHistory', id: string, cancelCompletionDate?: any | null, createdAt: any } | null, book?: { __typename?: 'Book', id: string, name?: string | null, category?: Array<{ __typename?: 'BookCategory', id: string, name?: string | null }> | null, brand?: { __typename?: 'Brand', id: string, name?: string | null } | null, thumbnailFile?: { __typename?: 'File', id: string, filename: string, url: string } | null, sortField?: { __typename?: 'commonSortView', gpaAvg?: number | null, gpaCount?: number | null, businessCount?: number | null } | null } | null, user?: { __typename?: 'User', id: string, email?: string | null } | null, payment?: { __typename?: 'Payment', id: string, paidDate?: any | null, state?: PaymentStateEnumType | null } | null } };

export type OrderStatusQueryVariables = Exact<{
  filter?: InputMaybe<Array<PublisherBusinessFilterInput> | PublisherBusinessFilterInput>;
  sort?: InputMaybe<Array<BusinessOrderByInput> | BusinessOrderByInput>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}>;


export type OrderStatusQuery = { __typename?: 'Query', publisherBusinesses: { __typename?: 'BusinessList', totalCount: number, edges: Array<{ __typename?: 'BusinessEdge', node: { __typename?: 'business', id: string, createdAt: any, state?: OrderState | null, bookReview?: { __typename?: 'BookReview', bookgpa?: number | null, bookgpaContent?: string | null } | null, refundHistory?: { __typename?: 'RefundHistory', id: string, cancelCompletionDate?: any | null, createdAt: any } | null, orderInfo?: { __typename?: 'OrderInfo', onePrice?: number | null, discountPrice?: number | null, discountRate?: number | null, totalOriginalPrice?: number | null, totalPrice?: number | null } | null, book?: { __typename?: 'Book', price?: number | null, name?: string | null, id: string, category?: Array<{ __typename?: 'BookCategory', id: string, name?: string | null }> | null, sortField?: { __typename?: 'commonSortView', gpaAvg?: number | null, gpaCount?: number | null, businessCount?: number | null } | null, brand?: { __typename?: 'Brand', id: string, name?: string | null } | null } | null, user?: { __typename?: 'User', id: string, email?: string | null } | null, payment?: { __typename?: 'Payment', id: string, paidDate?: any | null } | null } } | null> } };

export type SignUpPublisherMutationVariables = Exact<{
  data: PublisherSignupInput;
}>;


export type SignUpPublisherMutation = { __typename?: 'Mutation', signUpPublisher: { __typename?: 'Publisher', id: string } };


export const BookCategoriesDocument = gql`
    query BookCategories {
  bookCategories {
    id
    name
    children {
      id
      name
      children {
        id
        name
        children {
          id
          name
          children {
            id
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useBookCategoriesQuery__
 *
 * To run a query within a React component, call `useBookCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useBookCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<BookCategoriesQuery, BookCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BookCategoriesQuery, BookCategoriesQueryVariables>(BookCategoriesDocument, options);
      }
export function useBookCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BookCategoriesQuery, BookCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BookCategoriesQuery, BookCategoriesQueryVariables>(BookCategoriesDocument, options);
        }
export type BookCategoriesQueryHookResult = ReturnType<typeof useBookCategoriesQuery>;
export type BookCategoriesLazyQueryHookResult = ReturnType<typeof useBookCategoriesLazyQuery>;
export type BookCategoriesQueryResult = Apollo.QueryResult<BookCategoriesQuery, BookCategoriesQueryVariables>;
export const BookBrandListDocument = gql`
    query BookBrandList {
  publisherBrands(filter: {state: [{value: ACCEPTANCE, operator: EQUAL}]}) {
    edges {
      node {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useBookBrandListQuery__
 *
 * To run a query within a React component, call `useBookBrandListQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookBrandListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookBrandListQuery({
 *   variables: {
 *   },
 * });
 */
export function useBookBrandListQuery(baseOptions?: Apollo.QueryHookOptions<BookBrandListQuery, BookBrandListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BookBrandListQuery, BookBrandListQueryVariables>(BookBrandListDocument, options);
      }
export function useBookBrandListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BookBrandListQuery, BookBrandListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BookBrandListQuery, BookBrandListQueryVariables>(BookBrandListDocument, options);
        }
export type BookBrandListQueryHookResult = ReturnType<typeof useBookBrandListQuery>;
export type BookBrandListLazyQueryHookResult = ReturnType<typeof useBookBrandListLazyQuery>;
export type BookBrandListQueryResult = Apollo.QueryResult<BookBrandListQuery, BookBrandListQueryVariables>;
export const BookContentPopupDocument = gql`
    query BookContentPopup($businessId: ID!) {
  publisherBusiness(id: $businessId) {
    id
    book {
      id
      name
    }
    user {
      id
      email
    }
    bookReview {
      bookReviewWriteDate
      bookgpa
      bookgpaContent
    }
  }
}
    `;

/**
 * __useBookContentPopupQuery__
 *
 * To run a query within a React component, call `useBookContentPopupQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookContentPopupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookContentPopupQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useBookContentPopupQuery(baseOptions: Apollo.QueryHookOptions<BookContentPopupQuery, BookContentPopupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BookContentPopupQuery, BookContentPopupQueryVariables>(BookContentPopupDocument, options);
      }
export function useBookContentPopupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BookContentPopupQuery, BookContentPopupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BookContentPopupQuery, BookContentPopupQueryVariables>(BookContentPopupDocument, options);
        }
export type BookContentPopupQueryHookResult = ReturnType<typeof useBookContentPopupQuery>;
export type BookContentPopupLazyQueryHookResult = ReturnType<typeof useBookContentPopupLazyQuery>;
export type BookContentPopupQueryResult = Apollo.QueryResult<BookContentPopupQuery, BookContentPopupQueryVariables>;
export const BookDetailDocument = gql`
    query BookDetail($bookId: ID!) {
  publisherBook(id: $bookId) {
    id
    name
    author
    price
    discountRate
    discountPrice
    introductionText
    descriptionText
    isbn10
    isbn13
    state
    publicationDate
    revisedDate
    salesPolicy
    advertisingText
    type
    memo
    sortField {
      gpaAvg
      gpaCount
      businessCount
    }
    thumbnailFile {
      relativeURL
      filename
      url
      id
    }
    category {
      name
      id
    }
    brand {
      id
      name
      publisher {
        id
        name
      }
    }
    indexFile {
      filename
      relativeURL
      url
      id
    }
    contentFiles {
      filename
      relativeURL
      url
      id
    }
    answerFiles {
      filename
      relativeURL
      url
      id
    }
    previewFile {
      id
      filename
      url
      relativeURL
    }
  }
}
    `;

/**
 * __useBookDetailQuery__
 *
 * To run a query within a React component, call `useBookDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookDetailQuery({
 *   variables: {
 *      bookId: // value for 'bookId'
 *   },
 * });
 */
export function useBookDetailQuery(baseOptions: Apollo.QueryHookOptions<BookDetailQuery, BookDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BookDetailQuery, BookDetailQueryVariables>(BookDetailDocument, options);
      }
export function useBookDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BookDetailQuery, BookDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BookDetailQuery, BookDetailQueryVariables>(BookDetailDocument, options);
        }
export type BookDetailQueryHookResult = ReturnType<typeof useBookDetailQuery>;
export type BookDetailLazyQueryHookResult = ReturnType<typeof useBookDetailLazyQuery>;
export type BookDetailQueryResult = Apollo.QueryResult<BookDetailQuery, BookDetailQueryVariables>;
export const PublisherBooksDocument = gql`
    query PublisherBooks($filter: [PublisherBookFilterInput!], $sort: [BookOrderByInput!], $first: Int, $last: Int, $after: String, $before: String, $offset: Int) {
  publisherBooks(
    filter: $filter
    sort: $sort
    first: $first
    last: $last
    after: $after
    before: $before
    offset: $offset
  ) {
    totalCount
    edges {
      node {
        id
        name
        brand {
          name
        }
        price
        discountPrice
        discountRate
        state
        sortField {
          gpaAvg
          gpaCount
          businessCount
        }
      }
    }
  }
}
    `;

/**
 * __usePublisherBooksQuery__
 *
 * To run a query within a React component, call `usePublisherBooksQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublisherBooksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublisherBooksQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function usePublisherBooksQuery(baseOptions?: Apollo.QueryHookOptions<PublisherBooksQuery, PublisherBooksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublisherBooksQuery, PublisherBooksQueryVariables>(PublisherBooksDocument, options);
      }
export function usePublisherBooksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublisherBooksQuery, PublisherBooksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublisherBooksQuery, PublisherBooksQueryVariables>(PublisherBooksDocument, options);
        }
export type PublisherBooksQueryHookResult = ReturnType<typeof usePublisherBooksQuery>;
export type PublisherBooksLazyQueryHookResult = ReturnType<typeof usePublisherBooksLazyQuery>;
export type PublisherBooksQueryResult = Apollo.QueryResult<PublisherBooksQuery, PublisherBooksQueryVariables>;
export const PublisherBrandDetailDocument = gql`
    query PublisherBrandDetail($brandId: ID!) {
  publisherBrand(id: $brandId) {
    id
    createdAt
    name
    state
    memo
    attachment {
      id
      url
      relativeURL
      filename
    }
    publisher {
      id
      name
    }
  }
}
    `;

/**
 * __usePublisherBrandDetailQuery__
 *
 * To run a query within a React component, call `usePublisherBrandDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublisherBrandDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublisherBrandDetailQuery({
 *   variables: {
 *      brandId: // value for 'brandId'
 *   },
 * });
 */
export function usePublisherBrandDetailQuery(baseOptions: Apollo.QueryHookOptions<PublisherBrandDetailQuery, PublisherBrandDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublisherBrandDetailQuery, PublisherBrandDetailQueryVariables>(PublisherBrandDetailDocument, options);
      }
export function usePublisherBrandDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublisherBrandDetailQuery, PublisherBrandDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublisherBrandDetailQuery, PublisherBrandDetailQueryVariables>(PublisherBrandDetailDocument, options);
        }
export type PublisherBrandDetailQueryHookResult = ReturnType<typeof usePublisherBrandDetailQuery>;
export type PublisherBrandDetailLazyQueryHookResult = ReturnType<typeof usePublisherBrandDetailLazyQuery>;
export type PublisherBrandDetailQueryResult = Apollo.QueryResult<PublisherBrandDetailQuery, PublisherBrandDetailQueryVariables>;
export const PublisherBrandsDocument = gql`
    query PublisherBrands($filter: [PublisherBrandFilterInput!], $sort: [BrandOrderByInput!], $first: Int, $last: Int, $after: String, $before: String, $offset: Int) {
  publisherBrands(
    filter: $filter
    sort: $sort
    first: $first
    last: $last
    after: $after
    before: $before
    offset: $offset
  ) {
    totalCount
    edges {
      cursor
      node {
        id
        name
        state
        memo
        attachment {
          filename
        }
      }
    }
  }
}
    `;

/**
 * __usePublisherBrandsQuery__
 *
 * To run a query within a React component, call `usePublisherBrandsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublisherBrandsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublisherBrandsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function usePublisherBrandsQuery(baseOptions?: Apollo.QueryHookOptions<PublisherBrandsQuery, PublisherBrandsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublisherBrandsQuery, PublisherBrandsQueryVariables>(PublisherBrandsDocument, options);
      }
export function usePublisherBrandsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublisherBrandsQuery, PublisherBrandsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublisherBrandsQuery, PublisherBrandsQueryVariables>(PublisherBrandsDocument, options);
        }
export type PublisherBrandsQueryHookResult = ReturnType<typeof usePublisherBrandsQuery>;
export type PublisherBrandsLazyQueryHookResult = ReturnType<typeof usePublisherBrandsLazyQuery>;
export type PublisherBrandsQueryResult = Apollo.QueryResult<PublisherBrandsQuery, PublisherBrandsQueryVariables>;
export const BookCategoryDocument = gql`
    query BookCategory {
  sortedBookCategories {
    id
    name
    children {
      id
      name
      children {
        id
        name
        children {
          id
          name
          children {
            id
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useBookCategoryQuery__
 *
 * To run a query within a React component, call `useBookCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookCategoryQuery({
 *   variables: {
 *   },
 * });
 */
export function useBookCategoryQuery(baseOptions?: Apollo.QueryHookOptions<BookCategoryQuery, BookCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BookCategoryQuery, BookCategoryQueryVariables>(BookCategoryDocument, options);
      }
export function useBookCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BookCategoryQuery, BookCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BookCategoryQuery, BookCategoryQueryVariables>(BookCategoryDocument, options);
        }
export type BookCategoryQueryHookResult = ReturnType<typeof useBookCategoryQuery>;
export type BookCategoryLazyQueryHookResult = ReturnType<typeof useBookCategoryLazyQuery>;
export type BookCategoryQueryResult = Apollo.QueryResult<BookCategoryQuery, BookCategoryQueryVariables>;
export const CreatePublisherBookDocument = gql`
    mutation CreatePublisherBook($data: PublisherBookCreateInput!) {
  createPublisherBook(data: $data) {
    id
  }
}
    `;
export type CreatePublisherBookMutationFn = Apollo.MutationFunction<CreatePublisherBookMutation, CreatePublisherBookMutationVariables>;

/**
 * __useCreatePublisherBookMutation__
 *
 * To run a mutation, you first call `useCreatePublisherBookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePublisherBookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPublisherBookMutation, { data, loading, error }] = useCreatePublisherBookMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreatePublisherBookMutation(baseOptions?: Apollo.MutationHookOptions<CreatePublisherBookMutation, CreatePublisherBookMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePublisherBookMutation, CreatePublisherBookMutationVariables>(CreatePublisherBookDocument, options);
      }
export type CreatePublisherBookMutationHookResult = ReturnType<typeof useCreatePublisherBookMutation>;
export type CreatePublisherBookMutationResult = Apollo.MutationResult<CreatePublisherBookMutation>;
export type CreatePublisherBookMutationOptions = Apollo.BaseMutationOptions<CreatePublisherBookMutation, CreatePublisherBookMutationVariables>;
export const CreatePublisherBrandDocument = gql`
    mutation CreatePublisherBrand($data: PublisherBrandCreateInput!) {
  createPublisherBrand(data: $data) {
    id
  }
}
    `;
export type CreatePublisherBrandMutationFn = Apollo.MutationFunction<CreatePublisherBrandMutation, CreatePublisherBrandMutationVariables>;

/**
 * __useCreatePublisherBrandMutation__
 *
 * To run a mutation, you first call `useCreatePublisherBrandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePublisherBrandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPublisherBrandMutation, { data, loading, error }] = useCreatePublisherBrandMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreatePublisherBrandMutation(baseOptions?: Apollo.MutationHookOptions<CreatePublisherBrandMutation, CreatePublisherBrandMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePublisherBrandMutation, CreatePublisherBrandMutationVariables>(CreatePublisherBrandDocument, options);
      }
export type CreatePublisherBrandMutationHookResult = ReturnType<typeof useCreatePublisherBrandMutation>;
export type CreatePublisherBrandMutationResult = Apollo.MutationResult<CreatePublisherBrandMutation>;
export type CreatePublisherBrandMutationOptions = Apollo.BaseMutationOptions<CreatePublisherBrandMutation, CreatePublisherBrandMutationVariables>;
export const DeletePublisherBookDocument = gql`
    mutation DeletePublisherBook($deletePublisherBookId: ID!) {
  deletePublisherBook(id: $deletePublisherBookId) {
    id
  }
}
    `;
export type DeletePublisherBookMutationFn = Apollo.MutationFunction<DeletePublisherBookMutation, DeletePublisherBookMutationVariables>;

/**
 * __useDeletePublisherBookMutation__
 *
 * To run a mutation, you first call `useDeletePublisherBookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePublisherBookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePublisherBookMutation, { data, loading, error }] = useDeletePublisherBookMutation({
 *   variables: {
 *      deletePublisherBookId: // value for 'deletePublisherBookId'
 *   },
 * });
 */
export function useDeletePublisherBookMutation(baseOptions?: Apollo.MutationHookOptions<DeletePublisherBookMutation, DeletePublisherBookMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePublisherBookMutation, DeletePublisherBookMutationVariables>(DeletePublisherBookDocument, options);
      }
export type DeletePublisherBookMutationHookResult = ReturnType<typeof useDeletePublisherBookMutation>;
export type DeletePublisherBookMutationResult = Apollo.MutationResult<DeletePublisherBookMutation>;
export type DeletePublisherBookMutationOptions = Apollo.BaseMutationOptions<DeletePublisherBookMutation, DeletePublisherBookMutationVariables>;
export const ReviewManagementDocument = gql`
    query ReviewManagement($filter: [PublisherBusinessFilterInput!], $sort: [BusinessOrderByInput!], $first: Int, $offset: Int) {
  publisherBusinesses(
    filter: $filter
    sort: $sort
    first: $first
    offset: $offset
  ) {
    totalCount
    edges {
      node {
        id
        createdAt
        book {
          category {
            id
            name
          }
          name
          id
          sortField {
            gpaAvg
            gpaCount
            businessCount
          }
        }
        user {
          id
          email
        }
        payment {
          id
          paidDate
        }
        bookReview {
          bookReviewWriteDate
          bookgpa
          bookgpaContent
        }
      }
    }
  }
}
    `;

/**
 * __useReviewManagementQuery__
 *
 * To run a query within a React component, call `useReviewManagementQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewManagementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewManagementQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useReviewManagementQuery(baseOptions?: Apollo.QueryHookOptions<ReviewManagementQuery, ReviewManagementQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReviewManagementQuery, ReviewManagementQueryVariables>(ReviewManagementDocument, options);
      }
export function useReviewManagementLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReviewManagementQuery, ReviewManagementQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReviewManagementQuery, ReviewManagementQueryVariables>(ReviewManagementDocument, options);
        }
export type ReviewManagementQueryHookResult = ReturnType<typeof useReviewManagementQuery>;
export type ReviewManagementLazyQueryHookResult = ReturnType<typeof useReviewManagementLazyQuery>;
export type ReviewManagementQueryResult = Apollo.QueryResult<ReviewManagementQuery, ReviewManagementQueryVariables>;
export const UpdateBookStateDocument = gql`
    mutation UpdateBookState($id: ID!, $data: BookStateUpdateInput!) {
  updateBookState(id: $id, data: $data) {
    id
  }
}
    `;
export type UpdateBookStateMutationFn = Apollo.MutationFunction<UpdateBookStateMutation, UpdateBookStateMutationVariables>;

/**
 * __useUpdateBookStateMutation__
 *
 * To run a mutation, you first call `useUpdateBookStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBookStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBookStateMutation, { data, loading, error }] = useUpdateBookStateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateBookStateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBookStateMutation, UpdateBookStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBookStateMutation, UpdateBookStateMutationVariables>(UpdateBookStateDocument, options);
      }
export type UpdateBookStateMutationHookResult = ReturnType<typeof useUpdateBookStateMutation>;
export type UpdateBookStateMutationResult = Apollo.MutationResult<UpdateBookStateMutation>;
export type UpdateBookStateMutationOptions = Apollo.BaseMutationOptions<UpdateBookStateMutation, UpdateBookStateMutationVariables>;
export const UpdateBookStatesDocument = gql`
    mutation UpdateBookStates($ids: [ID!]!, $state: BookSaleState!) {
  updatePublisherBookStates(ids: $ids, state: $state) {
    id
  }
}
    `;
export type UpdateBookStatesMutationFn = Apollo.MutationFunction<UpdateBookStatesMutation, UpdateBookStatesMutationVariables>;

/**
 * __useUpdateBookStatesMutation__
 *
 * To run a mutation, you first call `useUpdateBookStatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBookStatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBookStatesMutation, { data, loading, error }] = useUpdateBookStatesMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useUpdateBookStatesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBookStatesMutation, UpdateBookStatesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBookStatesMutation, UpdateBookStatesMutationVariables>(UpdateBookStatesDocument, options);
      }
export type UpdateBookStatesMutationHookResult = ReturnType<typeof useUpdateBookStatesMutation>;
export type UpdateBookStatesMutationResult = Apollo.MutationResult<UpdateBookStatesMutation>;
export type UpdateBookStatesMutationOptions = Apollo.BaseMutationOptions<UpdateBookStatesMutation, UpdateBookStatesMutationVariables>;
export const UpdatePublisherBookDocument = gql`
    mutation UpdatePublisherBook($data: PublisherBookUpdateInput!, $id: ID!) {
  updatePublisherBook(data: $data, id: $id) {
    id
  }
}
    `;
export type UpdatePublisherBookMutationFn = Apollo.MutationFunction<UpdatePublisherBookMutation, UpdatePublisherBookMutationVariables>;

/**
 * __useUpdatePublisherBookMutation__
 *
 * To run a mutation, you first call `useUpdatePublisherBookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePublisherBookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePublisherBookMutation, { data, loading, error }] = useUpdatePublisherBookMutation({
 *   variables: {
 *      data: // value for 'data'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdatePublisherBookMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePublisherBookMutation, UpdatePublisherBookMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePublisherBookMutation, UpdatePublisherBookMutationVariables>(UpdatePublisherBookDocument, options);
      }
export type UpdatePublisherBookMutationHookResult = ReturnType<typeof useUpdatePublisherBookMutation>;
export type UpdatePublisherBookMutationResult = Apollo.MutationResult<UpdatePublisherBookMutation>;
export type UpdatePublisherBookMutationOptions = Apollo.BaseMutationOptions<UpdatePublisherBookMutation, UpdatePublisherBookMutationVariables>;
export const UpdatePublisherBrandDocument = gql`
    mutation UpdatePublisherBrand($data: PublisherBrandUpdateInput!, $updatePublisherBrandId: ID!) {
  updatePublisherBrand(data: $data, id: $updatePublisherBrandId) {
    id
  }
}
    `;
export type UpdatePublisherBrandMutationFn = Apollo.MutationFunction<UpdatePublisherBrandMutation, UpdatePublisherBrandMutationVariables>;

/**
 * __useUpdatePublisherBrandMutation__
 *
 * To run a mutation, you first call `useUpdatePublisherBrandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePublisherBrandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePublisherBrandMutation, { data, loading, error }] = useUpdatePublisherBrandMutation({
 *   variables: {
 *      data: // value for 'data'
 *      updatePublisherBrandId: // value for 'updatePublisherBrandId'
 *   },
 * });
 */
export function useUpdatePublisherBrandMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePublisherBrandMutation, UpdatePublisherBrandMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePublisherBrandMutation, UpdatePublisherBrandMutationVariables>(UpdatePublisherBrandDocument, options);
      }
export type UpdatePublisherBrandMutationHookResult = ReturnType<typeof useUpdatePublisherBrandMutation>;
export type UpdatePublisherBrandMutationResult = Apollo.MutationResult<UpdatePublisherBrandMutation>;
export type UpdatePublisherBrandMutationOptions = Apollo.BaseMutationOptions<UpdatePublisherBrandMutation, UpdatePublisherBrandMutationVariables>;
export const UpdateStateReApprovalRequestPublisherBrandDocument = gql`
    mutation UpdateStateReApprovalRequestPublisherBrand($id: ID!) {
  updateStateReApprovalRequestPublisherBrand(id: $id) {
    id
  }
}
    `;
export type UpdateStateReApprovalRequestPublisherBrandMutationFn = Apollo.MutationFunction<UpdateStateReApprovalRequestPublisherBrandMutation, UpdateStateReApprovalRequestPublisherBrandMutationVariables>;

/**
 * __useUpdateStateReApprovalRequestPublisherBrandMutation__
 *
 * To run a mutation, you first call `useUpdateStateReApprovalRequestPublisherBrandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStateReApprovalRequestPublisherBrandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStateReApprovalRequestPublisherBrandMutation, { data, loading, error }] = useUpdateStateReApprovalRequestPublisherBrandMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateStateReApprovalRequestPublisherBrandMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStateReApprovalRequestPublisherBrandMutation, UpdateStateReApprovalRequestPublisherBrandMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStateReApprovalRequestPublisherBrandMutation, UpdateStateReApprovalRequestPublisherBrandMutationVariables>(UpdateStateReApprovalRequestPublisherBrandDocument, options);
      }
export type UpdateStateReApprovalRequestPublisherBrandMutationHookResult = ReturnType<typeof useUpdateStateReApprovalRequestPublisherBrandMutation>;
export type UpdateStateReApprovalRequestPublisherBrandMutationResult = Apollo.MutationResult<UpdateStateReApprovalRequestPublisherBrandMutation>;
export type UpdateStateReApprovalRequestPublisherBrandMutationOptions = Apollo.BaseMutationOptions<UpdateStateReApprovalRequestPublisherBrandMutation, UpdateStateReApprovalRequestPublisherBrandMutationVariables>;
export const BrandListDocument = gql`
    query BrandList {
  publisherBrands(filter: {state: [{value: ACCEPTANCE, operator: EQUAL}]}) {
    edges {
      node {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useBrandListQuery__
 *
 * To run a query within a React component, call `useBrandListQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandListQuery({
 *   variables: {
 *   },
 * });
 */
export function useBrandListQuery(baseOptions?: Apollo.QueryHookOptions<BrandListQuery, BrandListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BrandListQuery, BrandListQueryVariables>(BrandListDocument, options);
      }
export function useBrandListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BrandListQuery, BrandListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BrandListQuery, BrandListQueryVariables>(BrandListDocument, options);
        }
export type BrandListQueryHookResult = ReturnType<typeof useBrandListQuery>;
export type BrandListLazyQueryHookResult = ReturnType<typeof useBrandListLazyQuery>;
export type BrandListQueryResult = Apollo.QueryResult<BrandListQuery, BrandListQueryVariables>;
export const PublisherBusinessesDocument = gql`
    query PublisherBusinesses($filter: [PublisherBusinessFilterInput!], $sort: [BusinessOrderByInput!], $first: Int, $last: Int, $after: String, $before: String, $offset: Int) {
  publisherBusinesses(
    filter: $filter
    sort: $sort
    first: $first
    last: $last
    after: $after
    before: $before
    offset: $offset
  ) {
    totalCount
    edges {
      node {
        id
        state
        orderInfo {
          totalOriginalPrice
          totalPrice
          onePrice
          discountPrice
          googleMeetFee
          pageCallFee
        }
        bookFinalCalculatedAmount
        payment {
          orderNumber
          paidDate
          amount
        }
        book {
          category {
            id
            name
          }
          name
        }
        user {
          id
          email
          createdAt
        }
      }
    }
  }
}
    `;

/**
 * __usePublisherBusinessesQuery__
 *
 * To run a query within a React component, call `usePublisherBusinessesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublisherBusinessesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublisherBusinessesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function usePublisherBusinessesQuery(baseOptions?: Apollo.QueryHookOptions<PublisherBusinessesQuery, PublisherBusinessesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublisherBusinessesQuery, PublisherBusinessesQueryVariables>(PublisherBusinessesDocument, options);
      }
export function usePublisherBusinessesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublisherBusinessesQuery, PublisherBusinessesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublisherBusinessesQuery, PublisherBusinessesQueryVariables>(PublisherBusinessesDocument, options);
        }
export type PublisherBusinessesQueryHookResult = ReturnType<typeof usePublisherBusinessesQuery>;
export type PublisherBusinessesLazyQueryHookResult = ReturnType<typeof usePublisherBusinessesLazyQuery>;
export type PublisherBusinessesQueryResult = Apollo.QueryResult<PublisherBusinessesQuery, PublisherBusinessesQueryVariables>;
export const PublisherPublisherCalculatesDocument = gql`
    query PublisherPublisherCalculates($filter: [PublisherPublisherCalculateFilterInput!], $sort: [PublisherCalculateOrderByInput!], $first: Int, $last: Int, $after: String, $before: String, $offset: Int) {
  publisherPublisherCalculates(
    filter: $filter
    sort: $sort
    first: $first
    last: $last
    after: $after
    before: $before
    offset: $offset
  ) {
    totalCount
    edges {
      node {
        id
        calculateCode
        createdAt
        calculateDate
        monthBookUserCount
        monthBookCount
        monthCalculate
        state
        calculateConfirmationDate
        calculateProcessDate
      }
    }
  }
}
    `;

/**
 * __usePublisherPublisherCalculatesQuery__
 *
 * To run a query within a React component, call `usePublisherPublisherCalculatesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublisherPublisherCalculatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublisherPublisherCalculatesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function usePublisherPublisherCalculatesQuery(baseOptions?: Apollo.QueryHookOptions<PublisherPublisherCalculatesQuery, PublisherPublisherCalculatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublisherPublisherCalculatesQuery, PublisherPublisherCalculatesQueryVariables>(PublisherPublisherCalculatesDocument, options);
      }
export function usePublisherPublisherCalculatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublisherPublisherCalculatesQuery, PublisherPublisherCalculatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublisherPublisherCalculatesQuery, PublisherPublisherCalculatesQueryVariables>(PublisherPublisherCalculatesDocument, options);
        }
export type PublisherPublisherCalculatesQueryHookResult = ReturnType<typeof usePublisherPublisherCalculatesQuery>;
export type PublisherPublisherCalculatesLazyQueryHookResult = ReturnType<typeof usePublisherPublisherCalculatesLazyQuery>;
export type PublisherPublisherCalculatesQueryResult = Apollo.QueryResult<PublisherPublisherCalculatesQuery, PublisherPublisherCalculatesQueryVariables>;
export const PublisherPublisherCalculateDocument = gql`
    query PublisherPublisherCalculate($publisherPublisherCalculateId: ID!) {
  publisherPublisherCalculate(id: $publisherPublisherCalculateId) {
    id
    state
    calculateDate
    monthBookUserCount
    monthBookCount
    monthlySales
    monthCalculate
    publisher {
      bookNumber
    }
  }
}
    `;

/**
 * __usePublisherPublisherCalculateQuery__
 *
 * To run a query within a React component, call `usePublisherPublisherCalculateQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublisherPublisherCalculateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublisherPublisherCalculateQuery({
 *   variables: {
 *      publisherPublisherCalculateId: // value for 'publisherPublisherCalculateId'
 *   },
 * });
 */
export function usePublisherPublisherCalculateQuery(baseOptions: Apollo.QueryHookOptions<PublisherPublisherCalculateQuery, PublisherPublisherCalculateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublisherPublisherCalculateQuery, PublisherPublisherCalculateQueryVariables>(PublisherPublisherCalculateDocument, options);
      }
export function usePublisherPublisherCalculateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublisherPublisherCalculateQuery, PublisherPublisherCalculateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublisherPublisherCalculateQuery, PublisherPublisherCalculateQueryVariables>(PublisherPublisherCalculateDocument, options);
        }
export type PublisherPublisherCalculateQueryHookResult = ReturnType<typeof usePublisherPublisherCalculateQuery>;
export type PublisherPublisherCalculateLazyQueryHookResult = ReturnType<typeof usePublisherPublisherCalculateLazyQuery>;
export type PublisherPublisherCalculateQueryResult = Apollo.QueryResult<PublisherPublisherCalculateQuery, PublisherPublisherCalculateQueryVariables>;
export const PublisherConfirmPublisherCalculateDocument = gql`
    mutation PublisherConfirmPublisherCalculate($publisherConfirmPublisherCalculateId: ID!) {
  publisherConfirmPublisherCalculate(id: $publisherConfirmPublisherCalculateId) {
    id
  }
}
    `;
export type PublisherConfirmPublisherCalculateMutationFn = Apollo.MutationFunction<PublisherConfirmPublisherCalculateMutation, PublisherConfirmPublisherCalculateMutationVariables>;

/**
 * __usePublisherConfirmPublisherCalculateMutation__
 *
 * To run a mutation, you first call `usePublisherConfirmPublisherCalculateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublisherConfirmPublisherCalculateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publisherConfirmPublisherCalculateMutation, { data, loading, error }] = usePublisherConfirmPublisherCalculateMutation({
 *   variables: {
 *      publisherConfirmPublisherCalculateId: // value for 'publisherConfirmPublisherCalculateId'
 *   },
 * });
 */
export function usePublisherConfirmPublisherCalculateMutation(baseOptions?: Apollo.MutationHookOptions<PublisherConfirmPublisherCalculateMutation, PublisherConfirmPublisherCalculateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PublisherConfirmPublisherCalculateMutation, PublisherConfirmPublisherCalculateMutationVariables>(PublisherConfirmPublisherCalculateDocument, options);
      }
export type PublisherConfirmPublisherCalculateMutationHookResult = ReturnType<typeof usePublisherConfirmPublisherCalculateMutation>;
export type PublisherConfirmPublisherCalculateMutationResult = Apollo.MutationResult<PublisherConfirmPublisherCalculateMutation>;
export type PublisherConfirmPublisherCalculateMutationOptions = Apollo.BaseMutationOptions<PublisherConfirmPublisherCalculateMutation, PublisherConfirmPublisherCalculateMutationVariables>;
export const DashboardDocument = gql`
    query Dashboard {
  publisherBookSalesDashboard {
    dailySalesVolume
    weeklySalesVolume
    monthlySalesVolume
    cumulativeSales
  }
  publisherPublisherCalculateDashboard {
    previousCalculateDate
    previousCalculateAmount
    nextCalculateDate
    nextCalculateAmount
  }
}
    `;

/**
 * __useDashboardQuery__
 *
 * To run a query within a React component, call `useDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardQuery({
 *   variables: {
 *   },
 * });
 */
export function useDashboardQuery(baseOptions?: Apollo.QueryHookOptions<DashboardQuery, DashboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardQuery, DashboardQueryVariables>(DashboardDocument, options);
      }
export function useDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardQuery, DashboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardQuery, DashboardQueryVariables>(DashboardDocument, options);
        }
export type DashboardQueryHookResult = ReturnType<typeof useDashboardQuery>;
export type DashboardLazyQueryHookResult = ReturnType<typeof useDashboardLazyQuery>;
export type DashboardQueryResult = Apollo.QueryResult<DashboardQuery, DashboardQueryVariables>;
export const RegistrationResultPanelDocument = gql`
    query RegistrationResultPanel {
  publisherBrands(first: 5) {
    edges {
      node {
        id
        name
        state
      }
    }
  }
  publisherBooks(first: 5) {
    edges {
      node {
        id
        name
        state
        brand {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useRegistrationResultPanelQuery__
 *
 * To run a query within a React component, call `useRegistrationResultPanelQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegistrationResultPanelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegistrationResultPanelQuery({
 *   variables: {
 *   },
 * });
 */
export function useRegistrationResultPanelQuery(baseOptions?: Apollo.QueryHookOptions<RegistrationResultPanelQuery, RegistrationResultPanelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RegistrationResultPanelQuery, RegistrationResultPanelQueryVariables>(RegistrationResultPanelDocument, options);
      }
export function useRegistrationResultPanelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RegistrationResultPanelQuery, RegistrationResultPanelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RegistrationResultPanelQuery, RegistrationResultPanelQueryVariables>(RegistrationResultPanelDocument, options);
        }
export type RegistrationResultPanelQueryHookResult = ReturnType<typeof useRegistrationResultPanelQuery>;
export type RegistrationResultPanelLazyQueryHookResult = ReturnType<typeof useRegistrationResultPanelLazyQuery>;
export type RegistrationResultPanelQueryResult = Apollo.QueryResult<RegistrationResultPanelQuery, RegistrationResultPanelQueryVariables>;
export const LoginDocument = gql`
    mutation Login($email: Email!, $password: Password!) {
  login: signInWithEmail(email: $email, password: $password) {
    accessToken
    refreshToken
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const AccountInfoDocument = gql`
    query AccountInfo {
  publisherMe {
    id
    bankBookFile {
      id
      filename
      mimetype
      url
    }
    bankName
    accountName
    accountNumber
    fee
  }
}
    `;

/**
 * __useAccountInfoQuery__
 *
 * To run a query within a React component, call `useAccountInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountInfoQuery(baseOptions?: Apollo.QueryHookOptions<AccountInfoQuery, AccountInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountInfoQuery, AccountInfoQueryVariables>(AccountInfoDocument, options);
      }
export function useAccountInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountInfoQuery, AccountInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountInfoQuery, AccountInfoQueryVariables>(AccountInfoDocument, options);
        }
export type AccountInfoQueryHookResult = ReturnType<typeof useAccountInfoQuery>;
export type AccountInfoLazyQueryHookResult = ReturnType<typeof useAccountInfoLazyQuery>;
export type AccountInfoQueryResult = Apollo.QueryResult<AccountInfoQuery, AccountInfoQueryVariables>;
export const ChangePublisherPasswordDocument = gql`
    mutation ChangePublisherPassword($oldPassword: Password!, $newPassword: Password!) {
  changePassword(oldPassword: $oldPassword, newPassword: $newPassword) {
    id
  }
}
    `;
export type ChangePublisherPasswordMutationFn = Apollo.MutationFunction<ChangePublisherPasswordMutation, ChangePublisherPasswordMutationVariables>;

/**
 * __useChangePublisherPasswordMutation__
 *
 * To run a mutation, you first call `useChangePublisherPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePublisherPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePublisherPasswordMutation, { data, loading, error }] = useChangePublisherPasswordMutation({
 *   variables: {
 *      oldPassword: // value for 'oldPassword'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useChangePublisherPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangePublisherPasswordMutation, ChangePublisherPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePublisherPasswordMutation, ChangePublisherPasswordMutationVariables>(ChangePublisherPasswordDocument, options);
      }
export type ChangePublisherPasswordMutationHookResult = ReturnType<typeof useChangePublisherPasswordMutation>;
export type ChangePublisherPasswordMutationResult = Apollo.MutationResult<ChangePublisherPasswordMutation>;
export type ChangePublisherPasswordMutationOptions = Apollo.BaseMutationOptions<ChangePublisherPasswordMutation, ChangePublisherPasswordMutationVariables>;
export const IsDuplicatePublisherEmailDocument = gql`
    mutation isDuplicatePublisherEmail($email: Email!) {
  isDuplicateEmail(email: $email)
}
    `;
export type IsDuplicatePublisherEmailMutationFn = Apollo.MutationFunction<IsDuplicatePublisherEmailMutation, IsDuplicatePublisherEmailMutationVariables>;

/**
 * __useIsDuplicatePublisherEmailMutation__
 *
 * To run a mutation, you first call `useIsDuplicatePublisherEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIsDuplicatePublisherEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [isDuplicatePublisherEmailMutation, { data, loading, error }] = useIsDuplicatePublisherEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useIsDuplicatePublisherEmailMutation(baseOptions?: Apollo.MutationHookOptions<IsDuplicatePublisherEmailMutation, IsDuplicatePublisherEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IsDuplicatePublisherEmailMutation, IsDuplicatePublisherEmailMutationVariables>(IsDuplicatePublisherEmailDocument, options);
      }
export type IsDuplicatePublisherEmailMutationHookResult = ReturnType<typeof useIsDuplicatePublisherEmailMutation>;
export type IsDuplicatePublisherEmailMutationResult = Apollo.MutationResult<IsDuplicatePublisherEmailMutation>;
export type IsDuplicatePublisherEmailMutationOptions = Apollo.BaseMutationOptions<IsDuplicatePublisherEmailMutation, IsDuplicatePublisherEmailMutationVariables>;
export const DeletePublisherMeDocument = gql`
    mutation DeletePublisherMe {
  deletePublisherMe {
    id
  }
}
    `;
export type DeletePublisherMeMutationFn = Apollo.MutationFunction<DeletePublisherMeMutation, DeletePublisherMeMutationVariables>;

/**
 * __useDeletePublisherMeMutation__
 *
 * To run a mutation, you first call `useDeletePublisherMeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePublisherMeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePublisherMeMutation, { data, loading, error }] = useDeletePublisherMeMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeletePublisherMeMutation(baseOptions?: Apollo.MutationHookOptions<DeletePublisherMeMutation, DeletePublisherMeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePublisherMeMutation, DeletePublisherMeMutationVariables>(DeletePublisherMeDocument, options);
      }
export type DeletePublisherMeMutationHookResult = ReturnType<typeof useDeletePublisherMeMutation>;
export type DeletePublisherMeMutationResult = Apollo.MutationResult<DeletePublisherMeMutation>;
export type DeletePublisherMeMutationOptions = Apollo.BaseMutationOptions<DeletePublisherMeMutation, DeletePublisherMeMutationVariables>;
export const PublisherExposingFaqsDocument = gql`
    query publisherExposingFaqs($filter: [PostFilterInput!], $sort: [PostOrderByInput!], $first: Int, $offset: Int) {
  publisherExposingFaqs(
    filter: $filter
    sort: $sort
    first: $first
    offset: $offset
  ) {
    totalCount
    edges {
      node {
        id
        title
        publishedDate
        attachment {
          id
          filename
          url
        }
        category {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __usePublisherExposingFaqsQuery__
 *
 * To run a query within a React component, call `usePublisherExposingFaqsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublisherExposingFaqsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublisherExposingFaqsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function usePublisherExposingFaqsQuery(baseOptions?: Apollo.QueryHookOptions<PublisherExposingFaqsQuery, PublisherExposingFaqsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublisherExposingFaqsQuery, PublisherExposingFaqsQueryVariables>(PublisherExposingFaqsDocument, options);
      }
export function usePublisherExposingFaqsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublisherExposingFaqsQuery, PublisherExposingFaqsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublisherExposingFaqsQuery, PublisherExposingFaqsQueryVariables>(PublisherExposingFaqsDocument, options);
        }
export type PublisherExposingFaqsQueryHookResult = ReturnType<typeof usePublisherExposingFaqsQuery>;
export type PublisherExposingFaqsLazyQueryHookResult = ReturnType<typeof usePublisherExposingFaqsLazyQuery>;
export type PublisherExposingFaqsQueryResult = Apollo.QueryResult<PublisherExposingFaqsQuery, PublisherExposingFaqsQueryVariables>;
export const PublisherExposingNoticesDocument = gql`
    query publisherExposingNotices($filter: [PostFilterInput!], $sort: [PostOrderByInput!], $first: Int, $offset: Int) {
  publisherExposingNotices(
    filter: $filter
    sort: $sort
    first: $first
    offset: $offset
  ) {
    totalCount
    edges {
      node {
        id
        title
        publishedDate
        expiredDate
        writer {
          id
          name
          email
          profileFile {
            url
          }
        }
      }
    }
  }
}
    `;

/**
 * __usePublisherExposingNoticesQuery__
 *
 * To run a query within a React component, call `usePublisherExposingNoticesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublisherExposingNoticesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublisherExposingNoticesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function usePublisherExposingNoticesQuery(baseOptions?: Apollo.QueryHookOptions<PublisherExposingNoticesQuery, PublisherExposingNoticesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublisherExposingNoticesQuery, PublisherExposingNoticesQueryVariables>(PublisherExposingNoticesDocument, options);
      }
export function usePublisherExposingNoticesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublisherExposingNoticesQuery, PublisherExposingNoticesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublisherExposingNoticesQuery, PublisherExposingNoticesQueryVariables>(PublisherExposingNoticesDocument, options);
        }
export type PublisherExposingNoticesQueryHookResult = ReturnType<typeof usePublisherExposingNoticesQuery>;
export type PublisherExposingNoticesLazyQueryHookResult = ReturnType<typeof usePublisherExposingNoticesLazyQuery>;
export type PublisherExposingNoticesQueryResult = Apollo.QueryResult<PublisherExposingNoticesQuery, PublisherExposingNoticesQueryVariables>;
export const PublisherFaqCategoriesDocument = gql`
    query publisherFaqCategories {
  publisherFaqCategories {
    id
    createdAt
    deletedAt
    updatedAt
    name
    children {
      id
      name
      children {
        id
        name
        children {
          id
          name
          children {
            id
            name
          }
        }
      }
    }
    priority
  }
}
    `;

/**
 * __usePublisherFaqCategoriesQuery__
 *
 * To run a query within a React component, call `usePublisherFaqCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublisherFaqCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublisherFaqCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePublisherFaqCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<PublisherFaqCategoriesQuery, PublisherFaqCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublisherFaqCategoriesQuery, PublisherFaqCategoriesQueryVariables>(PublisherFaqCategoriesDocument, options);
      }
export function usePublisherFaqCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublisherFaqCategoriesQuery, PublisherFaqCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublisherFaqCategoriesQuery, PublisherFaqCategoriesQueryVariables>(PublisherFaqCategoriesDocument, options);
        }
export type PublisherFaqCategoriesQueryHookResult = ReturnType<typeof usePublisherFaqCategoriesQuery>;
export type PublisherFaqCategoriesLazyQueryHookResult = ReturnType<typeof usePublisherFaqCategoriesLazyQuery>;
export type PublisherFaqCategoriesQueryResult = Apollo.QueryResult<PublisherFaqCategoriesQuery, PublisherFaqCategoriesQueryVariables>;
export const PublisherInfoDocument = gql`
    query PublisherInfo {
  publisherMe {
    id
    name
    representativeName
    representativeEmail
    representativePhone
    managerName
    managerEmail
    managerPhone
    businessRegistrationFile {
      id
      filename
      mimetype
      url
    }
    businessName
    businessRegistrationNumber
    policy
    createdAt
    lastLogin
    registrationState
    bankBookFile {
      id
      filename
      mimetype
      url
    }
    bankName
    accountName
    accountNumber
    fee
    memo
    user {
      id
      role
      profileFile {
        id
        filename
        mimetype
        url
      }
    }
  }
}
    `;

/**
 * __usePublisherInfoQuery__
 *
 * To run a query within a React component, call `usePublisherInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublisherInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublisherInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function usePublisherInfoQuery(baseOptions?: Apollo.QueryHookOptions<PublisherInfoQuery, PublisherInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublisherInfoQuery, PublisherInfoQueryVariables>(PublisherInfoDocument, options);
      }
export function usePublisherInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublisherInfoQuery, PublisherInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublisherInfoQuery, PublisherInfoQueryVariables>(PublisherInfoDocument, options);
        }
export type PublisherInfoQueryHookResult = ReturnType<typeof usePublisherInfoQuery>;
export type PublisherInfoLazyQueryHookResult = ReturnType<typeof usePublisherInfoLazyQuery>;
export type PublisherInfoQueryResult = Apollo.QueryResult<PublisherInfoQuery, PublisherInfoQueryVariables>;
export const PublisherPostDocument = gql`
    query PublisherPost($id: ID!) {
  publisherPost(id: $id) {
    id
    title
    content
    link
    publishedDate
    thumbnail {
      id
      filename
      url
    }
    attachment {
      id
      filename
      url
    }
    category {
      id
      name
    }
  }
}
    `;

/**
 * __usePublisherPostQuery__
 *
 * To run a query within a React component, call `usePublisherPostQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublisherPostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublisherPostQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePublisherPostQuery(baseOptions: Apollo.QueryHookOptions<PublisherPostQuery, PublisherPostQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublisherPostQuery, PublisherPostQueryVariables>(PublisherPostDocument, options);
      }
export function usePublisherPostLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublisherPostQuery, PublisherPostQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublisherPostQuery, PublisherPostQueryVariables>(PublisherPostDocument, options);
        }
export type PublisherPostQueryHookResult = ReturnType<typeof usePublisherPostQuery>;
export type PublisherPostLazyQueryHookResult = ReturnType<typeof usePublisherPostLazyQuery>;
export type PublisherPostQueryResult = Apollo.QueryResult<PublisherPostQuery, PublisherPostQueryVariables>;
export const UpdatePublisherMeDocument = gql`
    mutation UpdatePublisherMe($data: MemberPublisherUpdateInput!) {
  updatePublisherMe(data: $data) {
    id
  }
}
    `;
export type UpdatePublisherMeMutationFn = Apollo.MutationFunction<UpdatePublisherMeMutation, UpdatePublisherMeMutationVariables>;

/**
 * __useUpdatePublisherMeMutation__
 *
 * To run a mutation, you first call `useUpdatePublisherMeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePublisherMeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePublisherMeMutation, { data, loading, error }] = useUpdatePublisherMeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePublisherMeMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePublisherMeMutation, UpdatePublisherMeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePublisherMeMutation, UpdatePublisherMeMutationVariables>(UpdatePublisherMeDocument, options);
      }
export type UpdatePublisherMeMutationHookResult = ReturnType<typeof useUpdatePublisherMeMutation>;
export type UpdatePublisherMeMutationResult = Apollo.MutationResult<UpdatePublisherMeMutation>;
export type UpdatePublisherMeMutationOptions = Apollo.BaseMutationOptions<UpdatePublisherMeMutation, UpdatePublisherMeMutationVariables>;
export const RefreshTokenDocument = gql`
    mutation RefreshToken($refreshToken: String!) {
  reissueToken(refreshToken: $refreshToken) {
    accessToken
    refreshToken
  }
}
    `;
export type RefreshTokenMutationFn = Apollo.MutationFunction<RefreshTokenMutation, RefreshTokenMutationVariables>;

/**
 * __useRefreshTokenMutation__
 *
 * To run a mutation, you first call `useRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokenMutation, { data, loading, error }] = useRefreshTokenMutation({
 *   variables: {
 *      refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useRefreshTokenMutation(baseOptions?: Apollo.MutationHookOptions<RefreshTokenMutation, RefreshTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshTokenMutation, RefreshTokenMutationVariables>(RefreshTokenDocument, options);
      }
export type RefreshTokenMutationHookResult = ReturnType<typeof useRefreshTokenMutation>;
export type RefreshTokenMutationResult = Apollo.MutationResult<RefreshTokenMutation>;
export type RefreshTokenMutationOptions = Apollo.BaseMutationOptions<RefreshTokenMutation, RefreshTokenMutationVariables>;
export const OrderStatusDetailDocument = gql`
    query OrderStatusDetail($businessId: ID!) {
  publisherBusiness(id: $businessId) {
    id
    bookReview {
      bookgpa
      bookgpaContent
    }
    orderInfo {
      onePrice
      discountPrice
      discountRate
      totalOriginalPrice
      totalPrice
    }
    refundHistory {
      id
      cancelCompletionDate
      createdAt
    }
    book {
      id
      category {
        id
        name
      }
      name
      brand {
        id
        name
      }
      thumbnailFile {
        id
        filename
        url
      }
      sortField {
        gpaAvg
        gpaCount
        businessCount
      }
    }
    user {
      id
      email
    }
    state
    payment {
      id
      paidDate
      state
    }
  }
}
    `;

/**
 * __useOrderStatusDetailQuery__
 *
 * To run a query within a React component, call `useOrderStatusDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderStatusDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderStatusDetailQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useOrderStatusDetailQuery(baseOptions: Apollo.QueryHookOptions<OrderStatusDetailQuery, OrderStatusDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderStatusDetailQuery, OrderStatusDetailQueryVariables>(OrderStatusDetailDocument, options);
      }
export function useOrderStatusDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderStatusDetailQuery, OrderStatusDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderStatusDetailQuery, OrderStatusDetailQueryVariables>(OrderStatusDetailDocument, options);
        }
export type OrderStatusDetailQueryHookResult = ReturnType<typeof useOrderStatusDetailQuery>;
export type OrderStatusDetailLazyQueryHookResult = ReturnType<typeof useOrderStatusDetailLazyQuery>;
export type OrderStatusDetailQueryResult = Apollo.QueryResult<OrderStatusDetailQuery, OrderStatusDetailQueryVariables>;
export const OrderStatusDocument = gql`
    query OrderStatus($filter: [PublisherBusinessFilterInput!], $sort: [BusinessOrderByInput!], $first: Int, $offset: Int) {
  publisherBusinesses(
    filter: $filter
    sort: $sort
    first: $first
    offset: $offset
  ) {
    totalCount
    edges {
      node {
        id
        createdAt
        bookReview {
          bookgpa
          bookgpaContent
        }
        refundHistory {
          id
          cancelCompletionDate
          createdAt
        }
        orderInfo {
          onePrice
          discountPrice
          discountRate
          totalOriginalPrice
          totalPrice
        }
        state
        book {
          price
          category {
            id
            name
          }
          name
          id
          sortField {
            gpaAvg
            gpaCount
            businessCount
          }
          brand {
            id
            name
          }
        }
        user {
          id
          email
        }
        payment {
          id
          paidDate
        }
      }
    }
  }
}
    `;

/**
 * __useOrderStatusQuery__
 *
 * To run a query within a React component, call `useOrderStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderStatusQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useOrderStatusQuery(baseOptions?: Apollo.QueryHookOptions<OrderStatusQuery, OrderStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderStatusQuery, OrderStatusQueryVariables>(OrderStatusDocument, options);
      }
export function useOrderStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderStatusQuery, OrderStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderStatusQuery, OrderStatusQueryVariables>(OrderStatusDocument, options);
        }
export type OrderStatusQueryHookResult = ReturnType<typeof useOrderStatusQuery>;
export type OrderStatusLazyQueryHookResult = ReturnType<typeof useOrderStatusLazyQuery>;
export type OrderStatusQueryResult = Apollo.QueryResult<OrderStatusQuery, OrderStatusQueryVariables>;
export const SignUpPublisherDocument = gql`
    mutation SignUpPublisher($data: PublisherSignupInput!) {
  signUpPublisher(data: $data) {
    id
  }
}
    `;
export type SignUpPublisherMutationFn = Apollo.MutationFunction<SignUpPublisherMutation, SignUpPublisherMutationVariables>;

/**
 * __useSignUpPublisherMutation__
 *
 * To run a mutation, you first call `useSignUpPublisherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpPublisherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpPublisherMutation, { data, loading, error }] = useSignUpPublisherMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSignUpPublisherMutation(baseOptions?: Apollo.MutationHookOptions<SignUpPublisherMutation, SignUpPublisherMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignUpPublisherMutation, SignUpPublisherMutationVariables>(SignUpPublisherDocument, options);
      }
export type SignUpPublisherMutationHookResult = ReturnType<typeof useSignUpPublisherMutation>;
export type SignUpPublisherMutationResult = Apollo.MutationResult<SignUpPublisherMutation>;
export type SignUpPublisherMutationOptions = Apollo.BaseMutationOptions<SignUpPublisherMutation, SignUpPublisherMutationVariables>;